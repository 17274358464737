<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2 modal-content"
      id="report-found-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
      v-if="getAsset"
    >
      <div class="row" v-if="!isLoading">
        <h5
          class="modal-title text-center"
          v-html="
            $t('detailasset.re_found_asset', {
              assetId: getAsset.asset.id_fixed_asset,
            })
          "
        >
        </h5>
      </div>
      <label>{{ $t("asset.note") }}</label>
      <textarea class="comment-textarea w-100" v-model="note_lost"></textarea>
      <div class="row justify-content-center">
        <button
          type="button"
          class="btn btn-secondary col-6"
          @click="
            note_lost = '';

            $bvModal.hide('report-found-modal');

            $router.back();
          "
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          type="button"
          class="btn btn-primary col-6"
          @click="reportFound"
          :disabled="!note_lost"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <b-modal id="recount" centered hide-header hide-footer>
      <b-icon
        icon="exclamation-circle"
        class="d-block mx-auto my-3"
        style="width: 50px; height: 50px"
        variant="danger"
      ></b-icon>
      <div class="my-4">
        <p class="sub-label text-center">{{ $t("checkitem.is_count") }}</p>
        <label class="text-center d-block">{{
          $t("checkitem.is_count_sub")
        }}</label>
      </div>
      <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
        <button
          class="btn btn-edit mb-1 mb-sm-0"
          style="background: #c4c4c4"
          @click="goToScan"
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          class="btn btn-edit"
          @click="$bvModal.hide('recount'), (noNote = true)"
        >
          {{ $t("btn.conf") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal id="cannot-repair" centered hide-header hide-footer>
      <b-icon
        icon="exclamation-circle"
        class="d-block mx-auto my-3"
        style="width: 50px; height: 50px"
        variant="danger"
      ></b-icon>
      <div class="my-4">
        <p class="sub-label text-center">
          {{ $t("maintenance_report.cannot-repair-text") }}
        </p>
        <label class="text-center d-block">{{
          $t("checkitem.is_count_sub")
        }}</label>
      </div>
      <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
        <button
          class="btn btn-edit mb-1 mb-sm-0"
          style="background: #c4c4c4"
          @click="goToScan"
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          class="btn btn-edit"
          @click="$bvModal.hide('cannot-repair'), (noNote = true)"
        >
          {{ $t("btn.conf") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal
      id="repairing-alert"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <b-icon
        icon="exclamation-circle"
        class="d-block mx-auto my-3"
        style="width: 50px; height: 50px"
        variant="danger"
      ></b-icon>
      <div class="my-4">
        <p class="sub-label text-center">{{ $t("checkitem.repairing") }}</p>
      </div>
      <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
        <button
          class="btn btn-edit mb-1 mb-sm-0"
          style="background: #c4c4c4"
          @click="goToScan"
        >
          {{ $t("btn.close") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal
      id="writeoff-alert"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <b-icon
        icon="exclamation-circle"
        class="d-block mx-auto my-3"
        style="width: 50px; height: 50px"
        variant="danger"
      ></b-icon>
      <div class="my-4">
        <p class="sub-label text-center">{{ $t("checkitem.writeoff") }}</p>
      </div>
      <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
        <button
          class="btn btn-edit mb-1 mb-sm-0"
          style="background: #c4c4c4"
          @click="goToScan"
        >
          {{ $t("btn.close") }}
        </button>
      </b-row>
    </b-modal>
    <div class="responsive-bar" v-if="getAsset">
      <Footer></Footer>
      <div class="register">
        <!-- <Background></Background> -->
        <!-- <div v-if="prevRoute.name == 'EditDetailAsset'">
        <SuccessDialog
          :msg="$t('dialog.edit_done')"
          v-show="showSucces"
        ></SuccessDialog>
      </div> -->

        <!-- {{ getAsset.counting_item }} -->
        <div class="back-icon">
          <img src="../assets/previous.svg" width="30" @click="goToScan()" />
        </div>
        <h1 class="header-text">
          <div class="topic">{{ $t("checkitem.as_count") }}</div>
        </h1>
        <div class="" v-if="!isLoading">
          <!-- Custom VTN -->
          <div v-if="isCustom">
            <customCheckItemHeader
              :customid="permissionStatus.company.custom_id"
              :department="getAsset.asset.location_departments.name"
            ></customCheckItemHeader>
          </div>

          <div class="card-form-login">
            <div
              class="d-flex justify-content-evenly align-items-start"
              style="flex: 1"
            >
              <div class="d-flex flex-column image-show">
                <div class="frame-profile" v-if="this.first_img.url">
                  <img class="banner" :src="this.first_img.url" alt="" />
                </div>
                <!-- regis img -->
                <div v-if="this.first_img.url">
                  {{ $t("checkitem.img_reg") }}<br />{{ $t("count.date") }}
                  {{ this.img_date.regis_date }}
                </div>
              </div>
              <div class="d-flex flex-column image-show">
                <div class="frame-profile">
                  <img
                    v-if="this.current_img.url"
                    class="banner"
                    :src="this.current_img.url"
                    alt=""
                  />
                  <img
                    v-else-if="this.first_img.url"
                    class="banner"
                    :src="this.first_img.url"
                    alt=""
                  />
                  <div class="grey-bg" v-else>{{ $t("asset.img_not") }}</div>
                </div>
                <!-- current img -->

                <div v-if="this.current_img.url">
                  {{ $t("checkitem.img_new") }}<br />{{ $t("count.date") }}
                  {{ this.img_date.now_date }}
                </div>
                <div v-else-if="this.first_img.url">
                  {{ $t("checkitem.img_new") }}<br />{{ $t("count.date") }}
                  {{ this.img_date.regis_date }}
                </div>
              </div>
              <div class="d-flex flex-column image-show">
                <div
                  class="
                    frame-profile
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <div style="position: relative; width: 100%; height: 100%">
                    <img
                      v-if="data_form"
                      class="banner"
                      :src="data_form"
                      alt=""
                    />
                    <!-- <label class="plus-icon" v-if="!this.data_form">+</label>
                <div class="grey-bg" v-else></div> -->
                    <div
                      style="width: 100%; height: 100%"
                      class="d-flex align-items-center justify-content-center"
                      v-else
                    >
                      <img
                        style="width: 50px; height: 50px"
                        src="../assets/image.svg"
                        alt=""
                      />
                    </div>
                    <label
                      for="fusk"
                      style="
                        position: absolute;
                        bottom: -10px;
                        right: -10px;
                        border-radius: 100px;
                        background: white;
                      "
                    >
                      <img
                        src="../assets/pencil-round.svg"
                        style="cursor: pointer"
                      />
                    </label>
                  </div>
                </div>

                <input
                  id="fusk"
                  type="file"
                  name="photo"
                  accept="image/png, image/jpeg"
                  style="display: none; margin: 0px !important"
                  @change="onFileChange($event)"
                />

                <div>
                  {{ $t("checkitem.upimg") }}<br /><nobr>{{
                    $t("checkitem.recent")
                  }}</nobr>
                </div>
              </div>
            </div>

            <form>
              <div
                class="input-group"
                style="
                  display: flex;
                  justify-content: center;
                  padding: 0px;
                  margin-top: 10px;
                "
              >
                <label>{{ $t("asset.qr_id") }} :</label>
                <p
                  class="sub-label"
                  style="padding-left: 10px; margin-bottom: 0px"
                >
                  {{ getAsset.asset.qrcode.id_qrcode }}
                </p>
              </div>

              <div
                class="input-group"
                style="display: flex; justify-content: center; padding: 0px"
              >
                <label>{{ $t("asset.as_num") }}: </label>
                <p
                  class="sub-label"
                  style="padding-left: 10px; margin-bottom: 0px"
                >
                  {{ getAsset.asset.id_fixed_asset }}
                </p>
              </div>
              <hr />
              <div
                style="display: flex; justify-content: flex-end"
                v-if="!isRoleLower"
              >
                <img
                  class="edit-icon"
                  src="@/assets/pencil-square.svg"
                  @click="gotoEditDetail()"
                />
              </div>
              <div style="padding-left: 15px">
                <div class="data-group">
                  <label>{{ $t("asset.as_name") }}</label>
                  <br />
                  <p class="sub-label">{{ getAsset.asset.name }}</p>
                </div>
                <div class="data-group">
                  <label>{{ $t("asset.as_cat") }}</label>
                  <br />
                  <p class="sub-label">
                    {{
                      getAsset.asset.categories
                        ? isNull(getAsset.asset.categories.name)
                        : "-"
                    }}
                  </p>
                </div>
                <div class="data-group">
                  <label>{{ $t("asset.as_sub_cat") }}</label>
                  <br />
                  <p class="sub-label">
                    {{
                      getAsset.asset.sub_category
                        ? isNull(getAsset.asset.sub_category.name)
                        : "-"
                    }}
                  </p>
                </div>
                <div class="data-group" v-if="canUseCustom4">
                  <label>{{ $t("model.model") }}</label>
                  <br />
                  <p class="sub-label">
                    {{
                      getAsset.asset.sub_category2
                        ? isNull(getAsset.asset.sub_category2.name)
                        : "-"
                    }}
                  </p>
                </div>
                <div class="data-group">
                  <label>{{ $t("asset.as_dt") }}</label>
                  <br />
                  <p class="sub-label">
                    {{ isNull(getAsset.asset.description) }}
                  </p>
                </div>
                <div class="data-group">
                  <label>{{ $t("asset.as_dep") }}</label>
                  <br />
                  <p class="sub-label">
                    {{ $t("asset.build") }}
                    {{
                      getAsset.asset.location_buildings
                        ? isNull(getAsset.asset.location_buildings.name)
                        : "-"
                    }}
                    {{ $t("asset.fl") }}
                    {{
                      getAsset.asset.location_floors
                        ? isNull(getAsset.asset.location_floors.name)
                        : "-"
                    }}
                  </p>
                  <p class="sub-label">
                    {{ $t("asset.dep") }}
                    {{
                      getAsset.asset.location_departments
                        ? isNull(getAsset.asset.location_departments.name)
                        : "-"
                    }}
                    {{ $t("asset.zone") }}
                    {{
                      getAsset.asset.location_zones
                        ? isNull(getAsset.asset.location_zones.name)
                        : "-"
                    }}
                  </p>
                </div>
                <div class="data-group">
                  <label v-if="!getMapPosition.x && !getMapPosition.y"
                    >{{ $t("asset.as_loc") }} ({{ $t("asset.no_loc") }})</label
                  >
                  <label v-else>{{ $t("asset.as_loc") }}</label>
                  <br />
                  <image-map-display />
                </div>
                <br />
              </div>
              <div class="input-group input-group-custom">
                <customSubAsset></customSubAsset>
              </div>
              <div class="input-group input-group-custom">
                <p class="sub-label sub-label-grey">
                  {{ $t("checkitem.dt_as") }}
                </p>
                <div class="group-btn-status mt-2">
                  <p class="sub-label">{{ $t("checkitem.use_stat") }}</p>
                  <button
                    v-for="(asset_status, index) in status_list"
                    :key="index"
                    class="btn btn-status_counting"
                    :class="
                      asset_status === getAsset.counting_item.asset_status
                        ? asset_status + '_active'
                        : asset_status
                    "
                    @click="
                      (getAsset.counting_item.asset_status = asset_status),
                        noNoteCheck(asset_status)
                    "
                    type="button"
                  >
                    <img
                      :src="
                        activeIcon(
                          asset_status,
                          getAsset.counting_item.asset_status === asset_status
                        )
                      "
                      alt="icon"
                      width="13px"
                    />
                    {{ $t(status_map[asset_status]) }}
                  </button>
                </div>
              </div>
              <!-- <div class="input-group input-group-custom">
                <div class="group-btn-status">
                  <p class="sub-label">{{ $t("count.defect") }}</p>
                  <div class="flex-status-btn">
                    <button
                      class="btn btn-status_counting"
                      :class="{ active: active.active_3 }"
                      @click="changeStatusAsset(3)"
                      type="button"
                    >
                      {{ $t("count.wait_as") }}
                    </button>
                    <div>
                      <button
                        class="btn btn-status_counting"
                        :class="{ active: active.active_4 }"
                        @click="changeStatusAsset(4)"
                        type="button"
                      >
                        {{ $t("count.repar") }}

                      </button>
                      <button
                        class="btn btn-status_counting"
                        :class="{ active: active.active_5 }"
                        @click="changeStatusAsset(5)"
                        type="button"
                        id="last-btn"
                      >
                        {{ $t("count.irrepar") }}

                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <div
                class="input-group input-group-custom text-start"
                v-if="canUseCustom3"
              >
                <p class="sub-label">{{ $t("subasset.sub_asset_status") }}</p>
                <b-form-checkbox class="ps-2" v-model="useParentStatus">
                  <p class="px-2 sub-label">
                    {{ $t("subasset.use_parent_status") }}
                  </p>
                </b-form-checkbox>
              </div>
              <div class="input-group input-group-custom">
                <label
                  >{{ $t("count.note")
                  }}<span
                    style="color:red; padding-left:2px"
                    v-if="getAsset.counting_item.asset_status == 'broke'"
                    >*</span
                  ></label
                >
                <textarea
                  style="border: none"
                  v-model="verificationNote"
                ></textarea>
              </div>
              <!-- แจ้งซ่อม -->
              <div
                class="input-group input-group-custom"
                v-if="
                  getAsset.counting_item.asset_status == 'broke' &&
                    availablePackages.maintenance
                "
              >
                <label for="img" class="sub-label mt-3">{{
                  $t("maintenance_report.imageOp")
                }}</label>
                <div
                  class="mt-1"
                  style="color: #7C7C7C; font-size: 15px; text-align: start;"
                >
                  {{ $t("maintenance_report.imageDes") }}
                </div>
                <b-form-file
                  v-model="imagesFile"
                  id="img"
                  style="display: none;"
                  multiple
                  accept="image/*"
                  @change="onMaintainImageFileChange"
                ></b-form-file>
                <div class="image-show-maintain d-flex justify-content-start">
                  <div
                    v-for="(image, i) in maintainImg"
                    :key="i"
                    class="image-for mt-2"
                    :class="image ? '' : 'd-none'"
                  >
                    <img
                      class="icon"
                      src="@/assets/cancle.svg"
                      width="20px"
                      @click="deleteImageURL(i)"
                    />
                    <img :src="image" alt="image" class="image-upload" />
                  </div>

                  <div
                    v-for="(image, i) in images"
                    :key="i"
                    class="image-for mt-2"
                  >
                    <img
                      class="icon"
                      src="@/assets/cancle.svg"
                      width="20px"
                      @click="deleteImage(i)"
                    />
                    <img
                      :src="image.result"
                      alt="image"
                      class="image-upload "
                    />
                  </div>
                  <label class="mt-2" style="cursor: pointer;" for="img">
                    <img
                      src="@/assets/upload.svg"
                      alt="upload"
                      width="100px"
                      hight="100px"
                    />
                  </label>
                </div>
              </div>
              <div class="">
                <button
                  class="btn btn-size btn-quit "
                  type="button"
                  @click="goToScan()"
                >
                  {{ $t("maintenance_report.cancel") }}
                </button>
                <button
                  class="btn btn-size btn-login"
                  type="button"
                  @click="check"
                  :disabled="checkData"
                >
                  {{ $t("count.save") }}
                </button>
              </div>
            </form>
          </div>
          <div class="line-break"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Background from "@components/Background.vue";
import CustomQrName from "../util/CustomQrName.json";
import Swal from "sweetalert2";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";
import axios from "axios";
import moment from "moment";
import ImageMapDisplay from "@/components/ImageMapDisplay.vue";
import customCheckItemHeader from "@/components/CustomComponent/customCheckItemHeader.vue";
import customSubAsset from "../components/CustomComponent/SubAsset/customSubAsset.vue";
import { baseUrl, stage } from "../util/backend";
import base62 from "base62/lib/ascii";
import { authHeader } from "../store/actions";
// import SuccessDialog from "@/components/SuccessDialog.vue";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import available from "@/assets/check-available.svg";
import unavailable from "@/assets/check-unavailable.svg";
import broken from "@/assets/check-broke.svg";
import availableActive from "@/assets/check-available-active.svg";
import unavailableActive from "@/assets/check-unavailable-active.svg";
import brokenActive from "@/assets/check-broke-active.svg";
import Cookie from "js-cookie";

const webStage = stage();
const isProductionStage = webStage === "pre-prod" || webStage === "prod";

export default {
  name: "CheckItem",
  data() {
    return {
      apiFail: false,
      availablePackages: {},
      isUpdateAssetImage: false,
      maintain_detail: null,
      isError: false,
      initialStatus: "",
      maintainURL: [],
      noNote: false,
      available,
      unavailable,
      broken,
      availableActive,
      unavailableActive,
      brokenActive,
      maintainImg: [],
      initImgLenght: 0,
      images: [],
      imagesFile: [],
      company_id: null,
      waitConfirm: false,
      current_img: "",
      first_img: "",
      verificationNote: "",
      img_date: {
        now_date: "",
        regis_date: "",
      },
      active: {
        active_1: false,
        active_2: false,
        active_3: false,
        active_4: false,
        active_5: false,
      },
      isLoading: false,
      isDataLoad: false,
      fullPage: true,
      data_form: "",
      pictureFile: "",
      note: "",
      note_lost: "",
      status_asset: "",
      damaged_status: "",
      qrcode_id: "",
      showSucces: true,
      assetsPicture: "",
      url: "",
      all_asset: {
        name: "เก้าอี้",
        code: "FA-22444",
        category: "เครื่องใช้สำนักงาน",
        detail: "เก้าอี้สีดำ มีพนักพิง ตัวใหญ่",
        status: "ยังไม่ได้ตรวจนับ",
        comment: "-",
        building: "อาคาร A ชั้น 1",
        department: "บัญชี",
        zone: "C",
        date_check: "20/07/2563",
        user_regis: "นายสมชาย ทองมี",
        user_check: "นายสมชาย ทองมี",
        custom_status: "",
      },
      user: "",
      page: "",
      prevRoute: null,
      status_list: ["available", "unavailable", "broke"],
      status_map: {
        available: "count.inuse",
        unavailable: "count.not_inuse",
        broke: "count.defect",
      },
      // ------------------- START Custom 3 SubAsset ------------------------
      useParentStatus: false,
      // ------------------- END Custom 3 SubAsset ------------------------
    };
  },
  components: {
    Footer,
    Loading,
    ImageMapDisplay,
    customCheckItemHeader,
    customSubAsset,
    // SuccessDialog
  },
  computed: {
    ...mapGetters({
      getAsset: "getAsset",
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      isRoleLower: "isRoleLower",
      getMapPosition: "getMapPosition",
      canUseCustom3: "canUseCustom3",
      canUseCustom4: "canUseCustom4",
    }),

    checkData() {
      return (this.data_form == "" || this.getAsset.counting_item.asset_status == null) 
      || (this.availablePackages.maintenance && this.getAsset.counting_item.asset_status == "broke" && this.noNote && this.images.length == 0 && this.maintainImg.length == this.initImgLenght )
      ;
    },

    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    getAsset() {
      this.isDataLoad = true;
    },
    verificationNote(value) {
      if (
        this.getAsset.counting_item.asset_status === "broke" &&
        this.availablePackages.maintenance
      ) {
        if (value) {
          this.noNote = false;
        } else {
          this.noNote = true;
        }
      }
    },
  },
  methods: {
    noNoteCheck(a) {
      if (this.availablePackages.maintenance) {
        this.verificationNote = "";
        this.images = [];
        if (a == "broke") {
          this.noNote = true;
        } else {
          this.noNote = false;
        }
      }
    },
    activeIcon(status, isActive) {
      if (status === "available" && isActive) {
        return this.availableActive;
      } else if (status === "available") {
        return this.available;
      } else if (status === "unavailable" && isActive) {
        return this.unavailableActive;
      } else if (status === "unavailable") {
        return this.unavailable;
      } else if (status === "broke" && isActive) {
        return this.brokenActive;
      } else if (status === "broke") {
        return this.broken;
      }
    },
    onMaintainImageFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (let i = 0; i < files.length; i++) {
        this.createImage(files[i]);
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images.push({ file, result: e.target.result });
      };
      reader.readAsDataURL(file);
      // console.log(this.images);
    },
    deleteImage(imageIndex) {
      this.images.splice(imageIndex, 1);
      // console.log(this.imagesFile);
      // this.imageFile.splice(imageIndex, 1);
    },
    deleteImageURL(imageIndex) {
      this.maintainURL.splice(imageIndex, 1);
      this.maintainImg.splice(imageIndex, 1);
      // console.log(this.maintainURL);
    },
    isNull(data) {
      return data ? data : "-";
    },
    goToScan() {
      this.$router.replace({ name: "ScanQr" });
    },
    gotoEditDetail() {
      this.$router.push("/editDetailAsset/" + this.getAsset.asset.id_asset);
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    async upload() {
      this.isLoading = true;
      if (!this.isUpdateAssetImage) {
        for (const img of this.images) {
          const fileforupload = await this.compressImage(img.file);
          let file = new File([fileforupload], img.file.name);
          let formdata = new FormData();
          formdata.append("folder_id", 2);
          formdata.append("file", file);
          const res = await this.$store.dispatch("uploadImage", formdata);
          this.maintainURL.push(res.data.data.uploadFileAtLevel.url);
        }
        // console.log("maintainURL", this.maintainURL);
        this.isLoading = false;
      } else {
        const fileforupload = await this.compressImage(this.pictureFile);
        let file = new File([fileforupload], this.pictureFile.name, {
          type: "image/jpeg",
        });
        const id_company = this.getUserDetail.id_company;
        let formdata = new FormData();
        formdata.append("folder_id", 2);
        formdata.append("file", file);
        const res = await this.$store.dispatch("uploadImage", formdata);
        this.isLoading = false;
        this.url_new = res.data.data.uploadFileAtLevel.url;
        this.data_form = this.url_new;
      }
    },
    async reportFound() {
      this.$bvModal.hide("report-found-modal");
      this.isLoading = true;
      let asset = {
        id_asset: this.getAsset.asset.id_asset,
        is_lost: "report_found",
        note_lost: this.note_lost,
      };
      await this.$store.dispatch("ChangeLostStatus", asset);
      Swal.fire({
        icon: "success",
        title: this.$t("dialog.success"),
        showConfirmButton: false,
        timer: 3000,
      });
      this.isLoading = false;
      this.$router.back();
    },

    async onFileChange(e) {
      const file = e.target.files[0];
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.getAsset.asset.image_url = URL.createObjectURL(file);
      this.pictureFile = file;
      this.isUpdateAssetImage = true;
      this.upload();
      this.isUpdateAssetImage = false;
    },
    changeData() {
      this.status_asset = this.getAsset.counting_item.status;
    },
    async check() {
      let status;
      if (this.$i18n.locale == "en") {
        switch (this.getAsset.counting_item.custom_status[0]) {
          case "usable":
            status = "ใช้งานได้";
            break;

          default:
            break;
        }
      }
      await this.updateStatusAvailable();
    },

    async updateStatusAvailable() {
      if (this.useParentStatus) {
        try {
          const { data } = await axios.get(
            `${baseUrl()}asset/get/subAsset/${this.company_id}?id_asset=${
              this.getAsset.asset.id_asset
            }`,
            authHeader()
          );
          const allSubAssets = data.data;

          if (allSubAssets.length === 0) {
            const data = {
              all_data: this.getAsset,
              inspector: this.getUserDetail.name,
              asset_status: this.getAsset.counting_item.asset_status,
              note: this.verificationNote,
              data_form: this.data_form,
            };
            // console.log("data", data);
            this.isLoading = true;
            await this.updateCheckAsset(data);
            if (this.apiFail) {
              this.modalFail();
            } else {
              this.isLoading = false;
              this.modalSuccess();
              // this.$router.replace("/scan");
            }
          } else {
            const countingGroup = [];
            const customData = {
              ...this.getAsset.asset,
              inspector: this.getUserDetail.name,
              asset_status: this.getAsset.counting_item.asset_status,
              note: this.verificationNote,
              data_form: this.data_form,
              id_counting_item: this.getAsset.counting_item.id_counting_item,
            };
            countingGroup.push(customData);
            for (const item of allSubAssets) {
              if (item.qrcode) {
                countingGroup.push({
                  ...item,
                  inspector: this.getUserDetail.name,
                  asset_status: this.getAsset.counting_item.asset_status,
                  note: this.verificationNote,
                  data_form: item.image_url.url,
                  id_counting_item: item.counting_items[0].id_counting_item,
                });
              }
            }
            this.isLoading = true;
            try {
              await axios.post(
                `${baseUrl()}counting/updateSubCountingItem`,
                { data: countingGroup },
                authHeader()
              );
              this.isLoading = false;
              this.$router.replace("/scan");
            } catch (err) {
              console.log(err);
            }
          }
        } catch (err) {
          if (err.response) {
            alert(err.response.data.message);
          } else {
            alert(err.message || err);
          }
        }
      } else {
        await this.upload();
        const data = {
          all_data: this.getAsset,
          inspector: this.getUserDetail.name,
          asset_status: this.getAsset.counting_item.asset_status,
          note: this.verificationNote,
          data_form: this.data_form,
        };
        if (this.availablePackages.maintenance) {
          data.maintain_detail = {
            id_company: this.permissionStatus.company.id_company,
            type: "common",
            asset_img: this.maintainURL,
            description: this.verificationNote,
          };
          console.log(this.maintain_detail);
        }
        this.$store.commit("setAlertTrue", true);
        try {
          this.isLoading = true;
          await this.updateCheckAsset(data);
        } catch (err) {
          alert(err);
          this.apiFail = true;
        }

        if (this.apiFail) {
          this.modalFail();
          1;
        } else {
          this.isLoading = false;
          this.modalSuccess();
          // this.$router.replace("/scan");
        }
      }
    },

    async updateCheckAsset(data) {
      try {
        await axios.post(
          `${baseUrl()}counting/updateCountingItem`,
          data,
          authHeader()
        );
      } catch (err) {
        this.apiFail = true;
        this.modalFail();
        console.log(err);
      }
    },

    modalSuccess() {
      Swal.fire({ icon: "success", title: "ตรวจนับสำเร็จ", timer: 3000 }).then(
        () => {
          this.$router.replace({ name: "ScanQr" });
        }
      );
    },
    modalFail() {
      Swal.fire({ icon: "error", title: "ตรวจนับไม่สำเร็จ" }).then(() => {
        // this.checkAsset();
        // this.$router.replace({ name: "ScanQr" });
      });
    },

    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 2500);
    },

    async checkAsset() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.page = this.$route.params.page;
      const type = +this.$route.query.type;
      if (type === 2) {
        if (this.$route.params.id.slice(0, 1) == 0) {
          if (id_company === 323 && isProductionStage) {
            const firstTwoChars = this.$route.params.id.slice(0, 2);
            const removeFirst2 = this.$route.params.id.substring(2);
            const decodedNumber = base62.decode(removeFirst2);
            const paddedNumber = String(decodedNumber).padStart(6, "0");
            const customName = CustomQrName[firstTwoChars];
            if (customName) {
              this.qrcode_id = `${CustomQrName[firstTwoChars]}${paddedNumber}`;
            }
          }
        } else {
          if ((id_company === 52 || id_company === 212) && isProductionStage) {
            const decodedNumber = base62.decode(this.$route.params.id);
            const paddedNumber = String(decodedNumber).padStart(7, "0");
            this.qrcode_id = `VTN${paddedNumber}`;
          } else {
            const decodedNumber = base62.decode(this.$route.params.id);
            const paddedNumber = String(decodedNumber).padStart(11, "0");
            const result = String(paddedNumber)
              .splice(3, 0, "-")
              .splice(8, 0, "-");
            this.qrcode_id = result;
          }
        }
      } else {
        this.qrcode_id = this.$route.params.id;
      }
      if (this.page === "registerNow") {
        this.isLoading = true;
        const res = await this.$store.dispatch(
          "checkAssetExisted",
          this.qrcode_id
        );
        this.isLoading = false;
        if (res) {
          await Swal.fire({
            icon: "error",
            text: this.$t("dialog.qrcode_used"),
            showConfirmutton: false,
            timer: 2000,
          });
          let id_asset = localStorage.getItem("id_asset");
          this.$router.replace({ path: `/detailOneAsset/${id_asset}` });
        }
        return;
      } else if (this.page === "changeQR") {
        this.waitConfirm = true;
        const result = await Swal.fire({
          title: this.$t("asset.change_QR"),
          html: this.$t("asset.change_QR_to_QR", {
            oldQR: this.$route.query.qr,
            newQR: this.qrcode_id,
          }),
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          cancelButtonText: this.$t("btn.canc"),
          buttonsStyling: false,
          customClass: {
            cancelButton: "order-1 swal-cancel-btn",
            confirmButton: "order-2 swal-confirm-btn",
          },
        });
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            const res = await axios.put(
              `${baseUrl()}qrcode/update/qrcode`,
              {
                id_qrcode: this.qrcode_id,
                id_asset: this.$route.query.id,
              },
              authHeader()
            );
            Swal.fire({
              icon: "success",
              title:
                this.$t("asset.change_QR") + " " + this.$t("dialog.success"),
              showConfirmButton: false,
              timer: 2000,
            });
            this.isLoading = false;
            this.$router.replace({
              path: `/detailOneAsset/${this.$route.query.id}`,
            });
          } catch (err) {
            if (err.response) {
              if (err.response.data.message === "This qrcode is invalid") {
                Swal.fire({
                  icon: "error",
                  title: this.$t("dialog.wrong_qr"),
                  text: this.$t("dialog.wrong_qr_dt"),
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: this.$t("dialog.qrcode_used"),
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
            this.$router.push({
              path: `/detailOneAsset/${this.$route.query.id}`,
            });
          }
          this.isLoading = false;
        } else {
          this.$router.push({
            path: `/detailOneAsset/${this.$route.query.id}`,
          });
        }
        this.waitConfirm = false;
      } else {
        this.isLoading = true;
        const res = await this.$store.dispatch("getAsset", this.qrcode_id);
        if (res === "have asset not in counting" || !res) {
          return;
        }
        if (res === "already writeoff") {
          this.isLoading = false;
          this.$bvModal.show("writeoff-alert");
          return;
        }
        this.$store.dispatch(
          "setMapUrl",
          this.getAsset.asset.location_floors?.image_url || ""
        );
        if (this.getAsset) {
          if (this.availablePackages.maintenance) {
            //have package
            if (this.getAsset.counting_item.is_lost === "report_found") {
              await Swal.fire(this.$t("dialog.asset_found_reporting"));
              this.$router.replace({ name: "ScanQr" });
            } else if (this.getAsset.counting_item.is_lost === "lost") {
              this.$bvModal.show("report-found-modal");
            } else if (
              this.getAsset.counting_item.asset_status === "maintain"
            ) {
              this.$bvModal.show("repairing-alert");
            } else if (
              this.getAsset.counting_item.asset_status === "cannot_repair"
            ) {
              this.$bvModal.show("cannot-repair");
            } else if (this.getAsset.counting_item.asset_status !== null) {
              this.$bvModal.show("recount");
            }
          } else {
            if (this.getAsset.counting_item.is_lost === "report_found") {
              await Swal.fire(this.$t("dialog.asset_found_reporting"));
              this.$router.replace({ name: "ScanQr" });
            } else if (this.getAsset.counting_item.is_lost === "lost") {
              this.$bvModal.show("report-found-modal");
            } else if (this.getAsset.counting_item.asset_status) {
              if (
                this.getAsset.counting_item.asset_status === "broke" ||
                this.getAsset.counting_item.asset_status === "maintain" ||
                this.getAsset.counting_item.asset_status === "cannot_repair"
              ) {
                this.getAsset.counting_item.asset_status = "broke";
              }
              this.$bvModal.show("recount");
            }
          }
          // (
          //   this.getAsset.counting_item.status &&
          //   this.getAsset.counting_item.status !== "อยู่ระหว่างการซ่อม"
          // ) {
          //   this.$bvModal.show("recount");
          // } else if (
          //   this.getAsset.counting_item.status === "อยู่ระหว่างการซ่อม"
          // ) {
          //   this.$bvModal.show("repairing-alert");
          // }
        }

        this.first_img =
          typeof this.getAsset.asset.first_img_url === "string"
            ? JSON.parse(this.getAsset.asset.first_img_url)
            : this.getAsset.asset.first_img_url;
        this.current_img =
          typeof this.getAsset.asset.image_url === "string"
            ? JSON.parse(this.getAsset.asset.image_url)
            : this.getAsset.asset.image_url;
        this.img_date.regis_date = moment(this.first_img.date).format(
          "DD MMM YYYY HH:mm"
        );
        this.img_date.now_date = moment(this.current_img.date).format(
          "DD MMM YYYY HH:mm"
        );
        this.hide();
        this.isLoading = false;
      }
    },
  },

  async created() {
    let getCookie = Cookie.get("selectedcompany");
    if (getCookie) {
      let companyData = JSON.parse(getCookie);
      this.availablePackages = companyData.package;
    }
    ``;
    await this.checkAsset();
    this.initialStatus = this.getAsset.counting_item.asset_status;
    this.company_id = await this.$store.dispatch("getCompanyIdCookie");

    if (
      this.getAsset.counting_item.asset_status === "broke" &&
      this.availablePackages.maintenance
    ) {
      this.noNote = true;
      this.maintain_detail = this.getAsset.asset.maintain_detail;
      this.verificationNote = this.maintain_detail.description;
      this.maintainImg = this.maintain_detail.asset_img;
      this.initImgLenght = this.maintainImg.length
      this.maintain_detail.asset_img.forEach((element) => {
        this.maintainURL.push(element);
      });
    }
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.lightblue {
  padding: 0.5rem;
  background-color: #d6e7ff;
}
.image-show {
  width: calc(95% / 3);
}
.btn-regist {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
.card-form-login {
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    // margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.history-img {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 1rem;

  .history-img-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 5px;
  }

  .frame-asset {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: #b6b6b6;
    width: 90%;
    height: 70px;
    background: white;
    border-radius: 10px;
    border: 4px solid rgb(255, 255, 255);
    box-shadow: 0px 1px 6px #00000029;
  }

  .frame-bg-none {
    background: white;
  }
}

.flex-status-btn {
  display: flex;

  flex-wrap: wrap;
}

.grid-label {
  grid-template-columns: 1fr 1fr;
}

.group-btn-status {
  text-align: left;
}

.btn-status_counting {
  margin: 2px;
  font: normal normal normal 18px/21px Mitr;
  // padding: 1rem;
  height: 3rem;
  color: $font-grey;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 7px;
  opacity: 1;
  margin-right: 0.4rem;
  background: white; /* background: $color-pastel-blue !important; */
  &_active {
    color: white;
    background: #007afe;
  }
  &.available {
    border: #00ab1b solid 2px;
    color: #00ab1b;
  }
  &.available_active {
    background-color: #00ab1b;
    color: white;
  }
  &.unavailable {
    border: #01b1fd solid 2px;
    color: #01b1fd;
  }
  &.unavailable_active {
    background-color: #01b1fd;
    color: white;
  }
  &.broke {
    border: #ff8a00 solid 2px;
    color: #ff8a00;
  }
  &.broke_active {
    background-color: #ff8a00;
    color: white;
  }
}
.active {
  color: white;
  background: #007afe;
}
.sub-label-grey {
  color: $font-grey;
}
.input-group {
  grid-template-columns: 0.5fr 1fr;
  gap: 0 10px;
}
.data-group {
  grid-template-columns: 1fr 1fr;
  text-align: left;
  margin-bottom: 10px;
}

.data-group label {
  justify-self: start;
  padding-bottom: 0.3rem;
  margin-bottom: 0 !important;
  color: #7c7c7c;
}

@media only screen and (max-width: 409px) {
  .btn-status_counting {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .input-group {
    grid-template-columns: 0.7fr 1fr;
    gap: 0 0px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 320px) {
  .input-group {
    grid-template-columns: 1fr 1fr;
    gap: 0 0px;
  }
}

.input-group-custom {
  grid-template-columns: 1fr;
}

.form-check {
  text-align: left;
}
.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  height: 40vh;
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;
}

.modal-content label {
  color: #4b4b4b;
  padding: 0.4rem;
}
.btn-login {
  // margin-top: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;

  &:disabled {
    background: transparent linear-gradient(180deg, #dedede 0%, #dedede 100%) 0%
      0% no-repeat padding-box;
  }
}

.indoor-map {
  width: 100%;
  height: 10rem;
  background: rgb(225, 225, 225);
  border-radius: 0.75rem;
}

.frame-profile {
  // position: absolute;
  // top: -0.2rem;
  left: 50%;
  right: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  /* text-align: center; */
  margin: auto;
  background: rgb(223, 223, 223);
  border-radius: 10%;
  z-index: 90;
  // border: 5px solid white;
  // box-shadow: 0px 3px 6px #00000029;
}

.banner-profile {
  height: 104px;
  width: 104px;
  border-radius: 10px;
  z-index: 10;
}
.banner {
  height: 100%;
  width: 100%;
  border-radius: 10%;
  z-index: 1;
  object-fit: cover;
}
.register {
  z-index: 10;
}
input,
select,
textarea {
  border-radius: 10px !important;
  // border: 1px solid grey;
  background-color: #e0e0e0;
  padding: 0.4rem;
}

.btn-size {
  margin: 2px;
  height: 4rem;
}
.image-upload {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.image-show-maintain {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 6px;
}
.icon {
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}
.image-for {
  position: relative;
}
// .btn-asset {
//   margin-top: 1rem;
//   margin-bottom: 1rem;
//   &-custom {
//     line-height: 1.3;
//     border: 2px solid var(--unnamed-color-0053ad);
//     border: 2px solid #0053ad;
//     border-radius: 12px;
//     font: normal normal normal 18px/20px Mitr;
//     margin-bottom: 1rem;
//     color: black;
//     background: white;
//   }
// }
.header-text {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.sub-label {
  line-height: 1.3;
  font-size: 18px;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .btn-size {
    // margin: 2px;
    // font: normal normal normal 14px Mitr;
    // padding: 1rem;
    font-size: 13px !important;
    height: 3rem;
  }
  .btn-status_counting {
    // margin: 2px;
    font: normal normal normal 13px/17px Mitr;
  }
}

.plus-icon {
  color: #ffffff !important;
  font-size: 46px;
}

@media only screen and (max-width: 700px) {
  .frame-profile {
    width: 80px;
    height: 80px;
  }

  .image-show {
    font-size: 12px;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}
.edit-icon {
  cursor: pointer;
}
.labeltext {
  margin-left: calc(30% / 4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (min-width: 426px) {
  .labeltext {
    display: flex;
    align-items: center;
  }
}

.btn-primary {
  width: 40%;
  margin-left: 1rem;
}
.btn-secondary {
  background: $font-grey;
  width: 40%;
}
.btn-quit {
  background: $font-grey 0% 0% no-repeat padding-box;
  border-radius: 10px;
  // padding-left: 40px;
  // padding-right: 40px;
}
</style>
