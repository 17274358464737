import Reject from "@/assets/reject.svg";
import Cancel from "@/assets/cancle.svg";
import Reset from "@/assets/reset.svg";

export const state = {
  notification: {},
  statusFromDashboard: "all",
  dashboardData: {},
  statusSummary: {},
  maintenanceRole: "user",
  confirmModalSuccess: false,
  manageOptions: [
    {
      value: "assign",
      text: "test",
      subText: "",
      placeHolder: "",
      success: "maintenance_report.assign-success",
      icon: Reject,
    },
    {
      value: "download",
      text: "download",
      subText: "",
      placeHolder: "download",
      icon: Reject,
    },
    {
      value: "reject",
      text: "maintenance_report.reject-text",
      subText: "",
      placeHolder: "maintenance_report.reject-placeholder",
      success: "maintenance_report.reject-success",
      icon: Reject,
    },
    {
      value: "cancel",
      text: "maintenance_report.cancel-text",
      subText: "maintenance_report.cancel-subtext",
      placeHolder: "maintenance_report.cancel-placeholder",
      success: "maintenance_report.cancel-success",
      icon: Cancel,
    },
    {
      value: "reset",
      text: "maintenance_report.reset-text",
      subText: "maintenance_report.reset-subtext",
      placeHolder: "maintenance_report.reset-placeholder",
      success: "maintenance_report.reset-success",
      icon: Reset,
    },
  ],
};
