import { render, staticRenderFns } from "./EditDetailAsset.vue?vue&type=template&id=55a7459e&scoped=true&"
import script from "./EditDetailAsset.vue?vue&type=script&lang=js&"
export * from "./EditDetailAsset.vue?vue&type=script&lang=js&"
import style0 from "./EditDetailAsset.vue?vue&type=style&index=0&id=55a7459e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a7459e",
  null
  
)

export default component.exports