<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <template v-if="canUseCustom1 || canUseCustom5">
      <!-- Import Check Dialog -->
      <BaseDialog
        :showModal="importTableModal"
        size="XL"
        reverseButton
        @cancel-action="cancelImportCheck"
        @confirm-action="confirmImportCheck"
      >
        <div class="div-export-h">
          <a class="text-export-h">{{ $t("docregas.sum_im") }}</a>

          <p class="mt-3" v-if="id_company !== 196">
            {{ $t("docregas.all_imas") }}
            <!-- {{ getdataimport.result ? getdataimport.result.total : null }} -->
          </p>
        </div>
        <div
          class="justify-content-center text-center mt-5"
          style="padding-top: 35px"
        ></div>
        <div class="div-export-table">
          <AssetTable
            :dataTable="importedData.all"
            :otherColumn="importedData.otherColumn"
            type="TMP"
          />
          <p style="color: red; text-align: left">
            {{ $t("docregas.replace_dt") }}
          </p>
        </div>
      </BaseDialog>
      <!-- Show new dup incorrect Dialog -->
      <BaseDialog
        :showModal="importTagTableModal"
        size="XL"
        reverseButton
        :hideConfirm="
          importedData.result
            ? importedData.result.wrongData > 0 || importedData.result.error > 0
            : true
        "
        @confirm-action="confirmReplace"
        @cancel-action="cancelReplace"
      >
        <div class="div-export-h">
          <a class="text-export-h">{{ $t("asset.conf_im") }}</a>
          <p class="mt-3">
            {{ importedData.result ? importedData.result.change : null }}
            {{ $t("asset.as_fixdt") }}
          </p>
        </div>
        <div
          class="justify-content-center text-center mt-5"
          style="padding-top: 35px"
        >
          <div class="div-export-table">
            <!-- Ag Grid ข้อมูลมีซ้ำ -->
            <AssetTable
              :tag="true"
              :dataTable="importedData.dataNotDuplicate"
              :otherColumn="importedData.otherColumn"
              type="TMP"
            />
            <p style="color: red; text-align: left">
              {{ $t("asset.replace_dt") }}
            </p>
            <p style="color: red; text-align: left" v-if="canUseCustom5">
              *กรณีที่ชื่อ หมวดหมู่, หมวดหมู่ย่อย, BU, สาขา, ชั้น
              ไม่ตรงกับข้อมูลในระบบจะไม่สามารถนำเข้าข้อมูลได้
            </p>
          </div>
        </div>
      </BaseDialog>
      <b-modal
        class="p-2"
        id="replace-modal"
        hide-header-close
        hide-header
        hide-footer
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('replace-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <h4 class="text-center pb-3">{{ $t("asset.replace_steps_header") }}</h4>
        <div class="mx-auto" style="width: fit-content;">
          <div
            class="procedure-step"
            v-for="(step, index) in $t('asset.replace_steps')"
            :key="index"
          >
            <div class="number">
              <p class="m-0">{{ index + 1 }}</p>
            </div>
            <p>{{ step }}</p>
            <u v-if="index === 0" class="download" @click="onExport"
              ><nobr>{{ $t("asset.here") }}</nobr></u
            >
          </div>
        </div>
        <b-row align-h="center"
          ><button class="p-2 replace-btn" @click="$refs.fileInput.click()">
            <div class="d-flex align-items-center justify-content-evenly">
              <img
                src="@/assets/replace.svg"
                style="margin-right: 5px"
                alt="replace-icon"
              />
              {{ $t("btn.import_replace") }}
            </div>
          </button>
          <input
            id="fusk"
            type="file"
            ref="fileInput"
            style="display: none;"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="importCheckTmp($event)"
          />
        </b-row>
      </b-modal>
      <button class="p-2 replace-btn" @click="$bvModal.show('replace-modal')">
        <div class="d-flex align-items-center justify-content-evenly">
          <img
            src="@/assets/replace.svg"
            style="margin-right: 5px"
            alt="replace-icon"
          />
          <nobr>{{ $t("btn.replace_tmp") }}</nobr>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import { exportUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import BaseDialog from "../BaseDialog.vue";
import AssetTable from "../Table/AssetTable.vue";
export default {
  components: { Loading, BaseDialog, AssetTable },
  data() {
    return {
      isLoading: false,
      id_company: null,
      importTableModal: false,
      importTagTableModal: false,
      importedData: {},
    };
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      canUseCustom1: "canUseCustom1",
      canUseCustom5: "canUseCustom5",
    }),
  },

  methods: {
    async onExport() {
      this.isLoading = true;
      try {
        const res = await axios.post(
          `${exportUrl()}admin/export/excel/replace/temp/${this.id_company}`,
          { is_email: false },
          { ...authHeader(), responseType: "blob" }
        );
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        const elink = document.createElement("a");
        elink.download = `${this.$t("asset.tmp_file_name")} (TMP).xlsx`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } catch (err) {
        console.log(err.response);
      }
      this.isLoading = false;
    },
    // async onFileChange(e) {
    //   this.isLoading = true;
    //   try {
    //     const file = e.target.files[0];
    //     const fd = new FormData();
    //     fd.append("file", file);
    //     this.$refs.fileInput.value = null;
    //     this.isLoading = true;
    //     const res = await axios.post(
    //       `${baseUrl()}admin/importcheck/replace/temp/${this.id_company}`,
    //       fd,
    //       authHeader()
    //     );
    //     await this.$parent.handleFilter();
    //     this.$bvModal.hide("replace-modal");
    //   } catch (err) {
    //     if (err.response) {
    //       if (
    //         err.response.data.message === "New ID Fixed Asset has duplicate"
    //       ) {
    //         alert(this.$t("asset.new_fixed_asset_id_dup"));
    //       } else {
    //         alert(err.response.data.message);
    //       }
    //     } else {
    //       alert(err.message);
    //     }
    //   }
    //   this.isLoading = false;
    // },
    async importCheckTmp(e) {
      try {
        const file = e.target.files[0];
        const fd = new FormData();
        fd.append("file", file);
        this.$refs.fileInput.value = null;
        this.isLoading = true;
        const response = await axios.post(
          `${baseUrl()}admin/importchecktmp/${this.id_company}`,
          fd,
          authHeader()
        );
        this.importedData = {
          ...response.data,
          dataNotDuplicate: response.data.all.filter((data) => {
            return data.status !== "duplicate";
          }),
        };
        this.$bvModal.hide("replace-modal");
        this.importTableModal = true;
      } catch (error) {
        console.error(error);
        switch (error.response.data.message) {
          case "Excel doesn't match with template":
            alert(this.$t("docregas.excel_does_not_match"));
            break;
          case "Date Format":
            alert(this.$t("docregas.incorrect_date_format"));
            break;
          case "Reach package limit":
            alert(this.$t("dialog.register_limit"));
            break;
          case "ID Fixed Asset has duplicate":
            alert(
              this.$t("dialog.dup_fixedas") +
                `\n ${error.response.data.duplicated}`
            );
            break;
          default:
            alert(error.response.data.message);
            break;
        }
      }
      this.isLoading = false;
    },
    confirmImportCheck() {
      this.importTableModal = false;
      this.importTagTableModal = true;
    },
    cancelImportCheck() {
      this.importedData = {};
      this.importTableModal = false;
    },

    async confirmReplace() {
      this.isLoading = true;

      const dataDup = this.importedData.all.filter(
        (asset) => asset.status === "change"
      );

      try {
        await axios.post(
          `${baseUrl()}admin/importtmp/${this.id_company}`,
          {
            update: dataDup,
            id_company: this.id_company,
          },
          authHeader()
        );
        this.$parent.handleFilter();
      } catch (err) {
        if (err.response) {
          if (err.response.status === 524) {
            return;
          }
          if (err.response.data.message === "Reach package limit") {
            Swal.fire({
              icon: "error",
              text: this.$t("dialog.register_limit"),
            });
            return;
          }
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.importTagTableModal = false;
      this.isLoading = false;
    },

    cancelReplace() {
      this.importedData = {};
      this.importTagTableModal = false;
    },
    async loadData() {
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.procedure-step {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  p {
    margin: 0;
  }
  .number {
    border: 1px solid #007afe;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007afe;
    margin-right: 5px;
  }
  .download {
    color: #007afe;
    padding-left: 5px;
    cursor: pointer;
  }
}

.replace-btn {
  font-size: 14px;
  margin: 0.1rem;
  width: fit-content;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 11px;
}

.div-export-h {
  padding-top: 2rem;
  height: 50px;
  margin: auto;
  text-align: center;
}
.text-export-h {
  font-size: 24px;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.div-export-table {
  width: 90%;
  height: 500px;
  margin: auto;
}
</style>
