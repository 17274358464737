<template>
  <div class="position-relative" ref="BaseDropItem">
    <slot name="button" :onClick="onButtonClick"> </slot>
    <div
      class="position-absolute"
      style="z-index: 10; min-width: max-content;"
      :class="{
        'top-full end-0': props.direction === 'BOTTOM',
        'top-0 end-full': props.direction === 'LEFT',
        'top-full start-0': props.direction === 'BOTTOM_LEFT',
        '': props.direction === 'CUSTOM',
      }"
      v-if="showDropdown"
    >
      <slot name="item" :onClick="onMenuClick"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, shallowRef, defineEmits } from "vue";
import { onClickOutside } from "@vueuse/core";

const emit = defineEmits(["isButtonClick", "isButtonUnclick"]);

const props = defineProps({
  direction: {
    default: "BOTTOM_LEFT",
    validator(value) {
      return ["BOTTOM", "LEFT", "BOTTOM_LEFT", "CUSTOM"].includes(value);
    },
  },
});

const showDropdown = shallowRef(false);

const BaseDropItem = ref(null);
onClickOutside(BaseDropItem, () => {
  showDropdown.value = false;
  emit("isButtonUnclick");
});

const onButtonClick = () => {
  showDropdown.value = !showDropdown.value;
  emit("isButtonClick");
};

const onMenuClick = () => {
  showDropdown.value = false;
  emit("isButtonUnclick");
};
</script>

<style lang="scss" scoped></style>
