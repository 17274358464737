<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({ permissionStatus: "permissionStatus" }),
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    gotoSelectCompany() {
      this.$router.push("selectCompany");
    },
    async checkCustomLanguage() {
      if (
        this.getCustomName === "jagota" ||
        this.permissionStatus?.company?.custom_id === 2
      ) {
        this.switchLocale("th");
      }
      if (this.permissionStatus?.company?.custom_id === 5) {
        const LearnTH = await import("./plugins/lang/Custom/LearnTH.json");
        this.$i18n.mergeLocaleMessage("th", LearnTH.default);
        this.$i18n.mergeLocaleMessage("en", LearnTH.default);
      }
    },
  },
  created() {
    const lang = localStorage.getItem("locale") || "th";
    this.$i18n.locale = lang;
    localStorage.setItem("locale", lang);
    moment.locale(lang);
  },
  mounted() {
    if (
      this.$route.name !== "SelectCompany" &&
      this.$route.name !== "LoginType" &&
      this.$route.name !== "Login"
    ) {
      this.checkCustomLanguage();
    }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");

*,
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Mitr", sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

.bm-overlay {
  background-color: #fff !important;
}

.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  // width: 130px !important;
  min-width: 150px;
  width: fit-content !important;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.download-file-outline {
  padding: 0 10px 0 10px;
  border-radius: 8px;
  // width: 130px !important;
  min-width: 150px;
  width: fit-content !important;
  height: 34px;
  font-size: 14px;
  color: #007afe;
  border-color: #007afe;
  background: #fff;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px !important;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}

.bm-burger-bars {
  background-color: white !important;
}
select.form-control {
  background-image: url("./assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  // padding-right: 0.5rem !important;
  padding-right: 40px !important;
}
#filter-modal button.btn.btn-secondary {
  background: white !important;
  border-color: $color-active-blue !important;
  color: $color-active-blue !important;
  border: 1px solid !important;
}

#filter-modal select.form-control {
  border-radius: 10px !important;
  padding-left: 10px !important;
}
input.form-control.filter-box {
  border: none;
}

.close {
  background: transparent;
  border: none;
  font-size: 30px;
}
.title_table {
  th {
    background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }

  tr:nth-child(odd) {
    background-color: #c4c4c4;
  }
}

.btn-auto {
  width: auto !important;
}

.radiusBodyFirst {
  border-bottom-left-radius: 30px !important;
}
.radiusBodyLast {
  border-bottom-right-radius: 30px !important;
}
.radiusFirst {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 0px !important;
}
.radiusLast {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 0px !important;
}
.border-black {
  border: black 1px solid;
}
.cursor-pointer {
  cursor: pointer !important;
}
.border5 {
  border-radius: 5% !important;
  padding: 0.5rem;
}
.bg-grey-light {
  background: #e0e0e0 !important;
}

.btn-regist {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

input,
textarea,
select {
  background-color: #f4f4f4 !important;
  border: none !important;
}
option {
  background-color: #fff;
}
.card-img,
.card-img-top {
  height: 220px;
  object-fit: cover;
  border-radius: 3% !important;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important;
  }
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
  /* Remove First */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

::ng-deep .mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
}

// .home {
//   background: transparent linear-gradient(162deg, #007afe 0%, #013f81 100%) 0%
//     0% no-repeat padding-box;
//   background-attachment: fixed;
// }

@media only screen and (min-width: 1025px) {
  .responsive-bar {
    display: grid;
    grid-template-columns: 200px calc(100vw - 200px);
    grid-template-rows: minmax(100vh, auto);
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #007afe;
}

.close_modal {
  border-radius: 50%;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 0 0 auto !important;
  border: none;
  font-size: 16px;
  width: 30px !important;
  height: 30px !important;
}
.modal-footer {
  justify-content: center !important;
}
.filter-box {
  background-color: #e0e0e0 !important;
}
.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 2px solid #000;
  line-height: 0;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

.swal2-title {
  font-family: "Mitr", sans-serif !important;
}

.swal2-container {
  z-index: 99999 !important;
}

.swal-confirm-btn {
  margin: 5px;
  width: 45%;
  max-width: 140px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: $background-gradient-btn-blue;
}

.swal-cancel-btn {
  margin: 5px;
  width: 45%;
  max-width: 140px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: #c4c4c4;
}

.invalid-feedback {
  padding: 0rem 1rem !important;
}
// AG Grid style
.ag-header-cell-label {
  justify-content: center;
}
.ag-cell-value {
  text-align: left;
}

.ag-cell {
  user-select: text;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

// v-select styling
.vs__selected-options {
  flex-wrap: nowrap !important;
  min-width: 0px;
}
.vs--single.vs--open .vs__selected {
  opacity: 0 !important;
}
.vs__selected {
  white-space: nowrap;
  overflow: hidden;
}
.v-select {
  min-width: 0px;
}

// Primevue global styling
.p-tooltip-text {
  font-size: 12px;
  font-weight: lighter;
}
.p-image-toolbar {
  z-index: 1006;
}

.p-button.refresh {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);

  padding: 0.5rem 1.25rem;
}
</style>
