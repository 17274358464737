import moment from "moment";
export const filterConverter = (filterOptions) => {
  const maskedFilter = {
    ...filterOptions,
    dateUsed_start: filterOptions.dateUsed_start
      ? moment(filterOptions.dateUsed_start)
          .startOf("d")
          .toISOString()
      : undefined,
    dateUsed_end: filterOptions.dateUsed_end
      ? moment(filterOptions.dateUsed_end)
          .endOf("d")
          .toISOString()
      : undefined,
    datePurchase_min: filterOptions.datePurchase_min
      ? moment(filterOptions.datePurchase_min)
          .startOf("d")
          .toISOString()
      : undefined,
    datePurchase_max: filterOptions.datePurchase_max
      ? moment(filterOptions.datePurchase_max)
          .endOf("d")
          .toISOString()
      : undefined,

    // For Custom 9
    sum_depreciation_date_min: filterOptions.sum_depreciation_date_min
      ? moment(filterOptions.sum_depreciation_date_min)
          .startOf("d")
          .toISOString()
      : undefined,
    sum_depreciation_date_max: filterOptions.sum_depreciation_date_max
      ? moment(filterOptions.sum_depreciation_date_max)
          .endOf("d")
          .toISOString()
      : undefined,

    expire_date_min: filterOptions.expire_date_min
      ? moment(filterOptions.expire_date_min)
          .startOf("d")
          .toISOString()
      : undefined,
    expire_date_max: filterOptions.expire_date_max
      ? moment(filterOptions.expire_date_max)
          .endOf("d")
          .toISOString()
      : undefined,
    write_off_date_min: filterOptions.write_off_date_min
      ? moment(filterOptions.write_off_date_min)
          .startOf("d")
          .toISOString()
      : undefined,
    write_off_date_max: filterOptions.write_off_date_max
      ? moment(filterOptions.write_off_date_max)
          .endOf("d")
          .toISOString()
      : undefined,

    // For Jagota Thailand VPS
    custom_start_time_min: filterOptions.custom_start_time_min || undefined,
    custom_start_time_max: filterOptions.custom_start_time_max || undefined,
    custom_end_time_min: filterOptions.custom_end_time_min || undefined,
    custom_end_time_max: filterOptions.custom_end_time_max || undefined,

    // For Custom 6
    counting_time_min: filterOptions.counting_time_min
      ? moment(filterOptions.counting_time_min)
          .startOf("d")
          .toISOString()
      : undefined,
    counting_time_max: filterOptions.counting_time_max
      ? moment(filterOptions.counting_time_max)
          .endOf("d")
          .toISOString()
      : undefined,
  };
  let sendingFilter = {};
  for (const [key, value] of Object.entries(maskedFilter)) {
    if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
      sendingFilter[`${key}`] = value;
    }
  }
  return sendingFilter;
};
