import qs from "qs";
import axios from "axios";
import { stage, baseUrl, exportUrl, uploadUrl } from "../util/backend.js";
import router from "../router";
import moment from "moment-timezone";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
import i18n from "../plugins/i18n";
import { cloneDeep } from "lodash";
import { rpcClient } from "../rpc/utils/rpcClient";
import { featureFlags } from "../native-app-feature-flag/index.js";
import { filterConverter } from "@/util/filterConverter.js";

// ฟังก์ชันเช็ค Access Token อยู่ในไฟล์ของ Router
export function authHeader() {
  // return authorization header with jwt token
  const user =
    typeof Cookie.get("cookielogin") === "string"
      ? JSON.parse(Cookie.get("cookielogin"))
      : Cookie.get("cookielogin");

  if (user && user.access_token) {
    return {
      headers: {
        Authorization: "Bearer " + user.access_token,
        "Time-Zone": moment.tz.guess(),
      },
    };
  } else {
    return {};
  }
}

export const actions = {
  setCustomName({ commit }, payload) {
    commit("setCustomName", payload);
  },

  switchLocale(_, lang) {
    i18n.locale = lang;
    localStorage.setItem("locale", lang);
    moment.locale(lang);
  },

  async login({ commit, state, getters }, payload) {
    try {
      const response = await axios.post(`${baseUrl()}user/login`, {
        email: payload.email,
        password: payload.password,
      });
      // ------------------- for maintenance mode ------------------------
      if (!response.data.data.is_tester && getters.isInMaintenanceMode) {
        return router.push({ name: "WebDownPage" });
      }
      // ------------------- for maintenance mode ------------------------
      const userData = {
        email: response.data.data.email,
        image_url: response.data.data.image_url,
        name: response.data.data.name,
        phone: response.data.data.phone,
        id_user: response.data.data.id_user,
        is_admin: response.data.data.is_admin,
        is_tester: response.data.data.is_tester,
      };
      Cookie.set(
        "cookielogin",
        {
          access_token: response.data.data.access_token,
          refresh_token: response.data.data.refresh_token,
          id_user: response.data.data.id_user,
        },
        {
          expires: 7,
          domain: location.hostname.endsWith(".verscan.com")
            ? "verscan.com"
            : null,
        }
      );
      commit("getUserMutations", userData);
      commit("setCustomName", response.data.custom);
      state.isFromProfile = false;
      router.push("/selectCompany");
    } catch (error) {
      if (error.response) {
        if (error.response.data.status === "error") {
          commit("loginStatusMutations", true);
        }
      } else {
        alert(error.message);
      }
    }
  },

  async logout({ getters, commit }) {
    const cookieLogin = Cookie.get("cookielogin");
    const IS_TESTER = getters.isTester;
    if (cookieLogin) {
      const access_token = JSON.parse(cookieLogin).access_token;
      await axios.get(`${baseUrl()}user/logout?token=${access_token}`);
      // ------------------- for maintenance mode ------------------------
      // ------------------- for maintenance mode ------------------------
      Object.keys(Cookie.get()).forEach((cookieName) => {
        console.log("remove cookie:", cookieName);
        Cookie.remove(cookieName, {
          path: "/",
          domain: location.hostname.endsWith(".verscan.com")
            ? ".verscan.com"
            : "localhost",
        });
      });
    }

    commit("logout");
    if (
      (IS_TESTER && getters.isInMaintenanceMode) ||
      !getters.isInMaintenanceMode
    ) {
      router.push({ name: "Login" });
    } else {
      router.push({ name: "WebDownPage" });
    }
  },

  async getUser({ commit, dispatch }) {
    //use when refresh
    try {
      const id_user = JSON.parse(Cookie.get("cookielogin")).id_user;
      const id_company = await dispatch("getCompanyIdCookie");
      const response = await axios.get(
        `${baseUrl()}user/getuser/${id_user}`,
        authHeader()
      );

      const company = response.data.data.user_companies.find((each) => {
        return each.id_company === id_company;
      });

      const data = {
        email: response.data.data.email,
        image_url: response.data.data.image_url,
        name: response.data.data.name,
        phone: response.data.data.phone,
        is_admin: response.data.data.is_admin,
        role: company.position,
        department: company.department,
        is_payer: company.is_payer,
        position_company: company.position_company,
        id_user: id_user,
      };
      commit("getUserMutations", data);
    } catch (error) {
      console.error(error);
    }
  },

  async importAsset({ commit, dispatch }, data) {
    // On file change backend return render File excel to JSON
    // เปลี่ยนจากไฟล์ Excel เป็น JSON
    try {
      const response = await axios.post(
        `${baseUrl()}admin/importcheck/${data.id_company}`,
        data.fd,
        authHeader()
      );
      commit("importRegisAssetCheckMutations", response.data);
      return true;
    } catch (error) {
      console.error(error);
      switch (error.response.data.message) {
        case "Excel doesn't match with template":
          return alert(i18n.t("docregas.excel_does_not_match"));
        case "Date Format":
          return alert(i18n.t("docregas.incorrect_date_format"));
        case "Reach package limit":
          return alert(i18n.t("dialog.register_limit"));
        case "ID Fixed Asset has duplicate":
          return alert(
            i18n.t("dialog.dup_fixedas") +
              `\n ${error.response.data.duplicated}`
          );
        default:
          return alert(error.response.data.message);
      }
    }
  },

  async importSAPAsset({ commit, dispatch }, data) {
    // On file change backend return render File excel to JSON
    // เปลี่ยนจากไฟล์ Excel เป็น JSON
    try {
      const response = await axios.post(
        `${baseUrl()}admin/importCheckSap/${data.id_company}`,
        data.fd,
        authHeader()
      );
      commit("importRegisAssetCheckMutations", response.data);
      return true;
    } catch (error) {
      console.error(error);
      switch (error.response.data.message) {
        case "Excel doesn't match with template":
          return alert(i18n.t("docregas.excel_does_not_match"));
        case "Date Format":
          return alert(i18n.t("docregas.incorrect_date_format"));
        case "Reach package limit":
          return alert(i18n.t("dialog.register_limit"));
        case "ID Fixed Asset has duplicate":
          return alert(
            `${i18n.t(
              "dialog.dup_fixedas"
            )} ${error.response.data.duplicated.join(", ")}`
          );
        default:
          return alert(error.response.data.message);
      }
    }
  },

  async firstGetAllAsset({ commit }, object) {
    // ฟังก์ชันดึงข้อมูลสินทรัพย์ครั้งแรกในหน้า All_asset.vue หน้าสินทรัพย์ทั้งหมด
    try {
      const { company_id } = object;

      const response = await axios.get(
        `${baseUrl()}asset/allasset/${company_id}`,
        authHeader()
      );
      commit("firstSetAllAsset", {
        tableData: response.data.data,
        otherColumn: response.data.other_column,
      });
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  async selectCompany() {
    const id_user = JSON.parse(Cookie.get("cookielogin")).id_user;
    const allcompany = await axios.get(
      `${baseUrl()}user/getAllCompanyByIdUser/${id_user}`,
      authHeader()
    );
    return allcompany.data;
  },

  async saveSelectCompany({ commit }, company) {
    // Set ค่าข้อมูลเลือกบริษัท เมื่อปิดเปิดใหม่ก็จะอยู่ในบริษัทนั้นๆ มีระยะเวลา 1 วัน ถ้าหมดจะเด้งไปยังหน้าเลือกบริษัท
    Cookie.set("selectedcompany", company, {
      expires: 1,
      domain: location.hostname.endsWith(".verscan.com") ? "verscan.com" : null,
    });
    await commit("setPermission", company);
    if (featureFlags.cookieLogin) {
      await rpcClient.stub.selectCompany({
        companyId: company.company.company_id,
        package: company.package,
      });
    }
    // console.log(JSON.parse(Cookie.get("selectedcompany")));
  },

  clearPermission({ commit }) {
    Cookie.remove("selectedcompany", {
      path: "/",
      domain: location.hostname.endsWith(".verscan.com")
        ? ".verscan.com"
        : "localhost",
    });
    commit("setPermission", {});
  },
  async goToSelectCompany({ commit }, payload) {
    if (payload) {
      commit("isFromProfileMutation", payload);
    } else {
      Cookie.remove("selectedcompany");
    }
    router.push("/selectCompany");
  },

  async getCompanyIdCookie() {
    // ดึงข้อมูล id_company จาก cookie
    // console.log("cookie", JSON.parse(Cookie.get("selectedcompany")).company.id_company);
    if (Cookie.get("selectedcompany")) {
      return JSON.parse(Cookie.get("selectedcompany")).company.id_company;
    }
  },

  async getCurrentIdUser() {
    // Function ดึง id_user จาก cookie
    return JSON.parse(Cookie.get("cookielogin")).id_user;
  },

  async deleteAsset({ dispatch }, assets) {
    try {
      const size = 500;
      let message = assets.cancelMessage;
      const deleteArrayTemp = [];
      for (let start = 0; start <= assets.selectedRow.length / size; start++) {
        deleteArrayTemp.push(
          assets.selectedRow.slice(start * size, (start + 1) * size)
        );
      }
      for await (const eachArray of deleteArrayTemp) {
        try {
          await axios.post(
            `${baseUrl()}asset/deleteAsset`,
            {
              assets: eachArray,
              note_delete: message,
            },
            authHeader()
          );
        } catch (err) {
          if (err.response) {
            alert(err.response.data.message);
          } else {
            alert(err.message);
          }
        }
      }
      // await dispatch("firstGetAllAsset", {
      //   company_id: assets.selectedRow[0].id_company,
      // });
    } catch (err) {
      console.error(err);
    }
  },

  async generateAllQr({ commit }, amount) {
    try {
      // คำสั่งขอจำนวน QR Code โดยเช็คจากBackend
      const response = await axios.post(
        `${baseUrl()}qrcode/generate`,
        {
          id_company: 1,
          amount: amount * 1,
        },
        authHeader()
      );
      console.log("generateAllQr >", response);

      commit("genQrCodeMutations", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  async getAsset({ commit, dispatch }, id) {
    // ใช้ในหน้าตรวจนับสินทรัพย์ เพื่อตรวจเช็คว่า หลังจากสแกนแล้วจะให้ไปหน้าไหนระหว่างหน้าตรวจนับกับหน้ารายละเอียดสินทรัพย์
    try {
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;
      // ดึงข้อมูลสินทรัพย์โดยใช้ id = id_qrcode
      const response = await axios.post(
        `${baseUrl()}counting`,
        { id_qrcode: id },
        {
          params: {
            id_company: company_id,
          },

          ...authHeader(),
        }
      );
      // ดึงวันตรวจนับทั้งหมด
      const checkCounting = await dispatch("checkCountingDate");
      const isCounting = checkCounting.is_counting;

      let dataFromApi = await response.data.data;
      // เช็ควันตรวจนับว่าครั้งล่าสุดนั้นเป็นวันที่กำหนดหรือยัง และ จบไปหรือยัง
      if (!isCounting) {
        await commit("getAssetMutations", dataFromApi);
        router.replace(`/detailOneAsset/${dataFromApi.asset.id_asset}`);
        return "have asset not in counting";
      } else {
        if (dataFromApi.asset.approve_write_off_at) {
          return "already writeoff";
        }
        //REVIEW - check this counting_item.status
        if (dataFromApi.counting_item.status == "ชำรุด") {
          dataFromApi.counting_item.custom_status = [
            "ชำรุด",
            dataFromApi.counting_item.damaged_status,
          ];
        } else {
          dataFromApi.counting_item.custom_status = [
            dataFromApi.counting_item.status,
            "",
          ];
        }
        let position = {
          x: response.data.data.asset.location_mapX,
          y: response.data.data.asset.location_mapY,
        };

        // let query = {
        //   id_company: company_id,
        //   building: dataFromApi.asset.location_building,
        //   floor: dataFromApi.asset.location_floor,
        // };
        dispatch("setMapPosition", position);
        // dispatch("getMapUrl", query);
        commit("getAssetMutations", dataFromApi);
      }
      return "have this asset";
    } catch (error) {
      console.log(error);
      let msg = error.response.data.message;
      console.error(`error: `, error.response.data.message);
      console.error(error);
      commit("getAssetMutations", error.response.data.message);
      if (msg == `This Item is not register in this year's counting yet`) {
        // console.log(msg);
      } else if (msg == `This QR code is not register yet`) {
        if (router.currentRoute.name === "CheckItem") {
          router.replace(`/registeritem/${encodeURIComponent(id)}`);
        }
      } else if (msg == "This qrcode is invalid") {
        await Swal.fire({
          title: i18n.t("dialog.wrong_qr"),
          text: i18n.t("dialog.wrong_qr_dt"),
          timer: 3000,
          imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
          confirmButtonText: i18n.t("btn.close"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "order-2 swal-confirm-btn",
          },
          imageHeight: 48,
          imageWidth: 48,
        });
        router.replace("/scan");
      } else if (msg == "Not in counting item") {
        await Swal.fire({
          text: i18n.t("dialog.err_counting"),
          imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
          buttonsStyling: false,
          customClass: {
            confirmButton: "order-2 swal-confirm-btn",
          },
          confirmButtonText: i18n.t("btn.close"),
          imageHeight: 48,
          imageWidth: 48,
        });
        router.replace(`/detailOneAsset/${error.response.data.id}`);
      }
      return false;
    }
  },

  async checkAssetExisted(_, id) {
    try {
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;
      const response = await axios.post(
        `${baseUrl()}counting`,
        { id_qrcode: id },
        {
          params: {
            id_company: company_id,
          },

          ...authHeader(),
        }
      );
      return response;
    } catch (err) {
      let msg = err.response.data.message;
      if (msg == "This QR code is not register yet") {
        if (router.currentRoute.name === "CheckItem") {
          router.replace(`/registerNow/${encodeURIComponent(id)}`);
        }
      }
      if (msg == "This qrcode is invalid") {
        Swal.fire({
          title: i18n.t("dialog.wrong_qr"),
          text: i18n.t("dialog.wrong_qr_dt"),
          timer: 3000,
          imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
          confirmButtonText: i18n.t("btn.close"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "order-2 swal-confirm-btn",
          },
          imageHeight: 48,
          imageWidth: 48,
        });
        router.back();
      }
      if (msg == "Not in counting item") {
        Swal.fire({
          text: i18n.t("dialog.qrcode_used"),
          timer: 3000,
          imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
          confirmButtonText: i18n.t("btn.close"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "order-2 swal-confirm-btn",
          },
          imageHeight: 48,
          imageWidth: 48,
        });
        router.back();
      }
    }
  },
  async getAssetRegisterNow({ commit, dispatch }, id) {
    // ใช้ในหน้าตรวจนับสินทรัพย์ เพื่อตรวจเช็คว่า หลังจากสแกนแล้วจะให้ไปหน้าไหนระหว่างหน้าตรวจนับกับหน้ารายละเอียดสินทรัพย์
    try {
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;
      // ดึงข้อมูลสินทรัพย์โดยใช้ id = id_qrcode
      const response = await axios.get(
        `${baseUrl()}counting`,
        { id_qrcode: id },
        {
          params: {
            id_company: company_id,
          },

          ...authHeader(),
        }
      );
      // ดึงวันตรวจนับทั้งหมด
      const checkCounting = await dispatch("checkCountingDate");
      const isCounting = checkCounting.is_counting;

      let dataFromApi = await response.data.data;

      // เช็ควันตรวจนับว่าครั้งล่าสุดนั้นเป็นวันที่กำหนดหรือยัง และ จบไปหรือยัง
      if (!isCounting) {
        await commit("getAssetMutations", dataFromApi);
        router
          .replace(`/detailOneAsset/${dataFromApi.asset.id_asset}`)
          .catch(() => {});
      } else {
        //REVIEW - check this counting_item.status
        if (dataFromApi.counting_item.status == "ชำรุด") {
          dataFromApi.counting_item.custom_status = [
            "ชำรุด",
            dataFromApi.counting_item.damaged_status,
          ];
        } else {
          dataFromApi.counting_item.custom_status = [
            dataFromApi.counting_item.status,
            "",
          ];
        }
      }

      let position = {
        x: response.data.data.asset.location_mapX,
        y: response.data.data.asset.location_mapY,
      };

      let query = {
        id_company: company_id,
        building: dataFromApi.asset.location_building,
        floor: dataFromApi.asset.location_floor,
      };
      dispatch("setMapPosition", position);
      dispatch("getMapUrl", query);
      commit("getAssetMutations", dataFromApi);
      return true;
    } catch (error) {
      let msg = error.response.data.message;

      commit("getAssetMutations", error.response.data.message);
      if (msg == `This Item is not register in this year's counting yet`) {
        console.log(msg);
      } else if (msg == `This QR code is not register yet`) {
        console.log(msg);

        if (router.currentRoute.name === "CheckItem") {
          router.replace(`/registerNow/${encodeURIComponent(id)}`);
        }
      } else if (msg == "This qrcode is invalid") {
        Swal.fire({
          title: i18n.t("dialog.wrong_qr"),
          text: i18n.t("dialog.wrong_qr_dt"),
          timer: 3000,
          imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
          confirmButtonText: i18n.t("btn.close"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "order-2 swal-confirm-btn",
          },
          imageHeight: 48,
          imageWidth: 48,
        });
        router.back();
      }
      return false;
    }
  },

  setAssetSelectedRegister({ commit }, payload) {
    commit("setAssetSelectedRegister", payload);
  },

  async getDetailAssets({ commit, dispatch }, asset_id) {
    try {
      // ดึงข้อมูลสินทรัพย์หลังจากเลือกสินทรัพย์ในหน้าสินทรัพย์ทั้งหมด
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;
      const response = await axios.get(
        `${baseUrl()}counting/asset/${asset_id}`,
        authHeader()
      );

      if (response.data.message === "Asset not found") {
        return "Asset not found";
      }

      let dataFromApi = await response.data.data;

      let position = {
        x: dataFromApi.asset.location_mapX,
        y: dataFromApi.asset.location_mapY,
      };
      console.log("getDetailAssets -> postion", position);
      let query = {
        id_company: company_id,
        building: dataFromApi.asset.location_building,
        floor: dataFromApi.asset.location_floor,
      };
      if (dataFromApi.asset.asset_details) {
        if (dataFromApi.asset.asset_details.length > 0) {
          dataFromApi.asset.asset_details.sort(
            (a, b) => a.column_no - b.column_no
          );
        }
      }
      await dispatch(
        "setMapUrl",
        dataFromApi.asset.location_floors?.image_url || ""
      );
      await dispatch("setMapPosition", position);
      // await dispatch("getMapUrl", query);
      await commit("getDetailAssetsMutations", dataFromApi);
    } catch (error) {
      console.error(error);
    }
  },
  async getDetailAssetsRegisterNow({ commit, dispatch }, payload) {
    try {
      // ดึงข้อมูลสินทรัพย์หลังจากเลือกสินทรัพย์ในหน้าสินทรัพย์ทั้งหมด
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;
      const response = await axios.get(
        `${baseUrl()}counting/asset/${payload.asset_id}`,
        authHeader()
      );

      // console.log("getDetailAssets -> response", response.data.data);

      let dataFromApi = await response.data.data;

      let position = {
        x: response.data.data.asset.location_mapX,
        y: response.data.data.asset.location_mapY,
      };
      // console.log("getDetailAssets -> postion", position);
      let query = {
        id_company: company_id,
        building: dataFromApi.asset.location_building,
        floor: dataFromApi.asset.location_floor,
      };

      await dispatch("setMapPositionRegisterNow", position);
      await dispatch("getMapUrlRegisterNow", query);
      await commit("getDetailAssetsRegisterNowMutations", dataFromApi);
      let toPage = "scan";
      if (payload.id_qrcode) {
        if (featureFlags.scan) {
          window.sendMessage("scan", {
            mode: "register",
            url: `/registerNow/${encodeURIComponent(
              payload.id_qrcode
            )}/${toPage}`,
          });
        } else {
          router.push(
            `/registerNow/${encodeURIComponent(payload.id_qrcode)}/${toPage}`
          );
        }
      } else {
        if (featureFlags.scan) {
          window.sendMessage("scan", {
            mode: "register",
            url: `/scan/registerNow`,
          });
        } else {
          router.push(`/scan/registerNow`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async uploadImage({ getters }, formdata) {
    const customName = getters.getCustomName;
    const webStage = stage();
    if (customName === "jagota") {
      const res = await axios.post(uploadUrl(), formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let response = {
        data: {
          data: {
            uploadFileAtLevel: {
              url: "",
            },
          },
        },
      };
      response.data.data.uploadFileAtLevel.url = res.data.url;
      return response;
    } else {
      // ----------- Cloudflare ---------------
      if (webStage === "prod" || webStage === "pre-prod") {
        const date = new Date();
        const timestamp = date.getTime();
        const url = window.location.hostname;
        let stageName = "dev";
        switch (url) {
          case "app.verscan.com":
          case "prod-app-verscan.web.app":
            stageName = "production";
            break;
          case "pre-prod-web.verscan.com":
            stageName = "pre-prod-web";
            break;
          case "pre-prod-app.verscan.com":
            stageName = "pre-prod-app";
            break;
          case "staging-app-verscan.web.app":
          case "staging-app.verscan.com":
            stageName = "uat";
            break;
          case "dev-app-verscan.web.app":
          case "dev-app.verscan.com":
            stageName = "dev";
            break;
          case "localhost":
            stageName = "local";
            break;
          default:
            stageName = "dev";
            break;
        }

        const filePrefix = `${stageName}_${timestamp}_`;
        const res = await axios.post(uploadUrl(), formdata, {
          params: { prefix: filePrefix },
        });

        const imageId = res.data.result.id;
        const imageLink = `https://imagedelivery.net/M71RFlp5fMNiKNdFVAedNA/${imageId}/public`;
        let response = {
          data: {
            data: {
              uploadFileAtLevel: {
                url: "",
              },
            },
          },
        };
        response.data.data.uploadFileAtLevel.url = imageLink;
        return response;
      } else {
        const res = await axios.post(uploadUrl(), formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return res;
      }
      // ----------- Cloudflare ---------------
    }
  },

  async regisAsset({ commit, dispatch }, data) {
    try {
      const body = { ...data }

      const date_list = [
        'purchase_date',
        'start_date',
        'depreciation_date',
        'sum_depreciation_date',
      ]

      for (let index = 0; index < date_list.length; index++) {
        const key = date_list[index];
        if (body[key]) {
          body[key] = moment(body[key]).format('YYYY-MM-DD')
        }
      }
      // ลงทะเบียนสินทรัพย์กับ QRCode
      const response = await axios.post(
        `${baseUrl()}qrcode/register`,
        {
          id_qrcode: body.id_qrcode,
          asset: {
            id_asset: body.id_asset,
            id_company: body.id_company,
            name: body.name,
            id_category: body.category,
            id_sub_category: body.sub_category,
            id_sub_category2: body.id_sub_category2,
            description: body.description,
            id_location_building: body.location_building,
            id_location_floor: body.location_floor,
            id_location_department: body.location_department,
            id_location_zone: body.location_zone,
            location_mapX: body.location_mapX,
            location_mapY: body.location_mapY,
            id_fixed_asset: body.id_fixed_asset,
            registrant: body.registrant,
            note: body.note,
            start_date: body.start_date,
            purchase_date: body.purchase_date,
            purchase_price: body.purchase_price,
            useful_life: body.useful_life,
            // acc_depreciation: body.acc_depreciation,
            // net_book: body.net_book,
            asset_details: body.asset_details,
            image_url: body.image_url,
            sector_id: body.sector,
            branch_id: body.branch,
            custom_start_time: body.custom_start_time,
            custom_end_time: body.custom_end_time,
            vendor_id: body.vendor,
            intendant: body.intendant,
            depreciation_date: body.depreciation_date,
            sum_depreciation_date: body.sum_depreciation_date,
            prior_depreciation: body.prior_depreciation,
            prior_net_book: body.prior_net_book,
            note_accounting_id: body.note_accounting?.note_accounting_id,
            sub_note_accounting_id:
              body.sub_note_accounting?.sub_note_accounting_id,
          },
        },
        authHeader()
      );
      if (response.status == 200) {
        // data.data_form.append(
        //   "id_asset",
        //   response.data.data.asset_result.id_asset
        // );
        if (data.page) {
          router.push(`/fixed_assets`);
        } else {
          router.push(`/scan`);
        }
      }
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        console.log(err.message);
      }
    }
  },
  async regisAssetManual({ commit, dispatch }, data) {
    // ลงทะเบียนสินทรัพย์กับ QRCode
    try {
      const response = await axios.post(
        `${baseUrl()}qrcode/register/manual`,
        {
          id_qrcode: data.id_qrcode,
          asset: {
            id_asset: data.id_asset,
            id_company: data.id_company,
            name: data.name,
            id_category: data.category,
            id_sub_category: data.sub_category,
            id_sub_category2: data.id_sub_category2,
            description: data.description,
            id_location_building: data.location_building,
            id_location_floor: data.location_floor,
            id_location_department: data.location_department,
            id_location_zone: data.location_zone,
            location_mapX: data.location_mapX,
            location_mapY: data.location_mapY,
            id_fixed_asset: data.id_fixed_asset,
            registrant: data.registrant,
            note: data.note,
            start_date: data.start_date,
            purchase_date: data.purchase_date,
            purchase_price: data.purchase_price,
            useful_life: data.useful_life,
            // acc_depreciation: data.acc_depreciation,
            // net_book: data.net_book,
            asset_details: data.asset_details,
            image_url: data.image_url,
            sector_id: data.sector,
            branch_id: data.branch,
            custom_start_time: data.custom_start_time,
            custom_end_time: data.custom_end_time,
            vendor_id: data.vendor,
            intendant: data.intendant,
            depreciation_date: data.depreciation_date,
            sum_depreciation_date: data.sum_depreciation_date
              ? moment(data.sum_depreciation_date).format('YYYY-MM-DD')
              : null,
            prior_depreciation: data.prior_depreciation,
            prior_net_book: data.prior_net_book,
            note_accounting_id: data.note_accounting?.note_accounting_id,
            sub_note_accounting_id:
              data.sub_note_accounting?.sub_note_accounting_id,
          },
        },
        authHeader()
      );
      if (response.status == 200) {
        // console.log(`asset is `, response.data.data.asset_result.id_asset);
        // data.data_form.append(
        //   "id_asset",
        //   response.data.data.asset_result.id_asset
        // );
        router.push(`/scan`);
        // dispatch("uploadPictureAsset", data.data_form).then(() => {
        //   console.log(response.data.data, "goto", qr_code);

        //   commit("regisAssetMutations", response.data.data);

        //   router.push(`/scan`);
        // });
      }
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        console.log(err.message);
      }
    }
  },

  async filterForDashboard({ commit }, data) {
    try {
      // console.log("data.assets>>>>>", data);
      await commit("setDashboardFiltered", data.assets);
    } catch (e) {
      console.log(e);
    }
  },

  setDashboardModalFilter({ commit }, data) {
    commit("setDashboardModalFilter", data);
  },

  async updateCheckAsset(_, data) {
    // ตรวจนับสินทรัพย์
    return await axios.post(
      `${baseUrl()}counting/updateCountingItem`,
      data,
      authHeader()
    );
  },
  async uploadPictureAsset({ commit }, data_form) {
    try {
      // ลงทะเบียนรูปภาพสินทรัพย์ครั้งแรกในตอนลงทะเบียนสินทรัพย์กับ QR Code
      const response = await axios.post(
        `${baseUrl()}asset/upload`,
        data_form,
        authHeader()
      );
      commit("uploadPictureAssetMutations", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async editAsset({ commit, dispatch }, data) {
    // แก้ไขข้อมูลสินทรัพย์
    const response = await axios.post(
      `${baseUrl()}asset/updateasset`,
      data,
      authHeader()
    );
    return response;
  },
  async adminEditAsset({ commit, dispatch }, data) {
    try {
      const response = await axios.post(
        `${baseUrl()}asset/updateasset`,
        data.asset,
        authHeader()
      );
      console.log("editAsset -> response", response);
      let postion = {
        x: data.asset.location_mapX,
        y: data.asset.location_mapY,
      };
      if (response) {
        dispatch("setMapPosition", postion).then(() => {
          commit("editAssetMutations", response.data.data);
          // router.go(0);
        });
        await dispatch("firstGetAllAsset", {
          company_id: data.asset.id_company,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  async ChangeLostStatus(_, data) {
    try {
      const response = await axios.put(
        `${baseUrl()}asset/updateLostAsset`,
        data,
        authHeader()
      );
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        throw err;
      }
    }
  },

  async userRegister({ commit }, data) {
    try {
      console.log("data: ", data);
      const response = await axios.post(
        `${baseUrl(data.customName)}user/register`,
        {
          data,
        }
      );
      commit("userRegisterMutations", response.data.data);
      if (response) {
        // router.push("/login");
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getAllUser({ commit }, id_company) {
    try {
      const response = await axios.get(
        `${baseUrl()}user/getAllUser/${id_company}`,
        authHeader()
      );
      commit("setAllUser", response);
    } catch (error) {
      console.log(error);
    }
  },

  async getOneUser({ commit, dispatch }, user_id) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      let data = await axios.get(
        `${baseUrl()}user/getuser/${user_id}?id_company=${id_company}`,
        authHeader()
      );
      commit("getUserMutations", {
        ...data.data.data,
        userCompany: data.data.userCompany,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async getEditUser({ commit, dispatch }, user_id) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      let data = await axios.get(
        `${baseUrl()}user/getuser/${user_id}?id_company=${id_company}`,
        authHeader()
      );
      commit("getEditUser", {
        ...data.data.data,
        userCompany: data.data.userCompany,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async getSelectedUser({ commit, dispatch }, user_id) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      let data = await axios.get(
        `${baseUrl()}user/getuser/${user_id}?id_company=${id_company}`,
        authHeader()
      );
      commit("getSelectedUserMutations", {
        ...data.data.data,
        userCompany: data.data.userCompany,
      });
    } catch (error) {
      console.error(error);
    }
  },

  async editUser({ commit, dispatch }, user) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      const response = await axios.post(
        `${baseUrl()}user/updateuser`,
        {
          id_user: user.id_user,
          email: user.email,
          name: user.name,
          role: user.role,
          department: user.department,
          phone: user.phone,
          image_url: user.image_url,
          position_company: user.position_company,
          id_company: id_company,
        },
        authHeader()
      );

      console.log("editUser -> response", response);
      const dataCookie = JSON.parse(Cookie.get("cookielogin"));
      const userData = {
        id_user: response.data.data.id_user,
        email: response.data.data.email,
        name: response.data.data.name,
        role: response.data.data.role,
        department: response.data.data.department,
        phone: response.data.data.phone,
        image_url: response.data.data.image_url,
        id_company: dataCookie.id_company,
        checkrole: dataCookie.checkrole,
        access_token: dataCookie.access_token,
        refresh_token: dataCookie.refresh_token,
      };
      console.log(Cookie.get("cookielogin"));
      commit("editUserMutations", response.data.data);
      Cookie.set("cookielogin", userData, {
        expires: 7,
        domain: location.hostname.endsWith(".verscan.com")
          ? "verscan.com"
          : null,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  async adminEditUser({ dispatch }, payload) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      const position = await payload.position.find((position) => {
        return payload.selectedPosition === position.text;
      });
      const response = await axios.post(
        `${baseUrl()}user/updateuser`,
        {
          id_company,
          id_permission: position.id_permission,
          id_user: payload.user.id_user,
          email: payload.user.email,
          name: payload.user.name,
          role: position.text,
          department: payload.user.userCompany.department,
          phone: payload.user.phone,
        },
        authHeader()
      );
    } catch (error) {
      console.error(error);
    }
  },
  async setMapUrl({ commit }, url) {
    try {
      commit("setMapUrlMutations", url);
    } catch (error) {
      console.error(error);
    }
  },
  async setMapPosition({ commit }, postion) {
    try {
      commit("setMapPositionMutations", postion);
    } catch (error) {
      console.error(error);
    }
  },
  async setMapPositionRegisterNow({ commit }, postion) {
    try {
      commit("setMapPositionRegisterNowMutations", postion);
    } catch (error) {
      console.error(error);
    }
  },
  async getMapUrl({ commit }, data) {
    console.log(`data get map`, data);
    const { building, floor, id_company } = data;
    try {
      const response = await axios.get(
        `${baseUrl()}admin/map/${id_company}?building=${building}&floor=${floor}`,
        authHeader()
      );
      // console.log("getMapUrl", response);
      commit("getMapUrlMutations", response.data.data.image_url);
    } catch (error) {
      console.error(error);
    }
  },
  async getMapUrlRegisterNow({ commit }, data) {
    console.log(`data get map`, data);
    const { building, floor, id_company } = data;
    try {
      const response = await axios.get(
        `${baseUrl()}admin/map/${id_company}?building=${building}&floor=${floor}`,
        authHeader()
      );
      // console.log("getMapUrl", response);
      commit("getMapUrlRegisterNowMutations", response.data.data.image_url);
    } catch (error) {
      console.error(error);
    }
  },
  async createMap({ commit, dispatch }, map) {
    try {
      // console.log("action create map: ", map)
      const response = await axios.post(
        `${baseUrl()}location/createFloor/`,
        map,
        authHeader()
      );
      dispatch("sendAllMap", response.data.data.id_company);
      commit("createMapMutations", response.data.data);
      return response;
    } catch (error) {
      // console.error(error);
      return error.response;
    }
  },
  async sendAllMap({ commit }, id_company) {
    try {
      const response = await axios.get(
        `${baseUrl()}location/${id_company}`,
        authHeader()
      );
      commit("sendAllMapMutations", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async getFloor({ commit }, payload) {
    try {
      console.log("payload GET", payload);
      const response = await axios.get(
        `${baseUrl()}location/getFloor/?id_company=${
          payload.id_company
        }&id_location_building=${payload.id_location_building}&location_floor=${
          payload.location_floor
        }&limit=${payload.limit}&page=${payload.page}`,
        authHeader()
      );
      console.log("!!data", response.data);
      commit("getFloorMutaions", response.data.data);
      commit("getCountFloorMutaions", response.data.count);
    } catch (error) {
      console.error(error);
    }
  },
  async createBuilding(_, payload) {
    try {
      const response = await axios.post(
        `${baseUrl()}location/createBuilding/`,
        payload,
        authHeader()
      );
      console.log("response", response);
      return response;
    } catch (err) {
      return err.response.data.message;
    }
  },
  async addCountingDate(_, data) {
    const { id_company, counting_date, type } = data;
    if (type === "pre") {
      try {
        const response = await axios.post(
          `${baseUrl()}dates/createPreCountingDate`,
          {
            id_company: id_company,
            counting_date: counting_date,
          },
          authHeader()
        );
      } catch (error) {
        if (error.response) {
          if (error.response.data.message === "Counting date is exist.") {
            Swal.fire(i18n.t("dialog.have_date"));
          }
        }
        console.error(error);
      }
    } else {
      try {
        const response = await axios.post(
          `${baseUrl()}dates/createCountingDate`,
          {
            counting_date: counting_date,
            id_company: id_company,
          },
          authHeader()
        );
        if (response.data.message === "date already exist") {
          alert(i18n.t("dialog.have_date"));
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  async changePassword({ commit, dispatch }, data) {
    const user = JSON.parse(Cookie.get("cookielogin"));
    // console.log("getusercookie is ", data.id_user);
    try {
      const response = await axios.post(
        `${baseUrl()}user/changePassword`,
        {
          ...data,
          id_user: user.id_user,
        },
        authHeader()
      );
      if (response.data.message === "Password ไม่ถูกต้อง") {
        // alert(i18n.t("dialog.wrong_pw"));
        throw i18n.t("dialog.wrong_pw");
      }
    } catch (e) {
      throw new Error(e);
    }
    // console.log("changePassword action", data);
  },

  async deleteUser({ commit, dispatch }, user) {
    try {
      console.log("delete User: ", user);
      const id_company = await dispatch("getCompanyIdCookie");
      const response = await axios.delete(
        `${baseUrl()}user/deleteUser?id_user=${user}&id_company=${id_company}`,
        authHeader()
      );
      console.log("delete User response: ", response);
      commit("deleteUser", user);
    } catch (e) {
      console.log(e);
    }
  },

  async getAllCountingDateByIdCompany({ commit }) {
    try {
      // การสรุปผลการตรวจนับทั้งหมดในบริษัท
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const response = await axios
        .get(
          `${baseUrl()}dates/getCountingDate/${company.company.id_company}`,
          authHeader()
        )
        .then((res) => {
          console.log(res);
          commit("setCountingDate", res.data.data);
        });
    } catch (e) {
      console.error(e);
    }
  },

  async checkCountingDate({ dispatch }) {
    const id_company = await dispatch("getCompanyIdCookie");
    const res = await axios.get(`${baseUrl()}dates/checkCountingDate`, {
      params: {
        id_company: id_company,
        date: moment().toISOString(),
      },
      ...authHeader(),
    });
    return res.data;
  },

  async getSummaryById_pagination({ commit, dispatch }, payload) {
    // ดูการสรุปผลการตรวจนับนั้นๆ
    const { id_summary, filterOptions } = payload;
    await dispatch("loadOptionChoice");

    try {
      let filter = {};
      // commit("setAllAssetFilter", {
      //   filterOptions: { ...filterOptions }
      // });
      if (filterOptions) {
        for (const [key, value] of Object.entries(filterOptions)) {
          if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
            filter[`${key}`] = value;
          }
        }
        const user =
          typeof Cookie.get("cookielogin") === "string"
            ? JSON.parse(Cookie.get("cookielogin"))
            : Cookie.get("cookielogin");

        const summary = await axios.get(
          `${baseUrl()}dashboard/summary/pagination/${id_summary}`,
          {
            headers: { Authorization: "Bearer " + user.access_token },
            params: { ...filter },
          }
        );
        await commit("setSummaryData", summary.data);
        // await commit("setOptionChoice", summary.data.result.options);
        await dispatch("setDashboardModalFilter", filterOptions);
      } else {
        const summary = await axios.get(
          `${baseUrl()}dashboard/summary/pagination/${id_summary}`,
          authHeader()
        );
        console.log("summary>>>>>>");
        console.log(summary);
        await commit("setSummaryData", summary.data);
        // await commit("setOptionChoice", summary.data.result.options);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateMap({ dispatch }, data) {
    try {
      const {
        new_url,
        id_map,
        location_building,
        location_floor,
        id_company,
        id_location_building,
      } = data;
      const updateMap = await axios
        .put(
          `${baseUrl()}location/updateLocation/`,
          {
            id_location_floor: id_map,
            location_building: location_building,
            location_floor: location_floor,
            image_url: new_url,
            id_company,
            id_location_building,
          },
          authHeader()
        )
        .then();
    } catch (e) {
      console.error(e);
      return e.response.data.message;
    }
  },
  async deleteBuilding(_, payload) {
    try {
      await axios.delete(
        `${baseUrl()}location/deleteBuilding/?id_company=${
          payload.id_company
        }&id_location_building=${payload.id_location_building}`,
        authHeader()
      );
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        throw err;
      }
    }
  },
  async deleteFloor({ commit }, payload) {
    try {
      await axios.delete(
        `${baseUrl()}location/deleteFloor/?id_company=${
          payload.id_company
        }&id_location_floor=${payload.id_location_floor}`,
        authHeader()
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async setSignature({ dispatch }, data) {
    try {
      // เซ็นลายเซ็น
      await axios.post(
        `${baseUrl()}dashboard/summary/signature`,
        {
          id_summary: data.id_summary,
          signature: data.signature,
        },
        authHeader()
      );
    } catch (e) {
      console.log(e);
    }
  },

  async endCountingDate({ dispatch }, data) {
    // จบการตรวจนับ
    try {
      await axios.post(
        `${baseUrl()}dates/updateFinishSummary`,
        {
          id_summary: data.id_summary,
        },
        authHeader()
      );
      await axios.post(
        `${baseUrl()}dashboard/summary/signature`,
        {
          id_summary: data.id_summary,
          signature: { ...data.signature, end: true },
        },
        authHeader()
      );
    } catch (e) {
      console.log(e);
    }
  },

  async deleteCountingDate({ dispatch }, data) {
    try {
      const { id_summary } = data;
      const response = await axios.delete(
        `${baseUrl()}dates/deleteCountingDate?id_summary=${id_summary}`,
        authHeader()
      );
      // console.log(response)
      // if (response.status == 200) {
      //   dispatch("getAllCountingDateByIdCompany");
      // }
    } catch (e) {
      console.log(e);
    }
  },

  async changePasswordByEmail({ commit }, data) {
    await axios.post(
      `${baseUrl(data.customName)}user/changePasswordByEmail`,
      {
        id_user: data.id,
        password: data.password,
      },
      authHeader()
    );
  },
  // console.log("changePassword action", data);
  async confirmUser({ dispatch }, data) {
    const { id_user, company_id } = data;
    const response = await axios.post(
      `${baseUrl()}user/confirmUser`,
      {
        id_user,
        id_company: company_id,
      },
      authHeader()
    );
    if (response.status == 200) {
      dispatch("getAllUser", company_id);
    }
  },

  async updateGallery({ dispatch }, data) {
    const { index, url, id_asset } = data;
    const response = await axios.post(
      `${baseUrl()}asset/updateGallery`,
      {
        index,
        url,
        id_asset,
      },
      authHeader()
    );
    if (response.status == 200) {
      dispatch("getDetailAssets", id_asset);
    }
  },
  async hashData({ commit }, data) {
    try {
      const response = await axios.post(
        `${baseUrl()}user/inviteEmail`,
        data,
        authHeader()
      );
      return response.data.message;
    } catch (err) {
      alert(err.response?.data?.message || err.message);
    }
  },
  async decodeToken({ commit }, token) {
    const response = await axios.post(`${baseUrl()}user/decodeToken`, {
      token,
    });
    return response.data.decode;
  },
  async acceptRequest({ commit }, id_company) {
    const id_user = JSON.parse(Cookie.get("cookielogin")).id_user;
    await axios.post(
      `${baseUrl()}user/acceptRequest`,
      { id_user, id_company },
      authHeader()
    );
  },
  async declineRequest({ commit }, id_company) {
    const id_user = JSON.parse(Cookie.get("cookielogin")).id_user;
    await axios.post(
      `${baseUrl()}user/declineRequest`,
      { id_user, id_company },
      authHeader()
    );
  },
  async getMaintenance({ commit }, summary_id) {
    try {
      const company = JSON.parse(Cookie.get("selectedcompany"));
      const company_id = company.company.id_company;

      const response = await axios.get(
        `${baseUrl()}maintain/getMaintainItem?id_company=${company_id}&summary_id=${summary_id}`,
        authHeader()
      );
      commit("setMaintenanceData", response.data.data);
      console.log("response-->", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateAssetDamagedStatus({ dispatch }, body) {
    try {
      const response = await axios.post(
        `${baseUrl()}maintain/updateAssetDamagedStatus`,
        body,
        authHeader()
      );
    } catch (error) {
      console.log(error);
    }
  },
  async updateRepairerDetail({ dispatch }, body) {
    try {
      console.log(" body.summary_id -->", body.summary_id);
      const response = await axios.post(
        `${baseUrl()}maintain/updaterepairerDetail`,
        body,
        authHeader()
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  clearMaintenance({ commit }) {
    commit("setMaintenanceData", []);
  },

  async addmaincategory({ commit }, payload) {
    console.log("cat", payload);
    let data = await axios.post(
      `${baseUrl()}category/create/category`,
      payload,
      authHeader()
    );
    return data.data;
  },

  // async getmaincategory({ commit }, payload) {
  //   let data = await axios.get(
  //     `${baseUrl()}category/get/category?id_company=${payload}`,
  //     authHeader()
  //   );
  //   commit("setCategory", data.data.data);
  // },

  async getmaincategory({ commit }, payload) {
    let data = await axios.get(`${baseUrl()}category/get/category`, {
      params: {
        id_company: payload.id_company,
        page: payload.currentPage,
        limit: payload.perPage,
        text: payload.searchText,
      },
      ...authHeader(),
    });
    commit("setCategory", data.data.data);
    return data.data.count;
  },

  async deletemaincategory({ commit }, payload) {
    console.log("deletemaincategory", payload);
    // console.log(`${baseUrl()}category/delete/category/${payload}`)
    console.log(authHeader());
    let data = await axios.delete(
      `${baseUrl()}category/delete/category/${payload.id_category}`,
      authHeader()
    );
    // commit("delCategory", data.data.data);
  },

  async updatemaincategory({ commit }, payload) {
    // console.log("deletemaincategory" ,payload);
    // console.log(`${baseUrl()}category/delete/category/${payload}`)
    console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}category/update/category`,
      payload,
      authHeader()
    );
    // commit("updateCategory", data);
  },

  async getsubcategory({ commit }, payload) {
    // console.log("getmaincategory" ,payload);
    // console.log(`${baseUrl()}category/get/category?id_company=${payload}`)
    let data = await axios.get(`${baseUrl()}category/get/subcategory`, {
      params: {
        id_company: payload.id_company,
        id_category: payload.id_category,
        page: payload.currentPage,
        limit: payload.perPage,
        text: payload.searchText,
      },
      ...authHeader(),
    });
    console.log(data.data);
    commit("setSubCategory", data.data.data);
    return data.data.count;
  },

  async addsubcategory({ commit }, payload) {
    console.log("cat", payload);
    let data = await axios.post(
      `${baseUrl()}category/create/subcategory`,
      payload,
      authHeader()
    );
    return data.data;
  },

  async deletesubcategory({ commit }, payload) {
    // console.log("deletemaincategory" ,payload);
    // console.log(`${baseUrl()}category/delete/category/${payload}`)
    console.log(authHeader());
    let data = await axios.delete(
      `${baseUrl()}category/delete/subcategory/${payload.id_category}`,
      authHeader()
    );
    // commit("delCategory", data.data.data);
  },

  async adddepartment({ commit }, payload) {
    console.log("adddepartment", payload);
    console.log("add-departments", payload);
    let data = await axios.post(
      `${baseUrl()}map/create/department`,
      payload,
      authHeader()
    );
    return data.data;
    // commit("setAllDepartments", data.data.data);
  },

  async getalldepartments({ commit }, payload) {
    let data = await axios.get(`${baseUrl()}map/get/department`, {
      params: {
        id_company: payload.id_company,
        page: payload.currentPage,
        limit: payload.perPage,
        text: payload.searchText,
      },
      ...authHeader(),
    });
    commit("setAllDepartments", data.data.data);
    return data.data.count;
  },

  async getdepartments({ commit }, payload) {
    console.log("getdepartments-- actions", payload);
    let data = await axios.get(
      `${baseUrl()}map/get/department?id_company=${
        payload.id_company
      }&id_location_department=${payload.id_location_department}`,
      authHeader()
    );
    commit("setDepartments", data.data.data);
  },

  async deletedepartment({ dispatch }, payload) {
    // console.log(authHeader());
    console.log("payload --", payload);
    let data = await axios.delete(
      `${baseUrl()}map/delete/department/${payload.id_location_department}`,
      authHeader()
    );
    dispatch("getalldepartments", payload.id_company);
  },

  async addzone({ commit }, payload) {
    // console.log("adddepartment", payload);
    // console.log("add-departments", payload);
    let data = await axios.post(
      `${baseUrl()}map/create/zone`,
      payload,
      authHeader()
    );
    return data.data;
  },
  async getallzone({ commit }, payload) {
    // console.log("getalldepartments -- actions" , id_company);
    let data = await axios.get(`${baseUrl()}map/get/zone`, {
      params: {
        id_company: payload.id_company,
        page: payload.currentPage,
        limit: payload.perPage,
        text: payload.searchText,
      },
      ...authHeader(),
    });
    commit("setAllZone", data.data.data);
    return data.data.count;
  },
  async getzone({ commit }, payload) {
    // console.log("getdepartments-- actions" ,payload);
    let data = await axios.get(
      `${baseUrl()}map/get/zone?id_company=${
        payload.id_company
      }&id_location_zone=${payload.id_location_zone}`,
      authHeader()
    );
    commit("setZone", data.data.data);
  },
  async deletezone({ dispatch }, payload) {
    let data = await axios.delete(
      `${baseUrl()}map/delete/zone/${payload.id_location_zone}`,
      authHeader()
    );
    dispatch("getallzone", payload.id_company);
  },
  async updatezone({ commit }, payload) {
    // console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}map/update/zone`,
      payload,
      authHeader()
    );
  },

  async getAllColumn({ commit }, object) {
    try {
      const { company_id } = object;
      const response = await axios.get(
        `${baseUrl()}admin/other_column?id_company=${company_id}`,
        authHeader()
      );
      commit("setAllColumn", response.data.data);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateColumnName({ commit }, payload) {
    let data = await axios.put(
      `${baseUrl()}admin/other_column?id_company=${
        payload.id_company
      }&id_other_column=${payload.id_other_column}`,
      { name: payload.name },
      authHeader()
    );
  },
  async firstGetAllAsset_Pagination({ dispatch, commit }, object) {
    // ฟังก์ชันดึงข้อมูลสินทรัพย์ครั้งแรกในหน้า All_asset.vue หน้าสินทรัพย์ทั้งหมด
    try {
      const { company_id, page, limit } = object;

      const response = await axios.get(
        `${baseUrl()}asset/allasset/pagination/${company_id}?page=${page}&limit=${limit}`,
        authHeader()
      );
      await dispatch("loadOptionChoice");
      commit("firstSetAllAsset", {
        tableData: response.data.data.rows,
        otherColumn: response.data.other_column,
        assets_count: response.data.data.count,
      });
      // commit("setOptionChoice", response.data.options);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },
  /**
   * @param {{ mode: 'able' | 'pending' | 'approved' }} param1 
   */
  async GetWriteOffList ({ commit, dispatch }, { page = 1, limit = 50, company_id, mode, text }) {
    const user =
      typeof Cookie.get("cookielogin") === "string"
        ? JSON.parse(Cookie.get("cookielogin"))
        : Cookie.get("cookielogin");
    const response = await axios(`${baseUrl()}depreciation/write_off/${mode}/list/${company_id}`, {
      headers: { Authorization: "Bearer " + user.access_token },
      params: {
        page,
        limit,
        text,
      }
    })
    commit("firstSetAllAsset", {
      tableData: response.data.rows,
      otherColumn: response.data.other_column,
      assets_count: response.data.count,
    });
  },
  async GetMasterList ({ commit, dispatch }, { page = 1, limit = 50, company_id, request_date, text, filterOptions }) {
    const user =
      typeof Cookie.get("cookielogin") === "string"
        ? JSON.parse(Cookie.get("cookielogin"))
        : Cookie.get("cookielogin");
    const filter = {
      asset_status: filterOptions.status ?? null,
      id_category: filterOptions.id_category ?? null,
      id_sub_category: filterOptions.id_sub_category ?? null,
      id_location_building: filterOptions.id_location_building ?? null,
      id_location_floor: filterOptions.id_location_floor ?? null,
      id_location_department: filterOptions.id_location_department ?? null,
      id_location_zone: filterOptions.id_location_zone ?? null,
      note_accounting_id: filterOptions.note_accounting_id ?? null,
      sub_note_accounting_id: filterOptions.sub_note_accounting_id ?? null,
      min_start_date: filterOptions.dateUsed_start ? moment(filterOptions.dateUsed_start).format('YYYY-MM-DD') : null,
      max_start_date: filterOptions.dateUsed_end ? moment(filterOptions.dateUsed_end).format('YYYY-MM-DD') : null,
      min_useful_life: typeof filterOptions.useful_life_min == 'number' ? filterOptions.useful_life_min : null,
      max_useful_life: typeof filterOptions.useful_life_max == 'number' ? filterOptions.useful_life_max : null,
      min_expire_date: filterOptions.expire_date_min ? moment(filterOptions.expire_date_min).format('YYYY-MM-DD') : null,
      max_expire_date: filterOptions.expire_date_max ? moment(filterOptions.expire_date_max).format('YYYY-MM-DD') : null,
      min_purchase_price: typeof filterOptions.purchase_price_min == 'number' ? filterOptions.purchase_price_min : null,
      max_purchase_price: typeof filterOptions.purchase_price_max == 'number' ? filterOptions.purchase_price_max : null,
      min_scrap_value: typeof filterOptions.scrap_value_min == 'number' ? filterOptions.scrap_value_min : null,
      max_scrap_value: typeof filterOptions.scrap_value_max == 'number' ? filterOptions.scrap_value_max : null,
      min_write_off_date: filterOptions.write_off_date_min ? moment(filterOptions.write_off_date_min).format('YYYY-MM-DD') : null,
      max_write_off_date: filterOptions.write_off_date_max ? moment(filterOptions.write_off_date_max).format('YYYY-MM-DD') : null,
      min_sold_price: typeof filterOptions.write_off_amount_min == 'number' ? filterOptions.write_off_amount_min : null,
      max_sold_price: typeof filterOptions.write_off_amount_max == 'number' ? filterOptions.write_off_amount_max : null,
    }
    const response = await axios(`${baseUrl()}depreciation/master/list/${company_id}`, {
      headers: { Authorization: "Bearer " + user.access_token },
      params: {
        page,
        limit,
        request_date,
        text,
        ...filter,
      }
    })
    commit("firstSetAllAsset", {
      tableData: response.data.rows,
      otherColumn: response.data.other_column,
      assets_count: response.data.count,
    });
  },
  async GetExpireList ({ commit, dispatch }, { page = 1, limit = 50, company_id, text }) {
    const user =
      typeof Cookie.get("cookielogin") === "string"
        ? JSON.parse(Cookie.get("cookielogin"))
        : Cookie.get("cookielogin");
    const response = await axios(`${baseUrl()}depreciation/expire/list/${company_id}`, {
      headers: { Authorization: "Bearer " + user.access_token },
      params: {
        page,
        limit,
        text,
      }
    })
    commit("firstSetAllAsset", {
      tableData: response.data.rows,
      otherColumn: response.data.other_column,
      assets_count: response.data.count,
    });
  },
  async filterAllAsset_pagination({ commit, dispatch }, object) {
    try {
      // ฟังก์ชันฟิลเตอร์ข้อมูลในหน้าสินทรัพย์ทั้งหมด

      const {
        filterOptions,
        page,
        limit,
        company_id,
        saveToStore,
        forPage,
        // <---- START depreciation feature ---->
        depreciation_status,
        request_date,
        is_requesting,
        calculate_depreciation,
        asset_type,
        // <!---- END depreciation feature ---->
        // <---- START customId3 subAsset ---->
        parent,
        sub_asset,
        page_name,
        // <!---- END customId3 subAsset ---->
      } = object;

      await dispatch("loadOptionChoice");

      if (saveToStore) {
        if (forPage === "ManageAsset") {
          commit("saveManageAssetFilter", {
            filterOptions: { ...filterOptions },
          });
        } else {
          commit("setAllAssetFilter", {
            filterOptions: { ...filterOptions },
          });
        }
      }
      const requestDate = request_date
        ? moment(request_date).toISOString()
        : undefined;

      if (filterOptions) {
        let filter = filterConverter(filterOptions);
        const user =
          typeof Cookie.get("cookielogin") === "string"
            ? JSON.parse(Cookie.get("cookielogin"))
            : Cookie.get("cookielogin");
        const response = await axios.get(
          `${baseUrl()}asset/allasset/pagination/${company_id}`,
          {
            headers: { Authorization: "Bearer " + user.access_token },
            params: {
              page,
              limit,
              ...filter,
              depreciation_status: depreciation_status,
              request_date: requestDate,
              is_requesting,
              calculate_depreciation,
              asset_type,
              parent,
              sub_asset,
              page_name,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          }
        );

        // console.log("after filter: ", response.data.data.rows);
        commit("firstSetAllAsset", {
          tableData: response.data.data.rows,
          otherColumn: response.data.other_column,
          assets_count: response.data.data.count,
        });
        // commit("setOptionChoice", response.data.options);

        //await commit("getAllAssetsMutations", allAsset);
      } else {
        // await commit("firstSetAllAsset", { tableData: assets });
        await dispatch("firstGetAllAsset_Pagination", {
          company_id: company_id,
          page: page,
          limit: limit,
        });
      }
    } catch (err) {
      if (err.response) {
        alert(err.response.data.message);
      } else {
        alert(err.message);
      }
    }
  },

  async getSummaryByStatus({ commit, dispatch }, payload) {
    try {
      const {
        filterOptions,
        page,
        summary_id,
        isVerified,
        limit,
        saveToStore,
        status,
        maintenance,
        company_id,
      } = payload;
      if (saveToStore) {
        commit("setAllAssetFilter", {
          filterOptions: { ...filterOptions },
        });
      }
      await dispatch("loadOptionChoice");

      let filter = filterConverter(filterOptions);
      if (status) {
        const summary = await axios.get(
          `${baseUrl()}dashboard/summary/pagination/all_asset/${summary_id}`,
          {
            params: { page, limit, isVerified, ...filter, status },
            ...authHeader(),
          }
        );
        await commit("setSummaryData", summary.data);
        // } else if (maintenance) {
        //   const summary = await axios.get(
        //     `${baseUrl()}maintain/asset/list/company/${company_id}`,
        //     {
        //       params: { page, limit, isVerified, ...filter, status },
        //       ...authHeader(),
        //     }
        //   );
        //   await commit("setSummaryData", summary.data);
      } else {
        const summary = await axios.get(
          `${baseUrl()}dashboard/summary/pagination/all_asset/${summary_id}`,
          {
            params: { page, limit, isVerified, ...filter },
            ...authHeader(),
          }
        );
        await commit("setSummaryData", summary.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getMaintainAssetList(
    { commit, dispatch },
    { filterOptions, page, limit, saveToStore, company_id }
  ) {
    if (saveToStore) {
      commit("setAllAssetFilter", {
        filterOptions: { ...filterOptions },
      });
    }
    let filter = {};
    for (const [key, value] of Object.entries(filterOptions)) {
      if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
        filter[`${key}`] = value;
      }
    }
    const response = await axios.get(
      `${baseUrl()}maintain/asset/list/company/${company_id}`,
      {
        params: { page, limit, ...filter },
        ...authHeader(),
      }
    );
    await commit("setMaintainAssetListData", response.data);
  },
  async ExportAsset(_, payload) {
    try {
      let url = "";
      let {
        request_date,
        company_id,
        filterOptions,
        is_email,
        filename,
        id_summary,
        type,
        selected_assets,
        depreciation_status,
        is_depreciation = true,
      } = payload;

      let baseApiUrl = exportUrl();

      if (type === "ReportCount") {
        url = `${baseApiUrl}admin/export/excelsummary/${id_summary}?id_company=${company_id}`;
      } else if (type === "docRegisAsset") {
        url = `${baseApiUrl}depreciation/export/master/list/${company_id}`;
      } else if (type === "SAP") {
        // For Singha company
        url = `${baseApiUrl}admin/export/excelSAP/${company_id}`;
      } else if (type === "SAP2") {
        // For Singha company
        url = `${baseApiUrl}admin/export/excelCustomSAP/${company_id}`;
      } else if (type === "expire") {
        url = `${baseApiUrl}depreciation/export/expire/list/${company_id}`;
      } else {
        return;
      }

      let filter = {};
      if (filterOptions && (selected_assets?.length == 0 || !selected_assets)) {
        if (filterOptions && (selected_assets?.length == 0 || !selected_assets)) {
          for (const [key, value] of Object.entries(filterOptions)) {
            if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
              filter[`${key}`] = value;
            }
          }
        }
      }
      const response = await (type == "docRegisAsset" || type == "expire" ?
      axios.get(
        `${url}`,
        {
          ...authHeader(),
          params: {
            ...filter,
            text: filter.text && filter.text.length > 0 ? filter.text : null,
            request_date: moment(request_date).format('YYYY-MM-DD'),
            is_depreciation: type == 'docRegisAsset' ? is_depreciation : null,
            is_email: Boolean(is_email),
          },
          responseType: "blob",
        }
      )
      : axios.post(
        `${url}`,
        { assets_list: selected_assets?.length > 0 ? selected_assets : null },
        {
          ...authHeader(),
          params: {
            ...filter,
            is_email,
            depreciation_status,
            request_date,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          responseType: "blob",
        }
      ));

      if (!is_email) {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
        alert(err.response.data.message);
      } else {
        console.log(err.message);
        alert(err.message);
      }
    }
  },

  async ExportDpReport(_, payload) {
    try {
      let url = "";
      let {
        company_id,
        filterOptions,
        is_email,
        filename,
        type,
        selected_assets,
        depreciation_status,
        year,
        custom,
        filter,
        text,
        period_date,
      } = payload;
      // let filter = {};
      // if (filterOptions && (selected_assets?.length == 0 || !selected_assets)) {
      //   for (const [key, value] of Object.entries(filterOptions)) {
      //     if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
      //       filter[`${key}`] = value;
      //     }
      //   }
      // }

      let baseApiUrl = exportUrl();

      if (type === "dpPerMonthPiece") {
        url = `${baseApiUrl}depreciation/export/each/${company_id}`;
      } else if (type === "dpPerMonthGroup") {
        url = `${baseApiUrl}depreciation/export/cate/${company_id}`;
      } else {
        return;
      }
      const response = await axios.get(
        `${url}`,
        {
          ...authHeader(),
          params: {
            text: text && text.length > 0 ? text : null,
            period_date: period_date,
            ...filter,
          },
          responseType: "blob",
        }
      )
      if (!is_email) {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
      } else {
        console.log(err.message);
      }
    }
  },

  async getDeletedAssetPagination({ commit, getters }, object) {
    try {
      // ฟังก์ชันฟิลเตอร์ข้อมูลในหน้าสินทรัพย์ทั้งหมด
      const { filterOptions, page, limit, company_id, saveToStore } = object;
      if (saveToStore) {
        commit("setAllAssetFilter", {
          filterOptions: { ...filterOptions },
        });
      }
      let filter = {};
      if (filterOptions) {
        for (const [key, value] of Object.entries(filterOptions)) {
          if (value && (value + "").trimStart() !== "" && value !== "ทั้งหมด") {
            filter[`${key}`] = value;
          }
        }
        const user =
          typeof Cookie.get("cookielogin") === "string"
            ? JSON.parse(Cookie.get("cookielogin"))
            : Cookie.get("cookielogin");

        const response = await axios.get(
          `${baseUrl()}asset/alldeleteasset/${company_id}`,
          {
            headers: { Authorization: "Bearer " + user.access_token },
            params: { page, limit, ...filter },
          }
        );
        commit("setDeletedAsset", {
          tableData: response.data.data,
          otherColumn: response.data.other_column,
          assets_count: response.data.count,
        });
        // commit("setOptionChoice", response.data.options);
      } else {
        const response = await axios.get(
          `${baseUrl()}asset/alldeleteasset/${company_id}?page=${page}&limit=${limit}`,
          authHeader()
        );
        commit("setDeletedAsset", {
          tableData: response.data.data,
          otherColumn: response.data.other_column,
          assets_count: response.data.count,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  async loadOptionChoice({ commit, dispatch }) {
    const id_company = await dispatch("getCompanyIdCookie");
    try {
      const res = await axios.get(
        `${baseUrl()}asset/get/option/${id_company}`,
        authHeader()
      );
      commit("setOptionChoice", res.data.data);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  },

  // <---- START customId2 Jagota MaintenancePlan & branch/sector---->

  setMaintenancePlanData({ commit }, payload) {
    commit("setMaintenancePlanData", payload);
  },

  setMaintenancePlanAsset({ commit }, payload) {
    commit("setMaintenancePlanAsset", payload);
  },

  removeMaintenancePlanAsset({ commit, getters }, payload) {
    let asset = cloneDeep(getters.getSelectedMaintenanceAsset);
    asset.splice(payload, 1);
    commit("setMaintenancePlanAsset", asset);
  },

  async loadPlanRoundList(_, payload) {
    let limit = 1;
    let page = 1;
    if (payload.limit && payload.page) {
      limit = payload.limit;
      page = payload.page;
    }
    try {
      const res = await axios.get(`${baseUrl()}maPlan/getMaPlanPagination`, {
        params: {
          id_company: payload.id_company,
          preventive_ma_plan_id: payload.planId,
          page: page,
          limit: limit,
          text: payload.filterOptions?.searchText,
          sector_id: payload.filterOptions?.sector,
          branch_id: payload.filterOptions?.branch,
          category_id: payload.filterOptions?.category,
          sub_category_id: payload.filterOptions?.sub_category,
        },
        ...authHeader(),
      });

      const plan = { ...res.data.data, detail: res.data.detail };

      let planDate = plan.detail.start_date;
      const round = [];
      do {
        round.push({ ...plan, round_date: planDate });
        planDate = moment(planDate)
          .add(plan.detail.range_of_recurring_month, "M")
          .toISOString();
      } while (
        moment(planDate).isSameOrBefore(plan.detail.end_date, "date") &&
        planDate
      );
      return { plan: plan, round: round };
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        throw err;
      }
    }
  },

  async loadAllPlanRoundList(_, payload) {
    let limit = 1;
    let page = 1;
    if (payload.limit && payload.page) {
      limit = payload.limit;
      page = payload.page;
    }
    try {
      const res = await axios.get(
        `${baseUrl()}maPlan/getAllMaPlans?id_company=${payload.id_company}`,
        authHeader()
      );
      const planList = res.data.data;
      const round = [];
      for await (const plan of planList) {
        let planDate = plan.start_date;
        do {
          round.push({ ...plan, round_date: planDate });
          planDate = moment(planDate)
            .add(plan.range_of_recurring_month, "M")
            .toISOString();
        } while (moment(planDate).isSameOrBefore(plan.end_date, "date"));
      }
      return round;
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        throw err;
      }
    }
  },

  async cancelRepairReport(_, payload) {
    try {
      const res = await axios.post(
        `${baseUrl()}maPlan/cancelRepairReport`,
        {
          id_company: payload.id_company,
          asset_repair_report_id_list: payload.asset_repair_report_id_list,
        },
        authHeader()
      );
    } catch (err) {
      if (err.response) {
        throw err.response.data.message;
      } else {
        throw err;
      }
    }
  },
  async getAllSector({ commit }, payload) {
    let data = await axios.get(
      `${baseUrl()}maPlan/getAllSector?id_company=${payload}`,
      authHeader()
    );
    commit("setSector", data.data.data);
  },

  async getSector({ commit }, payload) {
    let data = await axios.get(
      `${baseUrl()}maPlan/getSector?sector_id=${payload.sector_id}&id_company=${
        payload.id_company
      }`,
      authHeader()
    );
    commit("setSectorSelect", data.data.data);
  },

  async addSector({ commit }, payload) {
    let data = await axios.post(
      `${baseUrl()}maPlan/createSector`,
      payload,
      authHeader()
    );
    return data.data;
  },

  async deleteSector({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}maPlan/deleteSector?sector_id=${
        payload.sector_id
      }&id_company=${payload.id_company}`,
      authHeader()
    );
  },

  async updateSector({ commit }, payload) {
    console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}maPlan/updateSector`,
      payload,
      authHeader()
    );
  },

  async getAllBranch({ commit }, payload) {
    let data = await axios.get(
      `${baseUrl()}maPlan/getAllBranchBySector?sector_id=${
        payload.sector_id
      }&id_company=${payload.id_company}`,
      authHeader()
    );
    commit("setBranch", data.data.data);
  },

  async addBranch({ commit }, payload) {
    let data = await axios.post(
      `${baseUrl()}maPlan/createBranch`,
      payload,
      authHeader()
    );
    return data.data;
  },

  async deleteBranch({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}maPlan/deleteBranch?sector_id=${
        payload.sector_id
      }&id_company=${payload.id_company}&branch_id=${payload.branch_id}`,
      authHeader()
    );
  },

  async updateBranch({ commit }, payload) {
    console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}maPlan/updateBranch`,
      payload,
      authHeader()
    );
  },

  async getVendor({ commit }, payload) {
    let data = await axios.get(
      `${baseUrl()}maPlan/getAllVendor?id_company=${payload}`,
      authHeader()
    );
    commit("setVendor", data.data.data);
  },

  async addVendor({ commit }, payload) {
    let data = await axios.post(
      `${baseUrl()}maPlan/createVendor`,
      payload,
      authHeader()
    );
    return data.data;
  },

  async deleteVendor({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}maPlan/deleteVendor?vendor_id=${
        payload.vendor_id
      }&id_company=${payload.id_company}`,
      authHeader()
    );
  },

  async updateVendor({ commit }, payload) {
    console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}maPlan/updateVendor`,
      payload,
      authHeader()
    );
  },

  // <---- END customId2 Jagota MaintenancePlan & branch/sector---->

  setPageState({ commit }, payload) {
    commit("setPageState", payload);
  },

  checkFileType(_, payload) {
    const file = payload;
    const size = Math.round(file.size / 1024);
    console.log(file);
    console.log("filesize = " + size);
    if (size > 10240) {
      return false;
    }
    const idxDot = file.name.lastIndexOf(".") + 1;
    const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (!(extFile == "jpg" || extFile == "jpeg" || extFile == "png")) {
      return false;
    }
    return true;
  },

  // <---- START Depreciation ---->

  async getDpNotification({ dispatch, commit }) {
    try {
      const id_company = await dispatch("getCompanyIdCookie");
      const res = await axios.get(
        `${baseUrl()}depreciation/notification/${id_company}`,
        authHeader()
      );
      commit("setDepreciationNotification", res.data);
      return res.data;
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
      } else {
        console.log(err.message);
      }
      return false;
    }
  },

  async getAllNoteAccounting({ commit }, payload) {
    try {
      let data = await axios.get(
        `${baseUrl()}noteAcc/noteAcc/${payload}`,
        authHeader()
      );
      commit("setNoteAccounting", data.data.data);
    } catch (err) {
      if (err.response) {
        alert(err.response.data.message);
      } else {
        alert(err.message);
      }
    }
  },

  async addNoteAcc({ commit }, payload) {
    let data = await axios.post(
      `${baseUrl()}noteAcc/create/noteAcc/${payload.id_company}`,
      {
        name: payload.name,
        type: payload.type,
      },
      authHeader()
    );
    return data.data;
  },

  async updateNoteAcc({ commit }, payload) {
    console.log(authHeader());
    let data = await axios.put(
      `${baseUrl()}noteAcc/update/noteAcc`,
      payload,
      authHeader()
    );
  },

  async deleteNoteAcc({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}noteAcc/delete/noteAcc?note_accounting_id=${payload}`,
      authHeader()
    );
  },

  async getNoteAccounting({ commit }, payload) {
    let data = await axios.get(
      `${baseUrl()}noteAcc/noteAcc?note_accounting_id=${payload}`,
      authHeader()
    );
    commit("setNoteAccountingSelect", data.data.data);
  },

  addSubNoteAcc({ commit }, payload) {
    return axios.post(
      `${baseUrl()}noteAcc/create/subNoteAcc/${payload.id_company}`,
      {
        code_asset: payload.code_asset,
        name_asset: payload.name_asset,
        code_acc: payload.code_acc,
        name_acc: payload.name_acc,
        code_acc_dp: payload.code_acc_dp,
        name_acc_dp: payload.name_acc_dp,
        note_accounting_id: payload.note_accounting_id,
      },
      authHeader()
    );
  },

  updateSubNoteAcc({ commit }, payload) {
    return axios.put(
      `${baseUrl()}noteAcc/update/subNoteAcc/${payload.id_company}`,
      {
        code_asset: payload.code_asset,
        name_asset: payload.name_asset,
        code_acc: payload.code_acc,
        name_acc: payload.name_acc,
        code_acc_dp: payload.code_acc_dp,
        name_acc_dp: payload.name_acc_dp,
        sub_note_accounting_id: payload.sub_note_accounting_id,
      },
      authHeader()
    );
  },

  async deleteSubNoteAcc({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}noteAcc/delete/subNoteAcc/${
        payload.id_company
      }?sub_note_accounting_id=${payload.sub_note_accounting_id}`,
      authHeader()
    );
  },

  async getOptionAllIdFixAssets({ commit }, payload) {
    try {
      let data = await axios.get(
        `${baseUrl()}asset/getOptionAllIdFixAssets/${payload}`,
        authHeader()
      );
      commit("setOptionAllIdFixAssets", data.data.options);
    } catch (error) {
      console.error(error);
    }
  },

  async updateassetAsRealEstate({ commit, dispatch }, data) {
    await axios.post(
      `${baseUrl()}asset/updateassetAsRealEstate`,
      data,
      authHeader()
    );
  },

  async ExportLand(_, payload) {
    try {
      let url = `${baseUrl()}asset/land/${payload.company_id}/download`;
      let filename = payload.filename;
      const response = await axios.get(`${url}`, {
        ...authHeader(),
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      const elink = document.createElement("a");
      elink.download = filename;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
      } else {
        console.log(err.message);
      }
    }
  },

  async ExportLandLog(_, payload) {
    try {
      let url = `${baseUrl()}asset/getlandlog/${
        payload.id_company
      }/download?id_asset=${payload.id_asset}`;
      let filename = payload.filename;
      const response = await axios.get(`${url}`, {
        ...authHeader(),
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      const elink = document.createElement("a");
      elink.download = filename;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
      } else {
        console.log(err.message);
      }
    }
  },

  async deleteLand({ commit }, payload) {
    let data = await axios.delete(
      `${baseUrl()}asset/deleteland/${payload.id_company}?id_asset=${
        payload.id_asset
      }`,
      authHeader()
    );
  },

  // <---- END Depreciation ---->

  async getPackageLimitation({ dispatch, commit }) {
    const id_company = await dispatch("getCompanyIdCookie");
    this.company_id = id_company;
    const res = await axios.get(
      `${baseUrl()}qrcode/checkRegisterQr/${this.company_id}`,
      authHeader()
    );
    commit("setPackageLimitation", res.data);
    return res.data;
  },
};
