<template>
  <div>
    <div class="position-absolute" @click="toggleMenu" v-if="isSlide">
      <div class="burger-button">
        <span class="burger-bars line-style" style="top: 0%;"></span>
        <span class="burger-bars line-style" style="top: 40%;"></span>
        <span class="burger-bars line-style" style="top: 80%;"></span>
      </div>
    </div>
    <transition name="slide-in">
      <div
        class="responsive-bar-admin"
        v-if="showMenu"
        v-click-outside="toggleMenu"
      >
        <!-- Home -->
        <div
          @click="gotoHome()"
          class="responsive-bar-admin-custom"
          style="cursor: pointer"
        >
          <img src="@/assets/Logo2.png" width="60px" height="60px" />
        </div>
        <div class="responsive-bar-admin-menu my-3">
          <!-- dashboard -->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MaintenanceDashboard' })"
            v-if="maintenanceRole!== 'user' && maintenanceRole!== 'low-technician'"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MaintenanceDashboard',
              }"
            >
              <!-- <img src="@/assets/chart-pie.svg" width="20px" height="20px" /> -->
              <img
                class="icon"
                :src="
                  $route.name === 'MaintenanceDashboard'
                    ? dashboardIconActive
                    : dashboardIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.dashboard") }}
            </p>
          </div>

          <!-- maintenance all : รายการจ้งซ่อมทั้งหมด -->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MaintenanceReportAll' })"
            v-if="maintenanceRole!== 'user' && maintenanceRole!== 'low-technician'"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MaintenanceReportAll',
              }"
            >
              <img
                class="icon"
                :src="
                  $route.name === 'MaintenanceReportAll'
                    ? reportAllIconActive
                    : reportAllIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.maintenance-all") }}
            </p>
          </div>

          <!-- technician maintain list : รายการซ่อมของฉัน -->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MyMaintenanceList' })"
            v-if="maintenanceRole!== 'user' && maintenanceRole!== 'admin'"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MyMaintenanceList',
              }"
            >
              <img
                class="icon"
                :src="
                  $route.name === 'MyMaintenanceList'
                    ? reportListIconActive
                    : reportListIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.maintenance-list") }}
            </p>
          </div>

          <!-- report list : รายการแจ้งซ่อมของฉัน -->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MyMaintenanceReportList' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MyMaintenanceReportList',
              }"
            >
              <img
                class="icon"
                :src="
                  $route.name === 'MyMaintenanceReportList'
                    ? reportIconActive
                    : reportIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.my-maintenance-report") }}
            </p>
          </div>

          <!-- technician data : ข้อมูลช่าง -->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MaintenanceTechnician' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MaintenanceTechnician',
              }"
            >
              <img
                class="icon"
                :src="
                  $route.name === 'MaintenanceTechnician'
                    ? technicianIconActive
                    : technicianIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.technician-data") }}
            </p>
          </div>

          <!-- history : ประวัติ //not edit yet-->
          <div
            class="menu-item"
            @click="$router.push({ name: 'MaintenanceHistory' })"
            v-if="maintenanceRole == 'high-technician' || maintenanceRole == 'admin'"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'MaintenanceHistory',
              }"
            >
              <img
                class="icon"
                :src="
                  $route.name === 'MaintenanceHistory'
                    ? historyIconActive
                    : historyIcon
                "
                width="20px"
                height="20px"
              />
              {{ $t("maintenance_report.history") }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dashboardIconActive from "@/assets/Maintenancing/menu_dashboardActive.png";
import dashboardIcon from "@/assets/Maintenancing/menu_dashboard.png";
import reportAllIcon from "@/assets/Maintenancing/menu_maintain-all.png";
import reportAllIconActive from "@/assets/Maintenancing/menu_maintain-allActive.png";
import reportIcon from "@/assets/Maintenancing/menu_maintain-report.png";
import reportIconActive from "@/assets/Maintenancing/menu_maintain-reportActive.png";
import reportListIcon from "@/assets/Maintenancing/menu_maintain-list.png";
import reportListIconActive from "@/assets/Maintenancing/menu_maintain-listActive.png";
import technicianIcon from "@/assets/Maintenancing/menu_technician.png";
import technicianIconActive from "@/assets/Maintenancing/menu_technicianActive.png";
import historyIcon from "@/assets/Maintenancing/menu_history.png";
import historyIconActive from "@/assets/Maintenancing/menu_historyActive.png";

export default {
  data() {
    return {
      dashboardIcon,
      dashboardIconActive,
      reportAllIcon,
      reportAllIconActive,
      reportListIcon,
      reportListIconActive,
      reportIcon,
      reportIconActive,
      technicianIcon,
      technicianIconActive,
      historyIcon,
      historyIconActive,
      showMenu: false,
      windowWidth: 0,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  computed: {
    ...mapGetters({
      haveDpNotification: "haveDpNotification",
      permissionStatus: "permissionStatus",
      maintenanceRole: "maintenance/maintenanceRole",
    }),

    isSlide() {
      if (this.windowWidth <= 1024) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkScreenSize();
    const route = this.$route.name;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    toggleMenu() {
      if (this.isSlide) {
        this.showMenu = !this.showMenu;
      }
    },
    checkScreenSize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1024) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-bar-admin-menu {
  p {
    font-size: 15px;
    margin: 0;
    &.menu-selected {
      color: #007afe;
    }
  }
  cursor: pointer;
  padding: 0 !important;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  overflow-y: auto;
  height: 100%;
  user-select: none;
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  &-submenu {
    padding-left: 1rem;
    & div {
      padding-top: 1rem;
    }
  }
}
.icon{
  margin-right: 5px;
}
.menu-item {
  padding: 15px 10px;
}
.menu-dropdown {
  .main-dropdown {
    padding: 15px 10px;
    // background-color: #dfdfdf;
  }
  .dropdown-list {
    background-color: #f8f8f8;
    .menu-dropdown-item {
      padding: 15px 10px;
      p {
        // width: 100%;
        margin-left: 15px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    box-shadow: $shadow-default;
  }
}

/*
    Animation
  */

.burger-button {
  position: absolute;
  cursor: pointer;
  height: 30px;
  top: 35px;
  left: 15px;
  width: 36px;
}

.line-style {
  height: 20%;
  left: 0;
  position: absolute;
  right: 0;
}

.burger-bars {
  background-color: white !important;
}

@media only screen and (max-width: 1024px) {
  .slide-in-enter-active,
  .slide-in-leave-active {
    transform: translate(0, 0);
    transition: transform 0.5s;
  }
  .slide-in-enter,
  .slide-in-leave-to {
    transform: translate(-100%, 0);
    transition: transform 0.5s;
  }
}

.ping {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  box-sizing: content-box;
  background: #ed1616;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* animation: load 1.5s ease-out infinite; */
}
</style>
