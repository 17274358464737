<template>
  <ag-grid-vue
    style="width: 100%; height: 450px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    @grid-ready="onGridReady"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEdit from "../Table/components/iconEdit.vue";
import btnConfirmlost from "../Table/components/btnConfirmlost.vue";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../util/accountingPrice";
export default {
  components: {
    AgGridVue,
    "table-iconEdit": iconEdit,
    btnConfirmlost,
  },
  data() {
    return {
      isSelectedAsset: false,
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
    };
  },
  props: {
    dataTable: [],
    searchText: {
      type: String,
    },
    otherColumn: [],
    page: { type: Number, default: 1 },
    perPage: { type: Number, default: 100, required: false },
  },

  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom2: "canUseCustom2",
      canUseCustom4: "canUseCustom4",
      canUseCustom8: "canUseCustom8",
    }),
    rowData() {
      return this.dataTable.map((asset, index) => {
        let assetLocation = `${this.$t(
          "asset.build"
        )} ${asset.location_building || "-"} ${this.$t(
          "asset.fl"
        )} ${asset.location_floor || "-"} ${
          this.canUseCustom8
            ? ` ${this.$t("asset.room")} ${asset.location_room || "-"}`
            : ""
        } ${this.$t("asset.dep")} ${asset.location_department || "-"} ${this.$t(
          "asset.zone"
        )} ${asset.location_zone || "-"}`;
        let dataShow = {
          index: (this.page - 1) * this.perPage + index + 1,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          asset_status: this.checkAssetStatus(asset),
          description: asset.description ? asset.description : "-",
          description2: asset.description2 ? asset.description2 : "-",
          category: asset.category ? asset.category : "-",
          sub_category: asset.sub_category ? asset.sub_category : "-",
          model: asset.sub_category2 ? asset.sub_category2 : "-",
          location: assetLocation,
          registrant: asset.registrant ? asset.registrant : "-",
          note: asset.note ? asset.note : "-",
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          acc_depreciation: asset.acc_depreciation
            ? toShowAccountingPrice(asset.acc_depreciation)
            : "-",
          net_book: asset.net_book
            ? toShowAccountingPrice(asset.net_book)
            : "-",
          delete_note: asset.note_delete || "-",
          delete_by: asset.delete_by || "-",
          asset_sector: asset.sector?.name || "-",
          asset_branch: asset.branch?.name || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor?.name || "-",
        };

        this.otherColumn.forEach((col, i) => {
          dataShow[`other_${i}`] = asset.asset_details
            ? asset?.asset_details[i]?.value
            : "-";
        });
        return dataShow;
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    //FIXME - add NEW asset_status key
    checkAssetStatus(asset) {
      let status = "-";
      if (asset.date_regis) {
        status = this.$t("home.regist");
      } else {
        status = this.$t("asset.not_reg");
      }

      // asset.status and asset.damaged_status for old asset before 15/06/2023
      if (asset.status) {
        switch (asset.status) {
          case "ใช้งานได้":
            status = this.$t("count.inuse");
            break;
          case "ไม่ได้ใช้งาน":
            status = this.$t("count.not_inuse");
            break;
          case "ชำรุด":
            status = this.$t("count.defect");
            break;
          case "อยู่ระหว่างซ่อม":
            status = this.$t("count.repairing");
            break;
        }
      }
      if (asset.damaged_status) {
        switch (asset.damaged_status) {
          case "ซ่อมได้":
            status = this.$t("count.repar");
            break;
          case "ซ่อมไม่ได้":
            status = this.$t("count.irrepar");
            break;
          case "รอการประเมิน":
            status = this.$t("count.wait_as");
            break;
          case "กำลังซ่อม":
            status = this.$t("count.repairing");
            break;
        }
      }

      if (asset.asset_status) {
        switch (asset.asset_status) {
          case "available":
            status = this.$t("count.inuse");
            break;
          case "unavailable":
            status = this.$t("count.not_inuse");
            break;
          case "broke":
            status = this.$t("count.defect");
            break;
          case "maintain":
            status = this.$t("count.repairing");
            break;
          case "cannot_repair":
            status = this.$t("count.irrepar");
            break;
        }
      }

      if (asset.is_lost === "lost") {
        status = this.$t("count.lost");
      }
      if (asset.is_lost === "report") {
        status = this.$t("filter.rep_lost");
      }
      return status;
    },
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          maxWidth: 110,
          suppressSizeToFit: true,
          getQuickFilterText: (params) => {
            return "";
          },
        },
        {
          headerName: this.$t("asset.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("asset.as_dt"),
          field: "description",
          minWidth: 150,
        },
        {
          headerName: this.$t("asset.more_description"),
          field: "description2",
          minWidth: 150,
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("asset.as_cat"),
          field: "category",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.as_sub_cat"),
          field: "sub_category",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("model.model"),
          field: "model",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom4,
        },
        {
          headerName: this.$t("home.as_loc"),
          field: "location",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_stat"),
          maxWidth: 170,
          suppressSizeToFit: true,
          field: "asset_status",
          sortable: true,
          cellStyle: (params) => {
            if (
              params.value === this.$t("count.repar") ||
              params.value === this.$t("count.irrepar") ||
              params.value === this.$t("count.wait_as")
            ) {
              return { color: "#C18B00" };
            } else if (params.value === this.$t("count.not_reg")) {
              return { color: "#888888" };
            } else if (
              params.value === this.$t("count.lost") ||
              params.value === this.$t("filter.rep_lost")
            ) {
              return { color: "#FF0000" };
            } else if (params.value === this.$t("count.inuse")) {
              return { color: "#1E9400" };
            } else if (params.value === this.$t("home.regist")) {
              return { color: "#00AB1B" };
            }
            return null;
          },
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: this.$t("home.as_re"),
          field: "registrant",
          sortable: true,
          minWidth: 200,
        },
      ];
      if (!this.isRoleLower) {
        let fix_column = [
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
          },
          {
            headerName: this.$t("asset.insurance_start"),
            field: "insurance_start",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.insurance_end"),
            field: "insurance_end",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.vendor"),
            field: "vendor",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
      }
      let deletedNote = [
        {
          headerName: this.$t("asset.note"),
          field: "delete_note",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.delete_user_name"),
          field: "delete_by",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
        },
      ];
      this.columnDefs = [...columnDefs, ...deletedNote];
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
      this.$emit("set-watcher");
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    onSelectionChanged() {
      this.isSelectedAsset =
        this.gridApi.getSelectedNodes().length !== 0 ? true : false;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.dataTable.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        maxWidth: 110,
        suppressSizeToFit: true,
        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("asset.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("asset.as_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("asset.as_dt"),
        field: "description",
        minWidth: 150,
      },
      {
        headerName: this.$t("asset.more_description"),
        field: "description2",
        minWidth: 150,
        hide: !this.canUseCustom8,
      },
      {
        headerName: this.$t("asset.as_cat"),
        field: "category",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("asset.as_sub_cat"),
        field: "sub_category",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("model.model"),
        field: "model",
        sortable: true,
        minWidth: 200,
        hide: !this.canUseCustom4,
      },
      {
        headerName: this.$t("asset.location"),
        field: "asset_sector",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom2 || this.isRoleLower,
      },
      {
        headerName: this.$t("asset.branch"),
        field: "asset_branch",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.canUseCustom2 || this.isRoleLower,
      },
      {
        headerName: this.$t("home.as_loc"),
        field: "location",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.as_stat"),
        maxWidth: 170,
        suppressSizeToFit: true,
        field: "asset_status",
        sortable: true,
        cellStyle: (params) => {
          if (
            params.value === this.$t("count.repar") ||
            params.value === this.$t("count.irrepar") ||
            params.value === this.$t("count.wait_as")
          ) {
            return { color: "#C18B00" };
          } else if (params.value === this.$t("count.not_reg")) {
            return { color: "#888888" };
          } else if (
            params.value === this.$t("count.lost") ||
            params.value === this.$t("filter.rep_lost")
          ) {
            return { color: "#FF0000" };
          } else if (params.value === this.$t("count.inuse")) {
            return { color: "#1E9400" };
          } else if (params.value === this.$t("home.regist")) {
            return { color: "#00AB1B" };
          }
          return null;
        },
      },
      {
        headerName: this.$t("home.note"),
        field: "note",
        sortable: true,
        minWidth: 150,
      },
      {
        headerName: this.$t("home.as_re"),
        field: "registrant",
        sortable: true,
        minWidth: 200,
      },
    ];
  },
  updated() {
    this.onSelectionChanged();
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
