<template>
  <div>
    <div class="position-absolute" @click="toggleMenu" v-if="isSlide">
      <div class="burger-button">
        <span class="burger-bars line-style" style="top: 0%;"></span>
        <span class="burger-bars line-style" style="top: 40%;"></span>
        <span class="burger-bars line-style" style="top: 80%;"></span>
      </div>
    </div>
    <transition name="slide-in">
      <div
        class="responsive-bar-admin"
        v-if="showMenu"
        v-click-outside="toggleMenu"
      >
        <div
          @click="gotoHome()"
          class="responsive-bar-admin-custom"
          style="cursor: pointer"
        >
          <img src="@/assets/Logo2.png" width="60px" height="60px" />
        </div>
        <div class="responsive-bar-admin-menu my-3">
          <div class="menu-dropdown">
            <div
              class="main-dropdown d-flex justify-content-between"
              @click="showDepreciationAction = !showDepreciationAction"
              :style="{
                'background-color': showDepreciationAction ? '#dfdfdf' : '#fff',
              }"
            >
              <p>
                {{ $t("depreciation.acc_manage") }}
              </p>
              <img
                src="@/assets/arrow-dropdown.svg"
                alt=""
                :style="{
                  transform: showDepreciationAction ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <transition name="down">
              <div v-if="showDepreciationAction" class="dropdown-list">
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'depreciation-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'depreciation-asset',
                    }"
                  >
                    {{ $t("depreciation.asset") }}
                  </p>
                </div>
                <!-- <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'deactivate-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'deactivate-asset',
                    }"
                  >
                    {{ $t("depreciation.do_deactivate") }}
                  </p>
                </div>
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'activate-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'activate-asset',
                    }"
                  >
                    {{ $t("depreciation.do_active") }}
                  </p>
                </div> -->
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'writeoff-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'writeoff-asset',
                    }"
                  >
                    {{ $t("depreciation.do_writeoff") }}
                  </p>
                </div>
                <div
                  v-if="this.permissionStatus.permission.role.id_role === 2"
                  class="menu-dropdown-item position-relative"
                  @click="$router.push({ name: 'confirm-action' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'confirm-action',
                    }"
                  >
                    {{ $t("depreciation.confirm_action") }}
                  </p>
                  <template v-if="haveDpNotification">
                    <div class="pinging"></div>
                    <div class="ping"></div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
          <div class="menu-dropdown">
            <div
              class="main-dropdown d-flex justify-content-between"
              @click="showMonthlyDepreciation = !showMonthlyDepreciation"
              :style="{
                'background-color': showMonthlyDepreciation
                  ? '#dfdfdf'
                  : '#fff',
              }"
            >
              <p>
                {{ $t("depreciation.monthly_depreciation") }}
              </p>
              <img
                src="@/assets/arrow-dropdown.svg"
                alt=""
                :style="{
                  transform: showMonthlyDepreciation ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <transition name="down">
              <div v-if="showMonthlyDepreciation" class="dropdown-list">
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'depreciation-report' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'depreciation-report',
                    }"
                  >
                    {{ $t("depreciation.depreciation_report") }}
                  </p>
                </div>
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'jv-document' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'jv-document',
                    }"
                  >
                    {{ $t("depreciation.jv_document") }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="menu-dropdown">
            <div
              class="main-dropdown d-flex justify-content-between"
              @click="showAccReport = !showAccReport"
              :style="{
                'background-color': showAccReport ? '#dfdfdf' : '#fff',
              }"
            >
              <p>{{ $t("depreciation.acc_report") }}</p>
              <img
                src="@/assets/arrow-dropdown.svg"
                alt=""
                :style="{
                  transform: showAccReport ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <transition name="down">
              <div v-if="showAccReport" class="dropdown-list">
                <!-- <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'paused-assets' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'paused-assets',
                    }"
                  >
                    {{ $t("depreciation.pause_asset") }}
                  </p>
                </div> -->
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'using-expired-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'using-expired-asset',
                    }"
                  >
                    {{ $t("depreciation.asset_expired_use") }}
                  </p>
                </div>
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'sold-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'sold-asset',
                    }"
                  >
                    {{ $t("depreciation.sold_asset") }}
                  </p>
                </div>
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'accounting-note' })"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'accounting-note',
                    }"
                  >
                    {{ $t("depreciation.financial_note") }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="menu-dropdown">
            <div
              class="main-dropdown d-flex justify-content-between"
              @click="showOther = !showOther"
              :style="{
                'background-color': showOther ? '#dfdfdf' : '#fff',
              }"
            >
              <p>{{ $t("depreciation.other") }}</p>
              <img
                src="@/assets/arrow-dropdown.svg"
                alt=""
                :style="{
                  transform: showOther ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <transition name="down">
              <div v-if="showOther" class="dropdown-list">
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'construction-asset' })"
                >
                  <p
                    :class="{
                      'menu-selected':
                        $route.name === 'construction-asset' ||
                        $route.name === 'construction-history',
                    }"
                  >
                    {{ $t("depreciation.building_asset") }}
                  </p>
                </div>
                <!-- Next version -->
                <!-- <div class="menu-dropdown-item">
                  <p>
                    {{ $t("depreciation.rent_permission") }}
                  </p>
                </div> -->
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'land-list' })"
                >
                  <p
                    :class="{
                      'menu-selected':
                        $route.name === 'land-list' ||
                        $route.name === 'land-edit-log' ||
                        $route.name === 'land-log-detail',
                    }"
                  >
                    {{ $t("depreciation.land") }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="menu-dropdown">
            <div
              class="main-dropdown d-flex justify-content-between"
              @click="showSetting = !showSetting"
              :style="{
                'background-color': showSetting ? '#dfdfdf' : '#fff',
              }"
            >
              <p>{{ $t("depreciation.setting") }}</p>
              <img
                src="@/assets/arrow-dropdown.svg"
                alt=""
                :style="{
                  transform: showSetting ? 'rotate(180deg)' : '',
                }"
              />
            </div>
            <transition name="down">
              <div v-if="showSetting" class="dropdown-list">
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'manage-financial-note' })"
                >
                  <p
                    :class="{
                      'menu-selected':
                        $route.name === 'manage-financial-note' ||
                        $route.name === 'manage-sub-financial-note',
                    }"
                  >
                    {{ $t("depreciation.acc_note_categoty") }}
                  </p>
                </div>
                <div
                  class="menu-dropdown-item"
                  @click="$router.push({ name: 'account-period' })"
                  v-if="this.permissionStatus.permission.role.id_role === 2"
                >
                  <p
                    :class="{
                      'menu-selected': $route.name === 'account-period',
                    }"
                  >
                    {{ $t("depreciation.account_period") }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="menu-item"
            @click="$router.push({ name: 'depreciation-status-history' })"
          >
            <p
              :class="{
                'menu-selected': $route.name === 'depreciation-status-history',
              }"
            >
              {{ $t("depreciation.depreciation_history") }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showMenu: false,
      showDepreciationAction: false,
      showMonthlyDepreciation: false,
      showAccReport: false,
      showOther: false,
      showSetting: false,
      windowWidth: 0,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  computed: {
    ...mapGetters({
      haveDpNotification: "haveDpNotification",
      permissionStatus: "permissionStatus",
    }),

    isSlide() {
      if (this.windowWidth <= 1024) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkScreenSize();
    this.$store.dispatch("getDpNotification");
    const route = this.$route.name;
    if (
      route === "depreciation-asset" ||
      route === "activate-asset" ||
      route === "deactivate-asset" ||
      route === "writeoff-asset" ||
      route === "confirm-action"
    ) {
      this.showDepreciationAction = true;
    }
    if (route === "depreciation-report" || route === "jv-document") {
      this.showMonthlyDepreciation = true;
    }
    if (
      route === "paused-assets" ||
      route === "using-expired-asset" ||
      route === "sold-asset" ||
      route === "accounting-note"
    ) {
      this.showAccReport = true;
    }
    if (
      route === "account-period" ||
      route === "manage-financial-note" ||
      route === "manage-sub-financial-note"
    ) {
      this.showSetting = true;
    }
    if (
      route === "land-list" ||
      route === "construction-asset" ||
      route === "construction-history" ||
      route === "land-edit-log" ||
      route === "land-log-detail"
    ) {
      this.showOther = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    goToDepreciationAsset() {
      this.$router.push({ name: "depreciation-asset" });
    },
    toggleMenu() {
      if (this.isSlide) {
        this.showMenu = !this.showMenu;
      }
    },
    checkScreenSize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1024) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-bar-admin-menu {
  p {
    margin: 0;
    &.menu-selected {
      color: #007afe;
    }
  }
  cursor: pointer;
  padding: 0 !important;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  overflow-y: auto;
  height: 100%;
  user-select: none;
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  &-submenu {
    padding-left: 1rem;
    & div {
      padding-top: 1rem;
    }
  }
}

.menu-item {
  padding: 15px 10px;
}
.menu-dropdown {
  .main-dropdown {
    padding: 15px 10px;
    // background-color: #dfdfdf;
  }
  .dropdown-list {
    background-color: #f8f8f8;
    .menu-dropdown-item {
      padding: 15px 10px;
      p {
        // width: 100%;
        margin-left: 15px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.down-enter-active,
.down-leave-active {
  transition: all 0.5s;
  max-height: 300px;
  transform-origin: top;
}
.down-enter, .down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0;
  transform-origin: top;
  opacity: 0;
  transition: all 0.5s;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    box-shadow: $shadow-default;
  }
}

/*
  Animation
*/

.burger-button {
  position: absolute;
  cursor: pointer;
  height: 30px;
  top: 35px;
  left: 15px;
  width: 36px;
}

.line-style {
  height: 20%;
  left: 0;
  position: absolute;
  right: 0;
}

.burger-bars {
  background-color: white !important;
}

@media only screen and (max-width: 1024px) {
  .slide-in-enter-active,
  .slide-in-leave-active {
    transform: translate(0, 0);
    transition: transform 0.5s;
  }
  .slide-in-enter,
  .slide-in-leave-to {
    transform: translate(-100%, 0);
    transition: transform 0.5s;
  }
}

.ping {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  box-sizing: content-box;
  background: #ed1616;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* animation: load 1.5s ease-out infinite; */
}

// .pinging {
//   position: absolute;
//   top: 50%;
//   left: 5px;
//   transform: translate(0, -50%);
//   box-sizing: content-box;
//   background: rgba(255, 0, 0, 1);
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
//   // animation: load 1.5s ease-out infinite;
// }

// @keyframes load {
//   0% {
//     top: 50%;
//     left: 5px;
//     transform: scale(1);
//     background: rgba(255, 0, 0, 1);
//   }
//   50% {
//     top: 50%;
//     left: 5px;
//     transform: scale(2.5);
//     background: rgba(255, 0, 0, 0);
//   }
//   100% {
//     top: 50%;
//     left: 5px;
//     transform: scale(1);
//     background: rgba(255, 0, 0, 0);
//   }
// }
</style>
