<template>
  <div class="maintenance-recheck-report-data">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- <div class="maintenance-recheck-top">
      {{ $t("maintenance_report.counting") }}
    </div> -->
    <div class="mx-2">
      <img
        @click="closeReportDataRecheck"
        src="@/assets/arrow-back-black.svg"
        style="height: 14px; cursor: pointer;"
      />
    </div>
    <div style="margin: 0 30px;">
      <div class="maintenance-recheck-data">
        {{ $t("maintenance_report.reportdata") }}
      </div>
      <MaintenanceSelectedAsset
        :assetData="assetData"
        :recheckData="recheckData"
      >
      </MaintenanceSelectedAsset>
      <div>
        <div>
          <div>{{ $t("maintenance_report.category") }}</div>
          <div class="maintenance-recheck-text">
            {{ $t(`maintenance_report.${recheckData.type}`) }}
          </div>
          <div class="my-3">{{ $t("maintenance_report.detail") }}</div>
          <div class="maintenance-recheck-text">
            {{ this.recheckData.repairDetail }}
          </div>
          <div for="img" class="mt-3">{{ $t("maintenance_report.image") }}</div>
          <div class="mb-3" style="color: #7C7C7C; font-size: 16px;"></div>
          <div class="image-show" v-if="recheckData.images.length > 0">
            <div v-for="(image, i) in recheckData.images" :key="i">
              <!-- loop upload -->
              <img :src="image.result" alt="image" class="image-upload" />
            </div>
          </div>
          <p v-else class="maintenance-recheck-text">-</p>
          <div class="my-1 ml-2">
            {{ $t("maintenance_report.reporter") }}
            <span class="maintenance-recheck-text">
              {{ getUserDetail.name }}
            </span>
          </div>
          <div class="my-1 ml-2">
            {{ $t("asset.dep") }}
            <span class="maintenance-recheck-text">
              {{ getUserDetail.department }}
            </span>
          </div>
          <div class="my-1 ml-2">
            {{ $t("maintenance_report.email") }}
            <span class="maintenance-recheck-text">
              {{ getUserDetail.email }}
            </span>
          </div>
          <div class="my-1 ml-2">
            {{ $t("maintenance_report.tel") }}
            <span class="maintenance-recheck-text">
              {{ getUserDetail.phone }}
            </span>
          </div>
          <div class="my-1 ml-2">
            {{ $t("maintenance_report.reportdate") }}
            <span class="maintenance-recheck-text">
              {{ momentDateTime(recheckData.submitdate) }} <span>น.</span>
            </span>
          </div>
          <div class="maintenance-recheck-button-next">
            <button
              @click="closeReportData"
              class="maintenance-recheck-button-cancel"
            >
              {{ $t("maintenance_report.cancel") }}
            </button>
            <button
              @click="submitReportData"
              class="maintenance-recheck-button-submit"
            >
              {{ $t("maintenance_report.create") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <BaseDialog
      :errorMessage="Dialog"
      :showModal="showModalBase"
      :showCloseButton="true"
      :hideButton="true"
      @hide-modal="hideModal()"
    >
      <div class="modal-dialog" v-if="!Dialog.show" v-click-outside="hideModal">
        <div class="text-center pb-2">
          <img src="@/assets/check_confirm.svg" alt="check" width="35px" />
        </div>
        <p class="text-center">
          {{ $t("maintenance_report.submitSuccess") }}<br />{{
            $t("maintenance_report.submitSuccessWait")
          }}
        </p>
      </div>

      <div class="modal-dialog" v-else>
        <div class="text-center pb-2">
          <img src="@/assets/cancle.svg" alt="error" width="35px" />
        </div>
        <p class="text-center">
          {{ $t(`maintenance_report.${Dialog.message}`) }}
        </p>
      </div>
    </BaseDialog>
  </div>
</template>
<script>
import BaseDialog from "../../components/BaseDialog.vue";
import MaintenanceSelectedAsset from "./MaintenanceSelectedAsset.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import { authHeader } from "@/store/actions";
import baseUrl from "@/util/backend";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";

export default {
  components: {
    BaseDialog,
    MaintenanceSelectedAsset,
    Loading,
  },
  emits: ["close-report-data", "close-report-data-recheck"],
  props: {
    assetData: {
      type: Object,
      default: null,
    },
    recheckData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      images: [],
      imagesFile: [],
      showModalBase: false,
      isLoading: false,
      maintenanceData: this.recheckData,
      assetDataFromData: this.assetData,
      img_Url: [],
      ResponseItemId: 0,
      Dialog: { show: false, message: "" },
    };
  },
  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
    }),
  },
  methods: {
    momentDateTime(date) {
      return moment(date).isValid()
        ? moment(date).format("DD/MM/YYYY - HH:mm")
        : null;
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (let i = 0; i < files.length; i++) {
        this.createImage(files[i]);
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images.push({ file, result: e.target.result });
      };
      reader.readAsDataURL(file);
    },
    closeReportData() {
      this.$emit("close-report-data");
    },
    closeReportDataRecheck() {
      this.$emit("close-report-data-recheck");
    },
    showReportDetail(ItemId) {
      try{
        this.$emit("show-report-detail", ItemId);
      }catch(err){
        console.log(err)
      }
    },
    hideModal() {
      this.showModalBase = false;
      this.$emit('reload-table');
      setTimeout(() => {
        if (this.Dialog.show) {
          this.Dialog.show = false;
          this.closeReportData();
        } else this.showReportDetail(this.ResponseItemId);
      }, 100);
    },
    //compress Imagefile
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      this.isLoading = true;
      for (const img of this.maintenanceData.imageFile) {
        const fileforupload = await this.compressImage(img);
        var file = new File([fileforupload], img.name, {
          type: "image/jpeg",
        });

        var formdata = new FormData();
        formdata.append("folder_id", 2);
        formdata.append("file", file);

        const res = await this.$store.dispatch("uploadImage", formdata);
        // console.log("res.data.data;", res.data.data);
        this.url_new = res.data.data.uploadFileAtLevel.url;
        this.img_Url.push(this.url_new);
        // console.log(this.url_new);
      }
      // console.log(this.img_Url);
      this.isLoading = false;
    },
    // create maintenance report
    async submitReportData() {
      if (this.isLoading == false) {
        try {
          await this.upload();
          const res = await axios.post(
            `${baseUrl()}maintain/create`,
            {
              id_company: this.assetDataFromData.id_company,
              id_asset: this.assetDataFromData.id_asset,
              type: this.maintenanceData.type,
              asset_img: this.img_Url,
              description: this.maintenanceData.repairDetail,
            },
            {
              headers: authHeader().headers,
            }
          );
          // console.log(res.data.message);
          if (res.data.message != null) {
            this.ResponseItemId = res.data.message;
            this.showReportDetail(this.ResponseItemId);
          }
          this.showModalBase = true;
        } catch (error) {
          console.log(error.response)
          this.showModalBase = true;
          this.Dialog.show = true;
          if ( error.response.data.message == "There's non-finished maintain request.") {
            this.Dialog.message = "error-not-finish";
          } else if (error.response.data.message == "Asset not found.") {
            this.Dialog.message = "error-not-found";
          } else if(error.response.status == 403){
            this.Dialog.message = "error-lost";
          } else {
            this.Dialog.message = "invalidQr-title";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.maintenance-recheck-report-data {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 0;
  flex-direction: column;
  color: black;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  overflow: auto;
}
.maintenance-recheck-top {
  text-align: center;
  width: 100%;
  height: 24px;
  background: #acd4ff;
  font-size: 12px;
}
.maintenance-recheck-data {
  font-size: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 12px;
  padding: 3px;
  border-bottom: 1px solid #ededed;
}
.maintenance-recheck-data-list {
  padding: 3px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 12px;
}
.maintenance-recheck-button {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-recheck-form-select {
  width: 100%;
  height: 45px;
  background: transparent;
  border: solid 1px;
  border-radius: 11px;
  padding: 0 0.75rem;
}
.maintenance-recheck-button-next {
  display: flex;
  justify-content: flex-end;
}
.maintenance-recheck-button-cancel {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: #ffffff;
  color: #007afe;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-recheck-button-submit {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: #007afe;
  color: #ffffff;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-recheck-text {
  font-size: 16px;
  font-weight: 300;
}
.image-upload {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.image-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 6px;
  border-bottom: 1px solid #ededed;
}
.modal-dialog {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
