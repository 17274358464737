<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <div class="scan-qr">
      <!-- <p>{{getDashboardModalFilter}}</p>
      <hr>
      <p>{{currentFilterOptions}}</p>
      <hr>
      <p>{{filterOptions}}</p> -->
      <!-- Page -->
      <div class="back-icon">
        <img src="../../assets/previous.svg" width="30" @click="goBack()" />
      </div>
      <h1 class="header-text">
        <div class="topic" style="padding-top: 0px !important;">
          {{ this.statusName }}
        </div>
      </h1>
      <b-modal
        id="filter-modal"
        @cancel="handleclear"
        :cancel-title="$t('btn.clear')"
        hide-header-close
        hide-header
        :ok-title="$t('btn.conf')"
        @ok="handleFilter"
      >
        <b-row>
          <b-col class="text-end" cols="2"> </b-col>

          <b-col class="text-center" cols="8">
            <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
          </b-col>
          <b-col class="text-end" cols="2">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('filter-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <Custom2Filter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          :filterOptions="currentFilterOptions"
          @set-filter-option="addCustomFilter"
        />
        <label class="mt-2">{{ $t("home.cat") }}</label>
        <v-select
          :options="optionCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("filter.sub_cat") }}</label>
        <v-select
          :options="optionSubCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_sub_category"
          class="dropdown-style"
          :clearable="false"
        />
        <Custom4Filter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          :filterOptions="currentFilterOptions"
          @set-filter-option="addCustomFilter"
        />

        <label class="mt-2">{{ $t("home.build") }}</label>
        <v-select
          :options="optionBuilding"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_building"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.fl") }}</label>
        <v-select
          :options="optionFloor"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_floor"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.department") }}</label>
        <v-select
          :options="optionDepartment"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_department"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.zone") }}</label>
        <v-select
          :options="optionZone"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_zone"
          class="dropdown-style"
          :clearable="false"
        />

        <!-- <div v-if="!isRoleLower">
          <label class="mt-2">{{ $t("filter.purchase_date") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="currentFilterOptions.datePurchase_min"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              :min="currentFilterOptions.datePurchase_min"
              class="form-control form-border"
              v-model="currentFilterOptions.datePurchase_max"
            />
          </div>

          <label class="mt-2">{{ $t("filter.purchase_price") }}</label>

          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="currentFilterOptions.purchase_price_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="currentFilterOptions.purchase_price_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.useful_life") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest')"
              v-model="currentFilterOptions.useful_life_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest')"
              v-model="currentFilterOptions.useful_life_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.date_start") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="currentFilterOptions.dateUsed_start"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              :min="currentFilterOptions.dateUsed_start"
              class="form-control form-border"
              v-model="currentFilterOptions.dateUsed_end"
            />
          </div>

          <label class="mt-2">{{ $t("filter.acc_depreciation") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="currentFilterOptions.acc_depreciation_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="currentFilterOptions.acc_depreciation_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.net_book") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="currentFilterOptions.net_book_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="currentFilterOptions.net_book_max"
            ></vue-numeric>
          </div>
        </div> -->
      </b-modal>

      <div>
        <div class="card-form-login">
          <div class="sub-menu">
            <div class="input-group w-100">
              <TmpFilter
                :searchTerm="searchText"
                @set-search-term="
                  (text) => {
                    searchText = text;
                    searchByText();
                  }
                "
              />
              <span
                class="input-group-text"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px;"
              >
                <!-- @click="resetSearch" -->
                <img src="../../assets/g-search.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  margin-right: 1rem;
                  border-radius: 0 10px 10px 0;
                "
                :placeholder="$t('btn.search')"
                v-model="searchText"
                v-debounce:700ms="searchByText"
              />
              <div>
                <!-- <div class="input-group-append">
              </div> -->
              </div>
              <button
                v-if="!isFinishedCounting"
                class="filter"
                style="border: none;"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <!-- @click="filterShow = true" -->
                <img src="../../assets/filter-white.svg" />
              </button>
            </div>
            <div
              class="d-flex align-items-center px-3 filter-count-box pb-3"
              v-if="filterCount > 0"
            >
              <div class="filter-count me-3">
                <p class="px-2 m-0">
                  {{ $t("filter.filter_count") }}:
                  <span class="font-weight-bold">{{ filterCount }}</span>
                </p>
              </div>
              <u @click="handleclear" class="m-0" style="cursor: pointer;">{{
                $t("filter.clear_filter")
              }}</u>
            </div>
            <p class="text-start px-3" style="font-size: 14px; color: #7c7c7c;">
              {{ totalpages }} {{ $t("asset.total_items") }}
            </p>
          </div>
          <div class="asset-box mt-4" v-if="summarydata_rows.length > 0">
            <!-- Detail Each Asset -->
            <div
              v-for="(item, index) in summarydata_rows"
              :key="index"
              class="item-asset"
              @click="goToDetail(item)"
            >
              <Lazyloadimage
                v-if="item.image_url"
                class="banner"
                :image="checkImage(item.image_url)"
                :holderimg="holderimg"
              >
              </Lazyloadimage>

              <Lazyloadimage v-else class="banner" :image="noimg" alt="" />

              <div class="detail-asset">
                <p class="asset-id">
                  {{ item.id_fixed_asset }}
                </p>
                <p class="asset-detail">
                  {{ item.name }}
                </p>
                <p class="asset-detail">
                  {{ item.description || "-" }}
                </p>
              </div>
            </div>

            <!-- End Here -->
          </div>
          <div
            class="d-flex justify-content-center"
            style="margin-top: 25%;"
            v-else
          >
            <h1 class="">{{ $t("asset.dt_not") }}</h1>
          </div>
        </div>
        <div
          v-if="summarydata_rows.length > 0"
          class="d-flex justify-content-center mt-3"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalpages"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <TotalPageCount
          v-if="summarydata_rows.length > 0"
          :page="currentPage"
          :perPage="perPage"
          :currentAmount="summarydata_rows.length"
          :total="totalpages"
        />
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import TmpFilter from "@/components/CustomComponent/customTmpFilter.vue";
import Custom2Filter from "@/components/CustomComponent/customFilterJGT.vue";
import Custom4Filter from "@/components/CustomComponent/customFilterNinja.vue";
import { mapGetters } from "vuex";
import Lazyloadimage from "../../components/Lazyloadimage.vue";
import TotalPageCount from "../../components/TotalPageCount.vue";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: false,
      isFinishedCounting: true,
      searchText: "",
      onClick: true,
      filterShow: false,
      rows: 10,
      filterDashboard: [],
      perPage: 24,
      currentPage: 1,
      countingStatus: "ทั้งหมด",
      currentFilterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      },
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      },
      isAllAsset: false,
      noimg: require("../../image/Not-regis-qr-code.png"),
      holderimg: require("../../image/Pic_Loading.jpg"),
      storeAllAsset: [],
      statusName: null,
      // filterShow:false,
    };
  },

  methods: {
    addCustomFilter(e) {
      this.currentFilterOptions = {
        ...this.currentFilterOptions,
        ...e,
      };
    },
    handleclear() {
      this.searchText = "";
      this.currentFilterOptions = {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      };
      this.handleFilter();
    },

    checkImage(image) {
      if (typeof image === "string") {
        let images = JSON.parse(JSON.parse(image));
        return images.url;
      } else {
        return image.url;
      }
    },
    goBack() {
      this.$router.push(`/dashboard/${this.$route.params.id_summary}`);
    },
    goToDetail(asset) {
      if (!this.isFinishedCounting) {
        this.$router.push(`/detailOneAsset/${asset.id_asset}`);
      } else {
        this.$router.push(
          `/assetCounting/${this.$route.params.id_summary}/${asset.id_asset}`
        );
      }
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    async handleFilter() {
      this.isLoading = true;
      this.filterOptions = {
        ...this.currentFilterOptions,
      };
      // this.$store.dispatch("setDashboardModalFilter", {
      //   ...this.filterOptions,
      //   text: this.searchText,
      // });

      await this.$store.dispatch("getSummaryByStatus", {
        summary_id: this.$route.params.id_summary,
        status: this.$route.params.status,
        page: 1,
        limit: this.perPage,
        filterOptions: this.filterOptions,
      });
      this.setFilter();
      this.isFinishedCounting = this.summaryData.summary.is_finish;
      this.isLoading = false;
    },
    async searchByText() {
      this.isLoading = true;
      this.currentFilterOptions.text = this.searchText;
      this.filterOptions = this.currentFilterOptions;
      await this.$store.dispatch("getSummaryByStatus", {
        summary_id: this.$route.params.id_summary,
        status: this.$route.params.status,
        page: 1,
        limit: this.perPage,
        filterOptions: this.filterOptions,
      });
      this.isLoading = false;
    },

    setFilter() {
      const assets = this.getDashboardFilter;
      // this.storeAllAsset = this.storeAllAsset = !this.isAllAsset? this.getDashboardFilter : this.storeAllAsset
      return assets;
    },
    setFilterFromStore() {
      if (this.getDashboardModalFilter) {
        this.currentFilterOptions.building = this.getDashboardModalFilter.building;
        this.currentFilterOptions.floor = this.getDashboardModalFilter.floor;
        this.currentFilterOptions.department = this.getDashboardModalFilter.department;
        this.currentFilterOptions.zone = this.getDashboardModalFilter.zone;
        this.searchText = this.currentFilterOptions.text;
      }

      this.handleFilter();
    },
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      getDashboardFilter: "getDashboardFilter",
      getUserDetail: "getUserDetail",
      getAllAssetsStore: "getAllAssetsStore",
      getDashboardModalFilter: "getDashboardModalFilter",
      optionChoice: "getfilterOption",
      isRoleLower: "isRoleLower",
      summaryData: "getSummaryData",
    }),
    summarydata_rows() {
      // console.log("this.summaryData",this.summaryData)
      return this.summaryData?.result?.data?.rows || [];
    },
    filterCount() {
      if (this.filterOptions) {
        let filter = this.filterOptions;
        let count = 0;
        // console.log("filter", filter);
        if (filter.category && filter.category !== "ทั้งหมด") count++;
        if (filter.sub_category && filter.sub_category !== "ทั้งหมด") count++;
        if (filter.sub_category2) count++;
        if (
          filter.status !== "ทั้งหมด" &&
          !this.$route.params.id_summary &&
          !this.$route.params.status
        )
          count++;
        if (filter.building && filter.building !== "ทั้งหมด") count++;
        if (filter.floor && filter.floor !== "ทั้งหมด") count++;
        if (filter.department && filter.department !== "ทั้งหมด") count++;
        if (filter.zone && filter.zone !== "ทั้งหมด") count++;
        if (filter.intendant) count++;
        if (filter.vendor_id) count++;
        if (filter.sector_id) count++;
        if (filter.branch_id) count++;
        if (filter.dateUsed_start !== null || filter.dateUsed_end !== null)
          count++;
        if (
          filter.datePurchase_min !== null ||
          filter.datePurchase_min != null
        ) {
          if (filter.datePurchase_min != "" || filter.datePurchase_max != "") {
            count++;
          }
        }

        if (
          filter.useful_life_min !== null ||
          filter.useful_life_max !== null
        ) {
          if (filter.useful_life_min != "" || filter.useful_life_max != "") {
            count++;
          }
        }

        if (
          filter.purchase_price_min !== null ||
          filter.purchase_price_max !== null
        ) {
          if (
            filter.purchase_price_min != "" ||
            filter.purchase_price_max != ""
          ) {
            count++;
          }
        }

        if (
          filter.acc_depreciation_min !== null ||
          filter.acc_depreciation_max !== null
        ) {
          if (
            filter.acc_depreciation_min != "" ||
            filter.acc_depreciation_max != ""
          ) {
            count++;
          }
        }

        if (filter.net_book_min !== null || filter.net_book_max !== null) {
          if (filter.net_book_min != "" || filter.net_book_max != "") {
            count++;
          }
        }

        return count;
      } else {
        return 0;
      }
    },
    // filterDashboard() {
    //   return this.setFilter();
    // },
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.currentFilterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.currentFilterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }

      // console.log("?????", this.getAllAssetsStore[0]);
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.currentFilterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.currentFilterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { id: null, label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ id: null, label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    totalpages() {
      const total = this.summaryData.result?.data?.count || 0;
      return Math.ceil(total);
    },
  },
  mounted() {
    switch (this.$route.params.status) {
      case "available":
        this.statusName = this.$t("count.inuse");
        break;
      case "unavailable":
        this.statusName = this.$t("count.not_inuse");
        break;
      case "broke":
        this.statusName = this.$t("count.defect");
        break;
      case "maintain":
        this.statusName = this.$t("count.repairing");
        break;
      case "cannot_repair":
        this.statusName = this.$t("count.irrepar");
        break;
      case "lost":
        this.statusName = this.$t("count.lost");
        break;
      case "uncounting":
        this.statusName = this.$t("count.not_count");
        break;
      case "unregister":
        this.statusName = this.$t("count.not_reg");
        break;
      case "อยู่ระหว่างซ่อม":
        this.statusName = this.$t("count.repairing");
        break;
      default:
        break;
    }
    this.setFilterFromStore();
  },

  components: {
    Footer,
    Lazyloadimage,
    TmpFilter,
    Custom2Filter,
    Custom4Filter,
    TotalPageCount,
    Loading,
  },
  watch: {
    async currentPage(value) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("getSummaryByStatus", {
          page: value,
          limit: this.perPage,
          summary_id: this.$route.params.id_summary,
          status: this.$route.params.status,
          filterOptions: this.filterOptions,
        });

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    "currentFilterOptions.dateUsed_start"() {
      if (
        !this.currentFilterOptions.dateUsed_end ||
        this.currentFilterOptions.dateUsed_end <
          this.currentFilterOptions.dateUsed_start
      ) {
        this.currentFilterOptions.dateUsed_end = this.currentFilterOptions.dateUsed_start;
      }
    },
    "currentFilterOptions.datePurchase_min"() {
      if (
        !this.currentFilterOptions.datePurchase_max ||
        this.currentFilterOptions.datePurchase_max <
          this.currentFilterOptions.datePurchase_min
      ) {
        this.currentFilterOptions.datePurchase_max = this.currentFilterOptions.datePurchase_min;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}

#basic-addon1 {
  // border: 1px solid #007bff;
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}

.asset-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  word-wrap: break-word;
}

.banner {
  width: 70px;
  height: 70px;
  z-index: 10;
  object-fit: cover;
  border: #c4c4c4 2px solid;
  border-radius: 15px;
  margin: 0 auto;
}

.item-asset {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 375px) {
  .banner {
    height: 90px;
    width: 90px;
  }
}
@media only screen and (min-width: 440px) {
  .banner {
    height: 130px;
    width: 130px;
  }
}
@media only screen and (min-width: 640px) {
  .item-asset {
    width: 70%;
  }
  .banner {
    height: 140px;
    width: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .banner {
    height: 180px;
    width: 180px;
  }
}

@media only screen and (min-width: 1024px) {
  .asset-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  // .card-form {
  //   width: 700px !important;
  // }
  .banner {
    width: 200px;
    height: 200px;
    z-index: 10;
    object-fit: cover;
  }
}

.icon-back-to-top {
  width: 35px;
  height: 35px;
  background: $color-active-blue;
  padding: 0.5rem;
  border-radius: 2px;

  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.btn-to-top {
  width: 30px;
  height: 30px;
  font-size: 22px;
  line-height: 22px;
  background: #007afe 0% 0% no-repeat padding-box;
}
.detail-seemore {
  font-size: 11px;
  padding-top: 5px;
  color: #271a1a;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}
.detail-asset {
  @extend .banner;
  padding-top: 1rem;
  font-size: 16px;
  text-align: left;
  max-width: 220px;
  margin: 0 auto;
  border: none;
  height: auto;

  .asset-id {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  .asset-detail {
    @extend .asset-id;
    color: $font-grey;
    word-break: break-all;
  }
}

.sub-menu {
  color: $font-grey;
  // padding-bottom: 2rem;
  // display: flex;
  // grid-template-columns: 1fr;
  // grid-gap: 0.5rem;
  // justify-content: center;
}
.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}
.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.sub-menu button {
  margin-right: 1rem;
  cursor: pointer;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.btn-seemore {
  margin-top: 1rem;
  border-radius: 12px;
  font-size: 14px;
  padding: 0.3rem 1rem;
}

.btn-category {
  background: white;
  // color: #007afe;
  border-radius: 15px;
  padding: 0rem 2rem 0rem 2rem;
}

.detail {
  font-size: 11px;
  color: #7c7c7c;
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}
.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 2.5rem;
  // margin-left: 1rem;
  display: grid;
  gap: 1rem 1rem;
}

.card-form {
  width: 80%;
  padding-top: 50px;
  // padding: 2rem 1.5rem 2.5rem 1.5rem;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin-top: 8%;
  margin-bottom: 10%;
  height: 480px;
  overflow: hidden;
}

.card-form-login {
  margin: 5%;
  margin-bottom: 0px;
}

#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1025px) {
  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
  .card-form {
    width: 85%;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    margin-bottom: 17%;
    height: 480px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    // padding-bottom: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .card-form {
    padding-top: 30px;
    height: 480px;
    left: 0%;
    right: 0%;
    top: 5%;
    bottom: 10%;
    overflow: hidden;
  }
  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .card-form {
    width: 85%;
    height: 480px;
    padding-top: 50px;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    overflow: hidden;
  }
  .filter-block-inside {
    // width: 90%;
    // margin: auto;
    margin-top: 2.5rem;
    // margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

.btn-login {
  // margin-left: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  // background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
  //   0% no-repeat padding-box;
  width: 160px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: $background-gradient-btn-blue;
}

.btn-regist {
  width: 160px;
  height: auto;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}
@media only screen and (max-width: 766px) {
  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

.filter-count {
  border: 2px solid #007afe;
  border-radius: 5px;
}

.filter-count-box {
  color: #007afe;
}

.filter-box {
  height: 45px;
  border: 0px;
  background-color: #e0e0e0;
}

.input-group {
  // border-right-style: none;
  // width: 100%;
  // height: 38px;
  display: flex;
  // padding: 0;
  // margin-top: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}
</style>
