<template>
  <div class="maintenance-detail">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- <div class="maintenance-detail-top">
      {{ $t("maintenance_report.counting") }}
    </div> -->
    <div class="d-flex justify-content-between">
      <div style="margin-left:30px;">
        <b-dropdown
          class=""
          id="edit-menu"
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          dropleft
          style="outline: none;"
        >
        <!-- '(($route.name != 'MaintenanceReportAll' &&  maintenanceRole == 'admin') && dataFromApi.status !== 'in_progress')' -->
          <template #button-content>
            <img
              src="@/assets/more.svg"
              style="width: 18px; height: 14px; cursor: pointer;"
            />
          </template>
          <b-dropdown-item @click="downloadPDF">
            <img src="@/assets/Maintenancing/pdf-download.svg" alt="download" class="mr-1" />
            {{ $t("maintenance_report.download") }}
          </b-dropdown-item>

          <hr class="dropdown-line visible-on-has-next-li" />

          <!-- reject -->
          <b-dropdown-item
            v-if="
              (dataFromApi.status === 'pending' || dataFromApi.status === 'in_progress') &&
              (maintenanceRole == 'high-technician' || maintenanceRole == 'admin' || 
              (maintenanceRole == 'low-technician' && $route.name === 'MyMaintenanceList'))
            "
            @click="manageRequestDialog('reject')"
          >
            <img
              src="@/assets/Maintenancing/manage_reject.svg"
              alt="reject"
              class="mr-1"
            />
            {{ $t("maintenance_report.reject") }}
          </b-dropdown-item>
          <!-- reset -->
          <b-dropdown-item
              v-if="
                (dataFromApi.status === 'in_progress' || dataFromApi.status === 'reject') &&
                (maintenanceRole == 'high-technician' || maintenanceRole == 'admin' || 
                (maintenanceRole == 'low-technician' && $route.name === 'MyMaintenanceList'))
              "
              @click="manageRequestDialog('reset')"
            >
              <img
                src="@/assets/Maintenancing/reset.svg"
                alt="reject"
                class="mr-1"
              />
              {{ $t("maintenance_report.reset") }}
          </b-dropdown-item>
          <!-- cancel -->
          <template v-if="dataFromApi.status === 'pending' || dataFromApi.status === 'in_progress'">
            <!-- high-technician -->
            <b-dropdown-item
              v-if="
                (maintenanceRole === 'high-technician' || maintenanceRole == 'admin')
              "
              @click="manageRequestDialog('cancel')"
            >
              <img src="@/assets/cancel_detail.svg" alt="cancel" class="mr-1" />
              {{ $t("maintenance_report.cancel") }}
            </b-dropdown-item>

            <!-- lower-technician -->
            <b-dropdown-item
              v-else-if="
                maintenanceRole=== 'low-technician' &&
                (($route.name === 'MyMaintenanceReportList' && dataFromApi.status === 'pending') ||
                $route.name === 'MyMaintenanceList')
              "
              @click="manageRequestDialog('cancel')"
            >
              <img src="@/assets/cancel_detail.svg" alt="cancel" class="mr-1" />
              {{ $t("maintenance_report.cancel") }}
            </b-dropdown-item>

            <!-- user -->
            <b-dropdown-item
              v-else-if="
                maintenanceRole=== 'user' &&
                $route.name === 'MyMaintenanceReportList' &&
                dataFromApi.status === 'pending'
              "
              @click="manageRequestDialog('cancel')"
            >
              <img src="@/assets/cancel_detail.svg" alt="cancel" class="mr-1" />
              {{ $t("maintenance_report.cancel") }}
            </b-dropdown-item>
          </template>
          <!-- end cancel -->
        </b-dropdown>
        
      </div>
      <div class="mx-2 my-auto" >
        <img
          @click="closeReportData"
          src="@/assets/close.svg"
          style="width: 18px; height: 14px; cursor: pointer;"
        />
      </div>
    </div>
    <div style="margin: 0 30px;">
      <div class="maintenance-detail-data">
        {{ $t("maintenance_report.reportdata") }}
      </div>
      <div>
        <div>
          <div class="maintenance-detail-text">
            <div class="detail-first-section">
              <div class="d-flex justify-content-between">
                <p class="detailHead">
                  {{ $t("maintenance_report.maintain-ref-no") }}
                </p>
                <p class="detail">{{ dataFromApi.maintain_ref }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="detailHead">
                  {{ $t("maintenance_report.category") }}
                </p>
                <p class="detail">
                  {{ $t(`maintenance_report.${dataFromApi.type}`) }}
                </p>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <p class="detailHead py-2">{{ $t("asset.status") }}</p>
                <!-- status dropdown component -->
                <MaintenanceStatusDropdown
                  @upper-status-detail="statusFromComponent"
                  :dataFromApi="dataFromApi.status"
                  v-if="
                    (maintenanceRole == 'high-technician' || maintenanceRole == 'admin' || (maintenanceRole== 'low-technician' && $route.name == 'MyMaintenanceList')) 
                    && dataFromApi.status !== 'cancel' && dataFromApi.status !== 'complete'
                  "
                />
                <p :class="dataFromApi.status" v-else>
                  {{ $t(`maintenance_report.${dataFromApi.status}`) }}
                </p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <p class="detailHead py-2">
                  {{ $t("maintenance_report.urgency") }}
                </p>
                <!-- status dropdown component -->
                <MaintenanceUrgencyDropdown
                  @upper-urgency-detail="urgencyFromComponent"
                  :dataFromApi="dataFromApi.priority"
                  v-if="
                    (maintenanceRole == 'high-technician' || maintenanceRole == 'admin' ) 
                    && dataFromApi.status !== 'cancel' && dataFromApi.status !== 'complete'
                  "
                />
                <p :class="dataFromApi.priority" v-else>
                  {{ $t(`maintenance_report.${dataFromApi.priority}`) }}
                </p>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <p class="detailHead py-2">
                  {{ $t("maintenance_report.technician-name") }}
                </p>
                <!-- technician dropdown component -->
                <MaintenanceTechnicianDropdown
                  @upper-assign="assignTo"
                  :technicianData="technicianData"
                  :dataFromApi="dataFromApi.technician_name"
                  v-if="
                    (maintenanceRole== 'high-technician' || maintenanceRole == 'admin' ) &&
                      dataFromApi.status !== 'cancel' && dataFromApi.status !== 'complete'
                  "
                />
                <p v-else>
                  {{ dataFromApi.technician_name }}
                </p>
              </div>
            </div>
          </div>
          <div class="asset-thumbnail">
            <MaintenanceSelectedAsset :assetData="assetData" :detail="detail" />
          </div>
          <div class="position-relative">
            <div class="detail-and-image">
              {{ $t("maintenance_report.item-problem") }}
              <img
                v-if="
                  $route.name == 'MyMaintenanceReportList' &&
                    dataFromApi.status == 'pending'
                "
                src="@/assets/Maintenancing/edit.svg"
                class="position-absolute user-edit"
                @click="reporterEdit = !reporterEdit"
                width="18px"
              />
              <b-form-textarea
                v-model="reporterEditReport"
                id="detail-for-upper"
                placeholder="สาเหตุ"
                rows="3"
                max-rows="6"
                class="b-form-input"
                v-if="reporterEdit"
              >
              </b-form-textarea>
              <p class="maintenance-detail-text pt-1" v-else>
                {{ dataFromApi.description }}
              </p>

              <div for="img" class="mt-3">
                {{ $t("maintenance_report.image") }}
              </div>
              <div class="mb-3" style="color: #7C7C7C; font-size: 16px;">
                {{ $t("maintenance_report.imageDes") }}
              </div>
              <b-form-file
                v-model="reporterEditImages"
                id="reporterEditImages"
                style="display: none;"
                multiple
                accept="image/*"
                @change="onFileChange"
                v-if="reporterEdit"
              ></b-form-file>
              <!-- pic section -->
              <div class="image-show">
                <div v-for="(image, i) in imageURL" :key="i" class="image-for">
                  <img :src="image" alt="image" class="image-upload" />
                  <img
                    class="DelIconImg"
                    src="@/assets/cancle.svg"
                    width="20px"
                    @click="deleteImage(i)"
                    v-if="reporterEdit"
                  />
                </div>
                <div v-if="imageURL.length == 0 && !reporterEdit" class="mx-1">
                  -
                </div>
                <div
                  v-for="(image, i) in reporterEditImagesShow"
                  :key="i"
                  class="image-for"
                >
                  <img :src="image.result" alt="image" class="image-upload " />
                  <img
                    class="DelIconImg"
                    src="@/assets/cancle.svg"
                    width="20px"
                    @click="deleteImageShow(i)"
                  />
                </div>

                <label
                  style="cursor: pointer;"
                  for="reporterEditImages"
                  v-if="reporterEdit"
                >
                  <img
                    src="@/assets/upload.svg"
                    alt="upload"
                    width="80px"
                    hight="80px"
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="reporter-detail">
            <div class="my-1 ml-2">
              {{ $t("maintenance_report.reporter") }}
              <span class="maintenance-detail-text">
                {{ dataFromApi.request_user_info.name }}
              </span>
            </div>
            <div class="my-1 ml-2">
              {{ $t("asset.dep") }}
              <span class="maintenance-detail-text">
                {{ dataFromApi.request_user_info.department }}
              </span>
            </div>
            <div class="my-1 ml-2">
              {{ $t("maintenance_report.email") }}
              <span class="maintenance-detail-text">
                {{ dataFromApi.request_user_info.email }}
              </span>
            </div>
            <div class="my-1 ml-2">
              {{ $t("maintenance_report.tel") }}
              <span class="maintenance-detail-text">
                {{ dataFromApi.request_user_info.telephone }}
              </span>
            </div>
            <div class="my-1 ml-2">
              {{ $t("maintenance_report.reportdate") }}
              <span class="maintenance-detail-text">
                {{ momentDateTime(dataFromApi.request_user_info.request_at) }}
              </span>
            </div>
          </div>
          <div
            class="border-bottom"
            v-if="
              dataFromApi.status !== 'cancel' &&
                dataFromApi.status !== 'complete'
            "
          ></div>
          <!-- for upper maintenanceRole-->
          <div
            class="for-upper"
            v-if="
              (maintenanceRole== 'high-technician' || maintenanceRole == 'admin' ||
                (maintenanceRole== 'low-technician' &&
                  $route.name == 'MyMaintenanceList')) &&
                dataFromApi.status !== 'cancel' &&
                dataFromApi.status !== 'complete'
            "
          >
            <div class="d-flex justify-content-center upper-detail-head">
              <div>{{ $t("maintenance_report.repair-detail") }}</div>
              <img
                src="@/assets/Maintenancing/edit.svg"
                class="edit"
                @click="edit = !edit"
              />
            </div>

            <div class="sub-header my-2">
              {{ $t("maintenance_report.cost-list") }}
            </div>
              <div v-for="(cost, index) in allCost" :key="index">
                <div class="row added-cost justify-content-between py-1">
                  <div class="col-7">
                    <div>{{ $t("asset.data_list") }} {{ index + 1 }}</div>
                    <div style="padding-left: 10px;">{{ cost.name }}</div>
                  </div>
                  <div class="col-4">
                    <div>
                      {{ $t("maintenance_report.cost") }} ({{
                        $t("maintenance_report.baht")
                      }})
                    </div>
                    <div style="padding-left: 10px;">
                      {{ cost.cost }}
                    </div>
                  </div>
                  <div class="col-1 p-0" v-if="edit">
                    <img
                      src="@/assets/Maintenancing/bin.svg"
                      @click="deleteCost(index)"
                    />
                  </div>
                </div>
              </div>
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <b-form-group
                  id="cost-for-upper"
                  :label="
                    allCost.length > 0
                      ? $t('asset.data_list') + `${allCost.length + 1}`
                      : $t('asset.data_list')
                  "
                  label-for="cost"
                  class="b-form-group"
                >
                  <b-form-input
                    v-model="costValue"
                    id="cost"
                    :placeholder="$t('maintenance_report.add-cost-example')"
                    required
                    class="b-form-input"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group
                  id="cost-amount"
                  :label="
                    $t('maintenance_report.cost') +
                      ' (' +
                      $t('maintenance_report.baht') +
                      ')'
                  "
                  label-for="amount"
                  class="b-form-group"
                >
                  <b-form-input
                    v-model="amountValue"
                    id="cost-amount"
                    type="number"
                    placeholder="0.00"
                    class="b-form-input"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <button class="btn-upper mx-1" @click="addCost">
              + {{ $t("maintenance_report.add") }}
            </button>
            <label for="detail" class="sub-header">{{
              $t("maintenance_report.repair-detail")
            }}</label>
            <b-form-textarea
              v-model="maintenanceDetail"
              id="detail-for-upper"
              :placeholder="$t('maintenance_report.note')"
              rows="3"
              max-rows="6"
              class="b-form-input"
            >
            </b-form-textarea>

            <div class="sub-header">
              {{ $t("maintenance_report.repair-evidence") }}
            </div>
            <b-form-file
              multiple
              style="display: none;"
              id="maintainEvidence"
              @change="onFileChange"
            ></b-form-file>
            <label
              style="cursor: pointer;"
              for="maintainEvidence"
              class="btn-upper mx-1"
            >
              {{ $t("maintenance_report.upload-evidence") }}
              <img
                src="@/assets/Maintenancing/upload.svg"
                width="17px"
                class="upload"
              />
            </label>
            <!-- show PDF -->
            <div class="pdf-show">
              <div v-for="(image, i) in images" :key="i" class="image-for">
                <div
                  v-if="image.file.type.includes('application/pdf')"
                  class="pdfFile d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex">
                    <img
                      src="@/assets/Maintenancing/file-icon.svg"
                      alt="file-icon"
                    />
                    <div class="m-2" style="overflow-wrap: anywhere;">
                      <span>{{ image.file.name }}</span>
                      <span class="d-block" v-if="image.file.size"
                        >{{ $t("maintenance_report.size") }}
                        {{ convertSize(image.file.size) }} K.</span
                      >
                    </div>
                  </div>
                  <div>
                    <img
                      class="m-1"
                      src="@/assets/cancle.svg"
                      width="20px"
                      @click="deleteImage(i)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="image-show mt-2">
              <div v-for="(image, i) in images" :key="i" class="image-for">
                <div v-if="image.file.type.includes('image/')">
                  <img
                    class="DelIconImg"
                    src="@/assets/cancle.svg"
                    width="20px"
                    @click="deleteImage(i)"
                  />
                  <img :src="image.result" alt="image" class="image-upload" />
                </div>
              </div>
            </div>
          </div>
          <div class="maintenance-detail-button-next items-align-right">
            <button
              @click="closeReportData"
              class="maintenance-detail-button-cancel"
            >
              {{ $t("maintenance_report.close") }}
            </button>
            <button
              @click="submitChange()"
              class="maintenance-detail-button-submit"
              v-if="
                (maintenanceRole== 'high-technician' || maintenanceRole == 'admin' || maintenanceRole== 'low-technician') &&
                  dataFromApi.status !== 'cancel' &&
                  dataFromApi.status !== 'complete'
              "
            >
              {{ $t("maintenance_report.save") }}
            </button>
            <!-- user button -->
            <button
              @click="userUpdateReport()"
              class="maintenance-detail-button-submit"
              v-if="
                reporterEdit &&
                  maintenanceRole!== 'high-technician' &&
                  maintenanceRole!== 'admin' &&
                  maintenanceRole!== 'low-technician'
              "
            >
              {{ $t("maintenance_report.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog
      :successDetail="successDetail"
      :manage="manage"
      :showModal="showModal"
      :showCloseButton="false"
      :hideButton="true"
      :size="(size = 'S')"
      @hide-modal="hideModal()"
      @hide-modal-success="closeModalSuccess()"
      @manage-request="manageRequest"
    >
    </ConfirmDialog>
    <BaseDialog
      :showModal="showModalBase"
      :errorMessage="Dialog"
      :showCloseButton="true"
      :hideButton="true"
      @hide-modal="hideModalBase()"
    >
      <div v-click-outside="hideModalBase">
        <div class="modal-dialog" v-if="Dialog.show == 'error'" >
          <div class="text-center pb-2">
            <img src="@/assets/cancle.svg" alt="error" width="35px" />
          </div>
          <p class="text-center"> {{ $t('maintenance_report.request-fail') + " : " + Dialog.message }}</p>
        </div>

        <div class="modal-dialog" v-else>
          <div class="text-center pb-2">
            <img
              src="@/assets/check_confirm.svg"
              width="40px"
              alt="success"
              class="m-3"
            />
          </div>
          <p class="text-center">{{ Dialog.message }}</p>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>
<script>
import MaintenanceSelectedAsset from "./MaintenanceSelectedAsset.vue";
import MaintenanceStatusDropdown from "@/components/Maintenance/MaintenanceStatusDropdown.vue";
import MaintenanceTechnicianDropdown from "@/components/Maintenance/MaintenanceTechnicianDropdown.vue";
import MaintenanceUrgencyDropdown from "@/components/Maintenance/MaintenanceUrgencyDropdown.vue";
import ConfirmDialog from "@/components/MaintenanceConfirmDialog.vue";
import Loading from "vue-loading-overlay";
import Compressor from "compressorjs";
import BaseDialog from "@/components/BaseDialog.vue";

import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import { authHeader } from "../../store/actions";
import baseUrl from "@/util/backend";

export default {
  components: {
    BaseDialog,
    Loading,
    ConfirmDialog,
    MaintenanceSelectedAsset,
    MaintenanceStatusDropdown,
    MaintenanceTechnicianDropdown,
    MaintenanceUrgencyDropdown,
  },
  emits: ["close-report-data", "close-report-data-recheck"],
  props: {
    maintenanceRole: {
      type: String,
      default: "user",
    },
    /** @type {import('vue').PropOptions<{ status: 'pending' | 'in_progress' | 'complete' | 'reject' | 'cancel' }>} */
    dataFromApi: {
      type: Object,
      default: null,
    },
    ItemIdFromTable: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      reporterEditImagesShow: [],
      reporterEditImages: [],
      imageURL: [],
      reporterEdit: false,
      reporterEditReport: this.dataFromApi.description,
      technicianData: null,
      requestSuccess: true,
      isSubmit: false,
      isLoading: false,
      maintenanceDetail: this.dataFromApi.note_maintain? this.dataFromApi.note_maintain : "",
      manageReason: "",
      edit: false,
      detail: false,
      showModal: false, //modal
      showModalBase: false, //base modal
      Dialog: { show: null, message: "" },
      // dataFromApi: {},
      assetData: null,
      costValue: "",
      amountValue: null,
      allCost: [],
      images: [],
      img_Url: [],
      manage: "", //modal
      action: "",
      componentAction: "",
      componentUrgency: "",
      componentAssign: null,
    };
  },
  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      manageOptions: "maintenance/getManageOptions",
    }),
  },
  async created() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    // this.isLoading = true;
    await this.getMaintenanceDetail();
    await this.getTechnicianData();
    // this.isLoading = false;
    await this.showImage();
    if(this.dataFromApi.receipt){
      this.dataFromApi.receipt.forEach((element) => {
      const name = element.substring(element.lastIndexOf('/') + 1);
      const typeRead = element.substring(element.lastIndexOf('.')+ 1);
      let type = ''
      if(typeRead == "pdf"){
        type = `application/${typeRead}`
      } else {
        type = `image/${typeRead}`
      }
      this.images.push({file:{ name:name, type: type }, result: element })
      });
    }
    // console.log(this.images)
  },
  methods: {

    async downloadPDF() {
      console.log(this.dataFromApi)
      try {
          const res = await axios.get(`${baseUrl()}maintain/${this.ItemIdFromTable}/pdf/company/${this.id_company}`, {
          ...authHeader(),
          responseType: "blob", // set response type to blob
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // create link to download file
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.dataFromApi.id_fixed_asset}-Maintenance_Detail.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error);
        }
        console.log(error);
      }
    },
    async submitChange() {
      if (this.costValue == "") {
        if (this.reporterEdit) {
          try{
            await this.userUpdateReport();
            await this.upperUpdateMaintenanceDetail();
            
          } catch (error) {
            console.log(error);
            alert(error.response.data.message);
          }
        } else {
          await this.upperUpdateMaintenanceDetail();
        }
      } else if (this.costValue.length > 0 || this.amountValue.length > 0) {
        alert("Please click add cost");
      }
    },
    async userUpdateReport() {
      try {
        this.isLoading = true;
        await this.upload();
        const res = await axios.put(
          `${baseUrl()}maintain/${this.dataFromApi.id_maintain}/user`,
          {
            id_company: this.id_company,
            type: "common",
            asset_img: this.imageURL,
            description: this.reporterEditReport,
          },
          { headers: authHeader().headers }
        );
        this.reporterEdit = false;
      } catch (error) {
        console.log(error);
      }
    },
    showImage() {
      this.dataFromApi.asset_img.forEach((element) => {
        this.imageURL.push(element);
      });
    },
    async getTechnicianData() {
      try {
        const res = await axios.get(
          `${baseUrl()}company/${this.id_company}/technicianList`,
          { headers: authHeader().headers }
        );
        this.technicianData = res.data.message;
      } catch (err) {
        console.log(err);
      }
    },
    momentDateTime(date) {
      return moment(date).isValid()
        ? moment(date).format("DD/MM/YYYY - HH:mm")
        : null;
    },
    closeModalSuccess() {
      this.showModal = false;
      // window.location.reload();
      setTimeout(() => {
        this.closeReportData();
      }, 200);
      this.$emit("reload-table");
    },
    // open confirm dialog modal
    manageRequestDialog(value) {
      this.manage = value;
      this.showModal = true;
      // console.log(this.manage);
    },
    convertSize(size) {
      return Math.round(size / 1024);
    },
    deleteImage(imageIndex) {
      if (this.reporterEdit) {
        this.imageURL.splice(imageIndex, 1);
        // console.log(this.imageURL);
      } else {
        this.images.splice(imageIndex, 1);
      }
    },
    deleteImageShow(imageIndex) {
      this.reporterEditImagesShow.splice(imageIndex, 1);
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (let i = 0; i < files.length; i++) {
        this.createImage(files[i]);
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.reporterEdit === true) {
          this.reporterEditImagesShow.push({ file, result: e.target.result });
          // console.log(this.reporterEditImagesShow);
        } else {
          this.images.push({ file, result: e.target.result });
        }
      };
      reader.readAsDataURL(file);
    },
    deleteCost(index) {
      // console.log(index);
      this.allCost.splice(index, 1);
    },
    addCost() {
      if (this.costValue === "" || this.amountValue === null) {
        return;
      }
      const name = this.costValue;
      const cost = parseFloat(this.amountValue);
      this.allCost.push({
        name: name,
        cost: cost,
      });
      this.costValue = "";
      this.amountValue = null;
      // console.log(this.allCost);
    },

    statusFromComponent(value) {
      this.componentAction = value;
      // console.log(this.componentAction);
    },

    urgencyFromComponent(value) {
      this.componentUrgency = value;
      // console.log(this.componentUrgency);
    },
    assignTo(value) {
      this.componentAssign = value;
      // console.log(this.componentAssign);
    },
    //update maintenance detail Upper technician
    async upperUpdateMaintenanceDetail() {
      try {
        this.isLoading = true;
        await this.upload();
        let data = {};
        console.log(this.dataFromApi);
        if (this.componentAction != "") {
          data.status = this.componentAction;
        }
        if (this.componentUrgency != "") {
          data.priority = this.componentUrgency;
        }
        if (this.componentAssign != null) {
          data.id_technician = this.componentAssign;
        }
        if (this.img_Url.length > 0) {
          data.receipt = this.img_Url;
        }
        (data.id_company = this.id_company),
          (data.note_maintain = this.maintenanceDetail),
          (data.fee = this.allCost),
          console.log(data);
        const res = await axios.put(
          `${baseUrl()}maintain/${this.$props.ItemIdFromTable}/technician`,
          data,
          { headers: authHeader().headers }
        );
        this.Dialog = {
          show: "success",
          message: this.$t("maintenance_report.confirm-success"),
        };
        this.isLoading = false;
        this.showModalBase = true;
        this.isSubmit = true;
        // setTimeout(() => {
        //   this.showModalBase = false;
        //   this.$emit("close-report-data");
        // }, 3000);
      } catch (error) {
        console.log(error);
        this.Dialog = {
          show: "error",
          // message: error.response.data.message,
          message: this.$t("maintenance_report.error"),
        };
        this.isLoading = false;
        this.showModalBase = true;
      }
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      this.isLoading = true;
      try {
        if (this.reporterEdit) {
          for (const img of this.reporterEditImagesShow) {
            const fileforupload = await this.compressImage(img.file);
            let file = new File([fileforupload], img.file.name);
            let formdata = new FormData();
            formdata.append("folder_id", 2);
            formdata.append("file", file);
            const res = await this.$store.dispatch("uploadImage", formdata);
            this.imageURL.push(res.data.data.uploadFileAtLevel.url);
          }
          this.reporterEditImagesShow = [];
          this.reporterEditImages = [];
          this.isLoading = false;
        } else {
          for (const img of this.images) {
            if(!img.result.includes('https')){
              if (img.file.type.includes("image/")) {
              const fileforupload = await this.compressImage(img.file);
              var file = new File([fileforupload], img.file.name);
              // console.log(file);
            } else {
              file = new File([img.file], img.file.name);
              // console.log(file);
            }
            var formdata = new FormData();
            formdata.append("folder_id", 2);
            formdata.append("file", file);

            const res = await this.$store.dispatch("uploadImage", formdata);
            // console.log("res.data.data;", res.data.data);
            this.url_new = res.data.data.uploadFileAtLevel.url;
            this.img_Url.push(this.url_new);
            } else {
              this.img_Url.push(img.result);
            }
          }
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },

    //get maintaintenance detail (table)
    getMaintenanceDetail() {
      this.assetData = {
        image_url: { url: this.dataFromApi.asset_image_url },
        id_fixed_asset: this.dataFromApi.id_fixed_asset,
        name: this.dataFromApi.asset_name,
        id_asset: this.dataFromApi.id_asset,
      };
      if (this.dataFromApi.fee !== null) {
        this.allCost.push(...this.dataFromApi.fee);
      }
    },
    closeReportData() {
      this.$emit("close-report-data");
    },
    submitReportData() {
      this.showModal = true;
    },
    hideModal() {
      console.log(this.showModalBase);
      this.showModal = false;
      if (this.manage == null) {
        setTimeout(() => {
          this.closeReportData();
        }, 100);
      }
    },
    hideModalBase() {
      if (this.isSubmit == true) {
        this.showModalBase = false;
        setTimeout(() => {
          this.$emit("close-report-data");
        }, 400);
      } else {
        this.showModalBase = false;
      }
    },

    async manageRequest(value) {
      await this.manageRequestAPI(value.action, value.reason);
    },
    async manageRequestAPI(action, reason) {
      try {
        if (action === "reject") {
          const res = await axios.put(
            `${baseUrl()}maintain/${this.$props.ItemIdFromTable}/technician`,
            {
              id_company: this.id_company,
              status: action,
              note_reject: reason,
            },
            { headers: authHeader().headers }
          );
          this.Dialog.show = "success";
        } else if(action === "reset"){
          const res = await axios.put(
            `${baseUrl()}maintain/${this.$props.ItemIdFromTable}/reset`,
            {
              id_company: this.id_company,
              status: action,
              note_reject: reason,
            },
            { headers: authHeader().headers }
          );
          this.Dialog.show = "success";
        } else {
          const res = await axios.put(
            `${baseUrl()}maintain/${this.$props.ItemIdFromTable}/cancel`,
            {
              id_company: this.id_company,
              status: action,
              note_maintain: reason,
            },
            { headers: authHeader().headers }
          );
        }
        this.Dialog.show = "success";
        this.Dialog.message = this.$t(
          `${
            this.manageOptions.find((option) => option.value === action).success
          }`
        );
        this.isSubmit = true;
        this.showModalBase = true;
      } catch (error) {
        console.log(error);
        this.Dialog.show = "error";
        this.showModalBase = true;
        this.Dialog.message = error.response.data.message;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pdfFile {
  font-size: 13px;
  font-weight: 350;
  position: relative;
}
.DelIconImg {
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}
.image-for {
  position: relative;
}
.image-upload {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.image-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 6px;
}
.upper-detail-head {
  position: relative;
}
.edit {
  position: absolute;
  top: 3px;
  left: 300px;
}
.added-cost {
  font-size: 14px;
}
.upload {
  margin-left: 7px;
}
.b-form-group {
  font-size: 14px;
}
.b-form-input {
  margin: 5px 0;
  font-size: 14px;
  background-color: white !important;
  border: 1px solid #cfcfcf !important;
  border-radius: 9px;
}
.btn-upper {
  width: 97%;
  margin: 5px 0;
  padding: 5px 0;
  border: solid 1px #ededed;
  border-radius: 11px;
  font-size: 16px;
  background-color: #ededed;
  text-align: center;
}
.btn-upper:hover {
  background-color: #cdcdcd;
}
.sub-header {
  padding: 5px 0;
  font-size: 16px;
}
.border-bottom {
  border-bottom: 1px solid #ededed;
  margin-bottom: 12px;
  padding: 5px 0;
}

.detail-and-image {
  border-bottom: 1px solid #ededed;
  margin-bottom: 12px;
  padding: 5px 0;
}
.dropdown-line {
  width: 60%;
  margin: 3px auto;
  color: #cfcfcf;
}
.asset-thumbnail {
  margin: 20px 0;
}
.detail-first-section {
  border-bottom: 1px solid #ededed;
}
.detailHead {
  color: black;
  font-weight: 400;
  font-size: 16px;
}
.detail {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
}
.status {
  color: #d3a500;
  font-size: 16px;
  font-weight: 300;
}
.maintenance-detail {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 0;
  flex-direction: column;
  color: black;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  overflow: auto;
}
.maintenance-detail-top {
  text-align: center;
  width: 100%;
  height: 24px;
  background: #acd4ff;
  font-size: 12px;
}
.maintenance-detail-data {
  font-size: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 12px;
  padding: 3px;
  border-bottom: 1px solid #ededed;
}
.maintenance-detail-data-list {
  padding: 3px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 12px;
}
.maintenance-detail-button {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-detail-form-select {
  width: 100%;
  height: 45px;
  background: transparent;
  border: solid 1px;
  border-radius: 11px;
  padding: 0 0.75rem;
}
.maintenance-detail-button-next {
  display: flex;
  justify-content: flex-end;
}
.maintenance-detail-button-cancel {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 30%;
  height: 45px;
  background: #ffffff;
  color: #7c7c7c;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-detail-button-submit {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 30%;
  height: 45px;
  background: #007afe;
  color: #ffffff;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-detail-text {
  font-size: 16px;
  font-weight: 300;
}
.image-upload {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.image-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 6px;
  padding-bottom: 10px;
  // border-bottom: 1px solid #ededed;
}
.modal-dialog {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep .btn {
  background: none;
  width: fit-content;
  display: block;
  padding: 0;
  min-width: 0px;
}
::v-deep .dropdown-toggle::after {
  display: none;
}
::v-deep .dropdown-item:active {
  background-color: #e9ecef;
}

.pending {
  font-weight: 500;
  font-size: 16px;
  color: #fea800;
}
.in_progress {
  font-weight: 500;
  font-size: 16px;
  color: #4685ff;
}
.complete {
  font-weight: 500;
  font-size: 16px;
  color: #00ab1b;
}
.cancel {
  font-weight: 500;
  font-size: 16px;
  color: #616161;
}
.reject {
  font-weight: 500;
  font-size: 16px;
  color: #f46363;
}
.other {
  font-weight: 500;
  font-size: 16px;
  color: #a864d2;
}
.low {
  font-weight: 500;
  font-size: 16px;
  color: #00ab1b;
}
.medium {
  color: #fea800;
}
.null {
  color: #000000;
}
.high {
  color: #f46363;
}
.user-edit {
  top: 10;
  right: 0;
}
.icon-delete {
  z-index: 1;
  right: 10;
  top: 0;
}

.visible-on-has-next-li {
  display: none;
}

.visible-on-has-next-li:has(+ li) {
  display: block !important;
}
</style>
