<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>
    <!-- Filter Modal -->
    <b-modal
      hide-header-close
      hide-header
      id="filter-modal"
      :cancel-title="$t('btn.clear')"
      @cancel="handleclear"
      :ok-title="$t('btn.conf')"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("home.permission") }}</label>
      <select class="form-control" v-model="filterOptions.role">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in position"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
      <label>{{ $t("filter.dep") }}</label>
      <select class="form-control" v-model="filterOptions.department">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in department"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
    </b-modal>
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div style="padding: 0.4em;">
        <SuccessDialog
          :msg="successMessage"
          v-show="showSuccess"
        ></SuccessDialog>
        <div class="tab-content" id="myTabContent">
          <div class="pt-4 pb-2 gradient-text">
            <h2 class="head-table-text">{{ $t("home.user") }}</h2>
          </div>
        </div>
        <div class="mb-4">
          <b-row class="w-100 m-0">
            <b-col
              class="mb-2 mb-sm-0 d-flex justify-content-md-center"
              cols="12"
              md="2"
            >
              <button
                class="d-flex filter text-light p-2 m-0"
                style="border: none;"
                type="button"
                @click="openModalAddUser"
              >
                + {{ $t("btn.add_us") }}
              </button>
            </b-col>
            <b-col class="d-flex" cols="10" md="9">
              <span
                class="filter-box p-2 d-flex mr-2"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px;"
              >
                <img src="../../assets/search-white.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  border-radius: 0 10px 10px 0 !important;
                "
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </b-col>
            <b-col cols="2" md="1" class="d-flex align-items-center">
              <button
                class="filter"
                style="border: none;"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <!-- @click="(filterShow = !filterShow), (searchClick = false)" -->

                <img src="../../assets/filter-white.svg" width="20px" />
              </button>
            </b-col>
          </b-row>
        </div>

        <div class="main">
          <!-- Ag Grid User's Table -->
          <admin-edit-user-table
            :dataTable="userSearchResult"
            ref="userTable"
          />
          <!-- Modal Delete User -->
          <b-modal
            class="p-2"
            id="delete-user-modal"
            hide-header-close
            hide-header
            hide-footer
            centered
          >
            <div class="row">
              <p class="text-center">
                {{ $t("count.press") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("count.click_todel") }} <br />
                <b>{{ $t("home.name") }}</b>
                {{ selectedDeleteUser.name }} <br />
                <b>{{ $t("home.permission") }}</b>
                {{ selectedDeleteUser.role }}
              </p>
            </div>
            <div class="row">
              <button
                type="button"
                class="cancle col"
                @click="$bvModal.hide('delete-user-modal')"
              >
                {{ $t("btn.canc") }}
              </button>
              <button
                type="button"
                class="confirm col"
                data-dismiss="modal"
                @click="confirmDelete"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </b-modal>

          <!-- Modal Confirm User -->
          <div>
            <div
              class="modal fade"
              id="confirmLostModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div
                  class="modal-content container-fluid pt-4 pb-2"
                  style="border-radius: 10px;"
                >
                  <div class="row">
                    <p>
                      {{ $t("count.press") }} <b>{{ $t("btn.conf") }}</b>
                      {{ $t("count.verify_us") }} <br />
                      {{ data.name }} <br />
                      <b>{{ $t("home.pos") }}</b> {{ data.role }}
                      <b>{{ $t("home.dep") }}</b>
                      {{ data.department }}
                    </p>
                  </div>
                  <div class="row">
                    <button
                      type="button"
                      class="btn cancle col"
                      data-dismiss="modal"
                    >
                      {{ $t("btn.canc") }}
                    </button>
                    <button
                      type="button"
                      class="confirm col"
                      data-dismiss="modal"
                      @click="confirmUser()"
                    >
                      {{ $t("btn.conf") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add User -->
        <b-modal
          id="addUser"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
        >
          <form @submit.prevent="loopSendEmailInvite">
            <b-row>
              <b-col class="text-end">
                <button
                  class="close_modal text-light border-none"
                  @click="$bvModal.hide('addUser')"
                  type="button"
                >
                  <b-icon-x scale="1.5" />
                </button>
              </b-col>
            </b-row>
            <b-form-group>
              <p style="text-align: center;">
                {{ $t("dialog.sel_type") }}
              </p>
              <select class="w-100 form-control" v-model="selected" required>
                <option :value="null" disabled>
                  {{ $t("dialog.sel_access_level") }}
                </option>
                <option
                  v-for="(item, index) in optionPosition"
                  :key="index"
                  :value="item.id_permission"
                >
                  {{ item.text }}
                </option>
              </select>
              <img
                class="w-100"
                style="margin-top: 1rem;"
                src="@/image/permission-tech_new.png"
                v-if="technicianSelected"
              />
              <template v-else>
                <img
                  v-if="$i18n.locale === 'en'"
                  class="w-100"
                  style="margin-top: 1rem;"
                  src="@/image/permission_picture_new5_eng.png"
                />
                <img
                  v-else
                  class="w-100"
                  style="margin-top: 1rem;"
                  src="@/image/permission_picture_new5.png"
                />
              </template>
            </b-form-group>
            <b-row>
              <b-col cols="12 " class="d-flex pt-1 pb-3 flex-wrap">
                <div
                  class="tag-email"
                  v-for="(item, index) in emailArr"
                  :key="index"
                >
                  <p class="mb-0">{{ item }}</p>
                  <span style="margin-left: 5%;">
                    <button
                      type="button"
                      class="delete-tag text-light border-none h-100 align-self-center"
                      @click="removeTag(index)"
                    >
                      x
                    </button></span
                  >
                </div></b-col
              >
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  :state="state"
                  :invalid-feedback="invalidFeedback"
                >
                  <b-input-group>
                    <input
                      class="form-control"
                      type="text"
                      v-model="email"
                      :placeholder="$t('password.fill_mail')"
                    />
                    <b-input-group-append>
                      <b-button
                        type="button"
                        style="
                          width: 75px !important;
                          border-radius: 0 10px 10px 0 !important;
                        "
                        @click="addTag()"
                        >{{ $t("btn.add") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-4 flex justify-content-center text-center"
              >
                <button
                  class="filter h-auto p-2 px-5 text-light"
                  style="border: none;"
                  type="submit"
                  :disabled="emailArr.length <= 0"
                >
                  {{ $t("btn.invite") }}
                </button>
              </b-col>
            </b-row>
          </form>
        </b-modal>

        <!-- Modal After Send Email -->
        <b-modal
          id="addUserResult"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-row>
            <b-col class="text-center" v-if="isSuccess">
              <img
                src="./../../assets/check-circle-fill.svg"
                width="102"
                height="102"
                alt=""
              />
              <p
                class="mt-3"
                style="color: #007afe; font-size: 23px; font-weight: normal;"
              >
                {{ $t("dialog.invite_done") }}
              </p>
            </b-col>
            <b-col class="text-center" v-else>
              <img
                src="./../../assets/cancle.svg"
                width="102"
                height="102"
                alt=""
              />
              <p
                class="mt-3"
                style="color: #007afe; font-size: 23px; font-weight: normal;"
              >
                {{ $t("dialog.invite_fail") }}
              </p>
            </b-col>
          </b-row>
        </b-modal>
      </div>
      <!-- Modal Edit User -->
      <b-modal
        v-if="getEditUserDetail"
        class="p-2"
        id="edit-user-modal"
        hide-header-close
        hide-header
        hide-footer
        centered
      >
        <div class="text-center">
          <b-row align-h="end">
            <button
              class="close_modal text-light border-none p-0 mx-2"
              @click="$bvModal.hide('edit-user-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-row>
          <h3 class="gradient-text pb-2">{{ $t("home.edit_dt") }}</h3>

          <form @submit.prevent="editUserPermission">
            <div class="input-group input-group-edit">
              <label>{{ $t("home.name_sur") }}</label>
              <input type="text" v-model="getEditUserDetail.name" disabled />
            </div>

            <div class="input-group input-group-edit">
              <label>{{ $t("home.phonenum") }}</label>
              <input type="tel" v-model="getEditUserDetail.phone" disabled />
            </div>

            <div class="input-group input-group-edit">
              <label>{{ $t("login.email") }}</label>
              <input type="text" v-model="getEditUserDetail.email" disabled />
            </div>

            <div class="input-group input-group-edit pt-2 pb-2">
              <label
                >{{ $t("home.department") }}<span class="require-red">*</span>
              </label>
              <input
                type="text"
                v-model="getEditUserDetail.userCompany.department"
              />
            </div>

            <div
              class="input-group input-group-edit pt-2 pb-2"
              style="border-radius: 10px !important;"
            >
              <label
                >{{ $t("home.permission") }}<span class="require-red">*</span>
              </label>
              <br />
              <select class="form-control w-100" v-model="selectedPosition">
                <option
                  v-for="(option, key) in optionPosition"
                  :key="key"
                  :value="option.text"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <button class="btn btn-save" type="submit">
              {{ $t("btn.saveedit") }}
            </button>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserRoles } from "@/constants/UserRoles";
import Swal from "sweetalert2";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import AdminEditUserTable from "@/components/Table/AdminEditUserTable.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";
export default {
  data() {
    return {
      isLoading: false,
      showSuccess: false,
      successMessage: "",
      id_company: null,
      searchText: "",
      onClick: true,
      editShow: false,
      deleteShow: false,
      filterShow: false,
      searchClick: false,
      filterOptions: {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      },
      role_choice: [],
      selectedPosition: null,
      initialUserPosition: null,
      index: "",
      data: {
        id: "",
        name: "",
        departmnet: "",
        role: "",
      },
      selectedDeleteUser: {
        id: "",
        name: "",
        role: "",
      },
      name: "editUserModal",
      selectRoleAdmin: ["admin"],
      selectRoleAcc: ["บัญชี", "หัวหน้าบัญชี"],
      selectRoleTechs: ["ช่าง"],
      selectRoleIt: ["ไอทีซับพอร์ท", "โปรแกรมเมอร์"],
      selectRoleOther: ["พนักงานขาย", "พนักงานการตลาด"],
      position: [],
      department: [],
      selected: null,
      emailArr: [],
      tempEmailArr: [],
      email: null,
      isSuccess: false,
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
    AdminEditUserTable,
    SuccessDialog,
  },
  async created() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.searchClick = true;
    await this.$store.dispatch("getAllUser", this.id_company);
    const sort = [0, 1, 2, 3, 4, 5, 7, 6];
    for (let i = 0; i < sort.length; i++) {
      if (this.getAllUserDetail.data.permission[sort[i]]) {
        this.position.push({
          text: this.getAllUserDetail.data.permission[sort[i]].display_name,
          ref: `box${this.getAllUserDetail.data.permission[sort[i]].id_role}`,
          id: this.getAllUserDetail.data.permission[sort[i]].id_role,
          id_permission: this.getAllUserDetail.data.permission[sort[i]]
            .id_permission,
        });
      }
    }

    for await (let i of this.getAllUserDetail.data.data) {
      // if (this.department.length === 0) {
      if (
        i.department &&
        !this.department.find((element) => element.text === i.department)
      ) {
        this.department.push({
          text: i.department,
        });
      }
    }

    // this.department = [...new Set(this.department)]
    console.log("department", this.department);

    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
      getAllUserDetail: "getAllUserDetail",
      getEditUserDetail: "getEditUserDetail",
      permissionStatus: "permissionStatus",
      getCustomName: "getCustomName",
      canUseBorrowReturn: "canUseBorrowReturn",
    }),
    technicianSelected() {
      return (
        this.selected === this.position[5]?.id_permission ||
        this.selected === this.position[6]?.id_permission
      );
    },
    datatable() {
      let datafilter = this.getAllUserDetail.data.data;
      const filterOptions = this.filterOptions;
      const User = datafilter.filter((user) => {
        let searchRole =
          filterOptions.role == user.permission.display_name
            ? true
            : filterOptions.role == "ทั้งหมด"
            ? true
            : false;

        let searchDepartment =
          filterOptions.department == user.department
            ? true
            : filterOptions.department == "ทั้งหมด"
            ? true
            : false;
        return searchDepartment && searchRole;
      });
      return User;
    },
    userSearchResult() {
      if (this.getAllUserDetail) {
        let searchText = this.searchText.toLowerCase();
        // let getAllUserDetail = this.getAllUserDetail;
        // function search
        return this.getAllUserDetail.data.data.filter((user) => {
          this.role_choice.push(user.role);
          // console.log("this.role_choice", this.role_choice);

          // var variable = (condition) ? (true block) : ((condition2) ? (true block2) : (else block2))
          let searchName = user.user.name.toLowerCase().includes(searchText);
          let searchEmail = user.user.email.toLowerCase().includes(searchText);
          let searchTel = user.user.phone.toLowerCase().includes(searchText);

          let searchDepartment = false;
          let searchRole = false;

          if (this.filterOptions.department == user.department) {
            searchDepartment = true;
          } else if (this.filterOptions.department == "ทั้งหมด") {
            searchDepartment = true;
          }
          if (this.filterOptions.role == user.permission.display_name) {
            searchRole = true;
          }
          if (this.filterOptions.role == "ทั้งหมด") {
            searchRole = true;
          }

          this.searchClick = false;
          return (
            (searchName || searchEmail || searchTel) &&
            searchDepartment &&
            searchRole
          );
        });
      } else {
        return [];
      }
    },
    roleFilterArr() {
      let choice = [...new Set(this.role_choice)];
      // console.log("filter role",choice.length,this.role_choice)

      return choice[0] === undefined ? ["ไม่พบข้อมูล"] : choice;
    },
    optionPosition() {
      const roleUser = this.permissionStatus.permission.role.id_role;
      const filteredPosition = this.position.filter((pos) => {
        if (pos.id >= roleUser) {
          if (
            pos.id === UserRoles.WAREHOUSE_OFFICER &&
            !this.canUseBorrowReturn
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
      return roleUser === UserRoles.ACC_MANAGER
        ? this.position.filter((pos) => {
            if (
              pos.id === UserRoles.WAREHOUSE_OFFICER &&
              !this.canUseBorrowReturn
            ) {
              return false;
            } else {
              return true;
            }
          })
        : filteredPosition;
    },
    state() {
      // console.log("email", this.email?.length);
      return this.email?.length > 0 && this.email.includes("@");
    },
    invalidFeedback() {
      return this.$t("dialog.enter_email");
    },
  },
  methods: {
    handleclear() {
      this.filterOptions = {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      };
      this.searchText = "";
    },
    deleteUser() {
      this.$refs.userTable.getSelectedRowData();
    },
    async openModalAddUser() {
      await this.$bvModal.show("addUser");
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    removeTag(index) {
      this.emailArr = this.emailArr.filter((a, i) => {
        return i !== index;
      });
    },
    async sendEmail(email, id_permission) {
      let formData = new FormData();
      const current_id_user = await this.$store.dispatch("getCurrentIdUser");
      const email_sender = this.getAllUserDetail.data.data.find(
        (user) => user.id_user === current_id_user
      ).user.email;
      const position = this.position.find(
        (each) => each.id_permission === id_permission
      ).text;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      const hashData = await this.$store.dispatch("hashData", {
        email,
        id_permission,
        id_company,
      });
      let message = null;
      formData.append("subject", "Invite User");
      message = `<div style="text-align:center; padding-top:1.5rem; background-color:#ffffff">
            <img src="https://vulcan.houseofdev.tech/proj-file-management-system/assets-file-management/verscan-logo-new_1626081926150.png" width="108px" height="108px">
            <h3>คำเชิญเข้าใช้งานระบบ</h3>
            <div style="padding-left:14%">
                <div style="text-align: left; padding:10px; width: 80%">
                    <h3>สวัสดี ${email}</h3>
                       <p> ยินดีต้อนรับเข้าสู่  VERSCAN<br/>
คุณได้รับคำเชิญจากอีเมล ${email_sender} เพื่อเข้าร่วมใช้งานระบบ VERSCAN ในตำแหน่ง ${position} ของบริษัท ${
        this.companyName
      }<br/><br/>

กรุณากดที่ลิงค์ด้านล่างเพื่อสมัครและเข้าใช้งานระบบ</p>
                    <div style="text-align: center; width:100%; background-color:#2057AD; font-size: 18px;padding: 10px 0;">
                        <a style="color:#ffffff; text-decoration:none !important;" href="${
                          window.location.protocol === "http:"
                            ? "http://"
                            : "https://"
                        }${window.location.host}/${
        hashData.status === true ? "selectCompany" : "register"
      }?invitation=${hashData.token}${this.getCustomName === "jagota" &&
        "&company=jagota"}">เข้าร่วมใช้งาน
                        </a>
                    </div>
                    <br />
                    <br />
                    <p>หากไม่สามารถทำการคลิกลิงค์ได้ ให้ทำการคัดลอกลิงค์และนำไปวางในแถบที่อยู่ของเบราเซอร์หรือหากคุณไม่ได้ขอเปลี่ยนรหัสผ่านใหม่ โปรดสอบทานการตั้งค่ารหัสผ่านและความปลอดภัยอีกครั้ง หรือติดต่อทีมงานได้ที่ WWW.VERSCAN.COM</p>
                   <hr>
                   <h3>Hello, ${email}</h3>
                       <p> Welcome to VERSCAN, Asset counting system which help business owners easier to take care and manage. You have been invite by ${email_sender} to join VERSCAN as a ${position} of ${
        this.companyName
      }. Please follow this link below:</p>
                    <div style="text-align: center; width:100%; background-color:#2057AD; font-size: 18px;padding: 10px 0;">
                        <a style="color:#ffffff; text-decoration:none !important;" href="${
                          window.location.protocol === "http:"
                            ? "http://"
                            : "https://"
                        }${window.location.host}/${
        hashData.status === true ? "selectCompany" : "register"
      }?invitation=${hashData.token}${this.getCustomName === "jagota" &&
        "&company=jagota"}">CLICK HERE TO JOIN!
                        </a>
                    </div>
                    <br />
                    <br />
                    <p>If you don’t recognize this activity, please contact us immediately at WWW.VERSCAN.COM</p>
                    <br/>
                    Best Regards,<br/><br/>

                    VERSCAN Customer Service<br/>
                    Customer Service : www.houseofdev.tech<br/>
                    Tel : 02 096 2959<br/><br/>
                </div>
            </div>
        </div>
      <br><br>
      `;
      formData.append("body", message);
      formData.append("sender_email", "no-reply@verscan.com");
      formData.append("sender_name", "VERSCAN");
      formData.append("recipient_email", email);
      formData.append("number_of_file", 1);
      try {
        const res = await axios.post(
          `https://dev.houseofdev.tech/hod-mailer/verscan_mailer.php`,
          formData
        );
        console.log(res);
        if (res.data.result) {
          console.log("send email success");
          this.isSuccess = true;
        }
      } catch (e) {
        console.log(e);
        this.isSuccess = false;
      }
    },
    async loopSendEmailInvite() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");

      if (this.emailArr?.length === 0) {
        alert("กรุณาระบุอีเมล");
      } else {
        for await (const email of this.emailArr) {
          console.log("each email", email);
          await this.sendEmail(email, this.selected);
        }
        this.emailArr = [];
      }
      await this.$store.dispatch("getAllUser", id_company);
      this.isLoading = false;
      await this.$bvModal.hide("addUser");
      await this.$bvModal.show("addUserResult");
      setTimeout(() => {
        this.$bvModal.hide("addUserResult");
      }, 2000);

      // alert("success");
    },
    async addTag() {
      // let position = this.positionValue(position_id);
      let email_word = null;
      //เช็คกรอกอีเมลหรือไม่
      if (this.email?.length > 0) {
        email_word = this.email.split(/,| /);
        email_word.forEach(async (email) => {
          let isEmail = this.validateEmail(email);
          //เช็คอีเมลจริงหรือไม่
          if (isEmail) {
            let id_company = await this.$store.dispatch("getCompanyIdCookie");
            let response = await axios.get(
              `${baseUrl()}user/checkinvite/?email=${email}&id_company=${id_company}`
            );
            if (this.emailArr.length <= 0) {
              // console.log("emailArr",this.emailArr);
              if (response.data.status === "success") {
                this.emailArr.push(email);
              } else {
                alert("มีอีเมลนี้ในระบบแล้ว");
              }
            } else {
              //เช็คอีเมลซ้ำหรือไม่
              let isDuplicate =
                this.emailArr.filter(
                  (a) => a.toLowerCase() === email.toLowerCase()
                ).length > 0
                  ? true
                  : false;

              if (response.data.status === "success" && !isDuplicate) {
                this.emailArr.push(email);
              } else if (!isDuplicate) {
                alert("มีอีเมลนี้ในระบบแล้ว");
              } else {
                alert("อีเมลซ้ำ");
              }
            }
          } else {
            alert(this.$t("dialog.incorrect_email"));
          }
          // this.emailArr = [ ...new Set(this.emailArr)]
          this.email = null;
        });
      } else {
        alert(this.$t("dialog.incorrect_email"));
      }
    },
    positionValue(id) {
      return this.position.filter((a) => {
        return a.id === id;
      })[0].text;
    },

    clickBox(id) {
      let box = document.getElementById(`box${id}`);
      box.click();
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoCatAssets() {
      this.$router.push("/admin/CatAssets");
    },
    gotoManageDepartments() {
      this.$router.push("/admin/ManageDepartment");
    },
    gotoManageZone() {
      this.$router.push("/admin/ManageZone");
    },

    // showConfirm(user) {
    //   this.data.id = user.id_user;
    //   this.data.name = user.name;
    //   this.data.role = user.role;
    //   this.data.department = user.department;
    // },

    async confirmDelete() {
      this.isLoading = true;
      this.deleteShow = false;
      try {
        await this.$store.dispatch("deleteUser", this.selectedDeleteUser.id);
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
        this.$bvModal.hide("delete-user-modal");
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async confirmUser() {
      // Add console;

      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.$store.dispatch("confirmUser", {
        id_user: this.data.id,
        company_id: id_company,
      });
    },
    setFilter() {
      this.userSearchResult = this.userFilter;
    },
    resetFilter() {
      this.filterOptions.department = "ทั้งหมด";
      this.filterOptions.role = "ทั้งหมด";

      document.getElementById("search_button").click();
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    async goToDetail(id) {
      await this.$store.dispatch("getEditUser", id);
      this.initialUserPosition = this.getEditUserDetail.userCompany.permission.id_role;
      this.selectedPosition = this.getEditUserDetail.userCompany.permission.display_name;
      this.filterShow = false;
      console.log("this user is: ", this.getEditUserDetail);
    },
    async showEditUserModal(id) {
      await this.goToDetail(id);
      this.$bvModal.show("edit-user-modal");
    },
    showDeleteUserModal(user) {
      this.selectedDeleteUser.id = user.id_user;
      this.selectedDeleteUser.name = user.user.name;
      this.selectedDeleteUser.role = user.permission.role.name;
      this.$bvModal.show("delete-user-modal");
    },
    backToDetail() {
      this.editShow = false;
    },
    async editUserPermission() {
      this.isLoading = true;

      await this.$store.dispatch("adminEditUser", {
        user: this.getEditUserDetail,
        selectedPosition: this.selectedPosition,
        position: this.position,
      });

      this.successMessage = this.$t("dialog.save_edit");
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 2000);
      await this.$store.dispatch("getAllUser", this.id_company);
      this.$bvModal.hide("edit-user-modal");
      this.isLoading = false;
    },
  },
  watch: {
    email() {
      if (this.email) {
        this.email = this.email.trimStart();
        this.email = this.email.trimEnd();
      }
    },
    selected() {
      if (this.emailArr.length > 0) {
        if (this.tempEmailArr.length > 0) {
          this.emailArr = this.tempEmailArr;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}
.tag-email {
  display: flex;
  // height: 30px;  font-size: 14px;

  color: #000000;
  background: #c7c7c7;
  width: fit-content;
  border-radius: 15px;

  padding: 0.25em 1.25em 0.25em 1em;
  margin: 0.25em 0.4em;
}
.delete-tag {
  width: 20px !important;
  height: 20px !important;
  font-size: 13px !important;
  border-radius: 50%;
  background: #7e7e7e;
  margin: 0px !important;
  border: none;
  color: #c7c7c7 !important;
  cursor: pointer;
}

.main {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  thead th:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }
  tbody td {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  tbody td:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }

  tbody {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  //  table scrollable
  .main {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    tbody td {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    thead th:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }
    tbody td:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }

  &:disabled {
    background: #c4c4c4;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content !important;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
// .modal-content {
//   // padding: 1rem;
//   border-radius: 35px;
//   height: auto;

//   & label {
//     color: #4b4b4b;
//     padding: 0.4rem;
//   }
// }

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
