<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../util/accountingPrice";
export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    page: { type: Number },
    assetData: [],
    searchText: {
      type: String,
    },
    otherColumn: [],
  },
  beforeMount() {
    // let columnDefs = [
    //   {
    //     headerName: this.$t("count.order"),
    //     field: "index",
    //     headerCheckboxSelection: true,
    //     headerCheckboxSelectionFilteredOnly: true,
    //     checkboxSelection: true,
    //     maxWidth: 110,
    //     suppressSizeToFit: true,
    //     getQuickFilterText: (params) => {
    //       return "";
    //     },
    //   },
    //   {
    //     headerName: this.$t("home.fixedas_num"),
    //     field: "id_fixed_asset",
    //     sortable: true,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.as_name"),
    //     field: "name",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.as_dt"),
    //     field: "description",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.cat"),
    //     field: "category",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.sub_cat"),
    //     field: "sub_category",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.build"),
    //     field: "location_building",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.fl"),
    //     field: "location_floor",
    //     sortable: true,
    //     maxWidth: 110,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.department"),
    //     field: "location_department",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.zone"),
    //     field: "location_zone",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.note"),
    //     field: "note",
    //     sortable: true,
    //     minWidth: 150,
    //   },
    //   {
    //     headerName: "QR Code",
    //     field: "id_qrcode",
    //     sortable: true,
    //     suppressSizeToFit: true,
    //     cellStyle: (params) => {
    //       if (params.value === this.$t("home.not_reg")) {
    //         return { color: "#888888" };
    //       }
    //       return null;
    //     },
    //   },
    // ];
    // this.columnDefs = columnDefs;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),

    rowData() {
      return this.assetData.map((asset, index) => {
        let dataTable = {
          tag: this.tag ? asset : null,
          index: (this.page - 1) * 100 + index + 1,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          description: asset.description ? asset.description : "-",
          category: asset.category_name || "-",
          sub_category: asset.sub_category_name || "-",
          location_building: asset.location_building || "-",
          location_floor: asset.location_floor || "-",
          location_department: asset.location_department || "-",
          location_zone: asset.location_zone || "-",
          note: asset.note ? asset.note : "-",
          id_qrcode: this.qrCode(asset),
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          acc_depreciation: asset.acc_depreciation
            ? toShowAccountingPrice(asset.acc_depreciation)
            : "-",
          net_book: asset.net_book
            ? toShowAccountingPrice(asset.net_book)
            : "-",
          asset_sector: asset.sector_name || "-",
          asset_branch: asset.branch_name || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor_name || "-",
        };
        //console.log("asset.asset_details",asset.asset_details);
        if (asset.asset_details?.length > 0) {
          asset.asset_details.forEach((val, i) => {
            dataTable[`other_${i}`] = val.value || "-";
          });
        } else {
          //replace data null
          for (let i = 0; i <= 3; i++) {
            dataTable[`other_${i}`] = "-";
          }
        }
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    qrCode(asset) {
      if (this.$route.params.summaryId) {
        return asset?.id_qrcode ? asset?.id_qrcode : this.$t("home.not_reg");
      } else {
        return asset?.qrcode?.id_qrcode
          ? asset?.qrcode?.id_qrcode
          : this.$t("home.not_reg");
      }
    },
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          maxWidth: 110,
          suppressSizeToFit: true,

          getQuickFilterText: (params) => {
            return "";
          },
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "QR Code",
          field: "id_qrcode",
          sortable: true,
          suppressSizeToFit: true,
          cellStyle: (params) => {
            if (params.value === this.$t("home.not_reg")) {
              return { color: "#888888" };
            }
            return null;
          },
        },
      ];

      if (!this.isRoleLower) {
        const customColumnJGT = [
          {
            position: 6,
            value: {
              headerName: this.$t("asset.location"),
              field: "asset_sector",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 7,
            value: {
              headerName: this.$t("asset.branch"),
              field: "asset_branch",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 20,
            value: {
              headerName: this.$t("asset.insurance_start"),
              field: "insurance_start",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 21,
            value: {
              headerName: this.$t("asset.insurance_end"),
              field: "insurance_end",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 22,
            value: {
              headerName: this.$t("asset.vendor"),
              field: "vendor",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
        ];
        let fix_column = [
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.acc_depreciation"),
            field: "acc_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.net_book"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
        if (this.permissionStatus.company.custom_id === 2) {
          for (const item of customColumnJGT) {
            columnDefs.splice(item.position, 0, item.value);
          }
        }
        if (this.assetData.length > 0) {
          let column = this.otherColumn;
          column.forEach((val, i) => {
            let header = {
              headerName: column[i].name,
              field: `other_${i}`,
              suppressSizeToFit: true,
            };
            columnDefs.push(header);
          });
        }
      }
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      this.$emit("selected-assets", selectedData);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
