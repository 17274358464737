<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <!-- <Background></Background> -->
    <div class="login">
      <div class="greeting">
        <h2>{{ companyName }}</h2>
        <h3>
          ({{
            branchName === "Head Office"
              ? $t("selectcompany.headoff")
              : branchName
          }})
        </h3>
      </div>

      <div class="inRemind" v-if="isCounting">
        <p id="inReminder">{{ $t("home.counting") }}</p>
      </div>
      <div class="remind" v-if="!isCounting && nextDate">
        <p id="reminder">
          {{ $t("home.as_countnext") }}
          {{ moment(nextDate).format("DD/MM/YYYY") }}
        </p>
      </div>
      <AlertExpireBar />
      <!-- <div class="card-form card-form-custom" @click="gotoScan()">
        <img src="../assets/icon-home/qr-code4.svg" />
        <div class="group-text">
          <h1>สแกน QR Code</h1>
          เพื่อลงทะเบียนหรือตรวจนับสินทรัพย์
        </div>
      </div> -->
      <template v-if="canUseCustom8">
        <div class="pt-3">
          <div class="card-form" @click="gotoRegisAsset()" v-if="isImport">
            <img src="../assets/icon-home/import4.svg" />
            <div class="group-text">
              <h1>{{ $t("home.im_asregist") }}</h1>
              {{ $t("home.im_datafile") }}
            </div>
          </div>
        </div>

        <button
          class="card-form position-relative"
          style="border: none"
          @click="$router.push({ name: 'org' })"
        >
          <img src="../assets/icon-home/orgTree.svg" />
          <div class="group-text">
            <h1>
              {{ $t("home.asset_graph") }}
            </h1>
          </div>
        </button>

        <div class="card-form" @click="gotoMap()" v-if="isManage">
          <img src="../assets/icon-home/building.svg" />
          <div class="group-text">
            <h1>{{ $t("home.set_build") }}</h1>
          </div>
        </div>

        <div class="card-form" @click="gotoAdmin()" v-if="isManage">
          <img src="../assets/icon-home/gear.svg" />
          <div class="group-text">
            <h1>{{ $t("home.dt_manage") }}</h1>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="pt-3">
          <div class="card-form" @click="gotoRegisAsset()" v-if="isImport">
            <img src="../assets/icon-home/import4.svg" />
            <div class="group-text">
              <h1>{{ $t("home.im_asregist") }}</h1>
              {{ $t("home.im_datafile") }}
              <!-- เพื่อนำไปติดที่สินทรัพย์ -->
            </div>
          </div>
        </div>
        <!-- <div class="card-form" @click="gotoImport()" v-if="isPayer">
        <img src="../assets/icon-home/qr-code41.svg" />
        <div class="group-text">
          <h1>สร้าง QR Code</h1>
          เพื่อนำไปติดที่สินทรัพย์
        </div>
      </div> -->
        <button
          class="card-form position-relative"
          style="border: none"
          @click="gotoDepreciation"
          v-if="(!isRoleLower && canUseDepreciation) || isFreeTrial"
          :disabled="isFreeTrial && !canUseDepreciation"
        >
          <template v-if="haveDpNotification && canUseDepreciation">
            <div class="pinging"></div>
            <div class="ping"></div>
          </template>
          <img src="../assets/icon-home/accounting.svg" />
          <div class="group-text">
            <h1>
              {{ $t("home.acc_data") }}
            </h1>
          </div>
        </button>

        <div class="card-form" @click="gotoHistory">
          <img src="../assets/icon-home/pie-chart4.svg" />
          <div class="group-text">
            <h1>{{ $t("home.as_countre") }}</h1>
          </div>
        </div>

        <button
          class="card-form"
          style="border: none"
          @click="gotoBorrow"
          v-if="canUseBorrowReturn || isFreeTrial"
          :disabled="isFreeTrial && !canUseBorrowReturn"
        >
          <img src="../assets/icon-home/borrow_menu.svg" />
          <div class="group-text">
            <h1>
              {{ $t("borrow.borrow_menu") }}
              <b-badge style="background-color: #28A745;">{{
                $i18n.locale === "th" ? "ใหม่" : "NEW"
              }}</b-badge>
            </h1>
          </div>
        </button>
        <div
          class="card-form position-relative"
          @click="gotoAdminMaintenance()"
          v-if="canUseMaintenanceReport"
        >
          <template
            v-if="maintenanceNotification.pending && canUseMaintenanceReport"
          >
            <div class="pinging"></div>
            <div class="ping"></div>
          </template>
          <img src="../assets/maintenance.svg" />
          <div class="group-text">
            <h1>
              {{ $t("maintenance_report.reportitem") }}
              <b-badge style="background-color: #28A745;">{{
                $i18n.locale === "th" ? "ใหม่" : "NEW"
              }}</b-badge>
            </h1>
          </div>
        </div>
        <customMaintenancePlan
          v-if="isCustom && isManage"
          :customid="permissionStatus.company.custom_id"
        />
        <div class="card-form" @click="gotoAdmin()" v-if="isManage">
          <img src="../assets/icon-home/gear.svg" />
          <div class="group-text">
            <h1>{{ $t("home.dt_manage") }}</h1>
          </div>
        </div>
      </template>
      <p
        class="text-line"
        style="color: #B8B6B6; border-color: #B8B6B6; margin: 2rem auto !important;"
      >
        <span>{{ $t("sponga.manual") }}</span>
      </p>
      <img
        v-if="$i18n.locale === 'en'"
        class="cursor-pointer"
        style="max-width: 300px;"
        @click="$router.push({ name: 'sponga-redirect' })"
        src="../assets/Sponga/Sponga_Button_EN.png"
        alt=""
      />
      <img
        v-else
        class="cursor-pointer"
        style="max-width: 300px;"
        @click="$router.push({ name: 'sponga-redirect' })"
        src="../assets/Sponga/Sponga_Button.png"
        alt=""
      />
      <div class="pt-2">
        <!-- <button
          class="btn btn-manual m-1"
          onclick="window.open('https://www.verscan.com/manual')"
        >
          <img src="../assets/manual.png" style="width: 20px" />
          {{ $t("btn.man") }}
        </button> -->

        <template v-if="isFreeTrial">
          <a>
            <b-button class="button" @click="downloadFreeQR()">
              <div
                style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
              >
                {{ $t("btn.dl_QR") }}
              </div></b-button
            >
          </a>
        </template>
      </div>

      <div class="line-break"></div>
    </div>
  </div>
</template>

<script>
import { UserRoles } from "@/constants/UserRoles";
import customMaintenancePlan from "@/components/CustomComponent/customMaintenancePlan.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Cookie from "js-cookie";
import LearnTH from "../plugins/lang/Custom/LearnTH.json";
import AlertExpireBar from "@/components/AlertExpireBar.vue";

export default {
  data() {
    return {
      UserRoles,
      prevRoute: null,
      showSucces: true,
      save_status: false,
      checkDateRemind: true,
      hasDate: false,
      nextDate: "",
      isCounting: false,
      haveDpNoti: false,
    };
  },
  async mounted() {
    this.$store.dispatch("getDpNotification");
    this.$store.dispatch("maintenance/getMaintenanceNotification");
    this.checkCustomLanguage();

    this.hide();

    const cookieData = JSON.parse(Cookie.get("selectedcompany"));
    await this.$store.commit("setPermission", cookieData);
    // await this.$store.dispatch("getAllCountingDateByIdCompany");
    try {
      const checkCounting = await this.$store.dispatch("checkCountingDate");
      this.isCounting = checkCounting.is_counting;
      this.nextDate = checkCounting.recent_counting;
    } catch (err) {
      console.log(err);
    }
    console.log("permission--> ", this.permissionStatus);
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
      branchName: "branchName",
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      isManage: "isManage",
      isPayer: "isPayer",
      isImport: "isImport",
      isRoleLower: "isRoleLower",
      getCustomName: "getCustomName",
      haveDpNotification: "haveDpNotification",
      isFreeTrial: "isFreeTrial",
      canUseDepreciation: "canUseDepreciation",
      canUseBorrowReturn: "canUseBorrowReturn",
      canUseCustom5: "canUseCustom5",
      canUseCustom8: "canUseCustom8",
      canUseMaintenanceReport: "canUseMaintenanceReport",
      maintenanceRole: "maintenance/maintenanceRole",
      maintenanceNotification: "maintenance/getMaintenanceNotification",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    moment,
    checkCustomLanguage() {
      if (
        this.getCustomName === "jagota" ||
        this.permissionStatus.company.custom_id === 2
      ) {
        this.switchLocale("th");
      }
      if (this.canUseCustom5) {
        this.$i18n.mergeLocaleMessage("th", LearnTH);
        this.$i18n.mergeLocaleMessage("en", LearnTH);
      }
    },
    switchLocale(lang) {
      this.$store.dispatch("switchLocale", lang);
    },
    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 2500);
    },
    gotoAdminMaintenance() {
      if (this.maintenanceRole === "admin") {
        this.$router.push({ name: "MaintenanceDashboard" });
      } else {
        this.$router.push({ name: "MyMaintenanceReportList" });
      }
    },
    gotoHistory() {
      this.$router.push("/history");
    },
    gotoAdmin() {
      this.$store.dispatch("setPageState", {
        pageName: "",
        pageNum: 1,
      });
      this.$router.push("/admin/ManageAsset");
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoScan() {
      this.$router.push("/scan");
    },
    gotoImport() {
      this.$router.push("/import");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    gotoRegisAsset() {
      this.$router.push("/docRegisAsset");
    },
    gotoDepreciation() {
      this.$router.push({ name: "depreciation-asset" });
    },
    gotoBorrow() {
      this.$router.push({ name: "borrow-dashboard" });
    },
    downloadStandard() {
      let time = new Date();
      window.location.href =
        "https://static.verscan.com/verscan-v1/Template/VERSCAN_Template_V_1_5.xlsx?" +
        time.valueOf();
    },
    downloadFreeQR() {
      window.open(
        "https://static.verscan.com/verscan-v1/Template/VERSCAN_QR_Code_For_Demo.pdf"
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  components: {
    Footer,
    customMaintenancePlan,
    AlertExpireBar,
  },
};
</script>

<style lang="scss" scoped>
.ping {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
  background: #ed1616;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  /* animation: load 1.5s ease-out infinite; */
}

.pinging {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
  background: rgba(255, 0, 0, 1);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: load 1.5s ease-out infinite;
}

@keyframes load {
  0% {
    transform: scale(1);
    background: rgba(255, 0, 0, 1);
  }
  50% {
    transform: scale(2.5);
    background: rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    background: rgba(255, 0, 0, 0);
  }
}

.button {
  align-items: center;
  padding: 0;
  font-size: 12px;
  width: 180px;
  height: 45px;
}
.group-text {
  align-self: center;
  // height: 100%;
  // justify-content: center;
}
.card-form {
  &:disabled {
    color: #000;
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
  cursor: pointer;
  display: grid;
  width: 80%;
  grid-template-columns: auto 1fr;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 50px;
    height: 50px;
  }
  h1 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 400;
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }

  // &-custom {
  //   padding: 2rem 1.2rem 2rem 1.2rem;

  //   h1 {
  //     font: normal normal normal 27px/18px Mitr;
  //   }
  // }
}
.btn-manual {
  width: 180px;
  // height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  font-size: 12px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
.greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 1rem;
  height: 173px;
  // background: transparent
  //   linear-gradient(180deg, var(--unnamed-color-007AFE) 0%, #013F81 100%) 0% 0%
  //   no-repeat padding-box;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);

  h2 {
    font-size: 32px;
    font-weight: 700;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // @media screen and (min-width: 992px) {
    //   font-size: 24px;
    // }
  }

  h3 {
    font-size: 20px;
    // font-size: 14px;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // @media screen and (min-width: 992px) {
    //   font-size: 20px;
    // }
  }
}
.link-color {
  color: $color-link;
}
.header-text {
  color: $font-black;
}
.login {
  z-index: 90;
  // background: transparent linear-gradient(180deg, #007AFE 0%, #013F81 100%) 0%s
  // background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
  // 0% no-repeat padding-box;
}

.btn-login {
  padding: 0.55rem 1.75rem 0.55rem 1.75rem;
}

.link-forget {
  display: block;
  padding-top: 10px;
  font-size: 0.75rem;
  &-custom {
    text-align: right !important;
    color: $color-link;
  }
}

.banner {
  width: 206px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: 10;
}

.remind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #e0e0e0;
}

#reminder {
  font-size: 18px;
  margin: auto;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inRemind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #d2e2f8;
}

#inReminder {
  font-size: 18px;
  margin: auto;
  color: #434343;
}
.company-name {
  font-size: 18px;
  margin-top: 15px;
  font-weight: lighter;
}
</style>
