<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />

      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>
      <!-- Filter B-Modal -->
      <b-modal
        id="filter-modal"
        @cancel="handleclear"
        :cancel-title="$t('btn.clear')"
        hide-header-close
        hide-header
        :ok-title="$t('btn.conf')"
        @ok="handleFilter"
      >
        <b-row>
          <b-col class="text-end" cols="2"> </b-col>

          <b-col class="text-center" cols="8">
            <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
          </b-col>
          <b-col class="text-end" cols="2">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('filter-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <custom2Filter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          @set-filter-option="addCustomFilter"
        />
        <label class="mt-2">{{ $t("home.cat") }}</label>
        <v-select
          :options="optionCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.sub_cat") }}</label>
        <v-select
          :options="optionSubCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_sub_category"
          class="dropdown-style"
          :clearable="false"
        />
        <custom4Filter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          :filterOptions="filterOptions"
          @set-filter-option="addCustomFilter"
        />

        <label class="mt-2">{{ $t("home.as_stat") }}</label>
        <select class="form-control form-border" v-model="filterOptions.status">
          <option
            v-for="(stat, index) in statusData"
            :key="index"
            :value="stat.value"
          >
            {{ stat.name }}
          </option>
        </select>
        <label class="mt-2">{{ $t("home.build") }}</label>
        <v-select
          :options="optionBuilding"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_building"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.fl") }}</label>

        <v-select
          :options="optionFloor"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_floor"
          class="dropdown-style"
          :clearable="false"
        />

        <custom8Filter
          :options="optionChoice"
          :filterOptions="filterOptions"
          @set-filter-option="addCustomFilter"
        />

        <label class="mt-2">{{ $t("home.department") }}</label>
        <v-select
          :options="optionDepartment"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_department"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.zone") }}</label>
        <v-select
          :options="optionZone"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_zone"
          class="dropdown-style"
          :clearable="false"
        />

        <div v-if="!isRoleLower">
          <label class="mt-2">{{ $t("filter.purchase_date") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="filterOptions.datePurchase_min"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              :min="filterOptions.datePurchase_min"
              class="form-control form-border"
              v-model="filterOptions.datePurchase_max"
            />
          </div>

          <label class="mt-2">{{ $t("filter.purchase_price") }}</label>

          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.purchase_price_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.purchase_price_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.useful_life") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest')"
              v-model="filterOptions.useful_life_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest')"
              v-model="filterOptions.useful_life_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.date_start") }}</label>

          <div class="d-flex align-items-center w-100">
            <input
              type="date"
              class="form-control form-border"
              v-model="filterOptions.dateUsed_start"
            />

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <input
              type="date"
              class="form-control form-border"
              :min="filterOptions.dateUsed_start"
              v-model="filterOptions.dateUsed_end"
            />
          </div>

          <!-- <label class="mt-2">{{ $t("filter.acc_depreciation") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.acc_depreciation_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.acc_depreciation_max"
            ></vue-numeric>
          </div>

          <label class="mt-2">{{ $t("filter.net_book") }}</label>
          <div class="d-flex align-items-center w-100">
            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.lowest_price')"
              v-model="filterOptions.net_book_min"
            ></vue-numeric>

            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

            <vue-numeric
              separator=","
              decimal-separator="."
              :precision="2"
              class="w-100 form-control form-border"
              :placeholder="$t('filter.highest_price')"
              v-model="filterOptions.net_book_max"
            ></vue-numeric>
          </div> -->
        </div>
        <custom3Filter
          :customid="permissionStatus.company.custom_id"
          :options="optionChoice"
          @set-filter-option="addCustomFilter"
        />
      </b-modal>

      <div class="pt-4 pb-2 gradient-text">
        <h2 class="head-table-text">{{ $t("asset.as_register") }}</h2>
      </div>
      <div class="sub-menu" v-show="!editShow">
        <div class="input-group mb-3">
          <button
            v-if="
              permissionStatus.permission.role.id_role !== UserRoles.ACC_OFFICER
            "
            class="delete-asset"
            @click="isSelectedAsset()"
            :disabled="!allowDelete"
          >
            <!-- <img
                src="../../assets/writeoff.svg"
                alt="writeoff"
                class="-mt-5"
                style="width:20px; margin-top:-3px"
              /> -->
            <b-icon-trash class="-mt-5" style="width: 20px; margin-top: -3px" />
            {{ $t("asset.del_as") }}
          </button>
          <TmpFilter
            :searchTerm="searchText"
            @set-search-term="
              (text) => {
                searchText = text;
                searchByText();
              }
            "
          />
          <span
            class="filter-box p-2 ml-2"
            id="basic-addon1"
            style="border-radius: 10px 0 0 10px"
          >
            <img src="@/assets/search-white.svg" width="20px" />
          </span>
          <input
            type="text"
            class="form-control filter-box"
            style="
              border-right-style: none;
              margin-right: 1rem;
              border-radius: 0 10px 10px 0;
            "
            :placeholder="$t('home.search')"
            v-debounce:700ms="searchByText"
            v-model="searchText"
          />

          <button
            class="filter"
            style="border: none"
            type="button"
            id="button-addon2"
            @click="$bvModal.show('filter-modal')"
          >
            <img src="@/assets/filter-white.svg" />
          </button>
        </div>
        <div
          class="d-flex align-items-center px-3 filter-count-box pb-3"
          v-if="filterNumberCount > 0"
        >
          <div class="filter-count me-3">
            <p class="px-2 m-0">
              {{ $t("filter.filter_count") }}:
              <span class="font-weight-bold">{{ filterNumberCount }}</span>
            </p>
          </div>
          <u @click="handleclear" class="m-0" style="cursor: pointer">{{
            $t("filter.clear_filter")
          }}</u>
        </div>
        <div
          class="main d-flex justify-content-start justify-content-xl-center"
        >
          <admin-edit-asset-table
            :dataTable="datatable"
            :page="currentPage"
            :otherColumn="getAllAssetOtherColumn"
            @set-watcher="setWatcher"
            ref="assetstable"
          />
        </div>
        <div
          class="d-flex justify-content-center mt-3"
          v-if="getAllAssets.length > 0"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalpages"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </div>
      </div>
      <b-modal id="deleteAssetConfirm" @ok="deleteAsset()" :hide-header="true">
        <div class="mx-auto mb-2" style="width: fit-content">
          <!-- <img
            src="../../assets/writeoff.svg"
            alt="writeoff"
            class="my-2"
            style="width:30px"
          /> -->
          <b-icon-trash
            class="my-2"
            style="color: red; width: 30px; height: 30px"
          />
        </div>
        <p class="text-center">{{ $t("dialog.conf_del") }}</p>
        <textarea
          v-model="cancelDetail"
          class="w-100 rounded-lg"
          rows="5"
          :placeholder="$t('dialog.in_note')"
        ></textarea>
        <template #modal-header="{}"> </template>
        <template #modal-footer="{ ok, cancel }">
          <div class="d-flex justify-content-around w-100">
            <button class="btn decline-button" @click="cancel()">
              {{ $t("btn.canc") }}
            </button>
            <button
              v-if="cancelDetail"
              class="btn confirm-button"
              @click="ok()"
            >
              {{ $t("btn.conf") }}
            </button>
            <button
              v-else
              class="btn decline-button"
              :disabled="cancelDetail"
              @click="ok()"
            >
              {{ $t("btn.conf") }}
            </button>
          </div>
          <!-- Emulate built in modal footer ok and cancel button actions -->
        </template></b-modal
      >

      <!-- confirm lost -->
      <!-- <div
        class="modal fade"
        id="confirmLostModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-4 pb-2"
            style="border-radius: 10px"
          >
            <div class="row">
              <p>
                {{ $t("dialog.click") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("dialog.to_ensure") }} <br />
                {{ $t("asset.as_num") }} : {{ this.id_asset_lost }}
                <br />
                {{ $t("asset.note") }} : {{ this.note_lost }}
                <br />
                <b>{{ $t("dialog.t_lost") }}</b>
              </p>
            </div>
            <div class="justify-content-center col">
              <button
                type="button"
                class="cancle btn-bg"
                data-dismiss="modal"
                @click="cancelReportLost()"
              >
                {{ $t("btn.decline") }}
              </button>
              <button
                type="button"
                class="confirm"
                data-dismiss="modal"
                @click="confirmLost()"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <b-modal
        class="p-2"
        id="confirm-lost-modal"
        hide-header-close
        hide-header
        hide-footer
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('confirm-lost-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <div v-if="assetLostInfo">
          <div class="mx-3">
            <div
              class="d-flex flex-column align-items-center"
              style="font-size: 15px"
            >
              <h4 style="color: #015FC3;">{{ $t("asset.as_data") }}</h4>
              <div style="height: 150px; width:150px">
                <img
                  :src="assetLostInfo.image_url.url"
                  alt=""
                  class="d-block h-100 w-100"
                  style="object-fit: cover; border-radius: 10px;"
                />
              </div>
              <p>{{ $t("imgoneasset.img_new") }}</p>
              <p>
                {{ $t("imgoneasset.date") }}
                {{
                  moment(assetLostInfo.image_url.date).format(
                    "DD MMM YYYY HH:mm"
                  )
                }}
              </p>
              <br />
              <p>
                {{ $t("asset.qr_id") }}: {{ assetLostInfo.qrcode.id_qrcode }}
              </p>
              <p>
                {{ $t("asset.as_num") }}:
                {{ assetLostInfo.id_fixed_asset }}
              </p>
              <br />
            </div>
            <div class="asset-lost-info">
              <label>{{ $t("asset.as_name") }}</label>
              <p>
                {{ assetLostInfo.name }}
              </p>
              <label>{{ $t("asset.note") }}</label>
              <p>{{ assetLostInfo.note_lost }}</p>
              <label v-if="assetLostInfo.reportType === 'lost'">{{
                $t("asset.report_lost_by")
              }}</label>
              <label v-if="assetLostInfo.reportType === 'found'">{{
                $t("asset.report_found_by")
              }}</label>
              <p>
                {{ assetLostInfo.reporter || "-" }}
              </p>
              <label v-if="assetLostInfo.reportType === 'lost'">{{
                $t("asset.report_lost_date")
              }}</label>
              <label v-if="assetLostInfo.reportType === 'found'">{{
                $t("asset.report_found_date")
              }}</label>
              <p>
                {{
                  assetLostInfo.report_time
                    ? moment(assetLostInfo.report_time).format(
                        "DD MMM YYYY HH:mm"
                      )
                    : "-"
                }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center"
              v-if="assetLostInfo.reportType === 'lost'"
            >
              <button class="cancle" @click="cancelReportLost()">
                {{ $t("btn.decline") }}
              </button>
              <button class="confirm" @click="confirmLost()">
                {{ $t("btn.conf") }}
              </button>
            </div>
            <div
              class="d-flex justify-content-center"
              v-if="assetLostInfo.reportType === 'found'"
            >
              <button class="cancle" @click="cancelReportFound()">
                {{ $t("btn.decline") }}
              </button>
              <button class="confirm" @click="confirmFound()">
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { UserRoles } from "@/constants/UserRoles";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import { mapGetters, mapActions } from "vuex";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminEditAssetTable from "@/components/Table/AdminEditAssetTable.vue";
import moment from "moment";
import TmpFilter from "@/components/CustomComponent/customTmpFilter.vue";
import custom2Filter from "@/components/CustomComponent/customFilterJGT.vue";
import custom3Filter from "@/components/CustomComponent/SubAsset/customFilterBL.vue";
import custom4Filter from "@/components/CustomComponent/customFilterNinja.vue";
import custom8Filter from "../../components/CustomComponent/Hoshi/customFilterRoom.vue";
import Compressor from "compressorjs";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      UserRoles,
      cancelDetail: "",
      allowDelete: false,
      isLoading: false,
      searchText: "",
      onClick: true,
      isLoad: false,
      editShow: false,
      filterShow: false,
      url_new: null,
      assetLostInfo: null,
      countingStatus: "ทั้งหมด",
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      },
      filterNumberCount: 0,
      noimg: require("@/image/Not-regis-qr-code.png"),
      name: "editAssetModal",
      url: "",
      new_building: "",
      new_floor: "",
      pictureFile: null,
      ops: {
        vuescroll: {},
        scrollPanel: {
          initialScrollY: 281,
          initialScrollX: 496,
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "12px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "5px",
          keepShow: true,
        },
        bar: {
          showDelay: 0,
          onlyShowBarOnScroll: true,
          keepShow: true,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: true,
          specifyBorderRadius: false,
          minSize: 0,
          size: "14px",
        },
      },
      currentPage: 1,
      perPage: 100,
      company_id: "",
      statusData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("count.usable"), value: "available" },
        { name: this.$t("count.not_inuse"), value: "unavailable" },
        { name: this.$t("count.broke"), value: "broke" },
        { name: this.$t("count.repairing"), value: "maintain" },
        { name: this.$t("count.irrepar"), value: "cannot_repair" },
        { name: this.$t("filter.lost"), value: "lost" },
        { name: this.$t("asset.not_count"), value: "uncounting" },
        { name: this.$t("asset.not_reg"), value: "unregister" },
      ],
    };
  },
  components: {
    AdminMenuBar,
    AdminSlideMenu,
    Loading,
    AdminEditAssetTable,
    TmpFilter,
    custom2Filter,
    custom3Filter,
    custom4Filter,
    custom8Filter,
  },
  async mounted() {
    // if (this.$route.query.page) {
    //   this.currentPage = this.$route.query.page;
    // }
    await this.setFilterFromStore();
    this.loadAssetData();
  },
  watch: {
    "getDetailAsset.asset.location_building"() {
      this.getDetailAsset.asset.location_floor = this.floorHeader[0];
    },
    cancelDetail() {
      console.log(this.cancelDetail);
    },
    getDetailAsset(newVal, oldVal) {
      // this.$store.dispatch("getDetailAssets", this.getAllAssets[0].id_asset);
      this.isLoad = true;
      console.log("watch-> ", newVal, oldVal, this.isLoad);
    },
    async currentPage() {
      this.$store.dispatch("setPageState", {
        pageName: "ManageAsset",
        pageNum: this.currentPage,
      });
      this.getFilterAssetData();
    },
    "filterOptions.dateUsed_start"() {
      if (
        !this.filterOptions.dateUsed_end ||
        this.filterOptions.dateUsed_end < this.filterOptions.dateUsed_start
      ) {
        this.filterOptions.dateUsed_end = this.filterOptions.dateUsed_start;
      }
    },
    "filterOptions.datePurchase_min"() {
      if (
        !this.filterOptions.datePurchase_max ||
        this.filterOptions.datePurchase_max <
          this.filterOptions.datePurchase_min
      ) {
        this.filterOptions.datePurchase_max = this.filterOptions.datePurchase_min;
      }
    },
    // "$route.query.page"() {
    //   this.getFilterAssetData(this.$route.query.page);
    //   this.currentPage = this.$route.query.page || 1;
    // },
  },
  computed: {
    ...mapGetters({
      getAllAssets: "getAllAssets",
      getAllMap: "getAllMap",
      getDetailAsset: "getDetailAsset",
      getMapPosition: "getMapPosition",
      getUserDetail: "getUserDetail",
      getAllAssetsStore: "getAllAssetsStore",
      isRoleLower: "isRoleLower",
      assets_count: "assets_count",
      optionChoice: "getfilterOption",
      getManageAssetFilter: "getManageAssetFilter",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      permissionStatus: "permissionStatus",
      getPageState: "getPageState",
    }),

    totalpages() {
      console.log("totalpages", this.assets_count);
      return Math.ceil(this.assets_count);
    },
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.filterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.filterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    datatable() {
      return this.getAllAssets;
    },

    builddingHeader() {
      let map = this.$store.getters.getAllMap;
      map = this.lodash.map(map, (m) => {
        return m.location_building;
      });
      // console.log(`map lodash`, [...new Set(map)]);
      return [...new Set(map)];
    },
    floorHeader() {
      let map = this.$store.getters.getAllMap;
      let floorInBuilding = [];
      for (const building of map) {
        if (
          building.location_building ===
          this.getDetailAsset.asset.location_building
        ) {
          floorInBuilding.push(building.location_floor);
        }
      }
      return floorInBuilding;
      // map = this.lodash.map(map, (m) => {
      //   return m.location_floor;
      // });
      // console.log(`map lodash`, [...new Set(map)]);
      // return [...new Set(map)];
    },
  },
  methods: {
    moment,
    setWatcher() {
      this.$watch(
        () => {
          return this.$refs.assetstable.isSelectedAsset;
        },
        (val) => {
          this.allowDelete = val;
        }
      );
    },

    ...mapActions(["sendAllMap"]),
    async loadAssetData() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      if (this.getPageState.pageName === "ManageAsset") {
        this.currentPage = this.getPageState.pageNum;
      }
      this.company_id = id_company;
      await this.getFilterAssetData();
      this.$store.dispatch("setPageState", {
        pageName: "ManageAsset",
        pageNum: this.currentPage,
      });
      // if (this.getAllAssets.length != 0) {
      //   await this.$store.dispatch(
      //     "getDetailAssets",
      //     this.getAllAssets[0].id_asset
      //   );
      //   // console.log("one detail asset: ", this.getDetailAsset);
      //   await this.$store.dispatch("sendAllMap", id_company);

      //   this.url_new = this.getDetailAsset.asset.image_url
      //     ? JSON.parse(JSON.stringify(this.getDetailAsset.asset.image_url)).url
      //     : null;
      // }

      // console.log("JSON", this.url_new);

      this.isLoading = false;
    },
    setFilterFromStore() {
      console.log(this.getManageAssetFilter);
      if (this.getManageAssetFilter) {
        let filter = { ...this.getManageAssetFilter };
        console.log("filter:", filter);
        if (filter.filterOptions) {
          this.filterOptions = filter.filterOptions;
          this.searchText = this.filterOptions.text;
        }

        if (filter.isCountingDate) {
          this.isCountingDate = filter.isCountingDate;
        }
      }
    },
    async getFilterAssetData() {
      this.isLoading = true;
      try {
        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        this.company_id = id_company;
        await this.$store.dispatch("filterAllAsset_pagination", {
          filterOptions: this.filterOptions,
          saveToStore: true,
          countingStatus: this.countingStatus,
          company_id: id_company,
          isCountingDate: this.isCountingDate,
          page: this.currentPage,
          limit: this.perPage,
          forPage: "ManageAsset",
        });
      } catch (error) {
        console.log(error);
      }
      this.filterCount();
      this.isLoading = false;
    },
    filterCount() {
      if (this.filterOptions) {
        let filter = JSON.parse(JSON.stringify(this.filterOptions));
        // console.log("filter", filter);
        let count = 0;
        if (filter.custom_end_time_max && filter.custom_end_time_min) count++;
        if (filter.custom_start_time_max && filter.custom_start_time_min)
          count++;
        if (filter.vendor_id) count++;
        if (filter.sector_id) count++;
        if (filter.branch_id) count++;
        if (filter.id_category) count++;
        if (filter.id_sub_category) count++;
        if (filter.id_sub_category2) count++;
        if (filter.id_location_building) count++;
        if (filter.id_location_floor) count++;
        if (filter.id_location_department) count++;
        if (filter.id_location_zone) count++;
        if (filter.status && filter.status !== "ทั้งหมด") count++;
        if (filter.intendant) count++;
        if (filter.dateUsed_start !== null || filter.dateUsed_end !== null)
          count++;

        if (
          filter.datePurchase_min !== null ||
          filter.datePurchase_min != null
        ) {
          if (filter.datePurchase_min != "" || filter.datePurchase_max != "") {
            count++;
          }
        }

        if (
          filter.useful_life_min !== null ||
          filter.useful_life_max !== null
        ) {
          if (filter.useful_life_min != "" || filter.useful_life_max != "") {
            count++;
          }
        }

        if (
          filter.purchase_price_min !== null ||
          filter.purchase_price_max !== null
        ) {
          if (
            filter.purchase_price_min != "" ||
            filter.purchase_price_max != ""
          ) {
            count++;
          }
        }

        if (
          filter.acc_depreciation_min !== null ||
          filter.acc_depreciation_max !== null
        ) {
          if (
            filter.acc_depreciation_min != "" ||
            filter.acc_depreciation_max != ""
          ) {
            count++;
          }
        }

        if (filter.net_book_min !== null || filter.net_book_max !== null) {
          if (filter.net_book_min != "" || filter.net_book_max != "") {
            count++;
          }
        }
        this.filterNumberCount = count;
        return count;
      } else {
        this.filterNumberCount = 0;
        return 0;
      }
    },
    momentDayForUpdate(date) {
      return (date = moment(date).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }));
    },
    isNull(data) {
      return data ? data : "-";
    },
    isSelectedAsset() {
      if (this.$refs.assetstable.getSelectedRowData().length !== 0) {
        this.$bvModal.show("deleteAssetConfirm");
      }
    },

    handleFilter() {
      this.getFilterAssetData();
    },
    addCustomFilter(e) {
      this.filterOptions = {
        ...this.filterOptions,
        ...e,
      };
    },
    async handleclear() {
      // this.countingStatus = "ทั้งหมด";
      this.currentPage = 1;
      this.searchText = "";
      this.filterOptions = {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
        dateUsed_start: null,
        dateUsed_end: null,
        datePurchase_min: null,
        datePurchase_max: null,
        useful_life_min: "",
        useful_life_max: "",
        purchase_price_min: "",
        purchase_price_max: "",
        acc_depreciation_min: "",
        acc_depreciation_max: "",
        net_book_min: "",
        net_book_max: "",
      };
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.company_id = id_company;
      await this.getFilterAssetData();
    },
    async deleteAsset() {
      this.isLoading = true;
      const deleteLength = this.$refs.assetstable.getSelectedRowData().length;
      await this.$store.dispatch("deleteAsset", {
        selectedRow: this.$refs.assetstable.getSelectedRowData(),
        cancelMessage: this.cancelDetail,
      });
      if (deleteLength === this.datatable.length) {
        this.currentPage = 1;
      }
      await this.getFilterAssetData();
      this.$refs.assetstable.onSelectionChanged();
      this.cancelDetail = "";
      this.isLoading = false;
      this.allowDelete = false;
    },
    convertImage(image) {
      return typeof image === "string" ? JSON.parse(image).url : image.url;
    },
    searchByText() {
      this.filterOptions.text = this.searchText;
      this.getFilterAssetData();
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoManageAsset() {
      this.$router.push("/admin/ManageAsset");
    },
    gotoManageUser() {
      this.$router.push("/admin/ManageUser");
    },
    gotoMap() {
      this.$router.push("/admin/Map");
    },
    openConfirmLost(asset) {
      this.assetLostInfo = asset;
      this.$bvModal.show("confirm-lost-modal");
    },
    openConfirmFound(asset) {
      this.assetLostInfo = asset;
      this.$bvModal.show("confirm-lost-modal");
    },
    async cancelReportLost() {
      const result = await Swal.fire({
        text: this.$t("detailasset.found_asset", {
          assetId: this.assetLostInfo.id_fixed_asset,
        }),
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
        confirmButtonText: this.$t("btn.conf"),
        cancelButtonText: this.$t("btn.canc"),
        customClass: {
          cancelButton: "swal-cancel-btn",
          confirmButton: "swal-confirm-btn",
        },
      });
      if (result.isConfirmed) {
        // this.isLoading = true;
        let asset = {
          id_asset: this.assetLostInfo.id_asset,
          is_lost: "not lost",
        };

        await this.$store.dispatch("ChangeLostStatus", asset);
        await this.loadAssetData();
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.$bvModal.hide("confirm-lost-modal");
      }
    },
    async confirmLost() {
      this.isLoading = true;
      let lost = {
        id_asset: this.assetLostInfo.id_asset,
        is_lost: "lost",
      };
      try {
        await this.$store.dispatch("ChangeLostStatus", lost);
        await this.loadAssetData();
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          text: this.$t("dialog.confirm_report_lost"),
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: this.$t("dialog.asset_in_maintain"),
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.$bvModal.hide("confirm-lost-modal");
      this.isLoading = false;
    },
    async cancelReportFound() {
      const result = await Swal.fire({
        text: this.$t("detailasset.canc_found", {
          assetId: this.assetLostInfo.id_fixed_asset,
        }),
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
        confirmButtonText: this.$t("btn.conf"),
        cancelButtonText: this.$t("btn.canc"),
        customClass: {
          cancelButton: "swal-cancel-btn",
          confirmButton: "swal-confirm-btn",
        },
      });
      if (result.isConfirmed) {
        this.isLoading = true;
        let asset = {
          id_asset: this.assetLostInfo.id_asset,
          is_lost: "lost",
        };

        await this.$store.dispatch("ChangeLostStatus", asset);
        await this.loadAssetData();
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.isLoading = false;
        this.$bvModal.hide("confirm-lost-modal");
      }
    },
    async confirmFound() {
      this.isLoading = true;
      let lost = {
        id_asset: this.assetLostInfo.id_asset,
        is_lost: "not lost",
      };
      try {
        await this.$store.dispatch("ChangeLostStatus", lost);
        await this.loadAssetData();
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          text: this.$t("dialog.confirm_report_found"),
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (err) {
        alert(err);
      }
      this.$bvModal.hide("confirm-lost-modal");
      this.isLoading = false;
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    // async goToDetail(id) {
    //   this.$store.commit("setMapUrlMutations", "");
    //   this.$store.commit("setMapPositionMutations", "");
    //   // this.editShow = !this.editShow;
    //   this.filterShow = false;
    // },
    backToDetail() {
      this.editShow = false;
    },
    async onFileChange(event) {
      console.log(event);
      const file = event.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        event.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.url = URL.createObjectURL(file);
      console.log("pic", file);
      this.upload();
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      console.log(`before compress`, this.pictureFile);
      console.log(`after compress`, fileforupload);

      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      // console.log(`fileforupload`, fileforupload);
      const res = await this.$store.dispatch("uploadImage", formdata);
      this.getDetailAsset.asset.image_url = {
        url: res.data.data.uploadFileAtLevel.url,
        date: new Date(),
      };
      console.log("res.data.data;", res.data.data);
      this.url_new = this.getDetailAsset.asset.image_url;
      console.log("url_new ", this.url_new);
    },

    async gotoDetails() {
      this.getDetailAsset.asset.location_mapX = this.getMapPosition.x;
      this.getDetailAsset.asset.location_mapY = this.getMapPosition.y;
      this.getDetailAsset.asset.purchase_date = this.momentDayForUpdate(
        this.getDetailAsset.asset.purchase_date
      );
      this.getDetailAsset.asset.start_date = this.momentDayForUpdate(
        this.getDetailAsset.asset.start_date
      );
      if (
        !this.getAllAssets.find(
          (each) =>
            each.id_fixed_asset === this.getDetailAsset.asset.id_fixed_asset &&
            each.id_asset !== this.getDetailAsset.asset.id_asset
        )
      ) {
        let data_send = this.getDetailAsset;
        if (this.getDetailAsset.asset.location_building === "อื่นๆ") {
          data_send.asset.location_building = this.new_building;
        }
        if (this.getDetailAsset.asset.location_floor === "อื่นๆ") {
          data_send.asset.location_floor = this.new_floor;
        }
        if (!data_send.asset.acc_depreciation)
          data_send.asset.acc_depreciation = null;
        if (!data_send.asset.net_book) data_send.asset.net_book = null;
        if (!data_send.asset.purchase_price)
          data_send.asset.purchase_price = null;
        await this.$store.dispatch("adminEditAsset", data_send);
      } else {
        alert("รหัสสินทรัพย์นี้มีในระบบแล้ว");
      }
      // this.editShow = false;
    },
    convertTime(time) {
      return moment(time).format("HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
*::v-deep .modal-footer {
  border: none !important;
}

.delete-asset {
  margin-right: 1rem;
  color: red;
  background: transparent;
  border: 1px solid red;
  border-radius: 8px !important;
  padding: 0.5rem;
  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}
.main {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(80vw / 10);
    max-width: calc(80vw / 10);
  }
  thead th:last-child {
    min-width: calc(80vw / 10 + 16px);
    max-width: calc(80vw / 10 + 30px);
  }
  tbody td {
    min-width: calc(80vw / 10);
    max-width: calc(80vw / 10);
  }
  tbody td:last-child {
    min-width: calc(80vw / 10 + 16px);
    max-width: calc(80vw / 10 + 16px);
  }

  tbody {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  //  table scrollable
  .main {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(200vw / 10);
      max-width: calc(200vw / 10);
    }

    tbody td {
      min-width: calc(200vw / 10);
      max-width: calc(200vw / 10);
    }
    tbody td:last-child {
      min-width: calc(200vw / 10 + 32px);
      max-width: calc(200vw / 10 + 32px);
    }
    thead th:last-child {
      min-width: calc(200vw / 10 + 32px);
      max-width: calc(200vw / 10 + 32px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.modal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}

.filter-count {
  border: 2px solid #007afe;
  border-radius: 5px;
}

.filter-count-box {
  color: #007afe;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

select {
  color: #000;
  padding: 0.4rem !important;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
}

textarea {
  padding: 0.4rem;
  border: none;
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
  padding-bottom: 1rem;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: 1rem;
  // justify-content: start;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}
#basic-addon1:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}
.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
.table:last-child {
  border-radius: 30px 30px 0 0;
}

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 100%;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.confirm {
  width: 100%;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: 580px;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    padding-top: 30px;
    height: 600px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.modal-content {
  // padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    // padding: 0.4rem;
  }
}

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    overflow-x: scroll;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 6px;
}

.input-group[data-v-659b5a2a]
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  /* border-radius: 6px; */
  border: 0px;
  padding-left: 5px;
}

.decline-button {
  background: gray !important;
}

.confirm-button {
  background: #ed1616 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.asset-lost-info {
  label {
    color: #7c7c7c;
    font-size: 15px;
  }
  p {
    font-size: 18px;
    margin-left: 5px;
    margin-bottom: 20px;
  }
}
</style>
