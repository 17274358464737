<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :rowSelection="rowSelection"
      suppressDragLeaveHidesColumns
      @grid-ready="onGridReady"
      checkboxSelection="true"
      suppressMovableColumns
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import assetImg from "../Table/components/assetImg.vue";
import btnStatus from "../Table/components/btnStatus.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    dataTable: [],
    tag: { type: Boolean, default: false },
    columnPermission: { type: Boolean, default: false },
    otherColumn: [],
    page: { type: Number },
    perPage: { type: Number },
  },
  watch: {
    columnPermission() {
      this.setColumn();
    },
  },
  beforeMount() {
    this.setColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom2: "canUseCustom2",
      canUseCustom4: "canUseCustom4",
      canUseCustom8: "canUseCustom8",
    }),
    rowData() {
      return this.dataTable.map((log, index) => {
        let dataShow = {
          index: (this.page - 1) * this.perPage + index + 1,
          date: moment(log.created_at).format("DD/MM/YYYY"),
          time: moment(log.created_at).format("HH:mm"),
          editor: log.user.name,
          first_img: {
            data: log.first_img_url?.url,
            isChange: log.edit.includes("first_img_url"),
          },
          new_img: {
            data: log.image_url?.url,
            isChange: log.edit.includes("image_url"),
          },
          id_fixed_asset: {
            data: log.id_fixed_asset,
            isChange: log.edit.includes("id_fixed_asset"),
          },
          name: { data: log.name, isChange: log.edit.includes("name") },
          description: {
            data: log.description || "-",
            isChange: log.edit.includes("description"),
          },
          description2: {
            data: log.description2 || "-",
            isChange: log.edit.includes("description2"),
          },
          category: {
            data: log.categories || "-",
            isChange: log.edit.includes("category"),
          },
          sub_category: {
            data: log.sub_category || "-",
            isChange: log.edit.includes("sub_category"),
          },
          sub_category2: {
            data: log.sub_category2 || "-",
            isChange: log.edit.includes("sub_category2"),
          },
          location_building: {
            data: log.location_building || "-",
            isChange: log.edit.includes("location_building"),
          },
          location_floor: {
            data: log.location_floors || "-",
            isChange: log.edit.includes("location_floor"),
          },
          location_room: {
            data: log.location_room || "-",
            isChange: log.edit.includes("location_room"),
          },
          location_department: {
            data: log.location_departments || "-",
            isChange: log.edit.includes("location_department"),
          },
          location_zone: {
            data: log.location_zones || "-",
            isChange: log.edit.includes("location_zone"),
          },
          note: { data: log.note || "-", isChange: log.edit.includes("note") },
          qr_code: {
            data: log.qrcode || "-",
            isChange: log.edit.includes("qrcode"),
          },
        };
        if (this.columnPermission) {
          dataShow = {
            ...dataShow,
            purchase_date: {
              data: moment(log.purchase_date).isValid()
                ? moment(log.purchase_date).format("DD/MM/YYYY")
                : "-",
              isChange: log.edit.includes("purchase_date"),
            },
            purchase_price: {
              data: log.purchase_price || "-",
              isChange: log.edit.includes("purchase_price"),
            },
            useful_life: {
              data: log.useful_life || "-",
              isChange: log.edit.includes("useful_life"),
            },
            start_date: {
              data: moment(log.start_date).isValid()
                ? moment(log.start_date).format("DD/MM/YYYY")
                : "-",
              isChange: log.edit.includes("start_date"),
            },
            acc_depreciation: {
              data: log.acc_depreciation || "-",
              isChange: log.edit.includes("acc_depreciation"),
            },
            net_book: {
              data: log.net_book || "-",
              isChange: log.edit.includes("net_book"),
            },
            asset_sector: {
              data: log.sector || "-",
              isChange: log.edit.includes("sector"),
            },
            asset_branch: {
              data: log.branch || "-",
              isChange: log.edit.includes("branch"),
            },
            insurance_start: {
              data: moment(log.custom_start_time).isValid()
                ? moment(log.custom_start_time).format("DD/MM/YYYY")
                : "-",
              isChange: log.edit.includes("custom_start_time"),
            },
            insurance_end: {
              data: moment(log.custom_end_time).isValid()
                ? moment(log.custom_end_time).format("DD/MM/YYYY")
                : "-",
              isChange: log.edit.includes("custom_end_time"),
            },
            vendor: {
              data: log.vendor,
              isChange: log.edit.includes("vendor"),
            },
          };
          this.otherColumn.forEach((val) => {
            const assetColumn = log.asset_detail.find(
              (x) => x.column_no == val.column_no
            );
            if (assetColumn) {
              dataShow[`other_${val.column_no}`] = {
                data: assetColumn.value,
                isChange: false,
              };
            } else {
              dataShow[`other_${val.column_no}`] = {
                data: "-",
                isChange: false,
              };
            }
          });
        }
        return dataShow;
      });
    },
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    setColumn() {
      this.columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          maxWidth: 110,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.edit_date"),
          field: "date",
          width: 150,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.edit_time"),
          field: "time",
          maxWidth: 110,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("asset.editor_name"),
          field: "editor",
          // maxWidth: 110,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("detailasset.img_reg"),
          field: "first_img",
          maxWidth: 110,
          suppressSizeToFit: true,
          cellRendererFramework: "assetImg",
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("asset.edit_image"),
          field: "new_img",
          maxWidth: 110,
          suppressSizeToFit: true,
          cellRendererFramework: "assetImg",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.more_description"),
          field: "description2",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("model.model"),
          field: "sub_category2",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
          hide: !this.canUseCustom4,
        },
        {
          headerName: this.$t("asset.location"),
          field: "asset_sector",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
          hide: !this.canUseCustom2 || !this.columnPermission,
        },
        {
          headerName: this.$t("asset.branch"),
          field: "asset_branch",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
          hide: !this.canUseCustom2 || !this.columnPermission,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.room"),
          field: "location_room",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
          hide: !this.canUseCustom8,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: "QR Code",
          field: "qr_code",
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
      ];
      if (this.columnPermission) {
        this.columnDefs = [
          ...this.columnDefs,
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            maxWidth: 130,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => params.value.data,
            cellStyle: (params) => {
              if (params.value.isChange) {
                return { color: "#007AFE" };
              }
            },
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => params.value.data,
            cellStyle: (params) => {
              if (params.value.isChange) {
                return { color: "#007AFE" };
              }
            },
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => params.value.data,
            cellStyle: (params) => {
              if (params.value.isChange) {
                return { color: "#007AFE" };
              }
            },
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            maxWidth: 130,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => params.value.data,
            cellStyle: (params) => {
              if (params.value.isChange) {
                return { color: "#007AFE" };
              }
            },
          },
        ];
        if (this.canUseCustom2) {
          this.columnDefs = [
            ...this.columnDefs,
            {
              headerName: this.$t("asset.insurance_start"),
              field: "insurance_start",
              sortable: true,
              suppressSizeToFit: true,
              maxWidth: 130,
              cellRenderer: (params) => params.value.data,
              cellStyle: (params) => {
                if (params.value.isChange) {
                  return { color: "#007AFE" };
                }
              },
            },
            {
              headerName: this.$t("asset.insurance_end"),
              field: "insurance_end",
              sortable: true,
              suppressSizeToFit: true,
              maxWidth: 130,
              cellRenderer: (params) => params.value.data,
              cellStyle: (params) => {
                if (params.value.isChange) {
                  return { color: "#007AFE" };
                }
              },
            },
            {
              headerName: this.$t("asset.vendor"),
              field: "vendor",
              sortable: true,
              suppressSizeToFit: true,
              cellRenderer: (params) => params.value?.data || "-",
              cellStyle: (params) => {
                if (params.value?.isChange) {
                  return { color: "#007AFE" };
                }
              },
            },
          ];
        }
        if (this.otherColumn.length > 0) {
          let column = this.otherColumn;
          for (const col of column) {
            let header = {
              headerName: col.name,
              field: `other_${col.column_no}`,
              cellRenderer: (params) => {
                return params.value.data || "-";
              },
              suppressSizeToFit: true,
            };
            this.columnDefs.push(header);
          }
        }
      }
    },
  },
  components: {
    AgGridVue,
    btnStatus,
    assetImg,
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
