<script setup>
import { computed, reactive, ref, watch, defineProps } from "vue";
import { Gesture } from "@use-gesture/vanilla";
import store from "../store";

const props = defineProps(["disabled"]);
const mapUrl = computed(() => store.getters.getMapUrl);
const mapPosition = computed(() => store.getters.getMapPosition);

const container = ref();
const image = ref();
const map = reactive({ x: 0, y: 0, s: 1, width: 0, height: 0 });
const click = reactive({ x: 0, y: 0, timeStamp: 0, tap: false });
const pin = computed(() => {
  if (!image.value || !click.tap) {
    return { x: 0, y: 0, show: false };
  }
  return {
    x: click.x + map.x + (click.x - map.width / 2) * (map.s - 1),
    y: click.y + map.y + (click.y - map.height / 2) * (map.s - 1),
    show: true,
  };
});

const hasValue = (x) => x !== null && x !== undefined;
const resetPin = () => {
  const x = mapPosition.value.x;
  const y = mapPosition.value.y;
  if (hasValue(x) && hasValue(y) && image.value) {
    click.x = (x / image.value.naturalWidth) * image.value.clientWidth;
    click.y = (y / image.value.naturalHeight) * image.value.clientHeight;
    click.tap = true;
  } else {
    click.tap = false;
  }
};
watch(mapPosition, () => {
  resetPin();
});

const zoomIn = () => {
  map.s = map.s * 2;
};
const zoomOut = () => {
  map.s = map.s / 2;
};

const DOUBLE_CLICK_THRESHOLD = 200;
// const SNAP_THRESHOLD = 25;
let shouldCancelTap = false;

watch(image, () => {
  const imageElement = image.value;
  if (!imageElement) return;
  const containerElement = container.value;

  // console.log("image loading");
  let gesture;
  imageElement.onload = () => {
    // resize image when window resize
    const setImageSize = () => {
      map.width = imageElement.clientWidth;
      map.height = imageElement.clientHeight;
    };
    setImageSize();
    window.addEventListener("resize", setImageSize);

    const width = imageElement.clientWidth;
    const height = imageElement.clientHeight;
    const containerWidth = containerElement.clientWidth;
    const containerHeight = containerElement.clientHeight;
    // console.log("image", width, height);
    // console.log("container", containerWidth, containerHeight);
    const initial = {
      s: width > height ? containerWidth / width : containerHeight / height,
      x: containerWidth / 2 - width / 2,
      y: 0,
    };
    map.s = initial.s;
    map.x = initial.x;
    map.y = initial.y;

    resetPin();

    gesture = new Gesture(
      containerElement,
      {
        onDrag({ active, offset: [ox, oy], tap, event: e, timeStamp }) {
          if (tap) {
            // double click
            const isDoubleTap =
              click.timeStamp > 0 &&
              timeStamp - click.timeStamp < DOUBLE_CLICK_THRESHOLD;
            click.timeStamp = timeStamp;
            if (isDoubleTap) {
              // console.log("double tap");
              shouldCancelTap = true;
              if (
                map.s === initial.s &&
                map.x === initial.x &&
                map.y === initial.y
              ) {
                map.s = map.s * 2;
                // console.log("zoom in");
              } else {
                map.s = initial.s;
                map.x = initial.x;
                map.y = initial.y;
                // console.log("zoom out");
              }
              return;
            }
            // console.log("a tap");
            setTimeout(() => {
              if (shouldCancelTap) {
                shouldCancelTap = false;
                // console.log("cancle single tap");
              } else {
                // console.log("single tap");
                const event = e;
                //  as MouseEvent;
                if (event.target === imageElement) {
                  // console.log("click", event.offsetX, event.offsetY);
                  click.x = event.offsetX;
                  click.y = event.offsetY;
                  if (!click.tap) click.tap = true;
                }
              }
            }, DOUBLE_CLICK_THRESHOLD);
          } else if (active) {
            // console.log("drag", ox, oy);
            map.x = ox;
            map.y = oy;
          }
        },
        // https://codesandbox.io/s/amazing-tree-sfuexl?file=/src/App.jsx:1205-1700
        onPinch: ({
          origin: [ox, oy],
          first,
          movement: [ms],
          offset: [s],
          memo,
        }) => {
          // console.log("pinch");
          if (first) {
            const { width, height, x, y } =
              imageElement.getBoundingClientRect();
            const tx = ox - (x + width / 2);
            const ty = oy - (y + height / 2);
            memo = [map.x, map.y, tx, ty];
          }
          const x = memo[0] - (ms - 1) * memo[2];
          const y = memo[1] - (ms - 1) * memo[3];
          map.x = x;
          map.y = y;
          map.s = s;
          return memo;
        },
        // onWheel: ({ offset: [ox, oy], pinching }) => {
        //   if (!pinching) {
        //     console.log("wheel", ox, oy);
        //     map.x = -ox;
        //     map.y = -oy;
        //   }
        // },
      },
      {
        drag: {
          filterTaps: true,
          from: () => [map.x, map.y],
        },
        pinch: {
          scaleBounds: {
            min: initial.s,
          },
          from: () => [map.s, 0],
        },
        wheel: {
          from: () => [-map.x, -map.y],
        },
      }
    );
  };
  return () => {
    gesture && gesture.destroy();
  };
});

// watchEffect(() => {
//   if (Math.abs(image.x) + Math.abs(image.y) < SNAP_THRESHOLD) {
//     console.log("pan snap");
//     image.x = 0;
//     image.y = 0;
//   }
// });

const modal = ref();
const onSave = () => {
  if (image.value) {
    // console.log("dispatch setMapPosition", click.x, click.y);
    store.dispatch("setMapPosition", {
      x: (click.x / image.value.clientWidth) * image.value.naturalWidth,
      y: (click.y / image.value.clientHeight) * image.value.naturalHeight,
    });
  }
  modal.value.hide();
};
</script>

<template>
  <div>
    <b-button
      @click="modal.show()"
      class="image-map-button w-100"
      :disabled="props.disabled"
    >
      <b-icon-geo-alt-fill /> {{ $t("registeritem.specify_loc_map") }}
    </b-button>
    <div @click="modal.show()" class="cursor-pointer" v-if="$slots.default">
      <slot></slot>
    </div>
    <b-modal
      id="image-map"
      ref="modal"
      size="lg"
      centered
      dialog-class="mx-auto position-relative"
    >
      <div class="d-flex flex-column position-absolute top-0 end-0 m-3 z-40">
        <button class="bg-none border-none" @click="zoomOut">
          <b-icon-dash class="border rounded-top p-2 bg-white" font-scale="2" />
        </button>
        <button class="bg-none border-none -mt-2" @click="zoomIn">
          <b-icon-plus
            class="border rounded-bottom p-2 bg-white"
            font-scale="2"
          />
        </button>
      </div>
      <div
        ref="container"
        class="position-relative select-none touch-none cursor-grab w-100 overflow-hidden"
      >
        <svg
          class="position-absolute z-30"
          height="12"
          width="12"
          :style="{
            transform: `translate(-50%, -50%) translate(${pin.x}px, ${pin.y}px)`,
            display: pin.show ? 'block' : 'none',
          }"
        >
          <circle
            cx="6"
            cy="6"
            r="5"
            stroke-width="1"
            stroke="black"
            fill="red"
          />
        </svg>
        <img
          ref="image"
          :src="mapUrl"
          draggable="false"
          class="touch-none select-none image-map"
          :style="{
            transform: `translateX(${map.x}px) translateY(${map.y}px) scale(${map.s})`,
          }"
        />
      </div>
      <template #modal-footer class="d-flex justify-content-center">
        <b-button
          variant="secondary"
          size="sm"
          @click="modal.hide()"
          class="cancle-button"
        >
          {{ $t("registeritem.cancle") }}
        </b-button>
        <b-button variant="primary" size="sm" @click="onSave">
          {{ $t("registeritem.save") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.image-map {
  height: calc(100vh - 300px);
}
.image-map-button {
  background: white;
  color: #017afd;
  border: 1px solid #017afd;
}
.image-map-button:hover {
  background-color: #017afd;
  color: white;
  border: inherit;
}

.cancle-button {
  background: #949494;
}
.touch-none {
  touch-action: none;
}

.select-none {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.bg-none {
  background: none;
}

.border-none {
  border: none;
}

.\-mt-2 {
  margin-top: -2px;
}
</style>
