import HomeEN from "./lang/en/home.json";
import AssetEN from "./lang/en/asset.json";
import btnEN from "./lang/en/btn.json";
import countEN from "./lang/en/count.json";
import checkitemEN from "./lang/en/checkitem.json";
import dashboardEN from "./lang/en/dashboard.json";
import detailasEN from "./lang/en/detailasset.json";
import dialogEN from "./lang/en/dialog.json";
import docregasEN from "./lang/en/docregas.json";
import filterEN from "./lang/en/filter.json";
import historyEN from "./lang/en/history.json";
import profileEN from "./lang/en/profile.json";
import passwordEN from "./lang/en/password.json";
import scanEN from "./lang/en/scan.json";
import loginEN from "./lang/en/login.json";
import selectcomEN from "./lang/en/selectcompany.json";
import registerEN from "./lang/en/register.json";
import registeritemEN from "./lang/en/registeritem.json";
import approveEN from "./lang/en/approve.json";
import adddateEN from "./lang/en/adddate.json";
import adminEN from "./lang/en/admin.json";
import imgoneassetEN from "./lang/en/imgoneasset.json";
import importEN from "./lang/en/import.json";
// import maintenanceEN from "./lang/en/maintenance.json";
import categoryEN from "./lang/en/category.json";
import departmentEN from "./lang/en/department.json";
import zoneEN from "./lang/en/zone.json";
import columnEN from "./lang/en/column.json";
import maintenancePlanEN from "./lang/en/maintenance_plan.json";
import branchEN from "./lang/en/branch.json";
import vendorEN from "./lang/en/vendor.json";
import depreciationEN from "./lang/en/depreciation.json";
import subAssetEN from "./lang/en/sub_asset.json";
import maintenanceReportEN from "./lang/en/maintenance_report.json";

import borrowAssetEN from "./lang/en/borrowreturn.json";
import borrowReturnStatusesEN from "./lang/en/borrowreturn_statuses.json";
import spongaEN from "./lang/en/sponga.json";

export default {
  home: HomeEN,
  asset: AssetEN,
  btn: btnEN,
  count: countEN,
  checkitem: checkitemEN,
  dashboard: dashboardEN,
  detailasset: detailasEN,
  dialog: dialogEN,
  docregas: docregasEN,
  filter: filterEN,
  history: historyEN,
  profile: profileEN,
  password: passwordEN,
  scan: scanEN,
  login: loginEN,
  selectcompany: selectcomEN,
  register: registerEN,
  registeritem: registeritemEN,
  approve: approveEN,
  adddate: adddateEN,
  admin: adminEN,
  imgoneasset: imgoneassetEN,
  import: importEN,
  // maintenance: maintenanceEN,
  category: categoryEN,
  department: departmentEN,
  zone: zoneEN,
  column: columnEN,
  maintenance_plan: maintenancePlanEN,
  branch: branchEN,
  vendor: vendorEN,
  depreciation: depreciationEN,
  subasset: subAssetEN,
  maintenance_report: maintenanceReportEN,
  borrow: borrowAssetEN,
  borrow_return_statuses: borrowReturnStatusesEN,
  sponga: spongaEN,
};
