<template>
  <div class="asset-card" @click="gotoAsset">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div class="asset-img-container">
          <img :src="asset.image_url.url" alt="" v-if="asset.image_url" />
          <img :src="noimg" alt="" v-else />
        </div>
        <div class="asset-info">
          <p style="font-weight: 500">{{ asset.name }}</p>
          <p style="color: #818181">{{ asset.id_fixed_asset }}</p>
          <p v-if="asset.is_lost === 'lost'">
            {{ $t("count.as_lost") }}
          </p>
          <p v-else-if="!asset.image_url">
            {{ $t("count.not_reg") }}
          </p>
          <p v-else-if="asset.asset_status === null">
            {{ $t("count.not_count") }}
          </p>
          <p v-else>
            {{ assetStatusTranslate(asset.asset_status) }}
          </p>
        </div>
      </div>
      <img
        v-if="$route.name === 'CheckItem'"
        class="d-block ml-auto"
        style="cursor: pointer;"
        width="20"
        height="20"
        src="../../../assets/chevron-right.png"
        alt=""
      />
      <img
        v-else
        class="d-block ml-auto"
        style="cursor: pointer;"
        width="20"
        height="20"
        src="../../../assets/x.png"
        alt=""
        @click="handleDelete"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["delete-link"],
  props: {
    asset: {
      required: true,
    },
  },
  data() {
    return {
      noimg: require("@/image/Not-regis-qr-code.png"),
    };
  },
  methods: {
    assetStatusTranslate(text) {
      switch (text) {
        case "ใช้งานได้":
          return this.$t("count.inuse");
        case "ไม่ได้ใช้งาน":
          return this.$t("count.not_inuse");
        case "ชำรุด":
          return this.$t("count.defect");
        case "อยู่ระหว่างการซ่อม":
          return this.$t("count.repairing");
        case "ซ่อมได้":
          return this.$t("count.repar");
        case "ซ่อมไม่ได้":
          return this.$t("count.irrepar");
        case "รอการประเมิน":
          return this.$t("count.wait_as");
        case "กำลังซ่อม":
          return this.$t("maintenance.fixing");
        case "ซ่อมแล้ว":
          return this.$t("maintenance.fixed");
        default:
          return "";
      }
    },
    gotoAsset() {
      if (this.$route.name === "CheckItem") {
        this.$router.push({
          name: "DetailOneAsset",
          params: { id: this.asset.id_asset },
        });
      }
    },
    handleDelete() {
      this.$emit("delete-link", this.asset.id_asset);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.asset-card {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
}

.asset-img-container {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.asset-info {
  p {
    margin: 0;
  }
}
</style>
