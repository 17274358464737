const hostname = window.location.hostname;
switch (hostname) {
  case "prod-app-verscan.web.app":
  case "prod-app-verscan.firebaseapp.com":
    window.location.href = "https://app.verscan.com/";
    break;
  case "pre-prod-web-verscan.web.app":
  case "pre-prod-web-verscan.firebaseapp.com":
    window.location.href = "https://pre-prod-web.verscan.com/";
    break;
  case "pre-prod-app-verscan.web.app":
  case "pre-prod-app-verscan.firebaseapp.com":
    window.location.href = "https://pre-prod-app.verscan.com/";
    break;
  case "staging-app-verscan.web.app":
  case "staging-app-verscan.firebaseapp.com":
    window.location.href = "https://staging-app.verscan.com/";
    break;
  case "dev-app-verscan.web.app":
  case "dev-app-verscan.firebaseapp.com":
    window.location.href = "https://dev-app.verscan.com/";
    break;
  default:
    break;
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Cookie from "js-cookie";
import VModal from "vue-js-modal";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import "vue-js-modal/dist/styles.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueSignaturePad from "vue-signature-pad";
// import "./registerServiceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";
import i18n from "./plugins/i18n";

// import VConsole from "vconsole";

// const vConsole = (() => {
//   switch (window.location.hostname) {
//     case "app.verscan.com":
//     case "prod-app-verscan.web.app":
//       return null;
//     default:
//       return new VConsole();
//   }
// })();

import VueNumeric from "vue-numeric";
import vueDebounce from "vue-debounce";
import VueApexCharts from 'vue-apexcharts'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
Vue.use(PrimeVue);
import ImagePreview from "primevue/imagepreview";
import Button from "primevue/button"
Vue.component("ImagePreview", ImagePreview);
Vue.component("Button", Button);
import VCalendar from "v-calendar";

if (window.isNativeApp && !window.initializedFromNativeApp) {
  window.initializedFromNativeApp = true;
  // window.isNativeApp, window.cookielogin are value from application
  if (window.cookielogin) {
    console.log("window.cookielogin");
    console.log(window.cookielogin);
    Cookie.set("cookielogin", window.cookielogin, {
      expires: 7,
      domain: location.hostname.endsWith(".verscan.com") ? "verscan.com" : null,
    });
  }

  // window.isNativeApp, window.selectedcompany are value from application
  if (window.selectedcompany) {
    const company = JSON.parse(window.selectedcompany);
    const oldCompany = JSON.parse(Cookie.get("selectedcompany") ?? "null");
    if (oldCompany?.company?.id_company !== company.company.id_company) {
      Cookie.set("selectedcompany", company, {
        expires: 1,
        domain: location.hostname.endsWith(".verscan.com")
          ? "verscan.com"
          : null,
      });
    }
  }
}

Vue.use(vueDebounce);
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap4-vue'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("v-select", vSelect);
Vue.use(VueSignaturePad);
Vue.component(VueQrcode.name, VueQrcode);
// Vue.component('date-picker', DatePicker)
// Vue.use(BootstrapVueIcons)

// import BootstrapVue from "bootstrap-vue";
Vue.config.productionTip = false;
Vue.use(VModal, { componentName: "modal" });
Vue.use(VueLodash, { name: "custom", lodash: lodash });

// Vue.use(BootstrapVue);

import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);

Vue.use(VueNumeric);

Vue.use(vueDebounce, {
  defaultTime: "700ms",
});

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Object.definePrototype(Vue.prototype, "$moment", { value: moment });

String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

library.add(faUserSecret, faPlusCircle, faMinusCircle);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

// const app = createApp(/** App **/);
// app.use(BootstrapIconsPlugin);

// ----- function for mobile application  ---------

// set website cookie from mobile
window.nativeSetCookie = (cookie) => {
  Cookie.set("cookielogin", cookie, {
    expires: 7,
    domain: location.hostname.endsWith(".verscan.com") ? "verscan.com" : null,
  });
};

// for using router in mobile
window.useRouter = () => {
  return router;
};
