<template>
  <div class="maintenance-report-data">
    <!-- <div class="maintenance-top">{{ $t("maintenance_report.counting") }}</div> -->
    <div style="margin: 0 30px;">
      <div class="maintenance-data">{{ $t("maintenance_report.reportdata") }}</div>
      <MaintenanceSelectedAsset :assetData="assetData">
      </MaintenanceSelectedAsset>
      <div>
        <b-form-group>
          <div>{{ $t("maintenance_report.category") }}</div>
          <b-form-select
            v-model="type"
            :options="typeOptions"
            class="my-3 maintenance-form-select"
          ></b-form-select>
          <label for="detail" class="my-3"
            >{{ $t("maintenance_report.detail") }}<span style="color: red;">*</span></label
          >
          <b-form-textarea
            v-model="repairDetail"
            id="detail"
            placeholder="ระบุเช่น เปิดไม่ติด"
            rows="3"
            max-rows="6"
          >
          </b-form-textarea>
          <label for="img" class="mt-3">{{ $t("maintenance_report.imageOp") }}</label>
          <div class="mb-3" style="color: #7C7C7C; font-size: 16px;">
            {{ $t("maintenance_report.imageDes") }}
          </div>
          <b-form-file
            v-model="imagesFile"
            id="img"
            style="display: none;"
            multiple
            accept="image/*"
            @change="onFileChange"
          ></b-form-file>
          <div class="image-show">
            <div v-for="(image, i) in images" :key="i" class="image-for">
              <img
                class="icon"
                src="../../assets/cancle.svg"
                width="20px"
                @click="deleteImage(i)"
              />
              <img :src="image.result" alt="image" class="image-upload" />
            </div>
            <label style="cursor: pointer;" for="img">
              <img src="@/assets/upload.svg" alt="upload" />
            </label>
          </div>
          <div class="maintenance-button-next">
            <button @click="closeReportData" class="maintenance-button-cancel">
              {{ $t("maintenance_report.cancel") }}
            </button>
            <button @click="submitReportData" class="maintenance-button-submit">
              {{ $t("maintenance_report.next") }}
            </button>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>
<script>
import MaintenanceSelectedAsset from "./MaintenanceSelectedAsset.vue";

export default {
  components: {
    MaintenanceSelectedAsset,
  },
  emits: ["close-report-data", "submit-report-data"],
  data() {
    return {
      repairDetail: "",
      type: "common",
      typeOptions: [
        { value: "common", text:"CM - แจ้งซ่อมทั่วไป" },
        // { value: "protect", text:"PM - บำรุงรักษาเชิงป้องกัน" },
      ],
      images: [],
      imagesFile: [],
    };
  },
  props: {
    assetData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (let i = 0; i < files.length; i++) {
        this.createImage(files[i]);
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images.push({ file, result: e.target.result });
      };
      reader.readAsDataURL(file);
    },
    closeReportData() {
      this.$emit("close-report-data");
    },
    submitReportData() {
      if (this.$props.assetData == null) {
        alert(this.$t('maintenance_report.warning-select-asset'));
      } else if (this.repairDetail == "") {
        alert(this.$t('maintenance_report.warning-fill-detail'));
      } else {
      this.$emit("submit-report-data", {
        repairDetail: this.repairDetail,
        type: this.type,
        images: this.images,
        imageFile: this.imagesFile,
        recheck: true,
        submitdate: new Date(),
        assetId: this.$props.assetData.id_asset,
      });
      }
    },
    deleteImage(imageIndex) {
      this.images.splice(imageIndex, 1);
      this.imageFile.splice(imageIndex, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.maintenance-report-data {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 0;
  flex-direction: column;
  color: black;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  overflow: auto;
}
.maintenance-top {
  text-align: center;
  width: 100%;
  height: 24px;
  background: #acd4ff;
  font-size: 12px;
}
.maintenance-data {
  font-size: 24px;
  text-align: center;
  margin: 12px 0;
  padding: 3px;
  border-bottom: 1px solid #ededed;
}
.maintenance-form-select {
  width: 100%;
  height: 45px;
  background: transparent;
  border: solid 1px;
  border-radius: 11px;
  padding: 0 0.75rem;
}
.maintenance-button-next {
  display: flex;
  justify-content: flex-end;
}
.maintenance-button-cancel {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: #ffffff;
  color: #007afe;
  border: solid 1px;
  border-radius: 11px;
}
.maintenance-button-submit {
  font-size: 14px;
  margin: 12px 0.1rem;
  width: 100%;
  height: 45px;
  background: #007afe;
  color: #ffffff;
  border: solid 1px;
  border-radius: 11px;
}
.image-upload {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.image-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 6px;
}
.icon {
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}
.image-for {
  position: relative;
}
</style>
