<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <div class="DetailAsset">
      <div class="w-100">
        <div class="back-icon">
          <img src="../../assets/previous.svg" width="30" @click="goBack()" />
        </div>
        <h1 class="header-text">
          <div class="topic" style="padding-top: 0px !important">
            {{ $t("asset.as_data") }}
          </div>
        </h1>
        <template v-if="!isLoading">
          <div v-if="isCustom">
            <customCheckItemHeader
              :customid="permissionStatus.company.custom_id"
              :department="
                assetData.location_departments
                  ? assetData.location_departments.name
                  : null
              "
              :other1="
                assetData.counting_item_details[0]
                  ? assetData.counting_item_details[0].value
                  : null
              "
            ></customCheckItemHeader>
          </div>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <label v-if="!getMapPosition.x && !getMapPosition.y"
                  >{{ $t("asset.as_loc") }} ({{ $t("asset.no_loc") }}{})</label
                >
                <label v-else>{{ $t("asset.as_loc") }}</label>
                <img
                  class="indoor-map-modal"
                  src="../../assets/mark-plan.png"
                />
                <button
                  type="button"
                  class="btn btn-indoor-modal"
                  data-dismiss="modal"
                >
                  {{ $t("btn.conf") }}
                </button>
              </div>
            </div>
          </div>

          <div class="card-form-login">
            <b-container fluid>
              <b-row v-if="this.first_img">
                <b-col cols="6" class="d-flex justify-content-center">
                  <div class="asset-imgcard">
                    <b-card tag="article" class="border5 banner">
                      <Lazyloadimage
                        :image="checkImage(first_img)"
                        class="card-img-top"
                      ></Lazyloadimage>
                      <b-card-text>
                        {{ $t("checkitem.img_reg") }}
                        <br />{{ $t("count.date") }}
                        {{ this.img_date.regis_date }}
                      </b-card-text>
                    </b-card>
                  </div>
                </b-col>
                <b-col cols="6" class="d-flex justify-content-center">
                  <div v-if="this.current_img" class="asset-imgcard">
                    <b-card tag="article" class="border5 banner bg-grey-light">
                      <Lazyloadimage
                        :image="checkImage(current_img)"
                        class="card-img-top"
                      ></Lazyloadimage>
                      <b-card-text>
                        {{ $t("checkitem.img_new") }}<br />{{
                          $t("count.date")
                        }}
                        {{ this.img_date.now_date }}
                      </b-card-text>
                    </b-card>
                  </div>

                  <div v-else-if="first_img.url" class="asset-imgcard">
                    <b-card tag="article" class="border5 banner bg-grey-light">
                      <Lazyloadimage
                        :image="checkImage(first_img.url)"
                        class="card-img-top"
                      ></Lazyloadimage>
                      <b-card-text>
                        {{ $t("checkitem.img_reg") }}<br />{{
                          $t("count.date")
                        }}
                        {{ this.img_date.regis_date }}
                      </b-card-text>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
              <div class="grey-bg p-5" v-else>
                <h1>{{ $t("asset.img_not") }}</h1>
              </div>
            </b-container>

            <form>
              <div
                class=""
                style="
                display: flex;
                justify-content: center;
                padding: 0px;
                margin-top: 8px;
              "
              >
                <label>{{ $t("asset.qr_id") }} :</label>
                <p
                  class="sub-label"
                  style="padding-left: 10px; margin-bottom: 0px"
                  v-if="assetData.id_qrcode"
                >
                  {{ assetData.id_qrcode }}
                </p>
                <p
                  v-else
                  class="sub-label"
                  style="padding-left: 10px; margin-bottom: 0px"
                >
                  {{ $t("detailasset.not_reg") }}
                </p>
              </div>
              <div
                class=""
                style="display: flex; justify-content: center; padding: 0px"
              >
                <label>{{ $t("asset.as_num") }} :</label>
                <p class="sub-label" style="padding-left: 10px">
                  {{ assetData.id_fixed_asset }}
                </p>
              </div>

              <div v-if="isCustom">
                <customIDDetailOneAsset
                  :customid="permissionStatus.company.custom_id"
                ></customIDDetailOneAsset>
              </div>
              <hr style="border-top: 1px solid #c4c4c4;" />
              <div class="input-group">
                <label
                  >{{ $t("asset.as_name")
                  }}<span class="require-red">*</span></label
                >
                <p class="sub-label">
                  {{ isNull(assetData.name) }}
                </p>

                <label>{{ $t("asset.as_cat") }}</label>
                <p class="sub-label">
                  {{ isNull(assetData.category_name) }}
                </p>

                <label>{{ $t("asset.as_sub_cat") }}</label>
                <p class="sub-label">
                  {{ isNull(assetData.sub_category_name) }}
                </p>
                <template v-if="canUseCustom4">
                  <label>{{ $t("model.model") }}</label>
                  <p class="sub-label">
                    {{ isNull(assetData.sub_category2_name) }}
                  </p>
                </template>

                <label
                  >{{ $t("asset.as_dt")
                  }}<span class="require-red">*</span></label
                >
                <p class="sub-label">
                  {{ isNull(assetData.description) }}
                </p>
                <label>{{ $t("asset.as_stat") }} </label>
                <p
                  class="sub-label require-red clear-pad-mar"
                  style="border: 0px"
                  v-if="assetData.is_lost === 'lost'"
                >
                  {{ $t("count.as_lost") }}
                </p>
                <p
                  class="sub-label require-red clear-pad-mar"
                  style="border: 0px"
                  v-else-if="!assetData.id_qrcode"
                >
                  {{ $t("count.not_reg") }}
                </p>
                <p
                  class="sub-label require-red clear-pad-mar"
                  style="border: 0px"
                  v-else-if="
                    !isCountingFinish && !assetData.asset_status
                  "
                >
                  {{ $t("count.not_count") }}
                </p>
                <p
                  v-else-if="!isCountingFinish"
                  class="sub-label"
                  :class="{
                    'require-red':
                      assetData.asset_status === 'broke' ||
                      assetData.asset_status === 'maintain' ||
                      assetData.asset_status === 'cannot_repair',
                  }"
                >
                  {{ assetStatusTranslate(assetData.asset_status) }}
                </p>
                <p
                  class="sub-label require-red clear-pad-mar"
                  style="border: 0px"
                  v-else-if="
                    isCountingFinish == true && !assetData.asset_status
                  "
                >
                  {{ $t("count.not_count") }}
                </p>
                <p
                  v-else
                  class="sub-label"
                  :class="{
                    'require-red':
                      assetData.asset_status === 'broke' ||
                      assetData.asset_status === 'maintain' ||
                      assetData.asset_status === 'cannot_repair',
                  }"
                >
                  {{ assetStatusTranslate(assetData.asset_status) }}
                </p>

                <label>{{ $t("asset.note") }}</label>
                <p class="sub-label">
                  <span v-if="assetData.note">
                    {{ assetData.note }}
                  </span>
                  <span v-else> - </span>
                </p>

                <label
                  >{{ $t("asset.as_dep")
                  }}<span class="require-red">*</span></label
                >
                <p class="sub-label">
                  {{ $t("asset.build") }}
                  {{ isNull(assetData.location_building) }}
                  {{ $t("asset.fl") }}
                  {{ isNull(assetData.location_floor) }}<br />
                  {{ $t("asset.dep") }}
                  {{ isNull(assetData.location_department) }}
                  {{ $t("asset.zone") }}
                  {{ isNull(assetData.location_zone) }}
                </p>
                <div class="input-group p-0" v-if="!isRoleLower">
                  <label>{{ $t("asset.purchase_date") }}</label>
                  <p class="sub-label">
                    {{ momentDayShow(assetData.purchase_date) }}
                  </p>
                  <label>{{ $t("asset.purchase_price") }}</label>
                  <p class="sub-label">
                    {{ parseFloatShow(assetData.purchase_price) }}
                  </p>
                  <label>{{ $t("asset.useful_life") }}</label>
                  <p class="sub-label">
                    {{ isNull(assetData.useful_life) }}
                  </p>
                  <label>{{ $t("asset.start_date") }}</label>
                  <p class="sub-label">
                    {{ momentDayShow(assetData.start_date) }}
                  </p>
                  <label>{{ $t("asset.acc_depreciation") }}</label>
                  <p class="sub-label">
                    {{ parseFloatShow(assetData.acc_depreciation) }}
                  </p>
                  <label>{{ $t("asset.net_book") }}</label>
                  <p class="sub-label">
                    {{ parseFloatShow(assetData.net_book) }}
                  </p>
                  <customAssetInfo
                    v-if="isCustom"
                    :customid="permissionStatus.company.custom_id"
                    :assetInfo="assetData"
                  />
                  <div v-if="assetData.counting_item_details.length > 0">
                    <div
                      class="input-group p-0"
                      v-for="(item, index) in assetData.counting_item_details"
                      :key="index"
                    >
                      <label>{{ item.name }}</label>
                      <p class="sub-label">
                        {{ isNull(item.value) }}
                      </p>
                    </div>
                  </div>
                </div>

                <label>{{ $t("asset.as_loc") }}</label>
                <ImageMapDisplay></ImageMapDisplay>

                <label
                  >{{ $t("asset.as_reg")
                  }}<span class="require-red">*</span></label
                >
                <p class="sub-label">
                  {{ isNull(assetData.registrant) }}
                </p>

                <label
                  >{{ $t("asset.as_count")
                  }}<span class="require-red">*</span></label
                >
                <p v-if="!assetData.inspector" class="sub-label require-red">
                  {{ $t("asset.not_count") }}
                </p>
                <p v-else class="sub-label">
                  {{ assetData.inspector }}
                </p>

                <label
                  >{{ $t("asset.countdate")
                  }}<span class="require-red">*</span></label
                >
                <p v-if="!assetData.asset_status" class="sub-label require-red">
                  {{ $t("asset.not_count") }}
                </p>
                <p v-else class="sub-label">
                  {{ convertDate(assetData.counting_date) }}
                  {{ $t("asset.time") }}
                  {{ convertTime(assetData.updated_at) }}
                </p>
              </div>
            </form>
          </div>
          <div class="line-break"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ImageMapDisplay from "@/components/ImageMapDisplay.vue";
// import vuescroll from "vuescroll";
import Loading from "vue-loading-overlay";

// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import Swal from "sweetalert2";
import moment from "moment";
import Lazyloadimage from "@/components/Lazyloadimage.vue";

import customIDDetailOneAsset from "@/components/CustomComponent/customIDDetailOneAsset.vue";
import customCheckItemHeader from "@/components/CustomComponent/customCheckItemHeader.vue";
import customAssetInfo from "@/components/CustomComponent/customAssetCountingInfo.vue";
import axios from "axios";
import baseUrl from "../../util/backend";
import { authHeader } from "../../store/actions";
export default {
  data() {
    return {
      assetData: {},
      isLoading: false,
      current_img: "",
      first_img: "",
      img_date: {
        now_date: "",
        regis_date: "",
      },
      ops: {
        vuescroll: {},
        scrollPanel: {
          initialScrollY: 281,
          initialScrollX: 496,
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "12px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "5px",
          keepShow: true,
        },
        bar: {
          showDelay: 0,
          onlyShowBarOnScroll: true,
          keepShow: true,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: true,
          specifyBorderRadius: false,
          minSize: 0,
          size: "14px",
        },
      },
      qrcode_id: "",
      showSucces: true,
      assetsPicture:
        "https://images.unsplash.com/photo-1580480055273-228ff5388ef8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",

      prevRoute: null,
    };
  },
  components: {
    Loading,
    Footer,
    ImageMapDisplay,
    // vuescroll,
    Lazyloadimage,
    customIDDetailOneAsset,
    customCheckItemHeader,
    customAssetInfo,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      // getUserDetail: "getUserDetail",
      getMapPosition: "getMapPosition",
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseCustom4: "canUseCustom4",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    assetStatusTranslate(text) {
      switch (text) {
        case "available":
          return this.$t("count.inuse");
        case "unavailable":
          return this.$t("count.not_inuse");
        case "broke":
          return this.$t("count.defect");
        case "maintain":
          return this.$t("count.repairing");
        case "cannot_repair":
          return this.$t("count.irrepar");
        default:
          return "";
      }
    },
    checkImage(image) {
      if (typeof image === "string") {
        return JSON.parse(image).url;
      } else {
        return image.url;
      }
    },
    gotoSeeAllImage() {
      this.$router.push(`/all_image/${this.qrcode_id}`);
    },
    parseFloatShow(float) {
      console.log("parseFloat", float);
      if (!float || float === "-" || float === null) {
        return "-";
      }
      return parseFloat(float)
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull(data) {
      return data ? data : "-";
    },
    momentDayShow(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "-";
    },
    // gotoEditAsset() {
    //   this.$router.push(`/editDetailAsset/${this.qrcode_id}`);
    // },
    goBack() {
      this.$router.back();
    },
    convertDate(date) {
      return moment(date).format(`DD/MM/YYYY`);
    },
    convertTime(time) {
      return moment(time).format("HH:mm");
    },

    alertLost() {
      Swal.fire(
        this.$t("dialog.success"),
        this.$t("dialog.success_report_lost"),
        "success"
      );
      let lost = {
        id_company: this.assetData.id_company,
        id_asset: this.qrcode_id,
        is_lost: "report",
        note: this.assetData.note,
      };
      this.$store.dispatch("ReportLost", lost);
    },

    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 2500);
    },
  },

  created() {},
  async mounted() {
    this.isLoading = true;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");

    this.qrcode_id = this.$route.params.id;
    // const res = await this.$store.dispatch("getDetailAssets", this.qrcode_id);
    // if (res === "Asset not found") {
    //   alert("Asset not found");
    //   this.isLoading = false;
    //   this.$router.back();
    // }
    const res = await axios.get(
      `${baseUrl()}counting/history/${this.qrcode_id}/${
        this.$route.params.id_summary
      }`,
      {
        params: {
          id_company: id_company,
        },
        ...authHeader(),
      }
    );
    this.assetData = res.data.data;
    console.log(this.assetData);

    const position = {
      x: this.assetData.location_mapX,
      y: this.assetData.location_mapY,
    };

    this.$store.dispatch(
      "setMapUrl",
      this.assetData.location_floors?.image_url || ""
    );
    this.$store.dispatch("setMapPosition", position);

    this.ops.scrollPanel.initialScrollX = this.assetData.location_mapX - 50;
    this.ops.scrollPanel.initialScrollY = this.assetData.location_mapY - 50;
    this.hide();
    if (this.assetData.first_img_url) {
      this.first_img =
        typeof this.assetData.first_img_url === "string"
          ? JSON.parse(this.assetData.first_img_url)
          : this.assetData.first_img_url;
      this.current_img =
        typeof this.assetData.image_url === "string"
          ? JSON.parse(this.assetData.image_url)
          : this.assetData.image_url;
      this.img_date.regis_date = moment(this.first_img.date).format(
        "DD MMM YYYY HH:mm"
      );
      this.img_date.now_date = moment(this.current_img.date).format(
        "DD MMM YYYY HH:mm"
      );
    }

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.asset-imgcard {
  width: 100%;
  max-width: 250px;
  .card {
    border: transparent !important;
  }
  .card-body {
    padding: 1rem 0;
  }
  .card-img-top {
    height: 100px;
    @media only screen and (min-width: 375px) {
      height: 125px;
    }
    @media only screen and (min-width: 640px) {
      height: 220px;
    }
  }
}
.btn {
  font-size: 18px;
}
.btn-primary {
  width: 40%;
  margin-left: 1rem;
}
.btn-secondary {
  background: $font-grey;
  width: 40%;
}
.modal-body {
  padding: 0 !important;
}
.comment-textarea {
  background-color: #e0e0e0;
  border: none;
  border-radius: 10px 10px 10px 10px !important;
  padding: 10px;
}

.alert_lost_btn {
  display: block;
  color: $color-link;
  text-align: right;
}
.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  height: 40vh;
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    padding: 0.4rem;
  }
}

.indoor-map {
  width: 100%;
  // height: 300px;
  // background: rgb(225, 225, 225);
  background-color: white;
  border-radius: 0.75rem;
}
.indoor-map {
  margin-bottom: 0.5rem;
}
.frame-profile {
  // position: absolute;
  // top: -0.2rem;
  left: 50%;
  right: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  /* text-align: center; */
  margin: auto;
  background: rgb(223, 223, 223);
  border-radius: 5%;
  z-index: 90;
  // border: 5px solid white;
  // box-shadow: 0px 3px 6px #00000029;
}

.banner {
  height: 100%;
  width: 100%;
  border-radius: 10%;
  z-index: 1;
  object-fit: cover;
  border: black 1px solid;
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

// .card-form {
//   margin-top: 2rem;
//   width: 90%;
//   padding: 2rem 1rem 2rem 1rem;
// }

.sub-label {
  font-size: 18px;
  color: black;
  padding-left: 10px;
}

#goEdit {
  height: 27px;
  width: 27px;
  float: right;
  margin: 20px;
  margin-bottom: 0px;
  z-index: 100;
}

.DetailAsset {
  position: relative;
  z-index: 2;
}

.card-form-login {
  margin-left: 5%;
  margin-right: 5%;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    // margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>
