<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="responsive-bar">
      <Footer></Footer>
      <!-- <Background></Background> -->
      <div class="scan-qr">
        <div class="">
          <div class="back-icon">
            <img src="../assets/previous.svg" width="30" @click="gotoHome()" />
          </div>
          <h1 class="header-text">
            <div class="topic">{{ $t("history.count") }}</div>
          </h1>
          <div class="inRemind" v-if="is_counting">
            <p id="inReminder">{{ $t("dashboard.in_countpro") }}</p>
          </div>
          <div class="p-4">
            <div class="d-flex justify-content-center">
              <button
                class="btn p-0"
                :class="{ 'btn-login': isOne, 'btn-regist': isOneSwap }"
                style="margin-right: 10px"
                type="submit"
                @click="swapDate"
              >
                {{ $t("history.setcount") }}
              </button>
              <!-- regist login -->
              <button
                v-if="canUseCountingHistory"
                :class="{ 'btn-regist': isTwo, 'btn btn-login': isTwoSwap }"
                type="submit"
                @click="swapHistory"
              >
                {{ $t("history.history") }}
              </button>
            </div>
          </div>
          <div>
            <!-- loop show counting date card from filterDate(current, history) -->
            <div class="card-form card-form-login mt-2">
              <!-- Counting Day Log -->
              <div v-if="filterDate.length !== 0">
                <div v-for="(each, index) in filterDate" :key="index">
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <div
                        class="d-flex w-100 ml-3 align-items-center p-3"
                        @click="gotoDashboard(each.id_summary)"
                      >
                        <div
                          class="
                        icon-calendar
                        pb-1
                        d-flex
                        justify-content-center
                        align-items-around
                      "
                          style="cursor: pointer; width: 40%"
                        >
                          <img src="../assets/cal.svg" />
                        </div>

                        <div class="content content-detail">
                          <div style="cursor: pointer">
                            <h6>
                              {{ $t("history.counting") }}
                              <strong>{{
                                convertDate(each.counting_date)
                              }}</strong>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="checkDelete(each)"
                        style="width: fit-content"
                        class="d-flex align-items-center"
                      >
                        <img
                          class="img-button pb-1"
                          src="../assets/delete_bin.svg"
                          width="20px"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          style="cursor: pointer"
                          @click="setDeleteDate(each)"
                        />
                      </div>
                    </div>
                    <div
                      v-if="featureFlags.download && !each.is_finish"
                      class="d-flex justify-content-start align-items-start w-100"
                    >
                      <button
                        class="image-container-button"
                        :disabled="isDownloading(each.id_summary)"
                        @click="downloadAssets(each)"
                      >
                        <img
                          class="m-1"
                          :class="{ disabled: isDownloading(each.id_summary) }"
                          src="../assets/download.svg"
                        />
                      </button>
                      <div class="d-flex flex-column w-100">
                        <div
                          class="d-flex justify-content-between align-items-end w-100"
                        >
                          <div
                            v-if="!isDownloading(each.id_summary)"
                            class="px-1 mr-3"
                          >
                            {{ $t("history.download") }}
                          </div>
                          <div v-if="each.id_summary in assetsDownloadStatus">
                            <div
                              class="px-1 mr-3"
                              v-if="
                                assetsDownloadStatus[each.id_summary].status ===
                                  'downloading'
                              "
                            >
                              {{ $t("history.downloading") }}
                              {{
                                Math.floor(
                                  assetsDownloadStatus[each.id_summary]
                                    .progress * 100
                                )
                              }}%
                            </div>
                            <div
                              v-if="
                                assetsDownloadStatus[each.id_summary].status ===
                                  'downloaded'
                              "
                            >
                              <img
                                class="img-success"
                                src="../assets/success.svg"
                              />
                            </div>
                            <div
                              class="text-failed"
                              v-if="
                                assetsDownloadStatus[each.id_summary].status ===
                                  'failed'
                              "
                            >
                              failed
                            </div>
                          </div>
                        </div>
                        <div
                          class="m-1"
                          v-if="
                            each.id_summary in assetsDownloadStatus &&
                              assetsDownloadStatus[each.id_summary].status ===
                                'downloading'
                          "
                        >
                          <b-progress
                            height="0.5rem"
                            :value="
                              assetsDownloadStatus[each.id_summary].progress
                            "
                            :max="1"
                          ></b-progress>
                        </div>
                        <div
                          class="px-1"
                          v-else-if="
                            each.id_summary in assetsDownloadStatus &&
                              assetsDownloadStatus[each.id_summary].last_update
                          "
                        >
                          {{
                            `${$t(
                              "history.last_update"
                            )}: ${convertDateWithTime(
                              assetsDownloadStatus[each.id_summary].last_update
                            )}`
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="height: 100px; flex-direction: column; font-size: 16px"
                class="d-flex justify-content-around align-items-center"
              >
                <b-icon-calendar4 scale="1.5" />
                {{ $t("history.no_date") }}
              </div>
            </div>
            <!-- add counting date button -->
            <div
              class="pt-4"
              v-if="
                isOne &&
                  filterDate.length === 0 &&
                  ((permissionStatus.company.custom_id == 1 &&
                    permissionStatus.permission.role.id_role == 3) ||
                    permissionStatus.permission.role.id_role === 1 ||
                    permissionStatus.permission.role.id_role === 2) &&
                  checkRegisteredAsset
              "
            >
              <button
                class="btn-regist"
                style="padding-left: 10px"
                type="submit"
                v-on:click="gotoAdddate()"
                v-if="isOne"
              >
                {{ $t("history.add_date") }}
              </button>
            </div>
            <div v-if="!checkRegisteredAsset">
              {{ $t("history.check_regas") }}
            </div>
            <!-- future counting date display -->
            <customPreCountingDate
              v-show="isOne"
              :currentOpen="openedCounting"
            />
          </div>
          <div class="line-break"></div>
        </div>
      </div>
      <!-- delete counting day -->
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-4 pb-2"
            style="border-radius: 10px"
          >
            <div class="row">
              <p>
                {{ $t("dialog.click") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("dialog.to_del") }} <br />
                {{ $t("history.countingdate") }} <br />
                {{ this.countingDateDelete }}
              </p>
            </div>
            <div class="">
              <button type="button" class="btn cancle col" data-dismiss="modal">
                {{ $t("btn.canc") }}
              </button>
              <button
                type="button"
                class="btn btn-login col"
                data-dismiss="modal"
                @click="confirmDeleteDate()"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Footer from "@/components/Footer.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import customPreCountingDate from "@/components/CustomComponent/customPreCountingDate.vue";
import { UserRoles } from "@/constants/UserRoles";
import { rpcClient } from "../rpc/utils/rpcClient";
import { featureFlags } from "../native-app-feature-flag";

export default {
  data() {
    return {
      isLoading: false,
      id_company: null,
      countingDateDelete: "",
      dateDelete: "",
      isOne: true,
      isOneSwap: false,
      isTwo: true,
      isTwoSwap: false,
      counting_date: "",
      is_counting: false,
      assetsDownloadStatus: {},
      featureFlags,
    };
  },

  computed: {
    ...mapGetters({
      getUserDetail: "getUserDetail",
      getCountingDate: "getCountingDate",
      permissionStatus: "permissionStatus",
      getAllAssets: "getAllAssets",
      summaryData: "getSummaryData",
    }),

    canUseCountingHistory() {
      return [
        UserRoles.EXCLUSIVE,
        UserRoles.ACC_MANAGER,
        UserRoles.ACC_OFFICER,
      ].includes(this.permissionStatus.permission.role.id_role);
    },

    checkRegisteredAsset() {
      // if (this.getAllAssetsStore.some((each) => each.qrcode !== null)) {
      //   return true;
      // }
      // return false;
      return this.getAllAssets.length > 0;
    },
    openedCounting() {
      const countingDate = this.getCountingDate.find((each) => {
        return each.is_finish == false;
      });
      return countingDate;
    },
    filterDate() {
      if (this.isOne && this.getCountingDate) {
        const countingDate = this.getCountingDate.filter((each) => {
          return each.is_finish == false;
        });
        return countingDate;
      } else if (this.getCountingDate) {
        const countingDate = this.getCountingDate.filter((each) => {
          return each.is_finish == true;
        });
        return countingDate;
      } else {
        return [];
      }
    },
  },
  created() {
    window.setAssetsDownloadStatus = this.setAssetsDownloadStatus;
  },
  async mounted() {
    this.isLoading = true;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.$store.dispatch("firstGetAllAsset_Pagination", {
      company_id: this.id_company,
      isCountingDate: false,
      page: 1,
      limit: 1,
    });
    await this.$store.dispatch("getAllCountingDateByIdCompany");
    const checkCounting = await this.$store.dispatch("checkCountingDate");
    this.is_counting = checkCounting.is_counting;
    this.isLoading = false;
    console.log("Mounted");
  },
  watch: {
    async filterDate(newSummaries) {
      await Promise.all(
        newSummaries.map(async (summary) => {
          await this.getAssetsDownloadStatusFromApp(
            summary.id_summary,
            summary.id_company
          );
        })
      );
    },
  },
  methods: {
    checkDelete(summary) {
      if (
        this.permissionStatus.permission.role.id_role !== 1 &&
        this.permissionStatus.permission.role.id_role !== 2
      ) {
        return false;
      }
      if (summary.is_finish || summary.is_signed || summary.is_verified) {
        return false;
      }
      return true;
    },

    setDeleteDate(data) {
      this.deleteDate = data;
      console.log("confirm delete at date: ", data, this.deleteDate);
      this.countingDateDelete = this.convertDate(data.counting_date);
    },
    async confirmDeleteDate() {
      this.isLoading = true;
      await this.$store.dispatch("deleteCountingDate", this.deleteDate);
      await this.$store.dispatch("getAllCountingDateByIdCompany");
      const checkCounting = await this.$store.dispatch("checkCountingDate");
      this.is_counting = checkCounting.is_counting;
      this.isLoading = false;
    },
    swapDate() {
      this.isOne = true;
      this.isOneSwap = false;
      this.isTwo = true;
      this.isTwoSwap = false;
    },
    swapHistory() {
      this.isOne = false;
      this.isOneSwap = true;
      this.isTwo = false;
      this.isTwoSwap = true;
    },
    gotoAdddate() {
      this.$router.push("/adddate");
    },
    gotoDashboard(arg) {
      this.$router.push(`/dashboard/${arg}`);
      // this.$router.push("/dashboard");
    },
    gotoHome() {
      this.$router.push("/");
    },
    async addCountingDay() {
      if (this.counting_date) {
        this.$store.dispatch("addCountingDate", {
          id_company: this.id_company,
          counting_date: this.counting_date,
        });
        this.countingDate = "";
        await this.$store.dispatch("getAllCountingDateByIdCompany");
      } else {
        alert("error");
      }
    },
    convertDate(date) {
      const convertedDate = new Date(date);
      // return (
      //   convertedDate.getDate() +
      //   "/" +
      //   (convertedDate.getMonth() + 1) +
      //   "/" +
      //   convertedDate.getFullYear()
      // );
      return moment(convertedDate).format("DD/MM/YYYY");
    },
    convertDateWithTime(date) {
      const convertedDate = new Date(date);
      return moment(convertedDate).format("DD/MM/YYYY HH:mm:ss");
    },
    async downloadAssets(summary) {
      if (featureFlags.download) {
        this.setAssetsDownloadStatus({
          id_summary: summary.id_summary,
          status: "downloading",
          progress: 0,
        });
        await rpcClient.stub.downloadAssets(summary);
      }
    },
    setAssetsDownloadStatus(downloadStatus) {
      this.assetsDownloadStatus = {
        ...this.assetsDownloadStatus,
        [downloadStatus.id_summary]: downloadStatus,
      };
    },
    async getAssetsDownloadStatusFromApp(id_summary, id_company) {
      if (featureFlags.download) {
        await rpcClient.stub.getAssetsDownloadStatus(id_summary, id_company);
      }
    },
    isDownloading(id_summary) {
      return (
        this.assetsDownloadStatus[id_summary] &&
        this.assetsDownloadStatus[id_summary].status === "downloading"
      );
    },
  },
  components: { Loading, Footer, customPreCountingDate },
};
</script>

<style lang="scss" scoped>
.date:last-child {
  border: none;
}
.date {
  border-bottom: 1px solid lightgrey;
}
button.cancle {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}
.btn-login {
  // margin-left: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
.btn-regist {
  width: 160px;
  height: 45px;
  // padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
.content {
  padding: 0.2rem;
  padding-bottom: 0;
  text-align: center;
  align-self: end;
  justify-self: start;
}

.content-detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  // text-align: center;
}

.content-header {
  font-size: 18px;
}
.detail-block {
  color: #4b4b4b;
  padding: 1rem;
  display: flex;
  // border-bottom: 1px solid#BCBABA;
}
.detail-block:not(:last-child) {
  border-bottom: 1px solid #bcbaba;
}
.icon-calendar {
  grid-row: 1 / span 2;
  align-self: center;
}
.icon-calendar img {
  width: 36px;
  fill: linear-gradient(180deg, #007afe 0%, #013f81 100%);
}

.card-form {
  margin: auto;
  width: 80%;
  padding-bottom: 4rem;
}
.card-form {
  cursor: pointer;
  display: grid;
  width: 80%;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 30px;
    height: 30px;
  }
  img.img-success {
    width: 15px;
    height: 15px;
  }
  h1 {
    font: normal normal normal 20px/18px Mitr;
  }
  &-custom {
    padding: 2rem 1.2rem 2rem 1.2rem;

    h1 {
      font: normal normal normal 27px/18px Mitr;
    }
  }
}
.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 0px;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
@media only screen and (min-width: 320px) {
  button {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 2560px) {
  .detail-block {
    color: #4b4b4b;
    padding: 1rem;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    // border-bottom: 1px solid#BCBABA;
  }
  .detail-block:not(:last-child) {
    border-bottom: 1px solid #bcbaba;
  }
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}
.inRemind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #d2e2f8;
  padding-bottom: 5px;
}

#inReminder {
  font-size: 18px;
  margin: auto;
  color: #434343;
}

.text-failed {
  color: #ff0000;
}

.image-container-button {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.disabled {
  filter: grayscale(100%) opacity(50%);
}
</style>
