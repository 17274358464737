<template>
  <div v-if="permissionStatus.permission.id_role === 2">
    <p
      v-if="permissionStatus.package.status === 'exp' || days_left.day <= 0"
      class="expired-bar"
    >
      {{ $t("selectcompany.show_expired") }}
    </p>
    <p v-else-if="days_left.day <= 60" class="alert-bar">
      {{ $t("selectcompany.alert_expired", days_left) }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {};
  },

  mounted() {},
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
    days_left() {
      return {
        day: moment(this.permissionStatus.package.exp_date).diff(
          new Date(),
          "d"
        ),
        expireDate: moment(this.permissionStatus.package.exp_date).format(
          "DD/MM/YYYY"
        ),
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.alert-bar {
  background-color: #fffcde;
  padding: 0.35rem;
}

.expired-bar {
  background-color: #ffc5c5;
  padding: 0.35rem;
}
</style>
