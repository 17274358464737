<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <!-- <Background></Background> -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-if="!loadingAsset"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content container">
          <div class="row">
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-html="
                $t('dialog.conf_report_lost', {
                  assetId: getDetailAsset.asset.id_fixed_asset,
                })
              "
            ></h5>
          </div>
          <div class="row">
            <div class="input-group">
              <label>{{ $t("asset.note") }}</label>
              <textarea class="comment-textarea" v-model="note_lost"></textarea>
            </div>
          </div>
          <div class="row justify-content-center">
            <button
              type="button"
              class="btn btn-secondary col-6"
              data-dismiss="modal"
              @click="note_lost = null"
            >
              {{ $t("btn.canc") }}
            </button>
            <button
              type="button"
              class="btn btn-primary col-6"
              data-dismiss="modal"
              @click="alertLost()"
              :disabled="!note_lost"
            >
              {{ $t("btn.re_lost") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      class="p-2 modal-content"
      id="report-found-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <div class="row" v-if="!loadingAsset">
        <h5
          class="modal-title text-center"
          v-html="
            $t('detailasset.re_found_asset', {
              assetId: getDetailAsset.asset.id_fixed_asset,
            })
          "
        ></h5>
      </div>
      <div class="row">
        <div class="input-group">
          <label>{{ $t("asset.note") }}</label>
          <textarea class="comment-textarea" v-model="note_lost"></textarea>
        </div>
      </div>
      <div class="row justify-content-center">
        <button
          type="button"
          class="btn btn-secondary col-6"
          data-dismiss="modal"
          @click="
            note_lost = '';
            $bvModal.hide('report-found-modal');
          "
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          type="button"
          class="btn btn-primary col-6"
          data-dismiss="modal"
          @click="reportFound"
          :disabled="!note_lost"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <label>{{ $t("asset.as_loc") }}</label>
          <img class="indoor-map-modal" src="../assets/mark-plan.png" />
          <button
            type="button"
            class="btn btn-indoor-modal"
            data-dismiss="modal"
          >
            {{ $t("btn.conf") }}
          </button>
        </div>
      </div>
    </div>

    <div class="DetailAsset">
      <div class="w-100">
        <div class="back-icon">
          <img
            src="../assets/previous.svg"
            width="30"
            @click="gotoAllasset()"
          />
        </div>
        <h1 class="header-text">
          <div class="topic" style="padding-top: 0px !important">
            {{ $t("asset.as_data") }}
          </div>
        </h1>
        <template v-if="!loadingAsset">
          <!-- Custom VTN -->
          <div v-if="isCustom">
            <customCheckItemHeader
              :customid="permissionStatus.company.custom_id"
              :department="
                getDetailAsset.asset.location_departments
                  ? getDetailAsset.asset.location_departments.name
                  : null
              "
              :other1="
                getDetailAsset.asset.asset_details[0]
                  ? getDetailAsset.asset.asset_details[0].value
                  : null
              "
            ></customCheckItemHeader>
          </div>

          <div class="card-form-login">
            <div class="d-flex justify-content-between align-items-center">
              <div style="width:25px; cursor: pointer;">
                <img
                  class="w-100"
                  src="../assets/asset_link.png"
                  style="object-fit:contain;"
                  alt=""
                  @click="gotoLinkAsset"
                  v-if="
                    !isRoleLower &&
                      canUseCustom3 &&
                      (getDetailAsset.asset.sub_assets.length > 0 ||
                        getDetailAsset.asset.parent_id_asset)
                  "
                />
              </div>
              <b-dropdown
                class="mt-3 mb-4"
                id="edit-menu"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
                v-if="
                  (getDetailAsset.asset.qrcode || canUseCustom3) && !isRoleLower
                "
              >
                <template #button-content>
                  <!-- <img src="../assets/edit-gear.svg" alt="" /> -->
                  <svg
                    class="dot-style"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5385 13.5C23.0587 13.5 23.5576 13.7107 23.9255 14.0858C24.2933 14.4609 24.5 14.9696 24.5 15.5C24.5 16.0304 24.2933 16.5391 23.9255 16.9142C23.5576 17.2893 23.0587 17.5 22.5385 17.5C22.0182 17.5 21.5193 17.2893 21.1514 16.9142C20.7836 16.5391 20.5769 16.0304 20.5769 15.5C20.5769 14.9696 20.7836 14.4609 21.1514 14.0858C21.5193 13.7107 22.0182 13.5 22.5385 13.5ZM16 13.5C16.5202 13.5 17.0192 13.7107 17.387 14.0858C17.7549 14.4609 17.9615 14.9696 17.9615 15.5C17.9615 16.0304 17.7549 16.5391 17.387 16.9142C17.0192 17.2893 16.5202 17.5 16 17.5C15.4798 17.5 14.9808 17.2893 14.613 16.9142C14.2451 16.5391 14.0385 16.0304 14.0385 15.5C14.0385 14.9696 14.2451 14.4609 14.613 14.0858C14.9808 13.7107 15.4798 13.5 16 13.5ZM9.46154 13.5C9.98177 13.5 10.4807 13.7107 10.8486 14.0858C11.2164 14.4609 11.4231 14.9696 11.4231 15.5C11.4231 16.0304 11.2164 16.5391 10.8486 16.9142C10.4807 17.2893 9.98177 17.5 9.46154 17.5C8.94131 17.5 8.44238 17.2893 8.07452 16.9142C7.70666 16.5391 7.5 16.0304 7.5 15.5C7.5 14.9696 7.70666 14.4609 8.07452 14.0858C8.44238 13.7107 8.94131 13.5 9.46154 13.5Z"
                      fill="#007AFE"
                    />
                    <rect
                      x="2.5"
                      y="4.5"
                      width="27"
                      height="22"
                      rx="3.5"
                      stroke="#007AFE"
                    />
                  </svg>
                </template>
                <b-dropdown-item
                  @click="gotoEditAsset"
                  v-if="getDetailAsset.asset.qrcode"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/edit_pencil.svg"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">{{ $t("asset.edit_btn") }}</p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="gotoAssetLog"
                  v-if="getDetailAsset.asset.qrcode"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/history.svg"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">{{ $t("asset.log_btn") }}</p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeQR"
                  v-if="getDetailAsset.asset.qrcode && !canUseCustom8"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/qrcode.svg"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">{{ $t("asset.change_QR") }}</p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="toShowMaintenanceReport"
                  v-if="canUseMaintenanceReport && getDetailAsset.asset.asset_status !== 'broke' && getDetailAsset.asset.asset_status !== 'maintain'"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/gear.svg"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">
                      {{ $t("asset.maintenance_report") }}
                    </p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="gotoLinkAsset" v-if="canUseCustom3"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/asset_link.png"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">{{ $t("subasset.asset_order") }}</p>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                class="mt-3 mb-4"
                id="edit-menu"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
                v-if="
                  getDetailAsset.asset.qrcode &&
                    isRoleLower &&
                    canUseMaintenanceReport
                "
              >
                <template #button-content>
                  <!-- <img src="../assets/edit-gear.svg" alt="" /> -->
                  <svg
                    class="dot-style"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5385 13.5C23.0587 13.5 23.5576 13.7107 23.9255 14.0858C24.2933 14.4609 24.5 14.9696 24.5 15.5C24.5 16.0304 24.2933 16.5391 23.9255 16.9142C23.5576 17.2893 23.0587 17.5 22.5385 17.5C22.0182 17.5 21.5193 17.2893 21.1514 16.9142C20.7836 16.5391 20.5769 16.0304 20.5769 15.5C20.5769 14.9696 20.7836 14.4609 21.1514 14.0858C21.5193 13.7107 22.0182 13.5 22.5385 13.5ZM16 13.5C16.5202 13.5 17.0192 13.7107 17.387 14.0858C17.7549 14.4609 17.9615 14.9696 17.9615 15.5C17.9615 16.0304 17.7549 16.5391 17.387 16.9142C17.0192 17.2893 16.5202 17.5 16 17.5C15.4798 17.5 14.9808 17.2893 14.613 16.9142C14.2451 16.5391 14.0385 16.0304 14.0385 15.5C14.0385 14.9696 14.2451 14.4609 14.613 14.0858C14.9808 13.7107 15.4798 13.5 16 13.5ZM9.46154 13.5C9.98177 13.5 10.4807 13.7107 10.8486 14.0858C11.2164 14.4609 11.4231 14.9696 11.4231 15.5C11.4231 16.0304 11.2164 16.5391 10.8486 16.9142C10.4807 17.2893 9.98177 17.5 9.46154 17.5C8.94131 17.5 8.44238 17.2893 8.07452 16.9142C7.70666 16.5391 7.5 16.0304 7.5 15.5C7.5 14.9696 7.70666 14.4609 8.07452 14.0858C8.44238 13.7107 8.94131 13.5 9.46154 13.5Z"
                      fill="#007AFE"
                    />
                    <rect
                      x="2.5"
                      y="4.5"
                      width="27"
                      height="22"
                      rx="3.5"
                      stroke="#007AFE"
                    />
                  </svg>
                </template>
                <b-dropdown-item
                  @click="toShowMaintenanceReport"
                  v-if="canUseMaintenanceReport"
                  ><div class="d-flex">
                    <div class="me-2" style="width:20px; cursor: pointer;">
                      <img
                        class="w-100"
                        src="../assets/gear.svg"
                        style="object-fit:contain;"
                        alt=""
                      />
                    </div>
                    <p class="menu-item">
                      {{ $t("asset.maintenance_report") }}
                    </p>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-container fluid>
              <div class="row justify-content-evenly" v-if="first_img.url">
                <div class="asset-imgcard col-6">
                  <ImagePreview
                    class="card-img-top"
                    :src="first_img.url"
                    height="100%"
                    width="100%"
                    imageStyle="object-fit: cover; border: 1px solid transparent; border-radius: 10px;"
                    alt="Image"
                    preview
                  />
                  <p>
                    {{ $t("detailasset.img_reg") }} <br />{{ $t("count.date") }}
                    {{ img_date.regis_date }}
                  </p>
                </div>
                <div class="asset-imgcard col-6 border5 bg-grey-light">
                  <ImagePreview
                    v-if="current_img.url"
                    class="card-img-top"
                    :src="current_img.url"
                    height="100%"
                    width="100%"
                    imageStyle="object-fit: cover; border: 1px solid black; border-radius: 10px;"
                    alt="Image"
                    preview
                  />
                  <ImagePreview
                    v-else
                    class="card-img-top"
                    :src="first_img.url"
                    height="100%"
                    width="100%"
                    imageStyle="object-fit: cover; border: 1px solid black; border-radius: 10px;"
                    alt="Image"
                    preview
                  />
                  <p>
                    {{ $t("detailasset.img_new") }}<br />{{ $t("count.date") }}
                    {{ img_date.now_date }}
                  </p>
                </div>
              </div>
              <div class="grey-bg p-5" v-else>
                <h1>{{ $t("detailasset.img_not") }}</h1>
              </div>
            </b-container>
            <div
              class="d-flex justify-content-center"
              style="
              cursor: pointer;
              text-decoration: underline;
              margin-top: 20px;
            "
              @click="gotoSeeAllImage"
            >
              <u class="text-primary" v-if="first_img.url">{{
                $t("detailasset.view_img")
              }}</u>
            </div>
            <div
              v-if="!canUseCustom8"
              class="input-group d-flex justify-content-center p-0 mt-2"
            >
              <label>{{ $t("asset.qr_id") }} :</label>
              <p
                class="sub-label"
                style="padding-left: 10px; margin-bottom: 0px"
                v-if="getDetailAsset.asset.qrcode"
              >
                {{ getDetailAsset.asset.qrcode.id_qrcode }}
              </p>
              <p
                v-else
                class="sub-label"
                style="padding-left: 10px; margin-bottom: 0px"
              >
                {{ $t("detailasset.not_reg") }}
              </p>
            </div>
            <div
              class="input-group"
              style="display: flex; justify-content: center; padding: 0px"
            >
              <label>{{ $t("asset.as_num") }} :</label>
              <p class="sub-label" style="padding-left: 10px">
                {{ getDetailAsset.asset.id_fixed_asset }}
              </p>
            </div>

            <!-- Custom VTN -->
            <div v-if="isCustom">
              <customIDDetailOneAsset
                :customid="permissionStatus.company.custom_id"
              ></customIDDetailOneAsset>
            </div>

            <div v-if="!getDetailAsset.asset.qrcode" class="p-3">
              <b-button
                variant="primary"
                style="width: 30%; height: 100%"
                @click="registerNow"
                >{{ $t("registeritem.as_reg") }}</b-button
              >
            </div>
            <div class="asset-all-info">
              <div class="info-box">
                <div
                  class="d-flex justify-content-between align-items-center"
                  @click="showData.asset = !showData.asset"
                  style="cursor: pointer;"
                >
                  <p class="info-header">
                    {{ $t("asset.as_data") }}
                  </p>
                  <img
                    v-if="showData.asset"
                    width="16"
                    height="16"
                    src="../image/shrink.png"
                    alt=""
                  />
                  <img
                    v-else
                    width="16"
                    height="16"
                    src="../image/expand.png"
                    alt=""
                  />
                </div>
                <ExpandHeight>
                  <div class="text-start" v-if="showData.asset">
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.as_name") }}
                      </p>
                      <p class="info-text">
                        {{ getDetailAsset.asset.name }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.as_cat") }}
                      </p>
                      <p class="info-text">
                        {{
                          isNull(
                            getDetailAsset.asset.categories &&
                              getDetailAsset.asset.categories.name
                          )
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.as_sub_cat") }}
                      </p>
                      <p class="info-text">
                        {{
                          isNull(
                            getDetailAsset.asset.sub_category &&
                              getDetailAsset.asset.sub_category.name
                          )
                        }}
                      </p>
                    </div>
                    <div class="my-3" v-if="canUseCustom4">
                      <p class="info-label">
                        {{ $t("model.model") }}
                      </p>
                      <p class="info-text">
                        {{
                          isNull(
                            getDetailAsset.asset.sub_category2 &&
                              getDetailAsset.asset.sub_category2.name
                          )
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.as_dt") }}
                      </p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.asset.description) }}
                      </p>
                    </div>
                    <div class="my-3" v-if="canUseCustom8">
                      <p class="info-label">
                        {{ $t("asset.more_description") }}
                      </p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.asset.description2) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <div class="d-flex">
                        <p class="info-label">{{ $t("asset.asset_note") }}</p>
                        <div
                          v-if="permissionStatus.permission.id_role === 3"
                          class="ms-2"
                          style="cursor: pointer;"
                          @click="toggleEditNote"
                        >
                          <img src="../assets/edit_pencil.svg" alt="" />
                        </div>
                      </div>
                      <p class="info-text">
                        <span> {{ isNull(getDetailAsset.asset.note) }} </span>
                      </p>
                      <ExpandHeight>
                        <div v-if="showEditNote">
                          <b-textarea
                            class="w-100"
                            style="border-radius: 10px"
                            rows="4"
                            v-model="newNote"
                          ></b-textarea>
                          <div class="ms-auto mt-2">
                            <button
                              class="btn-disabled"
                              @click="toggleEditNote"
                            >
                              {{ $t("btn.canc") }}
                            </button>
                            <button
                              class="btn-confirm ms-3"
                              @click="updateNote"
                            >
                              {{ $t("btn.conf") }}
                            </button>
                          </div>
                        </div>
                      </ExpandHeight>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.as_dep") }}
                      </p>
                      <p class="info-text">
                        {{ $t("asset.build") }}
                        {{
                          isNull(
                            getDetailAsset.asset.location_buildings &&
                              getDetailAsset.asset.location_buildings.name
                          )
                        }}
                        {{ $t("asset.fl") }}
                        {{
                          isNull(
                            getDetailAsset.asset.location_floors &&
                              getDetailAsset.asset.location_floors.name
                          )
                        }}
                        <span v-if="canUseCustom8">
                          {{ $t("asset.room") }}
                          {{
                            isNull(
                              getDetailAsset.asset.location_rooms &&
                                getDetailAsset.asset.location_rooms.name
                            )
                          }}
                        </span>
                        <br />
                        {{ $t("asset.dep") }}
                        {{
                          isNull(
                            getDetailAsset.asset.location_departments &&
                              `${getDetailAsset.asset.location_departments.code} - ${getDetailAsset.asset.location_departments.name}`
                          )
                        }}
                        {{ $t("asset.zone") }}
                        {{
                          isNull(
                            getDetailAsset.asset.location_zones &&
                              getDetailAsset.asset.location_zones.name
                          )
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <image-map-display v-if="$store.getters.getMapUrl" />
                      <div class="indoor-map-none" v-else>
                        <p>{{ $t("registeritem.selectbuild_fl") }}</p>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.as_reg") }}</p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.asset.registrant) }}
                      </p>
                    </div>
                    <div class="my-3" v-if="canUseCustom3">
                      <p class="info-label">{{ $t("asset.intendant") }}</p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.asset.intendant) }}
                      </p>
                    </div>
                  </div>
                </ExpandHeight>
              </div>
              <div class="info-box">
                <div
                  class="d-flex justify-content-between align-items-center"
                  @click="showData.counting = !showData.counting"
                  style="cursor: pointer;"
                >
                  <p class="info-header">
                    {{ $t("asset.process_detail") }}
                  </p>
                  <img
                    v-if="showData.counting"
                    width="16"
                    height="16"
                    src="../image/shrink.png"
                    alt=""
                  />
                  <img
                    v-else
                    width="16"
                    height="16"
                    src="../image/expand.png"
                    alt=""
                  />
                </div>
                <ExpandHeight>
                  <div class="text-start" v-if="showData.counting">
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.as_stat") }}</p>
                      <p
                        class="info-text require-red"
                        style="border: 0px"
                        v-if="getDetailAsset.asset.is_lost === 'lost'"
                      >
                        {{ $t("count.as_lost") }}
                      </p>
                      <p
                        class="info-text require-red"
                        style="border: 0px"
                        v-else-if="!getDetailAsset.asset.qrcode"
                      >
                        {{ $t("count.not_reg") }}
                      </p>
                      <p
                        class="info-text require-red"
                        style="border: 0px"
                        v-else-if="getDetailAsset.asset.asset_status == null"
                      >
                        {{ $t("count.not_count") }}
                      </p>
                      <p
                        v-else
                        class="sub-label status_label"
                        :class="{
                          'require-red':
                            getDetailAsset.asset.asset_status === 'broke' ||
                            getDetailAsset.asset.asset_status === 'maintain' ||
                            getDetailAsset.asset.asset_status ===
                              'cannot_repair',
                        }"
                      >
                        {{
                          assetStatusTranslate(
                            getDetailAsset.asset.asset_status
                          )
                        }}
                      </p>
                      <div
                        v-if="
                          getDetailAsset.asset.asset_status !== 'broke' &&
                            getDetailAsset.asset.asset_status !== 'maintain' &&
                            getDetailAsset.asset.qrcode
                        "
                      >
                        <p
                          class="text-end"
                          v-if="getDetailAsset.asset.is_lost === 'not lost'"
                        >
                          <u
                            style="cursor: pointer; color: #ED1616"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            >{{ $t("detailasset.re_lost") }}</u
                          >
                        </p>
                        <div
                          v-else-if="getDetailAsset.asset.is_lost === 'report'"
                          class="d-flex justify-content-between"
                        >
                          <p class="sub-label" style="color: #ca9100;">
                            {{ $t("detailasset.wait_conflost") }}
                          </p>
                          <u
                            style="cursor: pointer; color: #7c7c7c"
                            @click="cancelReportLost"
                            >{{ $t("detailasset.canc_re") }}</u
                          >
                        </div>
                        <div
                          v-else-if="
                            getDetailAsset.asset.is_lost === 'report_found'
                          "
                          class="d-flex justify-content-between"
                        >
                          <p class="sub-label" style="color: #ca9100;">
                            {{ $t("detailasset.wait_conffound") }}
                          </p>
                          <u
                            style="cursor: pointer; color: #7c7c7c"
                            @click="cancelReportFound"
                            >{{ $t("detailasset.canc_re_found") }}</u
                          >
                        </div>
                        <p class="text-end" v-else>
                          <u
                            style="cursor: pointer; color: #0169DA"
                            @click="$bvModal.show('report-found-modal')"
                            >{{ $t("detailasset.found_re") }}</u
                          >
                        </p>
                      </div>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.verification_note") }}
                      </p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.counting_item.note_counting) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.as_count") }}</p>
                      <p
                        v-if="!getDetailAsset.counting_item.asset_status"
                        class="info-text require-red"
                      >
                        {{ $t("asset.not_count") }}
                      </p>
                      <p v-else class="info-text">
                        {{ isNull(getDetailAsset.counting_item.inspector) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.countdate") }}</p>
                      <p
                        v-if="
                          getDetailAsset.counting_status ===
                            'ยังไม่ได้ตรวจนับ' ||
                            !getDetailAsset.counting_status ||
                            !getDetailAsset.counting_item.asset_status
                        "
                        class="info-text require-red"
                      >
                        {{ $t("asset.not_count") }}
                      </p>
                      <p v-else class="info-text">
                        {{
                          convertDate(
                            getDetailAsset.counting_item.counting_time
                          )
                        }}
                        {{ $t("asset.time") }}
                        {{
                          convertTime(
                            getDetailAsset.counting_item.counting_time
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </ExpandHeight>
              </div>
              <div class="info-box" v-if="!isRoleLower && canUseDepreciation">
                <div
                  class="d-flex justify-content-between align-items-center"
                  @click="showData.accounting = !showData.accounting"
                  style="cursor: pointer;"
                >
                  <p class="info-header">
                    {{ $t("asset.acc_data") }}
                  </p>
                  <img
                    v-if="showData.accounting"
                    width="16"
                    height="16"
                    src="../image/shrink.png"
                    alt=""
                  />
                  <img
                    v-else
                    width="16"
                    height="16"
                    src="../image/expand.png"
                    alt=""
                  />
                </div>
                <ExpandHeight>
                  <div class="text-start" v-if="showData.accounting">
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("depreciation.acc_status") }}
                      </p>
                      <p class="info-text">
                        {{
                          dpStatusTranslate(
                            getDetailAsset.asset.depreciation_status
                          )
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.acc_note") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.note_accounting
                            ? isNull(getDetailAsset.asset.note_accounting.name)
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.asset_acc_code") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .code_asset
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.asset_acc_name") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .name_asset
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.acc_code") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .code_acc
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.dep_acc_name") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .name_acc
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.accumulate_dep_acc_code") }}
                      </p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .code_acc_dp
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.accumulate_dep_acc_name") }}
                      </p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.sub_note_accounting
                            ? isNull(
                                getDetailAsset.asset.sub_note_accounting
                                  .name_acc_dp
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.start_date") }}</p>
                      <p class="info-text">
                        {{ momentDay(getDetailAsset.asset.start_date) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.purchase_date") }}</p>
                      <p class="info-text">
                        {{ momentDay(getDetailAsset.asset.purchase_date) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.purchase_price") }}</p>
                      <p class="info-text">
                        {{
                          toShowAccountingPrice(
                            getDetailAsset.asset.purchase_price
                          ) || "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.useful_life") }}</p>
                      <p class="info-text">
                        {{ isNull(getDetailAsset.asset.useful_life) }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.scrap_value") }}</p>
                      <p class="info-text">
                        {{
                          toShowAccountingPrice(
                            getDetailAsset.asset.scrap_value
                          ) || "-"
                        }}
                      </p>
                    </div>

                    <div class="my-3">
                      <p class="info-label">
                        {{ $t("asset.depreciation_today") }}
                      </p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.write_off_date ? "-" : toShowAccountingPrice(
                            getDetailAsset.asset.acc_depreciation
                          ) || "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.net_book_today") }}</p>
                      <p class="info-text">
                        {{
                          getDetailAsset.asset.write_off_date ? "-" : toShowAccountingPrice(
                            getDetailAsset.asset.net_book
                          ) || "-"
                        }}
                      </p>
                    </div>

                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.dep_date") }}</p>
                      <p class="info-text">
                        {{ momentDay(getDetailAsset.asset.depreciation_date) }}
                      </p>
                    </div>
                    <div class="my-3" v-if="canUseCustom9">
                      <p class="info-label">{{$t("asset.sum_depreciation_date")}}</p>
                      <p class="info-text">
                        {{
                          momentDay(getDetailAsset.asset.sum_depreciation_date)
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.prior_dep") }}</p>
                      <p class="info-text">
                        {{
                          toShowAccountingPrice(
                            getDetailAsset.asset.prior_depreciation
                          ) || "-"
                        }}
                      </p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.prior_nb") }}</p>
                      <p class="info-text">
                        {{
                          toShowAccountingPrice(
                            getDetailAsset.asset.prior_net_book
                          ) || "-"
                        }}
                      </p>
                    </div>

                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.pause_date") }}</p>
                      <p
                        v-if="getDetailAsset.asset.pause_assets.length > 0"
                        class="info-text"
                      >
                        {{
                          momentDay(
                            getDetailAsset.asset.pause_assets[0].deactivate_at
                          )
                        }}
                      </p>
                      <p v-else>-</p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.active_date") }}</p>
                      <p
                        v-if="getDetailAsset.asset.pause_assets.length > 0"
                        class="info-text"
                      >
                        {{
                          momentDay(
                            getDetailAsset.asset.pause_assets[0].activate_at
                          )
                        }}
                      </p>
                      <p v-else>-</p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.writeoff_date") }}</p>
                      <p class="info-text">{{ getDetailAsset.asset.write_off_date ? momentDay(getDetailAsset.asset.write_off_date) : '-' }}</p>
                      <!-- <p v-if="getDetailAsset.asset.write_offs.length > 0">
                        {{
                          getDetailAsset.asset.write_offs[0]
                            .approve_write_off_at
                            ? momentDay(
                                getDetailAsset.asset.write_offs[0]
                                  .write_off_date
                              )
                            : "-"
                        }}
                      </p>
                      <p v-else>-</p> -->
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.acc_dp_at_writeoff") }}</p>
                      <p class="info-text">{{ getDetailAsset.asset.write_off_acc_depreciation ? toShowAccountingPrice(getDetailAsset.asset.write_off_acc_depreciation) : '-' }}</p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.net_writeoff_price") }}</p>
                      <p class="info-text">{{ getDetailAsset.asset.write_off_net_book ? toShowAccountingPrice(getDetailAsset.asset.write_off_net_book) : '-' }}</p>
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.writeoff_price") }}</p>
                      <p class="info-text">{{ getDetailAsset.asset.sold_price ? toShowAccountingPrice(getDetailAsset.asset.sold_price) : '-' }}</p>
                      <!-- <p v-if="getDetailAsset.asset.write_offs.length > 0">
                        {{
                          getDetailAsset.asset.write_offs[0]
                            .approve_write_off_at
                            ? toShowAccountingPrice(
                                getDetailAsset.asset.write_offs[0]
                                  .write_off_amount
                              ) || "-"
                            : "-"
                        }}
                      </p>
                      <p v-else>-</p> -->
                    </div>
                    <div class="my-3">
                      <p class="info-label">{{ $t("asset.profit_loss") }}</p>
                      <p class="info-text">{{ getDetailAsset.asset.profit_loss ? toShowAccountingPrice(getDetailAsset.asset.profit_loss) : '-' }}</p>
                      <!-- <p v-if="getDetailAsset.asset.write_offs.length > 0">
                        {{
                          getDetailAsset.asset.write_offs[0]
                            .approve_write_off_at
                            ? toShowAccountingPrice(
                                getDetailAsset.asset.write_offs[0].profit_loss
                              ) || "-"
                            : "-"
                        }}
                      </p>
                      <p v-else>-</p> -->
                    </div>
                  </div>
                </ExpandHeight>
              </div>
              <div class="info-box">
                <div
                  class="d-flex justify-content-between align-items-center"
                  @click="showData.other = !showData.other"
                  style="cursor: pointer;"
                >
                  <p class="info-header">
                    {{ $t("asset.more_info") }}
                  </p>
                  <img
                    v-if="showData.other"
                    width="16"
                    height="16"
                    src="../image/shrink.png"
                    alt=""
                  />
                  <img
                    v-else
                    width="16"
                    height="16"
                    src="../image/expand.png"
                    alt=""
                  />
                </div>
                <ExpandHeight>
                  <div class="text-start" v-if="showData.other">
                    <customAssetInfo
                      v-if="isCustom"
                      :customid="permissionStatus.company.custom_id"
                      :assetInfo="getDetailAsset.asset"
                    />
                    <div
                      class="my-3"
                      v-for="(item, index) in getDetailAsset.asset
                        .asset_details"
                      :key="index"
                    >
                      <p class="info-label">{{ item.name }}</p>
                      <p class="info-text">
                        {{ isNull(item.value) }}
                      </p>
                    </div>
                  </div>
                </ExpandHeight>
              </div>
            </div>
            <customMaintenanceHistory
              v-if="isCustom"
              :customid="permissionStatus.company.custom_id"
            />
          </div>
        </template>
        <div class="line-break"></div>
      </div>
    </div>
    <MaintenanceReport
      ref="maintenanceReport"
      :assetData="getDetailAsset.asset"
      @close-report-data="showMaintenanceReport = false"
    ></MaintenanceReport>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import axios from "axios";
import { baseUrl } from "../util/backend";
import { authHeader } from "../store/actions";
import { mapGetters } from "vuex";
import Lazyloadimage from "../components/Lazyloadimage.vue";
import ImageMapDisplay from "@/components/ImageMapDisplay.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import Swal from "sweetalert2";
import moment from "moment";
// import SuccessDialog from "@/components/SuccessDialog.vue";
import customIDDetailOneAsset from "@/components/CustomComponent/customIDDetailOneAsset.vue";
import customCheckItemHeader from "@/components/CustomComponent/customCheckItemHeader.vue";
import customMaintenanceHistory from "@/components/CustomComponent/customMaintenanceHistory.vue";
import customAssetInfo from "../components/CustomComponent/customAssetInfo.vue";

import ExpandHeight from "../components/Animation/expandHeight.vue";
import { toShowAccountingPrice } from "../util/accountingPrice";
import { featureFlags } from "../native-app-feature-flag";
import MaintenanceReport from "../components/Maintenance/MaintenanceReport.vue";

export default {
  data() {
    return {
      showData: {
        asset: true,
        counting: false,
        accounting: false,
        other: false,
      },
      isLoading: true,
      loadingAsset: true,
      current_img: "",
      first_img: "",
      img_date: {
        regis_date: "",
        now_date: "",
      },
      qrcode_id: "",
      showSucces: true,
      prevRoute: null,
      note_lost: "",
      showEditNote: false,
      newNote: "",
    };
  },
  components: {
    Footer,
    // SuccessDialog,
    ImageMapDisplay,
    customIDDetailOneAsset,
    customCheckItemHeader,
    customMaintenanceHistory,
    customAssetInfo,
    Loading,
    Lazyloadimage,
    ExpandHeight,
    MaintenanceReport,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      getDetailAsset: "getDetailAsset",
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
      getMapPosition: "getMapPosition",
      isRoleLower: "isRoleLower",
      canUseDepreciation: "canUseDepreciation",
      canUseMaintenanceReport: "canUseMaintenanceReport",
      canUseCustom3: "canUseCustom3",
      canUseCustom4: "canUseCustom4",
      canUseCustom8: "canUseCustom8",
      canUseCustom9: "canUseCustom9",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    toShowAccountingPrice,
    dpStatusTranslate(status) {
      switch (status) {
        case "active":
          return "Active";
        case "expire":
          return "Fully Depreciated";
        case "deactivate":
          return "Deactivate";
        case "write off":
          return "Written off / Disposed";
        default:
          return "-";
      }
    },
    toggleEditNote() {
      this.showEditNote = !this.showEditNote;
    },
    async updateNote() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.post(
          `${baseUrl()}asset/updateasset`,
          {
            id_asset: +this.$route.params.id,
            note: this.newNote,
            id_company: id_company,
          },
          authHeader()
        );
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
      this.showEditNote = false;
      this.loadData();
    },
    assetStatusTranslate(text) {
      switch (text) {
        case "available":
          return this.$t("count.inuse");
        case "unavailable":
          return this.$t("count.not_inuse");
        case "broke":
          return this.$t("count.defect");
        case "maintain":
          return this.$t("count.repairing");
        case "cannot_repair":
          return this.$t("count.irrepar");
        default:
          return "";
      }
    },
    parseFloatShow(float) {
      if (!float || float === "-" || float === null) {
        return "-";
      }
      return parseFloat(float)
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull(data) {
      return data ? data : "-";
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : "-";
    },
    async registerNow() {
      this.isLoading = true;
      await this.$store.dispatch("getDetailAssetsRegisterNow", {
        asset_id: this.id_asset,
        id_qrcode: this.$route.params.id_qrcode,
      });
      this.isLoading = false;
    },
    async changeQR() {
      if (this.isRoleLower) return;
      const result = await Swal.fire({
        title: this.$t("asset.change_QR"),
        html: this.$t("asset.conf_changeQR"),
        showCancelButton: true,
        confirmButtonText: this.$t("btn.conf"),
        cancelButtonText: this.$t("btn.canc"),
        buttonsStyling: false,
        customClass: {
          cancelButton: "order-1 swal-cancel-btn",
          confirmButton: "order-2 swal-confirm-btn",
        },
      });
      if (result.isConfirmed) {
        if (featureFlags.scan) {
          window.sendMessage("scan", {
            mode: "changeQR",
            qr: this.getDetailAsset.asset.qrcode.id_qrcode,
            id: this.id_asset,
          });
        } else {
          this.$router.push({
            path: "/scan/changeQR",
            query: {
              qr: this.getDetailAsset.asset.qrcode.id_qrcode,
              id: this.id_asset,
            },
          });
        }
      }
    },
    gotoSeeAllImage() {
      this.$router.push(`/all_image/${this.id_asset}`);
    },
    gotoEditAsset() {
      this.$router.push(`/editDetailAsset/${this.id_asset}`);
    },
    gotoAssetLog() {
      this.$router.push(`/assetlog/${this.id_asset}`);
    },
    gotoLinkAsset() {
      this.$router.push({
        name: "EditSubAsset",
        params: { assetId: this.id_asset },
      });
    },
    gotoAllasset() {
      this.$router.back();
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    convertTime(time) {
      return moment(time).format("HH:mm");
    },
    toShowMaintenanceReport() {
      if (
        this.getDetailAsset.asset.asset_status == "broke" ||
        this.getDetailAsset.asset.asset_status == "maintain"
      ) {
        Swal.fire({
          icon: "error",
          title: this.$t("dialog.asset_repairing"),
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        this.$refs.maintenanceReport.openReportData();
      }
    },
    async cancelReportLost() {
      const result = await Swal.fire({
        text: this.$t("detailasset.found_asset", {
          assetId: this.getDetailAsset.asset.id_fixed_asset,
        }),
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
        confirmButtonText: this.$t("btn.conf"),
        cancelButtonText: this.$t("btn.canc"),
        customClass: {
          cancelButton: "swal-cancel-btn",
          confirmButton: "swal-confirm-btn",
        },
      });
      if (result.isConfirmed) {
        this.isLoading = true;
        let asset = {
          id_asset: this.id_asset,
          is_lost: "not lost",
        };
        await this.$store.dispatch("ChangeLostStatus", asset);
        await this.$store.dispatch("getDetailAssets", this.id_asset);
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.isLoading = false;
      }
    },

    async reportFound() {
      this.isLoading = true;
      let asset = {
        id_asset: this.id_asset,
        is_lost: "report_found",
        note_lost: this.note_lost,
      };
      await this.$store.dispatch("ChangeLostStatus", asset);
      await this.$store.dispatch("getDetailAssets", this.id_asset);
      Swal.fire({
        icon: "success",
        title: this.$t("dialog.success"),
        showConfirmButton: false,
        timer: 3000,
      });
      this.$bvModal.hide("report-found-modal");
      this.isLoading = false;
    },

    async cancelReportFound() {
      const result = await Swal.fire({
        text: this.$t("detailasset.canc_found", {
          assetId: this.getDetailAsset.asset.id_fixed_asset,
        }),
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
        confirmButtonText: this.$t("btn.conf"),
        cancelButtonText: this.$t("btn.canc"),
        customClass: {
          cancelButton: "swal-cancel-btn",
          confirmButton: "swal-confirm-btn",
        },
      });
      if (result.isConfirmed) {
        this.isLoading = true;
        let asset = {
          id_asset: this.id_asset,
          is_lost: "lost",
        };
        await this.$store.dispatch("ChangeLostStatus", asset);
        await this.$store.dispatch("getDetailAssets", this.id_asset);
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.isLoading = false;
      }
    },

    async alertLost() {
      const lost = {
        id_asset: this.id_asset,
        is_lost: "report",
        note_lost: this.note_lost,
      };
      try {
        this.isLoading = true;
        await this.$store.dispatch("ChangeLostStatus", lost);
        await this.$store.dispatch("getDetailAssets", this.id_asset);
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          text: this.$t("dialog.success_report_lost"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },

    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 2500);
    },
    async loadData() {
      this.id_asset = this.$route.params.id;
      localStorage.setItem("id_asset", this.id_asset);
      this.loadingAsset = true;
      await this.$store.dispatch("getDetailAssets", this.id_asset);
      // await this.$store.dispatch("getAllCountingDateByIdCompany");

      this.loadingAsset = false;
      this.newNote = this.getDetailAsset.asset.note;
      this.hide();
      if (this.getDetailAsset.asset.first_img_url) {
        this.first_img =
          typeof this.getDetailAsset.asset.first_img_url === "string"
            ? JSON.parse(this.getDetailAsset.asset.first_img_url)
            : this.getDetailAsset.asset.first_img_url;
        this.img_date.regis_date = moment(this.first_img.date).format(
          "DD MMM YYYY HH:mm"
        );
      }
      if (this.getDetailAsset.asset.image_url) {
        this.current_img =
          typeof this.getDetailAsset.asset.image_url === "string"
            ? JSON.parse(this.getDetailAsset.asset.image_url)
            : this.getDetailAsset.asset.image_url;

        this.img_date.now_date = moment(this.current_img.date).format(
          "DD MMM YYYY HH:mm"
        );
      }
      this.isLoading = false;
    },
  },

  created() {},
  mounted() {
    this.loadData();
    console.log("getMapPosition", this.getMapPosition);
  },
};
</script>

<style lang="scss" scoped>
// .dot-style {
//   padding-bottom: 2 rem;
// }
.btn {
  font-size: 18px;
}
.btn-primary {
  width: 40%;
  margin-left: 1rem;
}
.btn-secondary {
  background: $font-grey;
  width: 40%;
}
.modal-body {
  padding: 0 !important;
}
.comment-textarea {
  background-color: #e0e0e0;
  border: none;
  border-radius: 10px 10px 10px 10px !important;
  padding: 10px;
}
.status_label {
  padding: 0.5rem;
  border-radius: 11px;
}

.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  height: 40vh;
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    padding: 0.4rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.indoor-map {
  width: 100%;
  // height: 300px;
  // background: rgb(225, 225, 225);
  background-color: white;
  border-radius: 0.75rem;
}
.indoor-map {
  margin-bottom: 0.5rem;
}
.indoor-map-none {
  border-radius: 12px;
  border: 1px solid #bcbaba;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://static.verscan.com/verscan-v1/images/default-floor-image.jpeg");
  background-size: cover;
  p {
    opacity: 1;
    font-size: 1.25rem;
    box-shadow: 0 0 black;
    background: white;
  }
}
.frame-profile {
  // position: absolute;
  // top: -0.2rem;
  left: 50%;
  right: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  /* text-align: center; */
  margin: auto;
  // background: rgb(223, 223, 223);
  // border-radius: 5%;
  flex-direction: column;
  z-index: 90;
}

.banner {
  height: 100%;
  width: 100%;
  border-radius: 10%;
  z-index: 1;
  object-fit: cover;
  border: black 1px solid;
}

.asset-imgcard {
  width: 100%;
  max-width: 250px;
  .card {
    border: transparent !important;
  }
  .card-body {
    padding: 1rem 0;
  }
  .card-img-top {
    height: 100px;
    @media only screen and (min-width: 375px) {
      height: 125px;
    }
    @media only screen and (min-width: 640px) {
      height: 220px;
    }
  }
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

// .card-form {
//   margin-top: 2rem;
//   width: 90%;
//   padding: 2rem 1rem 2rem 1rem;
// }

.sub-label {
  font-size: 18px;
  padding: 0 0 10px 0;
  color: black;
}

#edit-menu {
  &:focus {
    outline: none;
  }

  ::v-deep button {
    &:focus {
      outline: none;
    }
  }
  ::v-deep .btn {
    background: none;
    width: fit-content;
    display: block;
    padding: 0;
    min-width: 0px;
  }
  ::v-deep .dropdown-toggle::after {
    display: none;
  }
  ::v-deep .dropdown-item:active {
    background-color: #e9ecef;
  }

  .menu-item {
    color: #015fc3;
    margin: 0;
  }
}

.DetailAsset {
  position: relative;
  z-index: 2;
}

.card-form-login {
  margin-left: 5%;
  margin-right: 5%;
}

.asset-all-info {
  .info-box {
    padding: 20px 0;
    border-bottom: 1px solid #c4c4c4;
    &:first-child {
      border-top: 1px solid #c4c4c4;
    }
  }

  .info-header {
    font-size: 18px;
    color: #000;
    margin: 5px 0;
  }
  .info-label {
    font-size: 18px;
    color: #000;
  }
  .info-text {
    font-size: 16px;
    color: #7c7c7c;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    // margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.see-more:hover {
  opacity: 80%;
}

.btn-confirm {
  min-width: 80px;
  height: 40px;
  padding: 0px;
  border-radius: 10px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
}

.btn-disabled {
  min-width: 80px;
  height: 40px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background-color: #7c7c7c;
}
</style>
