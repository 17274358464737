<template>
  <div v-if="isCustom">
    <!-- delete counting day -->
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div v-if="permissionStatus.company.custom_id === 1">
      <div
        class="modal fade"
        id="deletePreModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content container-fluid pt-4 pb-2"
            style="border-radius: 10px"
          >
            <div class="row">
              <p>
                {{ $t("dialog.click") }} <b>{{ $t("btn.conf") }}</b>
                {{ $t("dialog.to_del") }} <br />
                {{ $t("history.countingdate") }} <br />
                {{
                  selectedCounting &&
                    selectedCounting.counting_date | formatDate
                }}
              </p>
            </div>
            <div class="">
              <button type="button" class="btn cancel col" data-dismiss="modal">
                {{ $t("btn.canc") }}
              </button>
              <button
                type="button"
                class="btn btn-login col"
                data-dismiss="modal"
                @click="confirmDeleteDate()"
              >
                {{ $t("btn.conf") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3">
        <p class="text-line">
          <span>{{ $t("history.pre_counting_topic") }} </span>
        </p>
      </div>
      <div v-if="preCountingList.length > 0">
        <div
          class="card-form card-form-login mt-2 text-center"
          v-for="counting in preCountingList"
          :key="counting.id_pre_counting"
        >
          <!-- Counting Day Log -->
          <div class="d-flex date">
            <div class="d-flex w-100 ml-3 align-items-center p-3">
              <div style="cursor: default; width: 40%">
                <img src="../../assets/cal.svg" />
              </div>

              <div class="content content-detail">
                <div style="cursor: default;">
                  <h6>
                    {{ $t("history.counting") }}
                    <strong>{{ counting.counting_date | formatDate }}</strong>
                  </h6>
                </div>
              </div>
            </div>
            <div
              style="width: fit-content; cursor: pointer;"
              class="d-flex align-items-center"
            >
              <b-dropdown
                class="mt-3 mb-4"
                id="edit-menu"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropright
                v-if="
                  (permissionStatus.company.custom_id == 1 &&
                    permissionStatus.permission.role.id_role == 3) ||
                    permissionStatus.permission.role.id_role === 1 ||
                    permissionStatus.permission.role.id_role === 2
                "
              >
                <template #button-content>
                  <img src="../../assets/more-detail.svg" alt="" />
                </template>
                <b-dropdown-item
                  @click="startCountingDate(counting)"
                  :disabled="disableStartNewCounting(counting.counting_date)"
                  ><div class="d-flex align-items-center">
                    <img
                      v-if="disableStartNewCounting(counting.counting_date)"
                      class="me-2"
                      src="../../assets/start-counting-disabled.svg"
                      alt=""
                    />
                    <img
                      v-else
                      class="me-2"
                      src="../../assets/start-counting.svg"
                      alt=""
                    />
                    <p class="menu-item">{{ $t("history.start_counting") }}</p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  data-toggle="modal"
                  data-target="#deletePreModal"
                  @click="setSelectedCounting(counting)"
                  ><div class="d-flex align-items-center">
                    <img
                      class="me-2"
                      src="../../assets/delete_bin.svg"
                      alt=""
                    />
                    <p class="menu-item" style="color: #ED1616;">
                      {{ $t("btn.del_all") }}
                    </p>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-form card-form-login mt-2 text-center">
        <div
          style="height: 100px; flex-direction: column; font-size: 16px"
          class="d-flex justify-content-around align-items-center"
        >
          <b-icon-calendar4 scale="1.5" />
          {{ $t("history.no_date") }}
        </div>
      </div>
      <div
        class="pt-4"
        v-if="
          (this.permissionStatus.company.custom_id == 1 &&
            this.permissionStatus.permission.role.id_role == 3) ||
            this.permissionStatus.permission.role.id_role === 1 ||
            this.permissionStatus.permission.role.id_role === 2
        "
      >
        <button
          class="btn-regist btn-auto px-3"
          style="font-size: 14px; border:2px solid;"
          type="submit"
          v-on:click="gotoAddPreDate()"
        >
          {{ $t("history.add_pre_date") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { baseUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";
import { mapGetters } from "vuex";
export default {
  props: ["currentOpen"],
  components: { Loading },
  data() {
    return {
      isLoading: false,
      id_company: null,
      preCountingList: [],
      selectedCounting: null,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
  },
  methods: {
    disableStartNewCounting(date) {
      if (this.currentOpen || moment(date).isBefore(new Date(), "day")) {
        return true;
      } else {
        return false;
      }
    },
    gotoAddPreDate() {
      this.$router.push({ path: "/adddate", query: { page: "preadd" } });
    },
    async getPreCountingDate() {
      this.isLoading = true;
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}dates/getPreCountingDate?id_company=${this.id_company}`,
          authHeader()
        );
        this.preCountingList = res.data.result;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },

    setSelectedCounting(data) {
      this.selectedCounting = data;
    },
    async startCountingDate(data) {
      this.isLoading = true;
      if (moment(data.counting_date).isBefore(new Date(), "day")) {
        this.$router.go();
      } else {
        try {
          await this.$store.dispatch("addCountingDate", {
            id_company: this.id_company, //user.id_company
            counting_date: data.counting_date,
          });
          await axios.post(
            `${baseUrl()}dates/startPreCountingDate`,
            {
              id_company: this.id_company,
              id_pre_counting: data.id_pre_counting,
            },
            authHeader()
          );
          this.setSelectedCounting(null);
          await Promise.all([
            this.getPreCountingDate(),
            this.$store.dispatch("getAllCountingDateByIdCompany"),
          ]);
        } catch (err) {
          console.log(err);
        }
      }
      this.isLoading = false;
    },
    async confirmDeleteDate() {
      this.isLoading = true;
      const res = await axios.delete(
        `${baseUrl()}dates/deletePreCountingDate`,
        {
          ...authHeader(),
          data: {
            id_company: this.id_company,
            id_pre_counting: this.selectedCounting.id_pre_counting,
          },
        }
      );
      await this.getPreCountingDate();
      this.isLoading = false;
    },
  },
  mounted() {
    this.getPreCountingDate();
  },
};
</script>

<style lang="scss" scoped>
.card-form {
  margin: auto;
  width: 80%;
  padding-bottom: 4rem;
}
.card-form {
  cursor: pointer;
  display: grid;
  width: 80%;
  gap: 1.2rem;
  padding: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: normal normal normal 11px/18px Mitr;
  text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  img {
    width: 30px;
    height: 30px;
  }
  h1 {
    font: normal normal normal 20px/18px Mitr;
  }
  &-custom {
    padding: 2rem 1.2rem 2rem 1.2rem;

    h1 {
      font: normal normal normal 27px/18px Mitr;
    }
  }
}

.cancel {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

#edit-menu {
  &:focus {
    outline: none;
  }

  ::v-deep button {
    *:focus {
      outline: none;
    }
  }
  ::v-deep .btn {
    background: none;
    width: fit-content;
    display: block;
    padding: 0;
    min-width: 0px;
  }
  ::v-deep .dropdown-toggle::after {
    display: none;
  }
  ::v-deep .dropdown-item:active {
    background-color: #e9ecef;
  }

  img {
    width: 15px;
  }

  .menu-item {
    margin: 0;
  }
}
</style>
