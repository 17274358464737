import { UserRoles } from "@/constants/UserRoles";

export const getters = {
  haveMaintenanceNotification(state) {
    if (state.notification.pending) {
      return true;
    } else {
      return false;
    }
  },
  setStatusFromDashboard(state) {
    return state.statusFromDashboard;
  },
  setDashboardData(state) {
    return state.dashboardData;
  },
  setCountAll(state) {
    return state.countAll;
  },
  statusSummary(state) {
    return state.statusSummary;
  },
  getComfirmModalSuccess(state) {
    return state.confirmModalSuccess;
  },
  getManageOptions(state) {
    return state.manageOptions;
  },
  maintenanceRole(state) {
    if (
      state.maintenanceRole === UserRoles.EXCLUSIVE ||
      state.maintenanceRole === UserRoles.ACC_MANAGER ||
      state.maintenanceRole === UserRoles.ACC_OFFICER
    ) {
      state.maintenanceRole = "admin";
    } else if (state.maintenanceRole === UserRoles.LOW_TECHNICIAN) {
      state.maintenanceRole = "low-technician";
    } else if (state.maintenanceRole === UserRoles.TECHNICIAN) {
      state.maintenanceRole = "high-technician";
    } else {
      state.maintenanceRole = "user";
    }
    return state.maintenanceRole;
  },
  getMaintenanceNotification(state, _, rootState) {
    if (
      [
        UserRoles.TECHNICIAN,
        UserRoles.LOW_TECHNICIAN,
        UserRoles.ACC_MANAGER,
      ].includes(rootState.permissionStatus.permission.id_role)
    ) {
      return state.notification;
    } else {
      return {};
    }
  },
};
