<template>
  <div class="responsive-bar">
    <NavMenu></NavMenu>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <div>
      <div class="back-icon" @click="goToAssetDetail">
        <img src="../assets/previous.svg" width="30" />
      </div>
      <div class="header-text">
        <div class="topic" style="">
          <h1>{{ $t("asset.log_topic") }}</h1>
          <h2>{{ $t("asset.as_num") }}: {{ assetId }}</h2>
        </div>
      </div>
      <div class="log-section">
        <div class="d-flex justify-content-between mb-3">
          <p class="count">
            {{
              $t("asset.log_amount", {
                amount: assetLog.length,
              })
            }}
          </p>
          <p class="more-log" @click="goToLogTable">
            {{ $t("asset.more_log") }}
          </p>
        </div>
        <div
          class="log-container"
          v-for="log in assetLog"
          :key="log.log_asset_id"
        >
          <p class="log-detail">
            {{ formatDate(log.created_at) }}, {{ $t("asset.edit_by") }}
            {{ log.user.name }},
            <span v-if="log.type === 'ลงทะเบียน'">{{
              $t("registeritem.regist")
            }}</span>
            <span v-if="log.type === 'แก้ไข'">
              <span v-for="(edit, i) in log.edit" :key="i"
                >{{ translateKeyToText(edit) }}
                <!-- <span v-if="i < log.edit.length - 1">, </span> -->
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import baseUrl from "../util/backend";
import axios from "axios";
import { authHeader } from "../store/actions";
export default {
  components: { NavMenu, Loading },
  data() {
    return {
      isLoading: false,
      assetLog: null,
      assetId: "",
      otherColumn: [],
      perPage: 5,
      currentPage: 1,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
    translateKeyToText(key) {
      // const customKey = this.otherColumn.some((x) => {
      //   console.log(x.name === key);
      //   return x.name === key;
      // });
      // if (customKey) {
      //   return key;
      // }
      switch (key) {
        case "id_fixed_asset":
          return `${this.$t("asset.edit")}${this.$t("home.fixedas_num")}`;
        case "name":
          return `${this.$t("asset.edit")}${this.$t("home.as_name")}`;
        case "category":
          return `${this.$t("asset.edit")}${this.$t("home.cat")}`;
        case "sub_category":
          return `${this.$t("asset.edit")}${this.$t("home.sub_cat")}`;
        case "description":
          return `${this.$t("asset.edit")}${this.$t("home.as_dt")}`;
        case "description2":
          return `${this.$t("asset.edit")}${this.$t("home.more_description")}`;
        case "location_building":
          return `${this.$t("asset.edit")}${this.$t("home.build")}`;
        case "location_floor":
          return `${this.$t("asset.edit")}${this.$t("home.fl")}`;
        case "location_room":
          return `${this.$t("asset.edit")}${this.$t("home.room")}`;
        case "first_img_url":
          return `${this.$t("asset.edit")}${this.$t("detailasset.img_reg")}`;
        case "image_url":
          return `${this.$t("asset.edit")}${this.$t("home.image")}`;
        case "location_department":
          return `${this.$t("asset.edit")}${this.$t("home.department")}`;
        case "location_zone":
          return `${this.$t("asset.edit")}${this.$t("home.zone")}`;
        case "selected_location":
          return `${this.$t("asset.edit")}${this.$t("home.as_loc")}`;
        case "note":
          return `${this.$t("asset.edit")}${this.$t("home.note")}`;
        case "purchase_date":
          return `${this.$t("asset.edit")}${this.$t("asset.purchase_date")}`;
        case "purchase_price":
          return `${this.$t("asset.edit")}${this.$t("asset.purchase_price")}`;
        case "useful_life":
          return `${this.$t("asset.edit")}${this.$t("asset.useful_life")}`;
        case "start_date":
          return `${this.$t("asset.edit")}${this.$t("asset.start_date")}`;
        case "acc_depreciation":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_depreciation")}`;
        case "net_book":
          return `${this.$t("asset.edit")}${this.$t("asset.net_book")}`;
        case "qrcode":
          return `${this.$t("asset.edit")}QR Code`;
        case "vendor":
          return `${this.$t("asset.edit")}${this.$t("asset.vendor")}`;
        case "custom_start_time":
          return `${this.$t("asset.edit")}${this.$t("asset.insurance_start")}`;
        case "custom_end_time":
          return `${this.$t("asset.edit")}${this.$t("asset.insurance_end")}`;
        case "sector":
          return `${this.$t("asset.edit")}${this.$t("asset.region_province")}`;
        case "branch":
          return `${this.$t("asset.edit")}${this.$t("asset.branch")}`;
        case "land_status":
          return `${this.$t("asset.edit")}${this.$t(
            "depreciation.land_status"
          )}`;
        case "note_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_note")}`;
        case "code_asset":
          return `${this.$t("asset.edit")}${this.$t("asset.asset_acc_code")}`;
        case "name_asset":
          return `${this.$t("asset.edit")}${this.$t("asset.asset_acc_name")}`;
        case "code_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_code")}`;
        case "name_acc":
          return `${this.$t("asset.edit")}${this.$t("asset.acc_name")}`;
        case "code_acc_dp":
          return `${this.$t("asset.edit")}${this.$t(
            "asset.accumulate_dep_acc_code"
          )}`;
        case "name_acc_dp":
          return `${this.$t("asset.edit")}${this.$t(
            "asset.accumulate_dep_acc_name"
          )}`;
        case "":
          return;
        default:
          // for other_column
          return `${this.$t("asset.edit")}${key}`;
      }
    },
    goToAssetDetail() {
      // this.$router.push(`/detailOneAsset/${this.$route.params.id_asset}`);
      this.$router.back();
    },
    goToLogTable() {
      this.$router.push(`/assetlogdetail/${this.$route.params.id_asset}`);
    },
    async getLog() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getlog/${id_company}?id_asset=${
            this.$route.params.id_asset
          }&limit=${this.perPage}&page=${this.currentPage}`,
          authHeader()
        );
        let logArr = res.data.data;
        this.otherColumn = res.data.other_column;
        this.assetId = res.data.asset.id_fixed_asset;
        console.log("logArr", logArr);
        if (logArr.length > 5) {
          logArr = logArr.slice(0, 5);
        }
        for (const log of logArr) {
          const uniqueEdit = [];
          for (const edit of log.edit) {
            if (edit === "location_mapX" || edit === "location_mapY") {
              uniqueEdit.push("selected_location");
            } else {
              uniqueEdit.push(edit);
            }
          }
          log.edit = [...new Set(uniqueEdit)];
        }
        this.assetLog = logArr;
      } catch (err) {
        if (err.response) {
          alert(err.response.message);
        } else {
          alert(err);
        }
      }
    },
  },
  created() {
    this.getLog();
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.topic {
  padding-top: 2rem;
}

.count {
  font-size: 14px;
  color: #888888;
}

.more-log {
  font-size: 14px;
  color: #007afe;
  font-weight: 700;
  cursor: pointer;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
.header-text {
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 14px;
  }
}

.log-section {
  width: 85%;
  text-align: left;
  margin: 20px auto 0 auto;
  .log-container {
    border-bottom: 1px solid #c4c4c4;
    padding: 10px 0px;
  }
  .log-detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
  }
}
</style>
