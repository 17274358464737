<template>
  <div class="scan-qr">
    <Background></Background>
    <div class="card-div">
      <h1 class="header-text">
        <div class="topic">{{ $t("password.change_pw") }}</div>
      </h1>

      <div class="card-form-login">
        <!-- <button @click="switchLocale">กดเปลี่ยนภาษาฉันสิ</button> <br /><br /> -->
        <div class="input-group">
          <label
            >{{ $t("password.new_pw") }}<span class="require-red">*</span>
          </label>
          <div class="position-relative">
            <input
              :type="showNewPassword ? 'text' : 'password'"
              v-model="newPassword"
              minlength="8"
            />
            <img
              v-if="showNewPassword"
              @click="showNewPassword = !showNewPassword"
              src="../assets/showpassword.png"
              alt="showpassword"
              class="password-toggler"
            />
            <img
              v-else
              @click="showNewPassword = !showNewPassword"
              src="../assets/hidepassword.png"
              alt="showpassword"
              class="password-toggler"
            />
          </div>
          <span class="sub-text">{{ $t("password.pass8") }}</span>
        </div>
        <div class="input-group">
          <label
            >{{ $t("password.conf_new_pw") }}<span class="require-red">*</span>
          </label>
          <div class="position-relative">
            <input
              :type="showConfirmPassword ? 'text' : 'password'"
              v-model="checkPassword"
              minlength="8"
            />
            <img
              v-if="showConfirmPassword"
              @click="showConfirmPassword = !showConfirmPassword"
              src="../assets/showpassword.png"
              alt="showpassword"
              class="password-toggler"
            />
            <img
              v-else
              @click="showConfirmPassword = !showConfirmPassword"
              src="../assets/hidepassword.png"
              alt="showpassword"
              class="password-toggler"
            />
          </div>
        </div>
        <button
          class="btn btn-email btn-auto text-center"
          type="button"
          @click="changePassword()"
        >
          {{ $t("btn.ch_pw") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
import Background from "@/components/Background.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      customName: null,
      showNewPassword: false,
      showConfirmPassword: false,
      data: {
        id: "",
        password: "",
      },
      newPassword: "",
      checkPassword: "",
      error: "",
    };
  },
  components: { Background },
 
  mounted() {
    this.customName = this.$route.query.company;
    this.data.id = this.$route.params.id;
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "th") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "th";
      }
    },
    async changePassword() {
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
      // const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
      // Minimum eight characters, at least one letter and one number:
      const strongPassword = new RegExp("(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})");
      if (this.newPassword == "" && this.checkPassword == "") {
        alert(this.$t("dialog.input_er"));
      } else if (!strongPassword.test(this.newPassword)) {
        alert(this.$t("dialog.pass8"));
      } else if (this.newPassword !== this.checkPassword) {
        alert(this.$t("dialog.incorrect_pw"));
      } else {
        console.log("1st password: ", this.newPassword);
        console.log("2nd password: ", this.checkPassword);
        this.data.password = this.newPassword;
        try {
          await this.$store.dispatch("changePasswordByEmail", {
            ...this.data,
            customName: this.customName,
          });
          Swal.fire({
            icon: "success",
            title: this.$t("dialog.success"),
            text: this.$t("dialog.di_pw"),
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.replace("/login");
        } catch (error) {
          console.error(error)
          Swal.fire({
            icon: "error",
            title: this.$t("dialog.error"),
            text: this.$t("dialog.di_pw_fail"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

    },
    // onDecode(decodedString) {
    //   console.log(`decodedString`, decodedString);
    //   this.$router.push(`/check/${decodedString}`);
    // },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-text {
  margin-top: 2px;
  text-align: left;
  font: normal normal normal 11px/18px Mitr;
  letter-spacing: 0px;
  color: #7c7c7c;
  opacity: 1;
  margin-right: auto;
}
.input-group {
  margin-bottom: 0.1rem;
  input {
    background-color: #e0e0e0;
    border-radius: 10px !important;
    width: 100%;
  }
  label {
    padding-bottom: 0.5rem;
  }
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-email {
  margin-top: 1rem;
  background: transparent linear-gradient(111deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.sacn-qr {
  z-index: 100;
}

.detail {
  padding-top: 1rem;

  font-size: 11px;
  color: #7c7c7c;
}

.header-text {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card-form-login {
  // display:flex;
  // margin: 0 auto;
  // width: 60%;
  height: auto;
  padding-bottom: 2.5rem;
}

.banner {
  width: 40px;
  z-index: 10;
}

.box {
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-repeat: no-repeat;
}
.box-e {
  // Cornered

  background-image: linear-gradient(
      0deg,
      var(--clr-border),
      var(--clr-border) 25%,
      transparent 25%,
      transparent 75%,
      var(--clr-border) 75%
    ),
    // left
      linear-gradient(
        90deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      ),
    // top
      linear-gradient(
        180deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      ),
    // right
      linear-gradient(
        270deg,
        var(--clr-border),
        var(--clr-border) 25%,
        transparent 25%,
        transparent 75%,
        var(--clr-border) 75%
      );
  // bottom;
}

@media only screen and (max-width: 425px) {
  .card-form-login {
    // width: 90%;
    height: auto;
    padding: 1rem;
    padding-bottom: 2rem;
  }
}
@media only screen and (min-width: 426px) and (max-width: 2560px) {
  .card-form-login {
    // width: 90%;
    height: auto;
    width: 500px;
    margin: 20px auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}
</style>
